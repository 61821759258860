import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import MultiSelect from '../../../../components/MultiSelect'
import api from '../../../../services/abtesttool/api'
import ProductTable from './ProductTable'
import DisplayTable from './table'

const CancelToken = Axios.CancelToken

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	gridWrapper: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: theme.spacing(0, 3),
		maxWidth: 977,
	},
	textField: {
		// maxWidth: 400,
		margin: `${theme.spacing(1)}px auto`,
		padding: theme.spacing(2),
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paperTitle: {
		fontWeight: 'bold',
	},
}))

function StepTwo(props) {
	const { values, errors, touched, handleBlur, handleChange, disabled } = props
	const classes = useStyles()
	const searchBoxTimeout = useRef(undefined)
	const cancelDashLstReq = useRef(null)
	const [distriSrLoad, setdistriSrLoad] = useState(false)
	const [data, setData] = useState({
		brand: [],
		brand_list: [],
		country_list: [],
		retailer_list: [],
	})
	const [searchkey, setSearchkey] = useState('')

	const { brand, brand_list, country_list, retailer_list } = data

	useEffect(() => {
		if (values.test_level === 'brand' || values.test_level === 'product' || values.test_level === 'retailer_brand') {
			api
				.fetchBrands(
					new CancelToken((exec) => {
						cancelDashLstReq.current = exec
					})
				)
				.then((res) => {
					let brands = _.map(res.data.data.brand, (brand) => ({
						id: brand.brand_id,
						name: brand.brand_title,
					}))
					setData((prevState) => ({ ...prevState, brand_list: brands }))
				})
		}
		if (values.test_level !== 'brand') {
			api
				.fetchCountries(
					new CancelToken((exec) => {
						cancelDashLstReq.current = exec
					})
				)
				.then((res) => {
					let countries = _.map(res.data.data.country, (country) => ({
						id: country.country,
						name: country.country,
					}))
					setData((prevState) => ({ ...prevState, country_list: countries }))
				})
		}
		// console.log('calling...')
	}, [values.test_level])
	useEffect(() => {
		if (values.test_level !== 'brand') {
			let pay_values = {
				search_text: searchkey,
				filters: [
					{
						column: 'country',
						includes: values.country !== [] ? _.map(values.country, (country) => country.id) : [],
						type: 'str',
						all: false,
					},
				],
				all: values.country !== [] ? false : true,
			}
			setdistriSrLoad(true)
			api.fetchRetailers(
				pay_values,
				(res) => {
					let retailers = _.map(res.data.data.retailers, (retailer) => ({
						id: retailer.retailer_id,
						name: retailer.retailer_name,
					}))
					setData((prevState) => ({ ...prevState, retailer_list: retailers }))
					setdistriSrLoad(false)
				},
				(err) => {
					setdistriSrLoad(false)
				}
			)
		}
		// console.log('calling...')
	}, [values.country, values.test_level, searchkey])

	const onInputSRChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					// let product_id = e.target.value
					// setProdSearch(e.target.value)
					setSearchkey(e.target.value)
				}, 1500)
			}
		}
	}

	return (
		<Box className={classes.contentWrapper} component='div'>
			<Grid container spacing={2} className={classes.gridWrapper}>
				<Typography className={classes.title} variant='h6'>
					Scope:
				</Typography>
				<Grid item xs={12} lg={12}>
					<FormControl component='fieldset' disabled={disabled}>
						<RadioGroup row aria-label='position' {...props} name='test_level' value={values.test_level} onChange={handleChange}>
							<FormControlLabel value='brand' control={<Radio size='small' color='primary' />} label='Brand Level' />
							<FormControlLabel value='product' control={<Radio size='small' color='primary' />} label='Product Level' />
							<FormControlLabel value='retailer' control={<Radio size='small' color='primary' />} label='Retailer Level' />
							<FormControlLabel value='retailer_brand' control={<Radio size='small' color='primary' />} label='Retailer & Brand Level' />
							<FormControlLabel value='retailer_product' control={<Radio size='small' color='primary' />} label='Retailer & Product Level' />
						</RadioGroup>
					</FormControl>
				</Grid>
				{(values.test_level === 'brand' || values.test_level === 'product') && (
					<Grid item xs={12} lg={6} className={classes.textField}>
						<MultiSelect
							items={brand_list || []}
							// getOptionDisabled={getOptionDisabled}
							label='Brand'
							// placeholder='Placeholder for textbox'
							name='brand'
							selectAllLabel=''
							onChange={(e) =>
								handleChange({
									target: { name: 'brand', value: e },
								})
							}
							value={values?.brand}
							onBlur={handleBlur}
							error={errors?.brand && touched.brand}
							helperText={errors?.brand && touched.brand && errors?.brand}
							disabled={disabled}
						/>
					</Grid>
				)}
				{values.test_level === 'retailer_brand' && (
					<>
						<Grid item xs={12} lg={6} className={classes.textField}>
							<MultiSelect
								items={country_list || []}
								// getOptionDisabled={getOptionDisabled}
								label='Country'
								// placeholder='Placeholder for textbox'
								name='country'
								selectAllLabel=''
								onChange={(e) =>
									handleChange({
										target: { name: 'country', value: e },
									})
								}
								value={values?.country}
								onBlur={handleBlur}
								error={errors?.country && touched.country}
								helperText={errors?.country && touched.country && errors?.country}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} lg={6} className={classes.textField}>
							<MultiSelect
								items={retailer_list || []}
								// getOptionDisabled={getOptionDisabled}
								label='Retailer'
								// placeholder='Placeholder for textbox'
								name='retailer'
								selectAllLabel=''
								onChange={(e) =>
									handleChange({
										target: { name: 'retailer', value: e },
									})
								}
								isLoading={distriSrLoad}
								onInputChange={(e) => onInputSRChange(e)}
								value={values?.retailer}
								onBlur={handleBlur}
								error={errors?.retailer && touched.retailer}
								helperText={errors?.retailer && touched.retailer && errors?.retailer}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} lg={6} className={classes.textField}>
							<MultiSelect
								items={brand_list || []}
								// getOptionDisabled={getOptionDisabled}
								label='Brand'
								// placeholder='Placeholder for textbox'
								name='brand'
								selectAllLabel=''
								onChange={(e) =>
									handleChange({
										target: { name: 'brand', value: e },
									})
								}
								value={values?.brand}
								onBlur={handleBlur}
								error={errors?.brand && touched.brand}
								helperText={errors?.brand && touched.brand && errors?.brand}
								disabled={disabled}
							/>
						</Grid>
					</>
				)}
				{(values.test_level === 'product' || values.test_level === 'retailer' || values.test_level === 'retailer_product') && (
					<>
						<Grid item xs={12} lg={6} className={classes.textField}>
							<MultiSelect
								items={country_list || []}
								// getOptionDisabled={getOptionDisabled}
								label='Country'
								// placeholder='Placeholder for textbox'
								name='country'
								selectAllLabel=''
								onChange={(e) =>
									handleChange({
										target: { name: 'country', value: e },
									})
								}
								value={values?.country}
								onBlur={handleBlur}
								error={errors?.country && touched.country}
								helperText={errors?.country && touched.country && errors?.country}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} lg={6} className={classes.textField}>
							<MultiSelect
								items={retailer_list || []}
								// getOptionDisabled={getOptionDisabled}
								label='Retailer'
								// placeholder='Placeholder for textbox'
								name='retailer'
								selectAllLabel=''
								onChange={(e) =>
									handleChange({
										target: { name: 'retailer', value: e },
									})
								}
								isLoading={distriSrLoad}
								onInputChange={(e) => onInputSRChange(e)}
								value={values?.retailer}
								onBlur={handleBlur}
								error={errors?.retailer && touched.retailer}
								helperText={errors?.retailer && touched.retailer && errors?.retailer}
								disabled={disabled}
							/>
						</Grid>
					</>
				)}
				{(values.test_level === 'product' || values.test_level === 'retailer_product') && (
					<>
						{!disabled && (
							<>
								<Grid item xs={12} lg={12} style={{ marginTop: '30px', textAlign: 'left' }}>
									<Typography className={classes.paperTitle} variant='h6'>
										Select available products
									</Typography>
								</Grid>
								<Grid item xs={12} lg={12}>
									<ProductTable values={values} handleChange={handleChange} />
								</Grid>
							</>
						)}
						{disabled && (
							<Grid item xs={12} lg={12}>
								<DisplayTable tableList={values.product} />
								{/* <MaterialTable values={values} handleChange={handleChange} /> */}
							</Grid>
						)}
					</>
				)}
			</Grid>
		</Box>
	)
}

export default StepTwo
