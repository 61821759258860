import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormLabel,
	TextField,
	Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import FormDialog from './FormDialog'
import { isAxiosCancelError } from '../../../utils'
import { fetchAllUsers } from '../../../services/users/actions'
import UsersApi from '../../../services/users/api'
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withCellRenderState, ActionCellRenderer, RoleCellRenderer, NameCellRenderer, StatusCellRenderer, EmailCellRenderer } from './CustomCells'
import AgGridCustom from '../../../components/AgGridCustom'
import { PAGE_MODES } from '../.././../constants'
import ListPageFilterSection from '../../../components/ListPageFilterSection'
import SpecialPermDialog from './SpecialPermDialog'
import DeleteDialog from './DeleteDialog'
import { PERMISSION_TYPE } from '../../../constants/modules'

const USER_STATUS_FILTER = {
	ALL: 'all',
	ACTIVE: 'active',
	INACTIVE: 'inactive',
}

const getUsrStatusFilterVal = (usrStatusVal) => {
	switch (usrStatusVal) {
		case USER_STATUS_FILTER.ALL:
			return undefined
		case USER_STATUS_FILTER.ACTIVE:
			return true
		case USER_STATUS_FILTER.INACTIVE:
			return false
		default:
			return undefined
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	switchLabel: {
		fontSize: '14px',
	},
	radioFormLabel: {
		fontSize: '15px',
		marginBottom: theme.spacing(0.5),
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	statusCellRoot: {
		verticalAlign: 'middle',
		'& span': {
			width: '8px',
			height: '8px',
			display: 'inline-block',
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		},
	},
	emailCellRoot: {
		verticalAlign: 'middle',
		'& > span': {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(1),
			fontSize: '18px',
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
}))

const Users = ({ width, action, userId, history, perms }) => {
	const initialState = {
		loading: true,
		searchText: '',
		offset: 0,
		limit: 10,
		roleFilter: [],
		usrStatusFilter: USER_STATUS_FILTER.ALL,
		totalCount: 0,
		userList: [],
	}
	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		searchText: '',
		offset: 0,
		limit: 10,
		roleFilter: [],
		usrStatusFilter: USER_STATUS_FILTER.ALL,
		totalCount: 0,
		userList: [],
	})
	const [roleListState, setRoleListState] = useState({
		isFetchRoles: true,
		roleList: null,
	})
	const [currentUser, setCurrentUser] = useState('All')
	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		selectedData: null,
	})
	const { open, anchorEl, selectedData } = popOverState

	const isCreateAllowed = _.get(perms, PERMISSION_TYPE.CREATE, false)
	const isEditAllowed = _.get(perms, PERMISSION_TYPE.EDIT, false)
	// const isDeleteAllowed = _.get(perms, PERMISSION_TYPE.DELETE, false)

	const [openSpecPermDialog, setOpenSpecPermDialog] = useState(false)
	const [openDelDialog, setOpenDelDialog] = useState(false)

	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const { searchText, loading, offset, totalCount, limit, roleFilter, usrStatusFilter, userList } = usersTblState
	const { isFetchRoles, roleList } = roleListState

	const isMounted = useRef(true)
	const searchBoxTimeout = useRef(undefined)
	const dispatch = useDispatch()

	const openFormDialog = !_.isEmpty(action)
	const handleCloseFormDialog = (e, isRefreshList) => {
		history.replace({
			pathname: `/usermanagement/users`,
			isRefreshList: _.isEqual(isRefreshList, true),
		})
	}

	const classes = useStyles()

	const closePopover = () => {
		setPopOverState({ ...popOverState, open: false })
	}

	const closeSpecPermDialog = () => {
		setOpenSpecPermDialog(false)
	}

	const closeDelDialog = (e, isRefreshList) => {
		setOpenDelDialog(false)
		history.replace({
			pathname: `/usermanagement/users`,
			isRefreshList: _.isEqual(isRefreshList, true),
		})
	}

	const onClickMoreOptions = useCallback((event, data) => {
		setPopOverState({
			open: true,
			anchorEl: event.currentTarget,
			selectedData: data,
		})
	}, [])

	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		return [
			{
				headerName: 'Name',
				field: 'first_name',
				cellRenderer: 'nameCellRenderer',
				cellRendererParams: {
					containerClass: nameCellContent,
					authToken: authToken,
					isRenderMobView: _.isEqual('xs', width),
				},
				sortable: true,
			},
			{
				headerName: 'Roles',
				field: 'role_id',
				cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Email Address',
				field: 'email',
				cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'User Status',
				field: 'is_active',
				cellRenderer: 'statusCellRenderer',
				cellRendererParams: { containerClass: statusCellRoot },
				maxWidth: 180,
				hide: !isWidthUp('lg', width),
			},
			{
				headerName: '',
				field: '',
				maxWidth: 80,
				cellRenderer: 'actionCellRenderer',
				cellRendererParams: {
					containerClass: actionCellContainer,
					onClickMoreOptions,
				},
			},
		]
	}, [width, classes, authToken, onClickMoreOptions, roleList])

	const onRowClicked = (e) => {
		const isSuppressed = e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection
		if (!isSuppressed) openExistingUser(_.get(e, 'data.user_id'), PAGE_MODES.VIEW)
	}

	const addBtnClick = () => {
		history.replace({ pathname: `/usermanagement/users/create` })
	}

	const resetBtn = () => {
		setUsersTblState(initialState)
	}

	const onClickEditMenu = () => {
		closePopover()
		changeToEditMode(_.get(selectedData, 'user_id'))
	}

	const onClickPermMenu = () => {
		closePopover()
		setOpenSpecPermDialog(true)
	}

	// const onClickDelMenu = () => {
	// 	closePopover()
	// 	setOpenDelDialog(true)
	// }

	const changeToEditMode = (userId) => {
		history.replace({
			pathname: `/usermanagement/users/${PAGE_MODES.EDIT}/${userId}`,
		})
	}

	const openExistingUser = (userId, action) => {
		history.replace({ pathname: `/usermanagement/users/${action}/${userId}` })
	}

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const onChangeSearchBox = (e) => {
		const searchText = e.target.value
		if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
		searchBoxTimeout.current = setTimeout(() => {
			addFilterAndRefreshTbl({ searchText, offset: 0 })
		}, 300)
	}

	const onChangeUserStatusRb = (event) => {
		addFilterAndRefreshTbl({ usrStatusFilter: event.target.value, offset: 0 })
		setCurrentUser(event.target.value)
	}

	const onChangeRoleSelectBox = (event, roles) => {
		addFilterAndRefreshTbl({ roleFilter: roles, offset: 0 })
	}

	const fetchUsersList = useCallback(
		({ limit, offset, searchText, usrStatusFilter, roleFilter }) => {
			const isActive = getUsrStatusFilterVal(usrStatusFilter)
			const roleFilterVal = _.join(
				_.map(roleFilter || [], (role) => _.get(role, 'role_id')),
				','
			)
			dispatch(
				fetchAllUsers(
					{
						limit,
						offset,
						search_text: searchText,
						is_active: isActive,
						role_id: roleFilterVal,
					},
					(resp) => {
						const totalCount = _.get(resp, 'data.data.total_count', 0)
						const userList = _.get(resp, 'data.data.users', [])
						if (isMounted.current) {
							setUsersTblState((prevState) => ({
								...prevState,
								loading: false,
								totalCount,
								userList,
							}))
						}
					},
					(err) => {
						if (!isAxiosCancelError(err) && isMounted.current) {
							setUsersTblState((prevState) => ({
								...prevState,
								loading: false,
								totalCount: 0,
								userList: [],
							}))
						}
					}
				)
			)
		},
		[dispatch]
	)

	const fetchRolesList = useCallback(() => {
		UsersApi.fetchAllUserRoles().then(
			(resp) => {
				setRoleListState((prevState) => ({
					...prevState,
					isFetchRoles: false,
					roleList: _.get(resp, 'data.data.roles', []),
				}))
			},
			(err) => {
				setRoleListState((prevState) => ({
					...prevState,
					isFetchRoles: false,
					roleList: [],
				}))
			}
		)
	}, [])

	useEffect(() => {
		if (_.isNull(roleList)) fetchRolesList()
		else
			fetchUsersList({
				limit,
				offset,
				searchText,
				roleFilter,
				usrStatusFilter,
			})
	}, [limit, offset, searchText, usrStatusFilter, roleFilter, roleList, fetchRolesList, fetchUsersList])

	//Refresh function start
	const isNeedRefreshList = _.get(history, 'location.isRefreshList', false)
	useEffect(() => {
		if (isNeedRefreshList) {
			setUsersTblState((prevState) => {
				fetchUsersList({
					limit: 10,
					offset: 0,
					searchText: prevState.searchText,
					roleFilter: [],
					usrStatusFilter: USER_STATUS_FILTER.ALL,
				})
				return {
					...prevState,
					loading: true,
					offset: 0,
					userList: [],
					roleFilter: [],
					usrStatusFilter: USER_STATUS_FILTER.ALL,
				}
			})
			_.set(history, 'location.isRefreshList', false)
		}
	}, [isNeedRefreshList, fetchUsersList, history])
	//Refresh function end

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<Grid container className={classes.root} item xs={12}>
			<ListPageFilterSection
				disabled={isFetchRoles}
				searchPlaceholder={'Search Users...'}
				addBtnClick={addBtnClick}
				hideAddBtn={!isCreateAllowed}
				onChangeSearchBox={onChangeSearchBox}
				addBtnTxt={'Add User'}
			>
				<div className={classes.advFilterContent}>
					<div>
						<FormLabel component='legend' className={classes.radioFormLabel}>
							User Status
						</FormLabel>
						<RadioGroup row aria-label='position' onChange={onChangeUserStatusRb} value={usrStatusFilter} name='position' defaultValue='top'>
							<FormControlLabel
								value='top'
								classes={{ label: classes.switchLabel }}
								control={<Radio size='small' value={USER_STATUS_FILTER.ALL} color='primary' />}
								label='All'
								labelPlacement='end'
							/>
							<FormControlLabel
								value='top'
								classes={{ label: classes.switchLabel }}
								control={<Radio size='small' value={USER_STATUS_FILTER.ACTIVE} color='primary' />}
								label='Active'
								labelPlacement='end'
							/>
							<FormControlLabel
								value='top'
								classes={{ label: classes.switchLabel }}
								control={<Radio size='small' value={USER_STATUS_FILTER.INACTIVE} color='primary' />}
								label='Inactive'
								labelPlacement='end'
							/>
						</RadioGroup>
					</div>
					<div>
						<Autocomplete
							multiple
							id='tags-outlined'
							options={roleList || []}
							size='small'
							value={roleFilter || []}
							getOptionLabel={(option) => option.role_name}
							onChange={onChangeRoleSelectBox}
							filterSelectedOptions
							renderInput={(params) => <TextField {...params} variant='outlined' label='User Roles' />}
						/>
					</div>
					{/* <div style={{padding:'inherit'}}> */}
					<Button onClick={resetBtn} color='primary' variant='text' size='medium' disableElevation style={{ padding: 'inherit', fontWeight: 'bold' }}>
						Reset
					</Button>
					{/* </div> */}
				</div>
			</ListPageFilterSection>
			<Grid item xs={12}>
				<div>
					<AgGridCustom
						columnDefs={gridHeaders}
						rowData={userList}
						loading={loading}
						frameworkComponents={{
							nameCellRenderer: withCellRenderState(NameCellRenderer),
							roleCellRenderer: withCellRenderState(RoleCellRenderer),
							statusCellRenderer: withCellRenderState(StatusCellRenderer),
							emailCellRenderer: withCellRenderState(EmailCellRenderer),
							actionCellRenderer: withCellRenderState(ActionCellRenderer),
						}}
						disableClickSelectionRenderers={['actionCellRenderer']}
						onRowClicked={onRowClicked}
						rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
						noDataTxt={!_.isEqual(currentUser, 'All') ? `No ${currentUser} users found` : 'No users found'}
					></AgGridCustom>
				</div>
				<div>
					{!loading && (
						<TablePagination
							component='div'
							count={totalCount}
							page={offset / limit}
							onChangePage={onChangePage}
							rowsPerPage={limit}
							onChangeRowsPerPage={onChangeRowsPerPage}
						/>
					)}
					<Popover
						id={'more_options'}
						open={open}
						anchorEl={anchorEl}
						onClose={closePopover}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuList autoFocusItem={open} id='options_list'>
							{isEditAllowed && (
								<MenuItem dense={true} onClick={onClickEditMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											edit
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Edit
									</Typography>
								</MenuItem>
							)}
							<MenuItem dense={true} onClick={onClickPermMenu}>
								<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
									<Icon className={classes.menuIcon} fontSize='small'>
										build
									</Icon>
								</ListItemIcon>
								<Typography className={classes.menuText} variant='inherit'>
									Permissions
								</Typography>
							</MenuItem>
							{/* {isDeleteAllowed && (
								<MenuItem dense={true} onClick={onClickDelMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											delete
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Delete
									</Typography>
								</MenuItem>
							)} */}
						</MenuList>
					</Popover>
					<FormDialog
						open={openFormDialog}
						userId={userId}
						action={action}
						isRoleListLoading={isFetchRoles}
						roleList={roleList}
						isEditAllowed={isEditAllowed}
						changeToEditMode={changeToEditMode}
						handleClose={handleCloseFormDialog}
					></FormDialog>
					<SpecialPermDialog
						open={openSpecPermDialog}
						handleClose={closeSpecPermDialog}
						userId={_.get(selectedData, 'user_id')}
						email={_.get(selectedData, 'email')}
						firstName={_.get(selectedData, 'first_name')}
						lastName={_.get(selectedData, 'last_name')}
						accessToken={authToken}
						isEditAllowed={isEditAllowed}
						profilePic={_.get(selectedData, 'profile_pic_url')}
					/>
					<DeleteDialog
						open={openDelDialog}
						handleClose={closeDelDialog}
						userId={_.get(selectedData, 'user_id')}
						email={_.get(selectedData, 'email')}
						firstName={_.get(selectedData, 'first_name')}
						lastName={_.get(selectedData, 'last_name')}
						accessToken={authToken}
						profilePic={_.get(selectedData, 'profile_pic_url')}
					/>
				</div>
			</Grid>
		</Grid>
	)
}

Users.propTypes = {
	action: PropTypes.string,
	userId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Users)
