import {all } from 'redux-saga/effects'
import SessionSagas from '../session/sagas'
import RoleSagas from '../roles/sagas'
import UserSagas from '../users/sagas'
import ABTestSagas from '../abtestresults/sagas'



export default function* rootSaga() {
	yield all([
		...SessionSagas,
		...RoleSagas,
		...UserSagas,
		...ABTestSagas
	])
}
