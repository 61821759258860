import React from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'

// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

const passwordSchema = Yup.object().shape({
	parent_cat: Yup.string().trim().nullable(true).required('Please select the Parent Category.'),
	sub_cat_lvl1: Yup.string().trim().nullable(true).required('Please select the Sub Category Level 1.'),
	// sub_cat_lvl2: Yup.string().trim().required('Please select the Sub Category Level 2.'),
})

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData) ? { parent_cat: '', sub_cat_lvl1: '', sub_cat_lvl2: '' } : props.initialFormData
	return (
		<Formik innerRef={ref} enableReinitialize initialValues={initialValues} validationSchema={passwordSchema} onSubmit={props.onSubmit || null}>
			{props.children}
		</Formik>
	)
})

export default Validator
