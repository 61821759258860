import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import { showLoader, hideLoader, showErrorMessage } from '../../loader/actions'
import api from '../api'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import Axios from 'axios'
import { cancelled } from 'redux-saga/effects'
import { getErrMsg, getSuccessMsg } from '../../../utils'

function* fetchAllTests(action) {
	const { filters, successCallback, errorCallback } = action.payload
	const cancelSource = Axios.CancelToken.source()
	try {
		const req = yield call(api.allTest, filters, cancelSource)
		yield call(successCallback, req)
	} catch (err) {
		yield call(errorCallback, err)
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* fetchAllUsersWatcher() {
	yield takeLatest(SAGA_ACTIONS.AB_TEST_TABLE, fetchAllTests)
}

function* moveStatus(action) {
	yield put(showLoader('Please wait moving status...'))
	const { testId, testDetails, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.moveStat, testId, testDetails)
		yield put(hideLoader())
		yield call(successCallback, resp)
		yield put(showSnackbarWithTimeout(getSuccessMsg(resp, 'Moved Successfully!'), 2500))
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* updateUserWatcher() {
	yield takeEvery(SAGA_ACTIONS.MOVE_STATUS, moveStatus)
}

function* deleteTest(action) {
	yield put(showLoader('Deleting user...'))
	const { testId, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.deleteTst, testId)
		yield call(successCallback, resp)
		yield put(hideLoader())
		yield put(showSnackbarWithTimeout(getSuccessMsg(resp, 'Test Deleted Successfully!'), 2500))
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* deleteUserWatcher() {
	yield takeEvery(SAGA_ACTIONS.DELETE_TEST, deleteTest)
}

function* fetchDashboard(action) {
	const cancelSource = Axios.CancelToken.source()
	yield put({ type: ACTION_TYPES.FETCH_AB_TEST_DASH_PENDING })
	try {
		const resp = yield call(api.fetchDashboard, cancelSource)
		yield put({ type: ACTION_TYPES.FETCH_AB_TEST_DASH_FULFILLED, payload: resp })
	} catch (err) {
		yield put({ type: ACTION_TYPES.FETCH_AB_TEST_DASH_REJECTED, payload: err })
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* fetchDashboardWatcher() {
	yield takeLatest(SAGA_ACTIONS.AB_TEST_DASH, fetchDashboard)
}

//all the saga watchers
// eslint-disable-next-line import/no-anonymous-default-export
export default [fetchAllUsersWatcher(), updateUserWatcher(), deleteUserWatcher(), fetchDashboardWatcher()]
