import React from 'react'
import { Grid, TextField, Typography, Switch, FormControlLabel } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import classnames from 'classnames'
import SelectBox from '../../../../../../components/SelectBox'

const useStyles = makeStyles((theme) => ({
	profilePic: {
		width: 170,
		height: 170,
		[theme.breakpoints.down('xs')]: {
			width: 140,
			height: 140,
			margin: '0 auto',
		},
	},
	flexRootResponsive: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	basicHeaderRight: {
		flex: 1,
		paddingLeft: theme.spacing(2),
		marginTop: theme.spacing(0.8),
		[theme.breakpoints.down('xs')]: {
			flex: 'unset',
			paddingLeft: 0,
		},
	},
	basicHeaderLeft: {
		maxWidth: 170,
		[theme.breakpoints.down('xs')]: {
			flex: 1,
			maxWidth: '100%',
			paddingBottom: theme.spacing(2),
			textAlign: 'center',
		},
	},
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	selectBox: {
		marginBottom: theme.spacing(1.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	parallelInputRoot: {
		width: 'calc(50% - 8px)',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	parallelInputEnd: {
		marginLeft: 16,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		},
	},
	basicHeader: {
		marginBottom: theme.spacing(0.5),
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
		},
	},
	pwdTitle: {
		textTransform: 'none',
	},
	statusIndicator: {
		marginLeft: theme.spacing(2),
		borderRadius: '50%',
		width: '10px',
		height: '10px',
		display: 'inline-block',
		marginRight: theme.spacing(1),
	},
}))

const BasicDetails = ({ isEditMode, isCreateMode, isViewMode, formik }) => {
	const classes = useStyles()

	const showFieldValue = (value) => {
		return isViewMode ? (_.isEmpty(_.trim(value)) ? '-' : value) : value
	}

	return (
		<Grid container>
			<Grid item xs={12} className={classnames(classes.flexRootResponsive, classes.basicHeader)}>
				{/* <div className={classes.basicHeaderLeft}>
					<Field type='text' name='profile_picture'>
						{({ field, form }) => (
							<File
								disabled={isViewMode}
								value={form.values.profile_picture}
								label='Active'
								field={field}
								form={form}
								acceptsExts={['jpg', 'png', 'jpeg']}
								resolution={[240, 240]}
								placeholder={''}
								imgRatioClass={classes.profilePic}
							/>
						)}
					</Field>
				</div> */}
				<div className={classes.basicHeaderRight}>
					{/* <Field type='text' name='distributor_id'>
						{({ field, form }) => (
							<SelectBox
								label='Master Category*'
								value={field.value || ''}
								onChange={(e) => {
									e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
								}}
								inputClass={classes.selectBox}
								name={field.name}
								onBlur={field.onBlur}
								// disabled={disabled}
								helperText={form.touched[field.name] && form.errors[field.name]}
								isError={Boolean(form.errors[field.name] && form.touched[field.name])}
								// options={distributors}
							></SelectBox>
						)}
					</Field> */}
					<Field type='text' name='category'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Category Name*'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={field.value || ''}
								// disabled={isViewMode || _.get(form, 'values.is_email_verified', false)}
								className={classes.textField}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field>
					{/* <Field type='text' name='phone'>
						{({ field, form }) => (
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Category Alias Negative*'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={showFieldValue(field.value) || ''}
								disabled={isViewMode}
								className={classes.textField}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field> */}
					{/* <div className={classes.flexRootResponsive}>
						<div className={classes.parallelInputRoot}>
							<Field type='text' name='first_name'>
								{({ field, form }) => (
									<TextField
										variant='outlined'
										margin='none'
										size='small'
										fullWidth
										name={field.name}
										label={'Price Range Min*'}
										type='number'
										autoComplete='off'
										onChange={field.onChange}
										onBlur={field.onBlur}
										value={field.value || ''}
										disabled={isViewMode}
										className={classes.textField}
										helperText={form.touched[field.name] && form.errors[field.name]}
										error={Boolean(form.errors[field.name] && form.touched[field.name])}
									/>
								)}
							</Field>
						</div>
						<div className={classnames(classes.parallelInputRoot, classes.parallelInputEnd)}>
							<Field type='text' name='last_name'>
								{({ field, form }) => (
									<TextField
										variant='outlined'
										margin='none'
										size='small'
										fullWidth
										name={field.name}
										label={'Price Range Max*'}
										type='number'
										autoComplete='off'
										onChange={field.onChange}
										onBlur={field.onBlur}
										value={showFieldValue(field.value) || ''}
										disabled={isViewMode}
										className={classes.textField}
										helperText={form.touched[field.name] && form.errors[field.name]}
										error={Boolean(form.errors[field.name] && form.touched[field.name])}
									/>
								)}
							</Field>
						</div>
					</div> */}
				</div>
			</Grid>
		</Grid>
	)
}

BasicDetails.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
	formik: PropTypes.object.isRequired,
}

export default BasicDetails
