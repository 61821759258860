import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	GetFilters: (data, filter, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.GET_BOT_FILTERS,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { filter } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetTable: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_BOT_TABLE, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	filterOnChange: (data, filter, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.BOT_FILTER_ONCHANGE,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { filter } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	ChartData: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.CHART_DATA, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	SummaryData: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.SUMMARY_DATA, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}

export default api
