

import { ACTION_TYPES } from '../../../../constants'
function getDefaultState() {
	//FETCH ALL USER DETAILS FROM LOCAL STORAGE TO AFFECT STORE
	return {
		isFetchingUser: true,
		isFetchingUserFailed: false,
		isFetchingUserError: undefined,
		userDetails: {},
	}
}

const users = (state = getDefaultState(), action) => {
	switch (action.type) {
		case ACTION_TYPES.FETCH_CURRENT_USER_PENDING: {
			return {
				...state,
				isFetchingUser: true,
				isFetchingUserFailed: false,
				isFetchingUserError: undefined,
				userDetails: {},
			}
		}
		case ACTION_TYPES.FETCH_CURRENT_USER_FULFILLED: {
			return {
				...state,
				isFetchingUser: false,
				isFetchingUserFailed: false,
				isFetchingUserError: undefined,
				userDetails: action.payload.data.data,
			}
		}
		case ACTION_TYPES.FETCH_CURRENT_USER_REJECTED: {
			return {
				...state,
				isFetchingUser: false,
				isFetchingUserFailed: true,
				isFetchingUserError: action.payload,
				userDetails: {},
			}
		}
		case ACTION_TYPES.RESET_CURRENT_USER: {
			return {
				...state,
				isFetchingUser: false,
				isFetchingUserFailed: false,
				isFetchingUserError: undefined,
				userDetails: {},
			}
		}
		default:
			return state
	}
}

export default users
