import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	// Avatar,
	Badge,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	Icon,
	IconButton,
	makeStyles,
	Paper,
	Typography,
	Switch,
	Radio,
} from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import SelectBox from '../../../../components/SelectBox'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
// import classnames from 'classnames'
import Axios from 'axios'
import { useSelector } from 'react-redux'
import SearchBox from '../../../../components/PainSearchBox'
import { API_ENDPOINTS } from '../../../../constants'
import { escapeRegExp, formTheName, getImgUrl } from '../../../../utils'
import ListItem from './Components/ListItem'
import ListItemLabel from './Components/ListItemLabel'
import { BOARD_HEADER_HEIGHT_MOBILE } from './contants'
import moment from 'moment'
import DateRangePicker from './DateSelect'

const useStyles = makeStyles((theme) => ({
	root: {
		border: 'none',
		height: `calc(100vh - (65px))`,
	},
	listContainer: {
		overflowX: 'hidden',
		overflowY: 'auto',
		padding: theme.spacing(0),
		maxHeight: 'calc(100vh - (48px + 74px))',
		'&::-webkit-scrollbar,::-webkit-scrollbar-track': {
			width: '8px',
			height: '8px',
			'-webkit-appearance': 'none',
			backgroundColor: 'transparent',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.almostBlack[400],
			height: '20px',
			borderRadius: '4px',
		},
	},
	muiExpansionPanelRoot: {
		width: '100%',
		'&:before': {
			height: 2,
			background: 'white',
		},
		'&:last-child': {
			marginBottom: 0,
		},
		'&.Mui-expanded': {
			margin: 0,
			'& + .MuiAccordion-root:before': {
				display: 'block',
			},
			'&:before': {
				opacity: 1,
			},
		},
		'& .MuiAccordionSummary-expandIcon': {
			padding: theme.spacing(1),
		},
	},
	muiExpanded: {},
	muiExpansionPanelSummaryRoot: {
		minHeight: 40,
		background: theme.palette.almostBlack[200],
		'&$muiExpanded': {
			minHeight: 40,
		},
	},
	muiExpansionPanelSummaryContent: {
		margin: [[`0px 0px`], '!important'],
		'&$muiExpanded': {
			margin: `12px 0px`,
		},
	},
	filterPanelTitleContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	filterTitleWithChip: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	anchorOriginTopRightRectangle: {
		top: `4px`,
		right: `-4px`,
	},
	filterPanelHeading: {
		fontSize: theme.typography.pxToRem(14),
		color: theme.palette.almostBlack[800],
		fontWeight: 'bold',
		letterSpacing: theme.typography.pxToRem(0.8),
	},
	menuHeader: {
		width: '100%',
		height: 40,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		boxShadow: `0px 1px 8px 0px ${theme.palette.almostBlack[200]}`,
		[theme.breakpoints.down('sm')]: {
			minHeight: BOARD_HEADER_HEIGHT_MOBILE,
		},
	},
	menuHeaderIconDiv: {
		width: 32,
		height: 32,
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .MuiIconButton-root': {
			padding: theme.spacing(0.75),
			fontSize: theme.typography.pxToRem(20),
			'& .MuiIcon-root': {
				fontSize: theme.typography.pxToRem(20),
			},
		},
	},
	menuTitle: {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
		width: '100%',
		textAlign: 'center',
	},
	optionItemLabel: {
		fontSize: theme.typography.pxToRem(14),
	},
	optionItemRadio: {
		padding: 6,
		'& .MuiSvgIcon-root': {
			fontSize: theme.typography.pxToRem(18),
		},
	},
	optionListItemContainer: {
		width: '100%',
		borderBottom: `1px solid ${theme.palette.almostBlack[200]}`,
		boxSizing: 'border-box',
		padding: theme.spacing(0, 2),
		cursor: 'pointer',
		'&:hover': {
			background: theme.palette.almostBlack[100],
		},
	},
	optionListLabelContainer: {
		display: 'flex',
		gap: `4px`,
		alignItems: 'center',
	},
	optionListLabelColorDiv: {
		width: 16,
		height: 16,
		borderRadius: 4,
	},
	listItemContainer: {
		padding: theme.spacing(0.5),
		margin: theme.spacing(0.5, 0, 0.5, 0),
		borderRadius: 4,
		border: `1px solid transparent`,
		cursor: 'pointer',
	},
	userPic: {
		width: 36,
		height: 36,
		marginRight: theme.spacing(1),
		fontSize: 17,
	},
	okBtn: {
		padding: theme.spacing(0.25, 0.75),
		fontSize: theme.typography.pxToRem(11),
		minWidth: 48,
		marginLeft: 'auto',
	},
	resetBtn: {
		padding: theme.spacing(0.25, 0.75),
		fontSize: theme.typography.pxToRem(11),
		minWidth: 48,
	},
	resetAllBtn: {
		padding: theme.spacing(0.25, 0.75),
		fontSize: theme.typography.pxToRem(11),
		minWidth: 80,
		right: '10px',
	},
	muiExpansionPanelDetailsRoot: {
		padding: theme.spacing(0.5, 0, 0.5),
		flexDirection: 'column',
		'& $optionListItemContainer:last-child': {
			borderBottom: `none`,
		},
	},
	searchContainer: {
		padding: theme.spacing(0, 1),
	},
	switch_track: {
		backgroundColor: '#9f9f9f',
	},
	switch_base: {
		color: '#9f9f9f',
		'&.Mui-disabled': {
			color: '#9f9f9f',
		},
		'&.Mui-checked': {
			color: '#9f9f9f',
		},
		'&.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#9f9f9f',
		},
	},
	switch_primary: {
		'&.Mui-checked': {
			color: '#9f9f9f',
		},
		'&.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#9f9f9f',
		},
	},
}))

const FilterMenu = ({
	setMenuProps,
	updateFunc,
	onOkClick,
	incExc,
	setincExc,
	activeFilters,
	setEnupdate,
	activeOKFilters,
	updateOKFunc,
	onChangeFilter,
	filters,
	token,
	glassCode,
	InitialFilter,
	setFilter,
}) => {
	const classes = useStyles()
	// const assignees = data || []
	// const render_Filters = filters || []
	const [render_Filters, setrenderFilters] = React.useState(filters || [])
	const [objSelected, setobjSelected] = React.useState({})
	const labels = useSelector((state) => state?.glass?.settings?.labels || [])
	const allLabels = labels
		? _.concat(labels, { is_edit: false, label_color: '#FFF', label_id: '', label_name: 'None' })
		: [{ is_edit: false, label_color: '#FFF', label_id: '', label_name: 'None' }]

	const updateFilter = (path, value, isSub, isMulti) => {
		let temp = _.cloneDeep(activeFilters || {})
		let tempValue = value
		let existingPathValue = _.cloneDeep(_.get(temp, path))
		let existingPathState = _.isArray(value)
			? _.isEmpty(_.xor(existingPathValue, value))
			: _.isPlainObject(value)
			? isMulti
				? !!_.find(existingPathValue, value)
				: _.isEqual(existingPathValue, value)
			: _.includes(existingPathValue, value)
		if (existingPathState && !_.isEmpty(existingPathValue)) {
			if (_.isObject(existingPathValue)) {
				if (_.isArray(value)) {
					tempValue = []
				} else if (_.isPlainObject(value)) {
					if (isMulti) {
						_.remove(existingPathValue, value)
						tempValue = existingPathValue
					} else {
						tempValue = {}
					}
				} else {
					_.remove(existingPathValue, (o) => _.isEqual(value, o))
					tempValue = existingPathValue
				}
			} else {
				tempValue = ''
			}
		} else if (!existingPathState && !_.isEmpty(existingPathValue)) {
			if (_.isArray(existingPathValue)) {
				tempValue = _.isString(value) || _.isNumber(value) ? [...existingPathValue, value] : _.uniq(_.concat(existingPathValue, value))
			} else if (_.isPlainObject(value) && isMulti) {
				tempValue = [...existingPathValue, value]
			}
		} else if (!existingPathState && _.isEmpty(existingPathValue) && (_.isPlainObject(value) || _.isString(value) || _.isNumber(value)) && isMulti) {
			tempValue = [value]
		}
		_.set(
			temp,
			path,
			(isSub && _.isString(tempValue)) || _.isNumber(tempValue) ? [tempValue] : _.isNull(tempValue) && isMulti ? [tempValue] : tempValue
		)

		if (_.isEmpty(tempValue)) {
			updateFunc(_.omit(temp, path))
			setEnupdate(true)
		} else {
			updateFunc(temp)
		}

		let temp2 = _.cloneDeep(activeOKFilters || {})
		let tempValue2 = value
		let existingPathValue2 = _.cloneDeep(_.get(temp2, path))
		let existingPathState2 = _.isArray(value)
			? _.isEmpty(_.xor(existingPathValue2, value))
			: _.isPlainObject(value)
			? isMulti
				? !!_.find(existingPathValue2, value)
				: _.isEqual(existingPathValue2, value)
			: _.includes(existingPathValue2, value)
		if (existingPathState2 && !_.isEmpty(existingPathValue2)) {
			if (_.isObject(existingPathValue2)) {
				if (_.isArray(value)) {
					tempValue2 = []
				} else if (_.isPlainObject(value)) {
					if (isMulti) {
						_.remove(existingPathValue2, value)
						tempValue2 = existingPathValue2
					} else {
						tempValue2 = {}
					}
				} else {
					_.remove(existingPathValue2, (o) => _.isEqual(value, o))
					tempValue2 = existingPathValue2
				}
			} else {
				tempValue2 = ''
			}
		} else if (!existingPathState2 && !_.isEmpty(existingPathValue2)) {
			if (_.isArray(existingPathValue2)) {
				tempValue2 = _.isString(value) || _.isNumber(value) ? [...existingPathValue2, value] : _.uniq(_.concat(existingPathValue2, value))
			} else if (_.isPlainObject(value) && isMulti) {
				tempValue2 = [...existingPathValue2, value]
			}
		} else if (
			!existingPathState2 &&
			_.isEmpty(existingPathValue2) &&
			(_.isPlainObject(value) || _.isString(value) || _.isNumber(value)) &&
			isMulti
		) {
			tempValue2 = [value]
		}
		_.set(
			temp2,
			path,
			(isSub && _.isString(tempValue2)) || _.isNumber(tempValue2) ? [tempValue2] : _.isNull(tempValue2) && isMulti ? [tempValue2] : tempValue2
		)
		_.isEmpty(tempValue2) ? updateOKFunc(_.omit(temp2, path)) : updateOKFunc(temp2)
	}

	const up = (path, obj, isSub, isMulti) => {
		let temp = _.cloneDeep(objSelected || {})
		let tempValue = obj
		let existingPathValue = _.cloneDeep(_.get(temp, path))
		let existingPathState = _.isArray(obj)
			? _.isEmpty(_.xor(existingPathValue, obj))
			: _.isPlainObject(obj)
			? isMulti
				? !!_.find(existingPathValue, obj)
				: _.isEqual(existingPathValue, obj)
			: _.includes(existingPathValue, obj)
		if (existingPathState && !_.isEmpty(existingPathValue)) {
			if (_.isObject(existingPathValue)) {
				if (_.isArray(obj)) {
					tempValue = []
				} else if (_.isPlainObject(obj)) {
					if (isMulti) {
						_.remove(existingPathValue, obj)
						tempValue = existingPathValue
					} else {
						tempValue = {}
					}
				} else {
					_.remove(existingPathValue, (o) => _.isEqual(obj, o))
					tempValue = existingPathValue
				}
			} else {
				tempValue = ''
			}
		} else if (!existingPathState && !_.isEmpty(existingPathValue)) {
			if (_.isArray(existingPathValue)) {
				tempValue = _.isString(obj) || _.isNumber(obj) ? [...existingPathValue, obj] : _.uniq(_.concat(existingPathValue, obj))
			} else if (_.isPlainObject(obj) && isMulti) {
				tempValue = [...existingPathValue, obj]
			}
		} else if (!existingPathState && _.isEmpty(existingPathValue) && (_.isPlainObject(obj) || _.isString(obj) || _.isNumber(obj)) && isMulti) {
			tempValue = [obj]
		}
		_.set(
			temp,
			path,
			(isSub && _.isString(tempValue)) || _.isNumber(tempValue) ? [tempValue] : _.isNull(tempValue) && isMulti ? [tempValue] : tempValue
		)

		if (_.isEmpty(tempValue)) {
			setobjSelected(_.omit(temp, path))
		} else {
			setobjSelected(temp)
		}
	}

	const resetFunc = (path, value) => {
		let tempState = activeFilters || {}
		// let temp = update(tempState, { [path]: { $set: path === 'due_date' || path === 'created_date' ? null : value } })
		let temp = _.omit(tempState, path)
		updateFunc(temp)
		onChangeFilter(path, 'reset')
		setEnupdate(true)
	}

	const resetOkFunc = (path, value) => {
		let tempState = activeOKFilters || {}
		// let temp = update(tempState, { [path]: { $set: path === 'due_date' || path === 'created_date' ? null : value } })
		let temp = _.omit(tempState, path)
		updateOKFunc(temp, path)
		onChangeFilter(path)
		onOkClick(path)
		setEnupdate(true)
	}

	const resetincExc = (path, value) => {
		let check = incExc[path]
		let tempState1 = activeFilters || {}
		let tempState2 = activeOKFilters || {}
		// let temp = update(tempState, { [path]: { $set: path === 'due_date' || path === 'created_date' ? null : value } })
		let temp1 = _.omit(tempState1, path)
		let temp2 = _.omit(tempState2, path)

		updateFunc(temp1)
		updateOKFunc(temp2, path)
		// resetOkFunc(path)
		// onChangeFilter(path, 'reset')
		// setEnupdate(true)
		setincExc((prev) => ({
			...prev,
			[path]: check === 'include' ? 'exclude' : 'include',
		}))
	}

	useEffect(() => {
		setrenderFilters(filters)
	}, [filters])

	const filterStructure = [
		{
			label: 'QA Done Date',
			path: 'due_date',
			type: 'date',
			options: [{ label: 'Custom', key: 'custom_dates' }],
			defaultEmptyValue: {},
			isMulti: false,
			inclExc: false,
		},
		{
			label: 'User',
			path: 'primary_lang',
			type: 'check',
			isAvatar: true,
			isSearchable: true,
			isSearchableWithAPI: false,
			idKey: 'value',
			firstNameKey: 'title',
			lastNameKey: 'last_name',
			emailKey: 'email',
			imageKey: 'profile_pic_url',
			token,
			isShowImageKey: 'is_active',
			defaultOptions: render_Filters.primary_lang || [],
			defaultTotalCount: render_Filters.primary_lang?.total,
			defaultOptionsReduxPath: 'glass?.glass_members',
			endpoint: API_ENDPOINTS.GET_FILTERS,
			currentGlassIdReduxPath: 'glass?.activeGlassDetails?.glass_id',
			defaultEmptyValue: [],
			isMulti: true,
			inclExc: true,
		},
		{
			label: 'Pred.Brand NLP',
			path: 'fallback_lang',
			type: 'check',
			isAvatar: true,
			isSearchable: true,
			isSearchableWithAPI: false,
			idKey: 'value',
			firstNameKey: 'title',
			lastNameKey: 'last_name',
			emailKey: 'email',
			imageKey: 'profile_pic_url',
			token,
			isShowImageKey: 'is_active',
			defaultOptions: render_Filters.fallback_lang || [],
			defaultTotalCount: render_Filters.fallback_lang?.total,
			defaultOptionsReduxPath: 'glass?.glass_members',
			endpoint: API_ENDPOINTS.GET_FILTERS,
			currentGlassIdReduxPath: 'glass?.activeGlassDetails?.glass_id',
			defaultEmptyValue: [],
			isMulti: true,
			inclExc: true,
		},
		{
			label: 'Pred.Cat NLP',
			path: 'country',
			type: 'check',
			isAvatar: true,
			isSearchable: true,
			isSearchableWithAPI: false,
			idKey: 'value',
			firstNameKey: 'title',
			lastNameKey: 'last_name',
			emailKey: 'email',
			imageKey: 'profile_pic_url',
			token,
			isShowImageKey: 'is_active',
			defaultOptions: render_Filters.country || [],
			defaultTotalCount: render_Filters.country?.total,
			defaultOptionsReduxPath: 'glass?.glass_members',
			endpoint: API_ENDPOINTS.GET_FILTERS,
			currentGlassIdReduxPath: 'glass?.activeGlassDetails?.glass_id',
			defaultEmptyValue: [],
			isMulti: true,
			inclExc: true,
		},
	]
	const handleClose = () => {
		setMenuProps((prev) => ({ ...prev, isOpen: false }))
	}
	return (
		<Paper className={classes.root} variant='outlined'>
			<div className={classes.menuHeader}>
				<Typography variant='h5' className={classes.menuTitle}>
					Filters
				</Typography>
				{!_.isEmpty(activeFilters) && (
					<Button
						size='small'
						className={classes.resetAllBtn}
						onClick={(e) => {
							e.stopPropagation()
							setFilter((prevState) => ({ ...prevState, filterData: {} }))
							InitialFilter()
							setEnupdate(true)
						}}
					>
						Reset All
					</Button>
				)}
				<div className={classes.menuHeaderIconDiv}>
					<IconButton onClick={handleClose} title='Close'>
						<Icon>close</Icon>
					</IconButton>
				</div>
			</div>
			<Grid container className={classes.listContainer}>
				{_.isEmpty(filterStructure)
					? 'No Filters Applicable'
					: filterStructure.map((o, i) => (
							<FilterPanel
								key={`filter_${i}`}
								{...o}
								glassCode={glassCode}
								classes={classes}
								selected={_.get(activeFilters, o?.path)}
								resetFunc={resetFunc}
								resetOkFunc={resetOkFunc}
								updateFunc={updateFilter}
								activeOKFilters={activeOKFilters}
								setrenderFilters={setrenderFilters}
								filters={filters}
								incExc={incExc}
								resetincExc={resetincExc}
								up={up}
								objSelected={_.get(objSelected, o?.path)}
							/>
					  ))}
			</Grid>
		</Paper>
	)
}

export default FilterMenu

const useAxios = (endpoint, method = 'post', filter, bodyData, cancelExec, isAuthRequired = true) => {
	if (cancelExec.current) {
		cancelExec.current()
	}
	return Axios[method](endpoint, bodyData, {
		cancelToken: new Axios.CancelToken(function executor(c) {
			cancelExec.current = c
		}),
		headers: {
			'Content-Type': 'application/json',
			isAuthRequired,
			path: { filter },
		},
	})
}

const FilterPanel = (props) => {
	const classes = useStyles()
	const [isLoading, setIsLoading] = useState(false)
	const cancelExecutor = React.useRef()
	const {
		label,
		updateFunc = () => null,
		up = () => null,
		glassCode,
		resetFunc = () => null,
		resetincExc = () => null,
		resetOkFunc = () => null,
		activeOKFilters,
		objSelected,
	} = props
	const [searchQuery, setSearchQuery] = useState('')
	const [options, setOptions] = useState(() => props?.defaultOptions || [])
	const [okFilter, setokFilter] = useState(false)
	const getData = useAxios

	// console.log(props, 'propi')

	React.useEffect(() => {
		let opt = _.get(props.filters, props?.path)
		setOptions(opt)
	}, [props.filters])

	React.useEffect(() => {
		if (!!props?.isSearchable) {
			if (props?.defaultTotalCount > props?.defaultOptions?.length && !!props?.endpoint) {
				setIsLoading(true)

				getData(
					props?.endpoint,
					'post',
					props?.path,
					{
						search_text: searchQuery,
						search_column: props?.path,
						all: false,
					},
					cancelExecutor
				)
					.then((resp) => {
						setOptions(_.uniqBy([..._.get(resp, 'data.data')], 'value'))
						setIsLoading(false)
					})
					.catch((err) => {
						if (Axios.isCancel(err)) {
							return true
						}
						setIsLoading(false)
					})
			} else {
				if (!!searchQuery && !!props?.isSearchableWithAPI) {
					// setOptions(
					// 	_.filter(props?.defaultOptions, (o) =>
					// 		new RegExp(`\\b(\\w*${escapeRegExp(searchQuery)}\\w*)\\b`, 'gi').test(
					// 			`${formTheName(o[props?.firstNameKey], o[props?.lastNameKey])} ${o[props?.emailKey]}`
					// 		)
					// 	)
					// )
					setIsLoading(true)

					getData(
						props?.endpoint,
						'post',
						props?.path,
						{
							search_text: searchQuery,
							// search_column: props?.path,
							all: false,
						},
						cancelExecutor
					)
						.then((resp) => {
							setOptions(_.uniqBy([..._.get(resp, `data.data.${props?.path}`)], 'value'))
							setIsLoading(false)
						})
						.catch((err) => {
							if (Axios.isCancel(err)) {
								return true
							}
							setIsLoading(false)
						})
				} else if (!!searchQuery && !props?.isSearchableWithAPI) {
					setOptions(
						_.filter(props?.defaultOptions, (o) =>
							new RegExp(`\\b(\\w*${escapeRegExp(searchQuery)}\\w*)\\b`, 'gi').test(
								`${formTheName(o[props?.firstNameKey], o[props?.lastNameKey])} ${o[props?.emailKey]}`
							)
						)
					)
				} else if (props?.defaultTotalCount !== options.length) {
					if (!_.isUndefined(objSelected)) {
						setOptions(_.unionBy(objSelected, props?.defaultOptions, 'value'))
					} else {
						setOptions(props?.defaultOptions)
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery])

	return (
		<Accordion
			classes={{
				root: classes.muiExpansionPanelRoot,
				expanded: classes.muiExpanded,
			}}
			elevation={0}
		>
			<AccordionSummary
				classes={{
					root: classes.muiExpansionPanelSummaryRoot,
					content: classes.muiExpansionPanelSummaryContent,
					expanded: classes.muiExpanded,
				}}
				expandIcon={<Icon>keyboard_arrow_down</Icon>}
			>
				<div className={classes.filterPanelTitleContainer}>
					<div className={classes.filterTitleWithChip}>
						<Badge
							color='error'
							badgeContent={_.isPlainObject(props?.selected) ? (_.isEmpty(props?.selected) ? 0 : 1) : _.toLength(props?.selected?.length)}
							variant='dot'
							classes={{
								anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
							}}
						>
							<Typography className={classes.filterPanelHeading} variant='h6'>
								{label || 'Filter'}
							</Typography>
						</Badge>
					</div>
					{(_.has(activeOKFilters, props?.path) || okFilter === true) && (
						<Button
							size='small'
							className={classes.okBtn}
							onClick={(e) => {
								e.stopPropagation()
								resetOkFunc(props?.path, props?.defaultEmptyValue || [])
								setokFilter(false)
							}}
						>
							Ok
						</Button>
					)}
					{!!(_.isPlainObject(props?.selected) ? (_.isEmpty(props?.selected) ? 0 : 1) : _.toLength(props?.selected?.length)) && (
						<>
							<Button
								size='small'
								className={classes.resetBtn}
								onClick={(e) => {
									e.stopPropagation()
									resetFunc(props?.path, props?.defaultEmptyValue || [])
									setokFilter(true)
								}}
							>
								Reset
							</Button>
						</>
					)}
				</div>
			</AccordionSummary>
			<AccordionDetails classes={{ root: classes.muiExpansionPanelDetailsRoot }}>
				{!!props?.isSearchable ? (
					<Grid item xs={12} className={classes.searchContainer}>
						<SearchBox
							onChange={(e) => setSearchQuery(e.target.value)}
							value={searchQuery}
							className={classes.searchInput}
							adornment={
								isLoading ? (
									<CircularProgress style={{ marginRight: '4px' }} size={20} />
								) : searchQuery === '' ? (
									<Icon className={classes.searchIcon}>search</Icon>
								) : (
									<IconButton onClick={() => setSearchQuery('')} size='small' title='Clear Search'>
										<Icon className={classes.searchIcon}>close</Icon>
									</IconButton>
								)
							}
						/>
					</Grid>
				) : (
					_.isEqual(props?.type, 'text') && (
						<Grid item xs={12} className={classes.searchContainer}>
							<SelectBox
								// label='Flix Product*'
								name='flix_product_type'
								onChange={(e) => (props?.selected?.length !== 0 ? updateFunc(props?.path, e, false, false) : console.log(e, 'e'))}
								value={props?.selected}
								options={props?.defaultOptions}
								// disabled={disabled}
							></SelectBox>
						</Grid>
					)
				)}
				{!!props?.inclExc && (
					<Typography component='div' style={{ marginLeft: '60px' }}>
						<Grid component='label' container alignItems='center' spacing={1}>
							<Grid item style={{ color: props?.incExc?.[props?.path] === 'include' && '#41e886' }}>
								Include
							</Grid>
							<Grid item>
								<Switch
									inputProps={{ 'aria-label': 'primary checkbox' }}
									classes={{
										track: classes.switch_track,
										switchBase: classes.switch_base,
										colorPrimary: classes.switch_primary,
									}}
									checked={props?.incExc?.[props?.path] === 'exclude'}
									onChange={() => {
										resetincExc(props?.path, props?.defaultEmptyValue || [])
										setokFilter(true)
									}}
								/>
							</Grid>
							<Grid item style={{ color: props?.incExc?.[props?.path] === 'exclude' && '#41e886' }}>
								Exclude
							</Grid>
						</Grid>
					</Typography>
				)}

				<RenderFilterOptions
					{...props}
					{...(props?.isSearchable
						? {
								defaultOptions: _.sortBy(options, (o) => _.isUndefined(_.find(props?.selected, (j) => j === o[props?.idKey]))),
						  }
						: {})}
					updateFunc={updateFunc}
					up={up}
					classes={classes}
				/>
			</AccordionDetails>
		</Accordion>
	)
}
const RenderFilterOptions = (props) => {
	const { classes = {} } = props
	switch (props?.type) {
		case 'date': {
			const { options = [], selected, updateFunc, path } = props
			let selectedOptionIdx = _.findIndex(options, (option) => {
				const getSelectedValue = (value) => {
					return option?.isCustom ? value : moment(value).valueOf()
				}

				return (
					_.isEqual(getSelectedValue(option.key?.from), getSelectedValue(selected?.from)) &&
					_.isEqual(getSelectedValue(option.key?.to), getSelectedValue(selected?.to))
				)
			})

			// let isCustom = _.isEmpty(_.filter(props.options, (o) => _.isEqual(o.key, selected))) && !_.isEmpty(selected?.from)
			let isCustom = selectedOptionIdx === -1
			if (!!options?.length) {
				return _.map(props.options, (o, i) => {
					const isChecked = _.isEqual(i, selectedOptionIdx)
					if (o?.key === 'custom_dates') {
						return (
							<DateRangePicker
								key={`option_date_custom_picker`}
								classes={classes}
								isSelected={!_.isEmpty(selected?.key) || isCustom}
								selectedKey={o?.key}
								selected={selected}
								path={path}
								updateFunc={updateFunc}
							/>
						)
					} else {
						return (
							<div
								key={`option_date_${path}_${i}`}
								className={classes.optionListItemContainer}
								onClick={(e) => {
									e.stopPropagation()
									if (!isChecked) {
										updateFunc(path, o?.key, false, false)
									} else {
										updateFunc(path, undefined, false, false)
									}
								}}
							>
								<FormControlLabel
									classes={{ label: classes.optionItemLabel }}
									checked={isChecked}
									value='end'
									control={<Radio className={classes.optionItemRadio} color='primary' />}
									label={<ListItemLabel classes={classes} text={o?.label} />}
								/>
							</div>
						)
					}
				})
			}
			return <Typography variant='body2'>No Options</Typography>
		}
		case 'check': {
			const {
				defaultOptions = [],
				selected,
				updateFunc,
				path,
				idKey,
				labelKey,
				firstNameKey,
				lastNameKey,
				emailKey,
				imageKey,
				isAvatar,
				token,
				colorKey,
				iconKey,
				isShowImageKey,
				up,
			} = props

			if (!!defaultOptions?.length && !!idKey && (!!labelKey || (!!firstNameKey && !!lastNameKey && !!emailKey))) {
				return _.map(defaultOptions, (o, i) => {
					if (o?.key === 'custom_dates') {
						return (
							<Typography key={`option_check_${path}_${i}`} variant='h6'>
								Custom Dates
							</Typography>
						)
					} else {
						if (isAvatar) {
							let imgProps = !_.isEmpty(_.get(o, imageKey)) ? { src: getImgUrl(token, _.get(o, imageKey)) } : { src: 'fake_path' }
							let isDontHighlight = !(_.isUndefined(isShowImageKey) || _.get(o, isShowImageKey))
							return (
								<div
									key={`option_check_${path}_${o[idKey]}`}
									className={classes.optionListItemContainer}
									role='button'
									onClick={() => {
										updateFunc(path, o[idKey], false, true)
										up(path, o, false, true)
									}}
								>
									<FormControlLabel
										classes={{ label: classes.optionItemLabel }}
										checked={_.includes(selected || [], o[idKey])}
										value='end'
										control={<Checkbox className={classes.optionItemRadio} color='primary' />}
										label={
											<ListItem
												{...imgProps}
												isDontHighlight={isDontHighlight}
												classes={classes}
												name={formTheName(o[firstNameKey], o[lastNameKey])}
												email={o[emailKey]}
												onClick={() => {
													updateFunc(path, o[idKey], false, true)
													up(path, o, false, true)
												}}
											/>
										}
									/>
								</div>
							)
						}
						return (
							<div
								key={`option_check_${path}_${o[idKey]}`}
								className={classes.optionListItemContainer}
								role='button'
								onClick={() => updateFunc(path, o[idKey], false, true)}
							>
								<FormControlLabel
									classes={{ label: classes.optionItemLabel }}
									checked={_.includes(selected || [], o[idKey])}
									value='end'
									control={<Checkbox className={classes.optionItemRadio} color='primary' />}
									label={<ListItemLabel classes={classes} icon={o[iconKey]} color={o[colorKey]} text={o[labelKey]} />}
								/>
							</div>
						)
					}
				})
			}
			return (
				<div key={`option_check_${path}_no_option`} className={classes.optionListItemContainer} style={{ cursor: 'auto', padding: 8 }}>
					<ListItemLabel classes={classes} text='No Data' />
				</div>
			)
		}
		default: {
			props?.type !== 'text' && (
				<div key={`option_check_no_option_main`} className={classes.optionListItemContainer} style={{ cursor: 'auto', padding: 8 }}>
					<ListItemLabel classes={classes} text='No Data' />
				</div>
			)
		}
	}
}
