import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Button, Typography, Box, Icon, Fab, Grow, Badge, TablePagination, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Card from './Card'
import FilterMenuList from './FilterMenu'
import { PRODUCT_MATCH_TYPE } from '../../../constants'
import SelectBox from '../../../components/SelectBox'
import classnames from 'classnames'
import api from '../../../services/prmatching/api'
import throttle from 'lodash/throttle'
import _ from 'lodash'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { hideLoader, showLoader } from '../../../services/loader/actions'
import { data } from './data'
import NoImage from './No.png'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	contentWrapper: {
		width: '100%',
		// textAlign: 'center',
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	// gridWrapper: {
	// 	flexGrow: 1,
	// 	overflow: 'hidden',
	// 	padding: theme.spacing(0, 3),
	// 	maxWidth: 800,
	// },
	textField: {
		// maxWidth: 400,
		// margin: `${theme.spacing(1)}px auto`,
		// padding: theme.spacing(2),
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		'& .MuiGrid-spacing-xs-3': {
			margin: 0,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	actionButton: {
		textAlign: 'center',
		fontWeight: 'bold',
		// textDecoration: 'underline',
	},
	key: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	table: {
		'& .MuiTableRow-head': {
			background: 'rgba(3, 169, 244, 0.44)',
		},
	},
	mobileBoardContainerOpened: {
		[theme.breakpoints.down('xs')]: {
			'&::after': {
				position: 'absolute',
				width: '100%',
				height: '100%',
				content: '" "',
				background: 'rgba(0,0,0,0.5)',
				zIndex: 1,
			},
		},
		paddingBottom: theme.spacing(0),
	},
	searchBox: {
		marginTop: '15px',
	},
	totalLength: {
		marginTop: '20px',
		textAlign: 'center',
	},
	prevNext: {
		marginTop: '11px',
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
}))

export default function CenteredGrid() {
	const classes = useStyles()
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const dispatch = useDispatch()
	const authToken = useSelector((state) => _.get(state, 'session.authToken'))
	const [filter, setFilter] = useState({ searchText: '', filterData: {} })
	const { filterData } = filter
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(filterData, _.isEmpty)), [filterData])

	const [cardData, setCardData] = useState([])
	const [matchmissData, setmatchmissData] = useState([])
	const [currentPage, setCurrentPage] = React.useState(0)
	const [offset, setOffset] = React.useState(0)
	const [limit, setLimit] = React.useState(6)
	const [count, setCount] = React.useState(0)
	const [value, setValue] = React.useState('')
	const [matchData, setmatchData] = React.useState('')
	const [enupdate, setEnupdate] = React.useState(false)
	const [Okfilter, setOkfilter] = React.useState({})
	const [matchtype, setMatchtype] = React.useState('OEM')
	const [filters, setFilters] = React.useState({
		sip: [],
		brand: [],
		longtail_brand: [],
		comp_brand: [],
		primary_lang: [],
		fallback_lang: [],
		country: [],
		distributor_id: [],
		impressions: data,
	})
	const [incExc, setincExc] = React.useState({
		sip: 'include',
		brand: 'include',
		longtail_brand: 'include',
		comp_brand: 'include',
		primary_lang: 'include',
		fallback_lang: 'include',
		country: 'include',
		distributor_id: 'include',
		impressions: 'include',
	})
	const renderAfterCalled = useRef(false)

	const updateFilter = useCallback(
		(value) => {
			let formatedData = {
				...value,
			}
			setFilter((prevState) => ({ ...prevState, filterData: formatedData }))
			// setOkfilter((prevState) => ({ ...prevState, ...value }))
			let data = _.omitBy(formatedData, _.isEmpty)
		},
		[dispatch]
	)
	const updateOKFilter = useCallback(
		(value, path) => {
			let formatedData = {
				...value,
			}
			// setFilter((prevState) => ({ ...prevState, filterData: formatedData }))

			setOkfilter(value)

			if (value === '') {
				setEnupdate(true)
			}
			let data = _.omitBy(formatedData, _.isEmpty)
		},
		[dispatch]
	)

	const onChangeFilter = (path, fn) => {
		if (path === 'sip') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: {} }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['sip']) }))
			}

			setEnupdate(true)
		}
		if (path === 'primary_lang') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['sip']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['sip', 'primary_lang']) }))
			}
			setEnupdate(true)
		}
		if (path === 'fallback_lang') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['sip']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang']) }))
			}
			setEnupdate(true)
		}
		if (path === 'country') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang', 'country']) }))
			}
			setEnupdate(true)
		}
		if (path === 'distributor_id') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang', 'country']) }))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang', 'country', 'distributor_id']),
				}))
			}
			setEnupdate(true)
		}
		if (path === 'brand') {
			if (fn === 'reset') {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang', 'country', 'distributor_id']),
				}))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang', 'country', 'distributor_id', 'brand']),
				}))
			}
			setEnupdate(true)
		}
		if (path === 'longtail_brand') {
			if (fn === 'reset') {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang', 'country', 'distributor_id', 'brand']),
				}))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['sip', 'primary_lang', 'fallback_lang', 'country', 'distributor_id', 'brand', 'longtail_brand']),
				}))
			}
			setEnupdate(true)
		}
	}

	const updateClick = () => {
		let impression
		let r1 = ['0-1000', '1000-10000']
		let r2 = ['0-1000', '10000-100000']
		let r3 = ['0-1000', '100000-500000']
		let r4 = ['0-1000', '>500000']
		let r5 = ['1000-10000', '10000-100000']
		let r6 = ['1000-10000', '100000-500000']
		let r7 = ['1000-10000', '>500000']
		let r8 = ['10000-100000', '100000-500000']
		let r9 = ['10000-100000', '>500000']
		let r10 = ['100000-500000', '>500000']
		let r11 = ['0-1000', '1000-10000', '10000-100000']
		let r12 = ['1000-10000', '10000-100000', '100000-500000']
		let r13 = ['10000-100000', '100000-500000', '>500000']
		let r14 = ['0-1000', '100000-500000', '>500000']
		let r15 = ['0-1000', '1000-10000', '10000-100000', '100000-500000']
		let r16 = ['1000-10000', '10000-100000', '100000-500000', '>500000']
		let r17 = ['0-1000', '10000-100000', '100000-500000', '>500000']
		let r18 = ['0-1000', '1000-10000', '100000-500000', '>500000']
		let r19 = ['0-1000', '1000-10000', '10000-100000', '>500000']
		let r20 = ['0-1000', '1000-10000', '10000-100000', '100000-500000', '>500000']

		if (_.isEqual(filterData.impressions, r1)) {
			impression = '0-10000'
		} else if (_.isEqual(filterData.impressions, r2)) {
			impression = '0-10000'
		} else if (_.isEqual(filterData.impressions, r3)) {
			impression = '0-100000'
		} else if (_.isEqual(filterData.impressions, r4)) {
			impression = '>0'
		} else if (_.isEqual(filterData.impressions, r5)) {
			impression = '1000-100000'
		} else if (_.isEqual(filterData.impressions, r6)) {
			impression = '1000-500000'
		} else if (_.isEqual(filterData.impressions, r7)) {
			impression = '>1000'
		} else if (_.isEqual(filterData.impressions, r8)) {
			impression = '10000-500000'
		} else if (_.isEqual(filterData.impressions, r9)) {
			impression = '>10000'
		} else if (_.isEqual(filterData.impressions, r10)) {
			impression = '>100000'
		} else if (_.isEqual(filterData.impressions, r11)) {
			impression = '0-100000'
		} else if (_.isEqual(filterData.impressions, r12)) {
			impression = '1000-500000'
		} else if (_.isEqual(filterData.impressions, r13)) {
			impression = '>10000'
		} else if (_.isEqual(filterData.impressions, r14)) {
			impression = '>0'
		} else if (_.isEqual(filterData.impressions, r15)) {
			impression = '0-500000'
		} else if (_.isEqual(filterData.impressions, r16)) {
			impression = '>1000'
		} else if (_.isEqual(filterData.impressions, r17)) {
			impression = '>0'
		} else if (_.isEqual(filterData.impressions, r18)) {
			impression = '>0'
		} else if (_.isEqual(filterData.impressions, r19)) {
			impression = '>0'
		} else if (_.isEqual(filterData.impressions, r20)) {
			impression = '>0'
		}

		let pay_values = {
			// search_text: '',

			filters: [
				{
					column: 'sip',
					data: filterData.sip ? filterData.sip : [],
					method: incExc.sip,
					type: 'bool',
				},
				{
					column: 'distributor_id',
					data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
					method: incExc.distributor_id,
					type: 'int',
				},
				{
					column: 'country',
					data: filterData.country ? filterData.country : [],
					method: incExc.country,
					type: 'str',
				},
				{
					column: 'longtail_brand',
					data: filterData.longtail_brand ? filterData.longtail_brand : [],
					method: incExc.longtail_brand,
					type: 'str',
				},
				{
					column: 'brand',
					data: filterData.brand ? filterData.brand : [],
					method: incExc.brand,
					type: 'str',
				},
				{
					column: 'comp_brand',
					data: filterData.comp_brand ? filterData.comp_brand : [],
					method: incExc.comp_brand,
					type: 'str',
				},
				{
					column: 'primary_lang',
					data: filterData.primary_lang ? filterData.primary_lang : [],
					method: incExc.primary_lang,
					type: 'str',
				},
				{
					column: 'fallback_lang',
					data: filterData.fallback_lang ? filterData.fallback_lang : [],
					method: incExc.fallback_lang,
					type: 'str',
				},
				{
					column: 'impression',
					data: filterData.impressions ? (filterData.impressions.length === 1 ? filterData.impressions : [impression]) : [],
					method: incExc.impressions,
					type: 'range',
				},
			],
			all: _.isEmpty(filterData) ? true : false,
			// offset: offset,
			// limit: limit,
		}
		dispatch(showLoader('Please wait, getting details'))
		api.GetMatchMiss(
			pay_values,
			(res) => {
				let resp = res.data.data
				let cust_data = {
					brand: _.isEmpty(resp.brand) ? '' : resp.brand,
					id: _.isEmpty(resp.id) ? '' : resp.id,
					distributor_id: resp.distributor_id === null ? 0 : resp.distributor_id,
					ean: _.isEmpty(resp.ean) ? '' : resp.ean,
					product_img: _.isEmpty(resp.product_img) ? '' : resp.product_img,
					primary_lang: _.isEmpty(resp.primary_lang) ? '' : resp.primary_lang,
					secondary_lang: _.isEmpty(resp.secondary_lang) ? '' : resp.secondary_lang,
					mpn: _.isEmpty(resp.mpn) ? '' : resp.mpn,
					page_url: _.isEmpty(resp.page_url) ? '' : resp.page_url,
					impression: resp.impression === null ? 0 : resp.impression,
					price: _.isEmpty(resp.price) ? '' : resp.price,
					product_title: _.isEmpty(resp.product_title) ? '' : resp.product_title,
				}
				setmatchmissData(cust_data)
				if (_.isEmpty(resp)) {
					dispatch(showSnackbarWithTimeout('No data found.', 2000))
					setValue('')
					setCardData([])
				} else {
					// setCount(res.data.data.total_count)
					setValue(!_.isEmpty(resp.mpn) ? resp.mpn : !_.isEmpty(resp.ean) ? resp.ean : resp.product_title)
				}
				setEnupdate(false)
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	const initialFilter = () => {
		let filtArray = ['country', 'distributor_id', 'brand', 'sip', 'primary_lang', 'fallback_lang', 'longtail_brand', 'comp_brand']
		filtArray.forEach((key) => {
			let pay_values = {
				search_text: '',
				all: true,
			}

			api.GetFilters(
				pay_values,
				key,
				(res) => {
					let resp = res.data.data
					// setmatchmissData(resp)
					setFilters((prevState) => ({
						...prevState,
						...resp,
					}))
				},
				(err) => {}
			)
		})
		// })
		// renderAfterCalled.current = true
		// console.log('calling...')
	}

	useEffect(() => {
		updateClick()
		setTimeout(() => {
			initialFilter()
		}, 2000)
	}, [])

	useEffect(() => {
		// if (!renderAfterCalled.current) {
		let language = ''
		if (!_.isEmpty(matchmissData.primary_lang)) {
			// language.push({
			// 	column: 'language_code',
			// 	value: matchmissData.primary_lang,
			// })
			language = matchmissData.primary_lang
		} else if (!_.isEmpty(matchmissData.secondary_lang)) {
			// language.push({
			// 	column: 'language_code',
			// 	value: matchmissData.secondary_lang,
			// })
			language = matchmissData.primary_lang
		} else if (!_.isEmpty(matchmissData.primary_lang && !_.isEmpty(matchmissData.secondary_lang))) {
			// language.push(
			// 	{
			// 		column: 'language_code',
			// 		value: matchmissData.primary_lang,
			// 	},
			// 	{
			// 		column: 'language_code',
			// 		value: matchmissData.secondary_lang,
			// 	}
			// )

			language = `${matchmissData.primary_lang},${matchmissData.secondary_lang}`
		}
		if (!_.isEmpty(matchmissData.primary_lang) || !_.isEmpty(matchmissData.secondary_lang)) {
			let pay_values = {
				search_text: value,
				language_code: language,
				offset: offset,
				limit: limit,
			}
			api.GetProducts(
				pay_values,
				(res) => {
					let resp = res.data.data.es_data
					setCardData(resp)
					setCount(res.data.data.total_count)
				},
				(err) => {}
			)
		}
		// }
		// renderAfterCalled.current = true
		// console.log('calling...')
	}, [currentPage, matchmissData])

	const searchData = useRef(
		throttle((val, language) => {
			const query = val
			setCurrentPage(0)
			console.log(!_.isEmpty(query), 'q')
			if (!_.isEmpty(query)) {
				let pay_values = {
					search_text: query,
					language_code: language,
					offset: 0,
					limit: limit,
				}

				api.GetProducts(
					pay_values,
					(res) => {
						let resp = res.data.data.es_data
						setCardData(resp)
						setCount(res.data.data.total_count)
					},
					(err) => {}
				)
			}
		}, 1000)
	)

	const onChangeSearch = (value) => {
		let language = ''
		if (!_.isEmpty(matchmissData.primary_lang)) {
			// language.push({
			// 	column: 'language_code',
			// 	value: matchmissData.primary_lang,
			// })
			language = matchmissData.primary_lang
		} else if (!_.isEmpty(matchmissData.secondary_lang)) {
			// language.push({
			// 	column: 'language_code',
			// 	value: matchmissData.secondary_lang,
			// })
			language = matchmissData.primary_lang
		} else if (!_.isEmpty(matchmissData.primary_lang && !_.isEmpty(matchmissData.secondary_lang))) {
			// language.push(
			// 	{
			// 		column: 'language_code',
			// 		value: matchmissData.primary_lang,
			// 	},
			// 	{
			// 		column: 'language_code',
			// 		value: matchmissData.secondary_lang,
			// 	}

			// )
			language = `${matchmissData.primary_lang},${matchmissData.secondary_lang}`
		}
		setValue(value)
		searchData.current(value, language)
		// console.log('calling...1', matchmissData)
	}

	const onChangePage = (e, pageNo) => {
		setCurrentPage(pageNo)
		setOffset(pageNo * limit)
	}

	// const onChangeSearch = (e, pageNo) => {
	// 	setCurrentPage(pageNo)
	// 	setOffset(pageNo * limit)
	// }

	const onMatchClick = (value) => {
		if (value === matchData) {
			setmatchData('')
		} else {
			setmatchData(value)
		}
	}
	const onClipboardClick = () => {
		navigator.clipboard.writeText(!_.isEmpty(matchmissData?.product_title) && matchmissData?.product_title)
		dispatch(showSnackbarWithTimeout('Product Title Copied Into Clipboard', 2000))
	}

	const onOkClick = (path) => {
		if (path !== 'impressions' && path !== 'comp_brand') {
			let pay_values = {}
			let filtArray = []
			let initial_values = [
				{
					column: 'sip',
					data: filterData.sip ? filterData.sip : [],
					method: incExc.sip,
					type: 'bool',
				},
				{
					column: 'primary_lang',
					data: filterData.primary_lang ? filterData.primary_lang : [],
					method: incExc.primary_lang,
					type: 'str',
				},
				{
					column: 'fallback_lang',
					data: filterData.fallback_lang ? filterData.fallback_lang : [],
					method: incExc.fallback_lang,
					type: 'str',
				},
				{
					column: 'country',
					data: filterData.country ? filterData.country : [],
					method: incExc.country,
					type: 'str',
				},
				{
					column: 'distributor_id',
					data: filterData.distributor_id ? filterData.distributor_id : [],
					method: incExc.distributor_id,
					type: 'int',
				},
				{
					column: 'brand',
					data: filterData.brand ? filterData.brand : [],
					method: incExc.brand,
					type: 'str',
				},
				{
					column: 'longtail_brand',
					data: filterData.longtail_brand ? filterData.longtail_brand : [],
					method: incExc.longtail_brand,
					type: 'str',
				},
			]
			if (path === 'sip') {
				pay_values = {
					onchange_for: 'sip',
					filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['sip'], column)])),
				}
				filtArray = ['primary_lang', 'fallback_lang', 'country', 'distributor_id', 'brand', 'longtail_brand', 'comp_brand']
			}
			if (path === 'primary_lang') {
				pay_values = {
					onchange_for: 'primary_lang',
					filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['sip', 'primary_lang'], column)])),
				}
				filtArray = ['fallback_lang', 'country', 'distributor_id', 'brand', 'longtail_brand', 'comp_brand']
			}
			if (path === 'fallback_lang') {
				pay_values = {
					onchange_for: 'fallback_lang',
					filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['sip', 'primary_lang', 'fallback_lang'], column)])),
				}
				filtArray = ['country', 'distributor_id', 'brand', 'longtail_brand', 'comp_brand']
			}
			if (path === 'country') {
				pay_values = {
					onchange_for: 'country',
					filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['sip', 'primary_lang', 'fallback_lang', 'country'], column)])),
				}
				filtArray = ['distributor_id', 'brand', 'longtail_brand', 'comp_brand']
			}
			if (path === 'distributor_id') {
				pay_values = {
					onchange_for: 'distributor_id',
					filters: _.filter(initial_values, ({ column }) =>
						_.every([_.includes(['sip', 'primary_lang', 'fallback_lang', 'country', 'distributor_id'], column)])
					),
				}
				filtArray = ['brand', 'longtail_brand', 'comp_brand']
			}
			if (path === 'brand') {
				pay_values = {
					onchange_for: 'brand',
					filters: _.filter(initial_values, ({ column }) =>
						_.every([_.includes(['sip', 'primary_lang', 'fallback_lang', 'country', 'distributor_id', 'brand'], column)])
					),
				}
				filtArray = ['longtail_brand', 'comp_brand']
			}
			if (path === 'longtail_brand') {
				pay_values = {
					onchange_for: 'longtail_brand',
					filters: _.filter(initial_values, ({ column }) =>
						_.every([_.includes(['sip', 'primary_lang', 'fallback_lang', 'country', 'distributor_id', 'brand', 'longtail_brand'], column)])
					),
				}
				filtArray = ['comp_brand']
			}
			filtArray.forEach((key) => {
				api.filterOnChange(
					pay_values,
					key,
					(res) => {
						let resp = res.data.data
						// setmatchmissData(resp)
						setFilters((prevState) => ({
							...prevState,
							...resp,
						}))
						// setValue(!_.isEmpty(resp.mpn) ? resp.mpn : !_.isEmpty(resp.ean) ? resp.ean : resp.product_title)
						// console.log(resp, key)
					},
					(err) => {}
				)
			})
		}
	}

	const onSkipClick = () => {
		let pay_values = {
			matchmiss_data: matchmissData,
		}

		api.matchmissSkip(
			pay_values,
			(res) => {
				updateClick()
			},
			(err) => {}
		)
	}
	const onSaveClick = () => {
		let es_data = _.filter(cardData, ({ product_id }) => _.every([_.includes([matchData], product_id)]))
		let resp = es_data[0]
		let cust_data = {
			// _id: _.isEmpty(resp._id) || resp._id === null ? '' : resp._id,
			category: _.isEmpty(resp.category) || resp.category === null ? '' : resp.category,
			created_at: _.isEmpty(resp.created_at) || resp.created_at === null ? '' : resp.created_at,
			ean: _.isEmpty(resp.ean) || resp.ean === null ? '' : resp.ean,
			inpage_link: _.isEmpty(resp.inpage_link) || resp.inpage_link === null ? '' : resp.inpage_link,
			inpage_status: _.isEmpty(resp.inpage_status) || resp.inpage_status === null ? '' : resp.inpage_status,
			language: _.isEmpty(resp.language) || resp.language === null ? '' : resp.language,
			language_code: _.isEmpty(resp.language_code) || resp.language_code === null ? '' : resp.language_code,
			language_id: resp.language_id === null ? '' : _.toString(resp.language_id),
			manufacturer: _.isEmpty(resp.manufacturer) || resp.manufacturer === null ? '' : resp.manufacturer,
			minisite_link: _.isEmpty(resp.minisite_link) || resp.minisite_link === null ? '' : resp.minisite_link,
			minisite_status: _.isEmpty(resp.minisite_status) || resp.minisite_status === null ? '' : resp.minisite_status,
			mpn: _.isEmpty(resp.mpn) || resp.mpn === null ? '' : resp.mpn,
			product_desc: _.isEmpty(resp.product_desc) || resp.product_desc === null ? '' : resp.product_desc,
			product_id: resp.product_id === null ? '' : _.toString(resp.product_id),
			product_image: _.isEmpty(resp.product_image) || resp.product_image === null ? '' : resp.product_image,
			product_title: _.isEmpty(resp.product_title) || resp.product_title === null ? '' : resp.product_title,
			updated_at: _.isEmpty(resp.updated_at) || resp.updated_at === null ? '' : resp.updated_at,
		}
		let pay_values = {
			matchmiss_data: matchmissData,
			es_matched_data: cust_data,
			search_word: value,
			product_match_type: matchtype,
		}
		api.matchmissSave(
			pay_values,
			(res) => {
				updateClick()
				setmatchData('')
			},
			(err) => {}
		)
	}

	return (
		<div
			className={classnames(classes.boardContainer, {
				[classes.mobileBoardContainerOpened]: filterMenuProps.isOpen,
			})}
		>
			<Box className={classes.contentWrapper} style={{ filter: enupdate === true && 'blur(1px)' }} component='div'>
				<Paper className={classes.paper}>
					<Grid container spacing={3}>
						<Grid item xs={12} lg={12} className={classes.textField}>
							<Typography className={classes.paperTitle} variant='body1'>
								Product Matching:
							</Typography>
						</Grid>
						<Grid container item xs={12} lg={8} className={classes.textField}>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Brand:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography noWrap className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.brand) ? matchmissData?.brand : '-'}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Product Title:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={2} className={classes.textField}>
								<Tooltip title={!_.isEmpty(matchmissData?.product_title) ? matchmissData?.product_title : '-'} classes={{ tooltip: classes.tooltip }}>
									<Typography noWrap className={classes.title} variant='body1'>
										{!_.isEmpty(matchmissData?.product_title) ? matchmissData?.product_title : '-'}
									</Typography>
								</Tooltip>
							</Grid>
							<Grid item xs={12} lg={1} className={classes.textField}>
								{!_.isEmpty(matchmissData?.product_title) && (
									<Icon fontSize='small' onClick={onClipboardClick}>
										content_copy
									</Icon>
								)}
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Price:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.price) ? matchmissData?.price : '-'}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Primary Language:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.primary_lang) ? matchmissData?.primary_lang : '-'}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Distributor Id:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.title} variant='body1'>
									{!_.isEmpty(_.toString(matchmissData?.distributor_id)) ? _.toString(matchmissData?.distributor_id) : '-'}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Secondary Language:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.secondary_lang) ? matchmissData?.secondary_lang : '-'}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Impression:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.title} variant='body1'>
									{!_.isEmpty(_.toString(matchmissData?.impression)) ? _.toString(matchmissData?.impression) : '-'}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									MPN:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography noWrap className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.mpn) ? matchmissData?.mpn : '-'}
								</Typography>
							</Grid>

							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									EAN:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography noWrap className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.ean) ? matchmissData?.ean : '-'}
								</Typography>
							</Grid>

							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Page URL:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Tooltip title={!_.isEmpty(matchmissData?.page_url) ? matchmissData?.page_url : '-'} classes={{ tooltip: classes.tooltip }}>
									<Typography noWrap className={classes.title} variant='body1'>
										<a style={{ textDecoration: 'none' }} target='_blank' href={matchmissData?.page_url}>
											{!_.isEmpty(matchmissData?.page_url) ? matchmissData?.page_url : '-'}
										</a>
									</Typography>
								</Tooltip>
							</Grid>
						</Grid>
						<Grid container item xs={12} lg={4} className={classes.textField}>
							<Grid item xs={12} lg={6} className={classes.textField}>
								<img src={!_.isEmpty(matchmissData?.product_img) ? matchmissData?.product_img : NoImage} width={200} height={200} />
							</Grid>
						</Grid>
					</Grid>
				</Paper>
				<Paper className={classes.paper}>
					<Grid container spacing={3} className={classes.formControl}>
						<Grid item xs={12} lg={4} className={classes.searchBox}>
							<input
								style={{ width: '80%' }}
								value={value}
								onChange={(e) => onChangeSearch(e.target.value)}
								placeholder='Search by MPN / EAN / Product Title'
							/>
						</Grid>
						<Grid item xs={12} lg={2}>
							<SelectBox
								label='Type'
								name='test_type'
								value={matchtype}
								onChange={(e) => setMatchtype(e)}
								options={_.values(PRODUCT_MATCH_TYPE)}
							></SelectBox>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.totalLength}>
							<Typography className={classes.title} variant='body1'>
								Total Search Results: {count}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3}>
							<TablePagination
								component='div'
								count={count}
								rowsPerPageOptions={[]}
								page={currentPage}
								onChangePage={onChangePage}
								rowsPerPage={limit}
								// onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						</Grid>
						{_.map(cardData, (card) => (
							<Grid item xs={12} lg={2} className={classes.textField}>
								<Card data={card} onMatchClick={onMatchClick} Match={matchData} />
							</Grid>
						))}
					</Grid>
					<Grid container spacing={3} className={classes.formControl}>
						<Grid item xs={12} lg={6} className={classes.actionButton}>
							<Button style={{ fontWeight: 'bold' }} color='primary' onClick={onSkipClick}>
								Skip
							</Button>
						</Grid>
						<Grid item xs={12} lg={6} className={classes.actionButton}>
							<Button style={{ fontWeight: 'bold' }} color='primary' onClick={onSaveClick} disabled={matchData === ''}>
								Save & Next
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Box>
			<Grow in={enupdate} unmountOnExit>
				<Fab variant='extended' className={classes.fabUpdate} color='primary' size='small' onClick={() => updateClick()}>
					<Icon>update</Icon>Update
				</Fab>
			</Grow>
			<Grow in={!filterMenuProps.isOpen} unmountOnExit>
				<Fab
					className={classes.fab}
					color='primary'
					size='small'
					onClick={() => {
						setMenuProps((prev) => ({
							...prev,
							isOpen: true,
						}))
					}}
				>
					{isFilterActive ? (
						<Badge color='error' badgeContent={''} variant='dot'>
							<Icon>filter_list</Icon>
						</Badge>
					) : (
						<Icon>filter_list</Icon>
					)}
				</Fab>
			</Grow>
			{filterMenuProps.isOpen && (
				<div className={classnames(classes.menuContainer, { [classes.openMenu]: filterMenuProps.isOpen })}>
					<FilterMenuList
						token={authToken}
						incExc={incExc}
						setincExc={setincExc}
						activeFilters={filter?.filterData}
						activeOKFilters={Okfilter}
						setMenuProps={setMenuProps}
						filters={filters}
						updateFunc={updateFilter}
						updateOKFunc={updateOKFilter}
						setEnupdate={setEnupdate}
						onOkClick={onOkClick}
						onChangeFilter={onChangeFilter}
						InitialFilter={initialFilter}
						setFilter={setFilter}
					/>
				</div>
			)}
		</div>
	)
}
