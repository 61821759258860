import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	fetchUser: (userId, params = {}) => {
		return Axios.get(API_ENDPOINTS.USER_PATH_API, {
			params,
			headers: { isAuthRequired: true, path: { userId } },
		})
	},
	fetchAllUsers: (filterParams, cancelSource) => {
		return Axios.get(API_ENDPOINTS.USER_SEARCH, {
			params: filterParams,
			cancelToken: cancelSource.token,
			headers: { isAuthRequired: true },
		})
	},
	saveUser: (userDetails) => {
		const formData = new FormData()
		const formDataKeys = _.keys(userDetails)
		for (let i = 0; i < formDataKeys.length; i++) formData.append(formDataKeys[i], userDetails[formDataKeys[i]])
		return Axios.post(API_ENDPOINTS.USER_API, formData, {
			headers: { isAuthRequired: true, 'Content-Type': 'multipart/form-data' },
		})
	},
	updateUser: (userId, userDetails) => {
		const formData = new FormData()
		const formDataKeys = _.keys(userDetails)
		for (let i = 0; i < formDataKeys.length; i++) {
			const value = userDetails[formDataKeys[i]]
			formData.append(formDataKeys[i], _.isNull(value) ? '' : value)
		}
		return Axios.put(API_ENDPOINTS.USER_PATH_API, formData, {
			headers: {
				isAuthRequired: true,
				path: { userId },
				'Content-Type': 'multipart/form-data',
			},
		})
	},
	getSpecPermissions: (userId) => {
		return Axios.get(API_ENDPOINTS.USER_PERMISSIONS_API, {
			headers: { isAuthRequired: true, path: { userId } },
		})
	},
	updateSpecPermissions: (userId, permissions) => {
		return Axios.put(
			API_ENDPOINTS.USER_PERMISSIONS_API,
			{ modules: permissions },
			{
				headers: {
					isAuthRequired: true,
					path: { userId },
					'Content-Type': 'application/json',
				},
			}
		)
	},
	deleteUser: (userId, params) => {
		return Axios.delete(API_ENDPOINTS.USER_PATH_API, {
			params,
			headers: { isAuthRequired: true, path: { userId } },
		})
	},
	checkUserDependencies: (userId) => {
		return Axios.get(API_ENDPOINTS.USER_DEPENDENCIES_API, {
			headers: { isAuthRequired: true, path: { userId } },
		})
	},
	fetchAllUserRoles: () => {
		return Axios.post(API_ENDPOINTS.USER_ALL_ROLES, {}, { headers: { isAuthRequired: true, 'Content-Type': 'application/json' } })
	},
	fetchAllUserTeamUnits: () => {
		return Axios.get(API_ENDPOINTS.USER_ALL_TEAM_UNITS, {
			params: {},
			headers: { isAuthRequired: true },
		})
	},
}

export default api
