import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import { Switch, IconButton, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import Api from '../../../services/aiqa/PrMatch/api'
import Axios from 'axios'
import { useDispatch } from 'react-redux'
import Form from './Form'
import { hideLoader, showLoader } from '../../../services/loader/actions'

const CancelToken = Axios.CancelToken

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 350,
		maxHeight: 250,
		margin: '16px auto',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.light}52`,
			cursor: 'pointer',
		},
	},
	container: {
		// paddingTop: 50,
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	avatar: {
		width: 60,
		height: 60,
		[theme.breakpoints.down('xs')]: {
			width: 40,
			height: 40,
		},
	},
	title: {
		marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '16px',
		},
	},
	subtitle: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	tabWrapper: {
		paddingTop: theme.spacing(2),
		'& .MuiTabs-scrollable': {
			display: 'flex',
			justifyContent: 'space-around',
		},
	},
	tab: {
		letterSpacing: '2px',
		fontSize: '12px',
		'& span': {
			verticalAlign: 'middle',
		},
	},
	tabIco: {
		paddingRight: theme.spacing(1),
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	pos: {
		marginBottom: 12,
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
	switch_track: {
		backgroundColor: '#9f9f9f',
	},
	switch_base: {
		color: '#9f9f9f',
		'&.Mui-disabled': {
			color: '#9f9f9f',
		},
		'&.Mui-checked': {
			color: '#41e886',
		},
		'&.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#41e886',
		},
	},
	switch_primary: {
		'&.Mui-checked': {
			color: '#41e886',
		},
		'&.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#41e886',
		},
	},
}))

function Dashboard(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const cancelDashLstReq = useRef(null)
	const renderAfterCalled = useRef(false)
	const [usersTblState, setUsersTblState] = useState({
		pending: 0,
		completed: 0,
	})
	const [bucket1, setBucket1] = useState({})
	const [bucket2, setBucket2] = useState({})
	const [bucket3, setBucket3] = useState({})
	const [bucket4, setBucket4] = useState({})
	const [selectedbatch, setSelectedbatch] = useState([])
	const { pending, completed } = usersTblState
	const { perms } = props

	const result = _.get(props.match, 'params.result')

	const getBucketDetails = () => {
		Api.GetBucDetails(
			'all',
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', {})
				setBucket1((prevState) => ({
					...prevState,
					...testList[0],
				}))
				setBucket2((prevState) => ({
					...prevState,
					...testList[1],
				}))
				setBucket3((prevState) => ({
					...prevState,
					...testList[2],
				}))
			},
			(err) => {
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
	}

	const onToggleChange = (id) => {
		dispatch(showLoader(`Please wait, enabling bucket ${id}`))
		Api.PutBucDetails(
			id,
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', {})
				getBucketDetails()
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
	}

	let mapforInfo = _.toString(_.map(selectedbatch, (list) => list.name))

	let infoBuc1 = (
		<p>
			Conditions <br />
			<br />
			Data Batch numbers : {mapforInfo} <br /> <br />
			Is-brand-category-black-list = No ( Derived from the view_brand_category_whitelable where each of the category specific to Brand relationship is
			captured and if the prediction of category falls under correct category to brand mapping blacklist for the record is no ) <br />
			<br />
			Is-brand—price-black-list = No ( Derived from the view_brand_category_whitelable where each of the category Max and Min Price is captured and if
			the prediction of category falls under correct category to Price mapping blacklist for the record is no ) <br />
			<br />
			Category names : "Mobile & Smartphones", "Tvs", "Hobs", "Cooker Hoods", "Fridge-Freezers", "Fridge", "Freezer", "Dishwashers", "Tumble Dryers",
			"Washing Machines" <br />
			<br />
			Page rank Ascending <br />
		</p>
	)

	let infoBuc2 = (
		<p>
			Conditions <br />
			<br />
			Data Batch numbers : {mapforInfo} <br /> <br />
			Is-brand-category-black-list = Yes ( Derived from the view_brand_category_whitelable where each of the category specific to Brand relationship
			is captured and if the prediction of category with no mapping to brand, blacklist for the record is no ) <br />
			<br />
			Is-brand—price-black-list = Yes ( Derived from the view_brand_category_whitelable where each of the category Max and Min Price is captured and
			if the prediction of category with no mapping to price , blacklist for the record is no ) <br />
			<br />
			Category names : 'Mobile & Smartphones','Tvs','Hobs','Cooker Hoods','Fridge-Freezers','Fridge','Freezer','Dishwashers','Tumble Dryers','Washing
			Machines' <br />
			<br />
			Page rank Ascending <br />
		</p>
	)

	let infoBuc3 = (
		<p>
			Conditions <br />
			<br />
			Data Batch numbers : {mapforInfo} <br /> <br />
			Category name = others <br />
			<br />
			Brand category black list = yes <br />
			<br />
		</p>
	)

	useEffect(() => {
		if (!renderAfterCalled.current) {
			getBucketDetails()
		}
		renderAfterCalled.current = true
	}, [])

	return (
		<div className={clsx(classes.container)}>
			<Grid container spacing={2}>
				<Grid container item xs={12} lg={4}>
					<Grid item xs={12}>
						<Card className={clsx(classes.root)} style={{ color: result === 'live' && '#41e886' }}>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={10}>
										<Typography className={classes.paperTitle} variant='body1'>
											Bucket 1:
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<Tooltip title={infoBuc1} classes={{ tooltip: classes.tooltip }}>
											<IconButton size='small'>
												<i className='material-icons'>info</i>
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Typography component='h5' variant='h5' className='card-title'>
									Total Records - <strong>{bucket1?.total_cnt}</strong>
								</Typography>
								<Typography component='h5' variant='h5' className='card-title'>
									Total Pending - <strong>{bucket1?.pending_cnt}</strong>
								</Typography>
								{perms.create === true ? (
									<Typography component='div' style={{ marginLeft: '100px' }}>
										<Grid component='label' container alignItems='center' spacing={1}>
											<Grid item>Off</Grid>
											<Grid item>
												<Switch
													inputProps={{ 'aria-label': 'primary checkbox' }}
													classes={{
														track: classes.switch_track,
														switchBase: classes.switch_base,
														colorPrimary: classes.switch_primary,
													}}
													checked={bucket1?.flow_status === 'On'}
													disabled={bucket1?.flow_status === 'On'}
													onChange={() => {
														onToggleChange(bucket1?.id)
													}}
												/>
											</Grid>
											<Grid item>On</Grid>
										</Grid>
									</Typography>
								) : (
									<Typography noWrap className={classes.title} variant='body1' style={{ marginLeft: '110px', fontWeight: 'bold' }}>
										{bucket1?.flow_status}
									</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid container item xs={12} lg={4}>
					<Grid item xs={12}>
						<Card className={clsx(classes.root)} style={{ color: result === 'live' && '#41e886' }}>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={10}>
										<Typography className={classes.paperTitle} variant='body1'>
											Bucket 2:
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<Tooltip title={infoBuc2} classes={{ tooltip: classes.tooltip }}>
											<IconButton size='small'>
												<i className='material-icons'>info</i>
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Typography component='h5' variant='h5' className='card-title'>
									Total Records - <strong>{bucket2?.total_cnt}</strong>
								</Typography>
								<Typography component='h5' variant='h5' className='card-title'>
									Total Pending - <strong>{bucket2?.pending_cnt}</strong>
								</Typography>
								{perms.create === true ? (
									<Typography component='div' style={{ marginLeft: '100px' }}>
										<Grid component='label' container alignItems='center' spacing={1}>
											<Grid item>Off</Grid>
											<Grid item>
												<Switch
													inputProps={{ 'aria-label': 'primary checkbox' }}
													classes={{
														track: classes.switch_track,
														switchBase: classes.switch_base,
														colorPrimary: classes.switch_primary,
													}}
													checked={bucket2?.flow_status === 'On'}
													disabled={bucket2?.flow_status === 'On'}
													onChange={() => {
														onToggleChange(bucket2?.id)
													}}
												/>
											</Grid>
											<Grid item>On</Grid>
										</Grid>
									</Typography>
								) : (
									<Typography noWrap className={classes.title} variant='body1' style={{ marginLeft: '110px', fontWeight: 'bold' }}>
										{bucket2?.flow_status}
									</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid container item xs={12} lg={4}>
					<Grid item xs={12}>
						<Card className={clsx(classes.root)} style={{ color: result === 'live' && '#41e886' }}>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={10}>
										<Typography className={classes.paperTitle} variant='body1'>
											Bucket 3:
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<Tooltip title={infoBuc3} classes={{ tooltip: classes.tooltip }}>
											<IconButton size='small'>
												<i className='material-icons'>info</i>
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
								<Typography component='h5' variant='h5' className='card-title'>
									Total Records - <strong>{bucket3?.total_cnt}</strong>
								</Typography>
								<Typography component='h5' variant='h5' className='card-title'>
									Total Pending - <strong>{bucket3?.pending_cnt}</strong>
								</Typography>
								{perms.create === true ? (
									<Typography component='div' style={{ marginLeft: '100px' }}>
										<Grid component='label' container alignItems='center' spacing={1}>
											<Grid item>Off</Grid>
											<Grid item>
												<Switch
													inputProps={{ 'aria-label': 'primary checkbox' }}
													classes={{
														track: classes.switch_track,
														switchBase: classes.switch_base,
														colorPrimary: classes.switch_primary,
													}}
													checked={bucket3?.flow_status === 'On'}
													disabled={bucket3?.flow_status === 'On'}
													onChange={() => {
														onToggleChange(bucket3?.id)
													}}
												/>
											</Grid>
											<Grid item>On</Grid>
										</Grid>
									</Typography>
								) : (
									<Typography noWrap className={classes.title} variant='body1' style={{ marginLeft: '110px', fontWeight: 'bold' }}>
										{bucket3?.flow_status}
									</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				{/* <Grid container item xs={12} lg={3}>
					<Grid item xs={12}>
						<Card className={clsx(classes.root)} style={{ color: result === 'live' && '#41e886' }}>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={10}>
										<Typography className={classes.paperTitle} variant='body1'>
											Bucket 4:
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<IconButton size='small' title='Show Actions'>
											<i className='material-icons'>info</i>
										</IconButton>
									</Grid>
								</Grid>
								<Typography component='h5' variant='h5' title='Live Tests' className='card-title'>
									Total Records - <strong>{completed}</strong>
								</Typography>
								<Typography component='h5' variant='h5' title='Live Tests' className='card-title'>
									Total Pending - <strong>{completed}</strong>
								</Typography>
								<Typography component='div' style={{ marginLeft: '70px' }}>
									<Grid component='label' container alignItems='center' spacing={1}>
										<Grid item>Off</Grid>
										<Grid item>
											<Switch
												inputProps={{ 'aria-label': 'primary checkbox' }}
												classes={{
													track: classes.switch_track,
													switchBase: classes.switch_base,
													colorPrimary: classes.switch_primary,
												}}
												// checked={props?.incExc?.[props?.path] === 'exclude'}
												// onChange={() => {
												// 	resetincExc(props?.path, props?.defaultEmptyValue || [])
												// 	setokFilter(true)
												// }}
											/>
										</Grid>
										<Grid item>On</Grid>
									</Grid>
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid> */}
			</Grid>
			<Form selectedbatch={selectedbatch} setSelectedbatch={setSelectedbatch} />
		</div>
	)
}

export default Dashboard
