

import { combineReducers } from 'redux'
import session from '../session/reducers'
import snackbar from '../snackbar/reducers'
import snackbarAdvance from '../snackbarAdvance/reducers'
import loader from '../loader/reducers'

export default combineReducers({
	session,
	snackbar,
	snackbarAdvance,
	loader,
})
