import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import { logout, dismissLogOutError } from './../../services/session/actions'
import { fetchProfile, resetProfile } from './../../services/session/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import Icon from '@material-ui/core/Icon'
import Hidden from '@material-ui/core/Hidden'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Skeleton from '@material-ui/lab/Skeleton'
import classNames from 'classnames'
import NavList from './../../components/Drawer'
import logo from './../../images/logo.jpg'
import favicon from './../../images/favicon.ico'
import _ from 'lodash'
import { Avatar, ListItemSecondaryAction } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { PERMISSION_TYPE, ROLE_MODULES } from './../../constants/modules'
import { isModuleAccessGranted, getImgUrl, stringToHslColor, addHttp } from './../../utils'
import BreadCrumb from './../../components/BreadCrumb'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

const mapStateToProps = (state) => ({
	isLoggingOut: state.session.isLoading,
	sisenseToken: state.session.sisenseToken,
	logOutError: state.session.error,
	isShowLogOutError: state.session.showLogOutError,
	user: state.session.user,
	appSettings: state.appSettings,
})

const mapDispatchToProps = (dispatch) => ({
	logout: (sisToken) => dispatch(logout(sisToken)),
	dismissLogOutError: () => dispatch(dismissLogOutError()),
	fetchProfile: () => dispatch(fetchProfile()),
	resetProfile: () => dispatch(resetProfile()),
})

const MasterList = [
	{
		// title: 'BB Prediction',
		// titleIcon: 'aspect_ratio',
		// dropdown: false,
		// moduleList: [ROLE_MODULES.AIQA_DIMENSION],
		// menus: [
		// 	{
		// 		menuText: '1. QA',
		// 		moduleList: [ROLE_MODULES.AIQA_DIMENSION],
		// 		// menuIcon: 'shopping_basket',
		// 		linkTo: '/aiqa_tool/product_qa/qa',
		// 	},
		// 	{
		// 		menuText: '2. Settings',
		// 		moduleList: [ROLE_MODULES.AIQA_DIMENSION],
		// 		// menuIcon: 'supervised_user_circle',
		// 		linkTo: '/aiqa_tool/product_qa/settings',
		// 	},
		// 	{
		// 		menuText: '3. Report',
		// 		moduleList: [ROLE_MODULES.AIQA_DIMENSION],
		// 		// menuIcon: 'assessment',
		// 		linkTo: '/aiqa_tool/product_qa/report',
		// 	},
		// ],
		menuText: 'BB Prediction',
		moduleList: [ROLE_MODULES.AIQA_BB],
		dropdown: false,
		menuIcon: 'format_list_bulleted',
		linkTo: '/aiqa_tool/bb_prediction/qa',
	},
	{
		title: 'Dimensions',
		titleIcon: 'aspect_ratio',
		dropdown: true,
		moduleList: [ROLE_MODULES.AIQA_DIMENSION],
		menus: [
			{
				menuText: '1. Products',
				moduleList: [ROLE_MODULES.AIQA_DIMENSION],
				// menuIcon: 'shopping_basket',
				linkTo: '/aiqa_tool/dimension/products/pending_products',
			},
			{
				menuText: '2. Distributors',
				moduleList: [ROLE_MODULES.AIQA_DIMENSION],
				// menuIcon: 'supervised_user_circle',
				linkTo: '/aiqa_tool/dimension/distributors/pending_distributors',
			},
			{
				menuText: '3. Report',
				moduleList: [ROLE_MODULES.AIQA_DIMENSION],
				// menuIcon: 'assessment',
				linkTo: '/aiqa_tool/dimension/report/products_report',
			},
		],
	},
	{
		title: 'Master',
		titleIcon: 'list_alt',
		dropdown: true,
		moduleList: [ROLE_MODULES.AIQA_MASTER],
		menus: [
			{
				menuText: '1. Dimension Category',
				moduleList: [ROLE_MODULES.AIQA_MASTER],
				// menuIcon: 'category',
				linkTo: '/aiqa_tool/master/dimension_category/parent_category',
			},
			{
				menuText: '2. BB Category',
				moduleList: [ROLE_MODULES.AIQA_MASTER],
				// menuIcon: 'category',
				linkTo: '/aiqa_tool/master/bb_category/parent_category',
			},
			{
				menuText: '3. BB Brand',
				moduleList: [ROLE_MODULES.AIQA_MASTER],
				// menuIcon: 'category',
				linkTo: '/aiqa_tool/master/bb_brand/parent_brand',
			},
			// {
			// 	menuText: 'Sub Category 2',
			// 	// moduleList: [ROLE_MODULES.AB_TEST],
			// 	menuIcon: 'home',
			// 	linkTo: '/aiqa_tool/subcategory_post',
			// },
		],
	},
]

const prmatchingList = [
	{
		menuText: 'Home',
		// moduleList: [ROLE_MODULES.AB_TEST],
		menuIcon: 'home',
		linkTo: '/home',
	},
	{
		menuText: 'A/B Test',
		moduleList: [ROLE_MODULES.AB_TEST],
		menuIcon: 'flip',
		linkTo: '/ab-test-tool/dashboard/pre_live',
	},
	{
		menuText: 'Product Match',
		moduleList: [ROLE_MODULES.PRODUCT_MATCHING],
		menuIcon: 'dynamic_feed',
		linkTo: '/product_match/matching_process',
	},
	{
		menuText: 'BOT',
		moduleList: [ROLE_MODULES.BOT_MONITORING],
		menuIcon: 'precision_manufacturing',
		linkTo: '/bot/monitor',
	},
	// {
	// 	menuText: 'Report',
	// 	moduleList: [ROLE_MODULES.PRODUCT_MATCHING],
	// 	menuIcon: 'assessment',
	// 	linkTo: '/product_matching/report',
	// },
]

const aiqaList = [
	{
		menuText: 'Dashboard',
		moduleList: [ROLE_MODULES.AIQA_TOOL],
		menuIcon: 'dashboard',
		linkTo: '/dashx',
	},
]

const abtestList = [
	{
		menuText: 'A/B Test',
		moduleList: [ROLE_MODULES.AB_TEST],
		menuIcon: 'flip',
		linkTo: '/ab-test-tool/dashboard/pre_live',
	},
]

const usermanagementList = [
	{
		menuText: 'Users',
		moduleList: [ROLE_MODULES.USER_MANAGEMENT],
		menuIcon: 'people',
		linkTo: '/usermanagement/users',
	},
	{
		menuText: 'Settings',
		moduleList: [ROLE_MODULES.ROLE_MANAGEMENT],
		menuIcon: 'settings',
		linkTo: '/usermanagement/roles',
	},
]

const getOnlyAllowedMenus = (menuList, userPermissions) => {
	const checkModuleIsAllowed = (moduleName) => isModuleAccessGranted(moduleName, PERMISSION_TYPE.VIEW, userPermissions)
	return _.filter(menuList, (menu) => {
		const mouduleList = _.get(menu, 'moduleList', [])
		return _.isEmpty(mouduleList) ? true : _.some(mouduleList, (moduleName) => checkModuleIsAllowed(moduleName))
	})
}

//styles for accounts popup
const useStylesAccPopup = makeStyles((theme) => ({
	popper: {
		top: '10px!important',
	},
	paper: {
		boxShadow: '0 1px 2px 0 rgba(60,64,67,0.10), 0 2px 6px 2px rgba(60,64,67,0.15)',
	},
	container: {
		minWidth: '250px',
	},
	profileContent: {
		padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
		textAlign: 'center',
	},
	listItem: {
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	profileFooter: {
		paddingBottom: theme.spacing(2),
		textAlign: 'center',
	},
	secondayInfo: {
		color: theme.palette.grey[500],
	},
	avatar: {
		margin: '8px auto 12px',
		background: theme.palette.warning.main,
		textTransform: 'uppercase',
		width: '83px',
		height: '83px',
	},
	logoutBtn: {
		textTransform: 'none',
		marginTop: theme.spacing(1),
		fontWeight: 'bold',
	},
}))

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: 'white',
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
		color: 'black',
	},
	infoButton: {
		marginRight: 40,
		color: 'green',
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		'& .MuiDrawer-paper': {
			backgroundColor: '#41e886',
			color: 'black',
		},
		'& .MuiListItem-root': {
			'& .Mui-selected': {
				'&:hover': {
					backgroundColor: '#41e886',
					color: 'black',
				},
			},
		},
	},
	drawerOpen: {
		width: drawerWidth,
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(8) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// background: 'white',
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}))

const Header = ({
	user,
	logout,
	dismissLogOutError,
	history,
	fetchProfile,
	resetProfile,
	appSettings,
	isLoggingOut,
	logOutError,
	sisenseToken,
	isShowLogOutError,
	location,
	isSocketConnected,
	socketRef,
	openIframeDialog,
	module,
}) => {
	const classes = useStyles()
	const theme = useTheme()
	const [open, setOpen] = React.useState(false)

	const handleDrawerOpen = () => {
		setOpen(true)
	}

	const path = _.split(window?.location.pathname, '/')

	const handleDrawerClose = () => {
		setOpen(false)
	}

	const accountAchorRef = React.useRef(null)

	const infoAchorRef = React.useRef(null)

	const token = useSelector((state) => _.get(state, 'session.authToken', ''))

	const openNewLinkOnNewTab = (link) => {
		window.open(addHttp(link), '_blank')
	}

	const [state, setState] = useState({
		openSettingsMenus: false,
		isDrawerOpened: false,
		openAccountPopup: false,
		openInfoPopup: false,
		openNotifications: false,
	})

	const { isDrawerOpened, openAccountPopup, openInfoPopup } = state

	const toggleDrawer = useCallback(() => {
		setState((prevState) => ({ ...prevState, isDrawerOpened: !isDrawerOpened }))
	}, [isDrawerOpened])

	const closeAllPopups = useCallback(() => {
		setState((prevState) => ({
			...prevState,
			isDrawerOpened: false,
			openSettingsMenus: false,
			openAccountPopup: false,
			openInfoPopup: false,
			openNotifications: false,
		}))
	}, [])

	const performLogout = useCallback(() => {
		//CALL LOGOUT FUNCTION
		toggleDrawer()
		let sisToken = {
			sisense_token: sisenseToken,
		}
		logout(sisToken)
		// window.location.replace(process.env.REACT_APP_SISENSE_URL)
		resetProfile()
	}, [logout, resetProfile, toggleDrawer])

	const performDismissLogoutErr = useCallback(() => {
		if (isShowLogOutError) dismissLogOutError()
	}, [isShowLogOutError, dismissLogOutError])

	const redirectToLink = useCallback(
		(link, isOpenNewTab) => {
			closeAllPopups()
			if (typeof link === 'function') {
				link()
			} else if (isOpenNewTab) {
				openNewLinkOnNewTab(link)
			} else link && history.push(link)
		},
		[history, closeAllPopups]
	)

	const fetchUserDetails = useCallback(() => {
		fetchProfile()
	}, [fetchProfile])

	useEffect(() => {
		fetchUserDetails()
	}, [fetchUserDetails])

	const activeLink = location.pathname
	const { isFetchingUser, userDetails } = user

	const userDisplayName = _.startCase(_.join([_.get(userDetails, 'first_name', '#'), _.get(userDetails, 'last_name')], ' '))
	const avatarChar = userDisplayName.substring(0, 1)

	const togglePopup = (key, val = null) => {
		val != null ? setState({ ...state, [key]: !state[key] }) : setState({ ...state, [key]: false })
	}

	const userProfilePic = _.get(userDetails, 'profile_pic_url') && getImgUrl(token, _.get(userDetails, 'profile_pic_url'), 50)

	const userEmail = _.get(userDetails, 'email', '-')
	const tenentName = useSelector((state) => _.get(state, 'appSettings.data.tenant_name', ''))

	const userPermissions = _.get(user, 'userDetails.module_permissions', [])

	let pr_match_report_access = isModuleAccessGranted('pr_matching', PERMISSION_TYPE.EXPORT, userPermissions)

	const home = getOnlyAllowedMenus(MasterList, userPermissions)
	const aiqa = getOnlyAllowedMenus(aiqaList, userPermissions)
	const abtest = getOnlyAllowedMenus(abtestList, userPermissions)
	const usermanagement = getOnlyAllowedMenus(usermanagementList, userPermissions)
	const productmatching = getOnlyAllowedMenus(prmatchingList, userPermissions)

	return (
		<div style={{ marginLeft: '22px', marginTop: '10px' }}>
			<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 700, fontSize: '28px', color: '#0E0E2C' }}>{_.startCase(module)}</div>
			<div className={classes.root}>
				{/* <CssBaseline /> */}
				<Helmet>
					<title>{_.isEmpty(_.get(appSettings, 'data.company_name')) ? 'Flix Lab' : _.get(appSettings, 'data.company_name', 'Flix Lab')}</title>
					{!_.isEmpty(_.get(appSettings, 'data.favicon')) ? (
						<link rel='shortcut icon' sizes='16x16' href={getImgUrl(token, _.get(appSettings, 'data.favicon', ''))} />
					) : (
						<link rel='shortcut icon' sizes='16x16' href={favicon} />
					)}
					{/* // <link rel='shortcut icon' href={!_.isEmpty(_.get(appSettings, 'data.favicon'))?getImgUrl(_.get(appSettings, 'data.favicon','')):'favicon'}></link> */}
				</Helmet>
				{/* <AppBar
				position='fixed'
				// className={clsx(
				// 	classes.appBar,
				// 	{
				// 		[classes.appBarShift]: open,
				// 	},
				// 	'no-shadow'
				// )}
				className={clsx(classes.appBar, 'no-shadow')}
			> */}
				{/* <Toolbar> */}
				{/* <IconButton
						color='inherit'
						aria-label='open drawer'
						onClick={handleDrawerOpen}
						edge='start'
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton> */}

				<Hidden xsDown>
					{/* <BreadCrumb path={_.compact(path)} /> */}
					<Breadcrumbs separator='›' aria-label='breadcrumb'>
						<p style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400, fontSize: '12px', color: '#444444' }}>A/B Test Tool</p>
						<p style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 600, fontSize: '12px', color: '#444444' }}>{_.startCase(module)}</p>
					</Breadcrumbs>
				</Hidden>
				{/* <div className='profile-info'> */}

				{/* </div> */}
				<InfoPopup open={openInfoPopup} anchorEl={infoAchorRef} handleClose={() => togglePopup('openInfoPopup')} />
				<div className='profile-info'>
					{/* <Hidden xsDown> */}
					<Button
						variant='contained'
						onClick={() => history.push('/ab_test_tool/create_test')}
						style={{ backgroundColor: '#374151', color: 'white', textTransform: 'none', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
					>
						Create New test
					</Button>
					<div className='user-name-info'>
						{!isFetchingUser && userDisplayName ? (
							<>
								<Avatar
									onClick={() => togglePopup('openAccountPopup', openAccountPopup)}
									ref={accountAchorRef}
									size='small'
									className={classNames('profile-circle', { active: openAccountPopup }, 'background-circle')}
									// style={{ backgroundColor: stringToHslColor(userDisplayName) }}
									style={{ backgroundColor: '#8C8CA2', width: '40px', height: '40px' }}
									component='div'
									src={userProfilePic}
								>
									<Typography
										variant='caption'
										style={{
											width: '12px',
											height: '28px',
											verticalAlign: '-webkit-baseline-middle',
											fontSize: '18px',
											fontFamily: 'FS-Jack',
											fontStyle: 'normal',
											fontWeight: 700,
										}}
									>
										{avatarChar}
									</Typography>
								</Avatar>
								<AccountPopup
									avatarChar={avatarChar}
									tenentName={tenentName}
									open={openAccountPopup}
									anchorEl={accountAchorRef}
									redirectToLink={redirectToLink}
									handleClose={() => togglePopup('openAccountPopup')}
									userDetails={userDetails}
									userProfilePic={userProfilePic}
									logoutFunc={performLogout}
								/>
							</>
						) : isLoggingOut ? (
							<CircularProgress variant='indeterminate' size={16} thickness={2} />
						) : (
							<Skeleton variant='circle' width={25} height={25} />
						)}
					</div>
					{/* </Hidden> */}
				</div>
				{/* </Toolbar> */}
				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={isShowLogOutError}
					onClose={performDismissLogoutErr}
					autoHideDuration={1000}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id='message-id'>{logOutError && logOutError.message}</span>}
				/>
				{/* </AppBar> */}
			</div>
		</div>
	)
}

const AccountPopup = ({ open, anchorEl, handleClose, redirectToLink, userDetails, avatarChar, userProfilePic, logoutFunc, tenentName }) => {
	const classes = useStylesAccPopup()
	const username = _.startCase(_.join([_.get(userDetails, 'first_name', '#'), _.get(userDetails, 'last_name')], ' '))
	const email = _.get(userDetails, 'email')

	const onClickMyAccSettings = () => {
		redirectToLink('/myaccount')
	}

	return (
		<Popper className={classes.popper} placement='bottom-end' open={open} anchorEl={anchorEl.current} role={undefined} transition disablePortal>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'bottom' ? 'right bottom' : 'right top',
					}}
				>
					<Paper className={classes.paper}>
						<ClickAwayListener onClickAway={handleClose}>
							<div className={classes.container}>
								<div className={classes.profileContent}>
									<Avatar
										size='large'
										className={classes.avatar}
										src={userProfilePic}
										alt={username}
										// style={{ backgroundColor: stringToHslColor(username) }}
										style={{ backgroundColor: '#41e886' }}
									>
										<Typography variant='h3'>{avatarChar}</Typography>
									</Avatar>
									<Typography variant='body1' component={'div'}>
										{username}
									</Typography>
									<Typography className={classes.secondayInfo} variant='body2' component={'div'} gutterBottom>
										{email}
									</Typography>
									{!_.isEmpty(tenentName) && (
										<Typography className={classes.secondayInfo} variant='body2' component={'div'} gutterBottom>
											{`Tenant : ${tenentName}`}
										</Typography>
									)}
								</div>

								{/* <List>
									<ListItem onClick={onClickMyAccSettings} className={classes.listItem} divider button>
										<ListItemIcon>
											<Icon fontSize='small'>settings</Icon>
										</ListItemIcon>
										<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary='Change Password' />
										<ListItemSecondaryAction>
											<Icon fontSize='small'>navigate_next</Icon>
										</ListItemSecondaryAction>
									</ListItem>
								</List> */}
								<div className={classes.profileFooter}>
									<Button onClick={logoutFunc} aria-label='logout' className={classes.logoutBtn} size='small' variant='outlined' color='primary'>
										Sign out
									</Button>
								</div>
							</div>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	)
}

const InfoPopup = ({ open, anchorEl, handleClose }) => {
	const classes = useStylesAccPopup()
	const onClickMyAccSettings = () => {
		// window.open(addHttp(link), '_blank')
	}

	return (
		<Popper className={classes.popper} placement='bottom-end' open={open} anchorEl={anchorEl.current} role={undefined} transition disablePortal>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'bottom' ? 'right bottom' : 'right top',
					}}
				>
					<Paper className={classes.paper}>
						<ClickAwayListener onClickAway={handleClose}>
							<div className={classes.container}>
								<List>
									<ListItem onClick={onClickMyAccSettings} button>
										<ListItemIcon>
											<Icon fontSize='small'>insert_drive_file</Icon>
										</ListItemIcon>
										<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary='A/B Test' />
									</ListItem>
									<ListItem onClick={onClickMyAccSettings} button>
										<ListItemIcon>
											<Icon fontSize='small'>insert_drive_file</Icon>
										</ListItemIcon>
										<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary='Product Matching' />
									</ListItem>
								</List>
							</div>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	)
}

Header.propTypes = {
	isLoggingOut: PropTypes.bool,
	logOutError: PropTypes.object,
	isShowLogOutError: PropTypes.bool,
	isSocketConnected: PropTypes.bool,
	socketRef: PropTypes.any,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
