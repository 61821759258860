import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Form from './Form'
import { login } from '../../services/session/actions'
import { connect } from 'react-redux'

const Login = (props) => {
	const redirectToHomeIfLoggedIn = () => {
		if (props.session.isAuthenticated) {
			// const configLanding = this.props.session.user?.userDetails?.config_landing
			// switch (configLanding) {
			// 	case ROLE_MODULES.DISCOVERX:
			// 		this.props.history.push('/discoverx')
			// 		return
			// default:
			props.history.push('/home?loggedIn=true')
			// 	return
		}
	}

	useEffect(() => {
		redirectToHomeIfLoggedIn()
	}, [props.session.isAuthenticated])

	return (
		<div>
			<Form {...props} />
			{/* <ForgotPasswordPopup open={this.state.openForgotPopup} tenantName={this.state.tenentName} handleClose={this.toggleForgotClick} /> */}
		</div>
	)
}

const mapStateToProps = (state) => ({
	session: state.session,
})

const mapDispatchToProps = (dispatch) => ({
	login: (username, pwd) => dispatch(login(username, pwd)),
})

Login.propTypes = {
	session: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
