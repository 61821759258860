import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import { TextField} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
	},
	roleViewRoot: {
		textAlign: 'center',
	},
	textField: {
		marginBottom: theme.spacing(1),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	statusLabel: {
		paddingLeft: theme.spacing(1),
		color: theme.palette.grey[500],
		'& span': {
			verticalAlign: 'middle',
			paddingRight: theme.spacing(0.5),
		},
	},
	statusIco: {
		color: theme.palette.grey[500],
	},
}))

const RoleDetails = ({ isCreateMode, isEditMode, isViewMode }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<Field type='text' name='role_name'>
				{({ form, field }) => (
					<TextField
						variant='outlined'
						margin='none'
						size='small'
						fullWidth
						name={field.name}
						label={'Role Name*'}
						type='text'
						autoComplete='off'
						onChange={field.onChange}
						onBlur={field.onBlur}
						value={field.value || ''}
						disabled={isViewMode}
						className={classes.textField}
						helperText={form.touched[field.name] && form.errors[field.name]}
						error={Boolean(form.errors[field.name] && form.touched[field.name])}
					/>
				)}
			</Field>
			{/* <Field type='text' name='is_default'>
				{({ field }) =>
					isEditMode || isCreateMode ? (
						<FormControlLabel
							value='end'
							control={
								<Checkbox
									size='small'
									color='primary'
									onChange={(event) =>
										field.onChange({
											target: {
												name: 'is_default',
												value: event.target.checked,
											},
										})
									}
									checked={field.value}
									readOnly={isViewMode}
								/>
							}
							label={'Default'}
							labelPlacement='end'
						/>
					) : (
						<Typography className={classes.statusLabel}>
							<Icon className={classes.statusIco} fontSize='small'>
								{field.value ? 'check_box' : 'check_box_outline_blank'}
							</Icon>
							<span>Default</span>
						</Typography>
					)
				}
			</Field> */}
		</div>
	)
}

RoleDetails.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
}

export default RoleDetails
