/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import Chip from '@material-ui/core/Chip'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import _ from 'lodash'

const useStyles = makeStyles({
	tag: {
		//   height: 24,
		//   position: "relative",
		//   zIndex: 0,
		//   "& .MuiChip-label": {
		// 	color: "#fff",
		//   },
		'& .MuiChip-deleteIcon': {
			color: 'black',
		},
		//   "&:after": {
		// 	content: '""',
		// 	right: 10,
		// 	top: 6,
		// 	height: 12,
		// 	width: 12,
		// 	position: "absolute",
		// 	backgroundColor: "white",
		// 	zIndex: -1,
		//   },
	},
	inputText: {
		'&::placeholder': {
			fontFamily: 'FS-Jack',
			fontStyle: 'normal',
			fontWeight: 400,
		},
	},
})

const MultiSelect = ({
	items,
	label,
	value,
	name,
	disabled,
	placeholder = '',
	onBlur,
	helperText,
	error,
	selectAllLabel,
	noOptionsText,
	limitTags,
	onChange,
	disableClearable,
	onInputChange = () => null,
	isLoading = false,
}) => {
	const [selectedOptions, setSelectedOptions] = useState(value || [])
	const classes = useStyles()
	const allSelected = items?.length === selectedOptions?.length
	const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions)
	const handleClearOptions = () => setSelectedOptions([])
	const getOptionLabel = (option) => `${option.name}`
	const handleSelectAll = (isSelected) => {
		if (isSelected) {
			setSelectedOptions(items)
		} else {
			handleClearOptions()
		}
	}

	useEffect(() => {
		setSelectedOptions(value)
	}, [value])

	const handleToggleSelectAll = () => {
		handleSelectAll && handleSelectAll(!allSelected)
	}

	const handleChange = (event, selectedOptions, reason) => {
		if (reason === 'select-option' || reason === 'remove-option') {
			if (selectedOptions.find((option) => option.id === 'all')) {
				handleToggleSelectAll()
				let result = []
				result = items.filter((el) => el.value !== 'all')
				return onChange(result)
			} else {
				handleToggleOption && handleToggleOption(selectedOptions)
				return onChange(selectedOptions)
			}
		} else if (reason === 'clear') {
			handleClearOptions && handleClearOptions()
			let result = []
			// result = items.filter((el) => el.value !== 'all')
			return onChange(result)
		}
	}
	const optionRenderer = (option, { selected }) => {
		const selectAllProps =
			option.id === 'all' // To control the state of 'select-all' checkbox
				? { checked: allSelected }
				: {}
		return (
			<>
				<Checkbox
					icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
					checkedIcon={<CheckBoxIcon fontSize='small' />}
					style={{ marginRight: 8, color: 'black' }}
					checked={selected}
					{...selectAllProps}
				/>
				<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>{getOptionLabel(option)}</div>
			</>
		)
	}
	const inputRenderer = (params) => (
		<TextField
			{...params}
			name={name}
			helperText={helperText}
			error={error}
			placeholder={_.isEmpty(selectedOptions) ? placeholder : ''}
			variant='outlined'
			label={label}
			InputProps={{
				classes: { input: classes.inputText },
				...params.InputProps,
				disabled: isLoading || disabled,
				readOnly: disabled,
				endAdornment: (
					<React.Fragment>
						{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
						{params.InputProps.endAdornment}
					</React.Fragment>
				),
			}}
		/>
	)
	const getOptionSelected = (option, anotherOption) => option.id === anotherOption.id
	const filter = createFilterOptions()
	return (
		<Autocomplete
			multiple
			size='small'
			name={name}
			loading={isLoading}
			limitTags={limitTags}
			options={items}
			onBlur={onBlur}
			value={selectedOptions || []}
			disabled={isLoading || disabled}
			disableClearable={disableClearable}
			disableCloseOnSelect
			getOptionLabel={getOptionLabel}
			getOptionSelected={getOptionSelected}
			noOptionsText={noOptionsText}
			filterOptions={(options, params) => {
				const filtered = filter(options, params)
				return !_.isEmpty(selectAllLabel) ? [{ name: selectAllLabel, id: 'all' }, ...filtered] : [...filtered]
			}}
			onChange={handleChange}
			onInputChange={(e) => {
				onInputChange(e)
			}}
			renderOption={optionRenderer}
			renderInput={inputRenderer}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						classes={{
							root: classNames(classes.tag),
						}}
						style={{ backgroundColor: '#d1d5d8', color: 'black', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
						variant='outlined'
						label={`${option.name}`}
						{...getTagProps({ index })}
					/>
				))
			}
		/>
	)
}

MultiSelect.defaultProps = {
	limitTags: 3,
	items: [],
	selectedValues: [],
	getOptionLabel: (value) => value,
}

export default MultiSelect
