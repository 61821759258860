
import _ from 'lodash'
import Axios from 'axios'
import { API_ENDPOINTS, APP_DEFAULT_ROLES } from '../constants'
import moment from 'moment'

//TRIM TEXT UTILITY
export function trimText(text, maxLen) {
	return text.length > maxLen ? `${text.substring(0, maxLen)}...` : text
}

export function getPaginationProperties(limit, total, currentIdx) {
	if (currentIdx <= 0) throw new Error(`Invalid index ${currentIdx}!, Index Should Start From 1`)

	let endIdx = currentIdx * limit > total ? total : currentIdx * limit
	let startIdx = currentIdx > 1 ? (currentIdx - 1) * limit + 1 : 1
	return {
		startIdx: total === 0 ? 0 : startIdx,
		endIdx: endIdx,
		isNextAvail: endIdx < total,
		isPrevAvail: currentIdx > 1,
	}
}

export function getDetailedPaginationProperties(limit, total, pageNo, selectedList = []) {
	if (pageNo <= 0) throw new Error(`Invalid index ${pageNo}!, Index Should Start From 1`)

	let endPageNo = total > 0 ? Math.ceil(total / limit) : 0
	let firstPageNo = total <= 0 ? 0 : 1
	let paginationNumbers = []

	let viewFrom = (pageNo - 1) * limit + 1
	let viewTo = parseInt(pageNo) === endPageNo ? pageNo * limit - (pageNo * limit - total) : pageNo * limit

	const selectedCount = (selectedList) => {
		if (selectedList.length > 0) {
			if (selectedList.indexOf('all') !== -1) {
				return total
			} else {
				return selectedList.length
			}
		} else {
			return null
		}
	}

	if (firstPageNo === 0) {
		paginationNumbers = []
	} else if (endPageNo <= 8) {
		paginationNumbers = [...Array(endPageNo + 1).keys()].splice(1, endPageNo)
	} else if (pageNo < 5) {
		paginationNumbers = [...[...Array(6).keys()].splice(1, 5), '...', endPageNo]
	} else if (pageNo > endPageNo - 4) {
		paginationNumbers = [
			1,
			'...',
			...Array(5)
				.fill()
				.map((_, i) => endPageNo - 4 + i),
		]
	} else {
		paginationNumbers = [
			1,
			'...',
			...Array(5)
				.fill()
				.map((_, i) => pageNo - 2 + i),
			'...',
			endPageNo,
		]
	}

	return {
		paginationNumbers: paginationNumbers,
		isNextAvail: pageNo < endPageNo,
		isPrevAvail: pageNo > 1,
		pageSummary: `${pageNo}/${endPageNo}`,
		pageNo: pageNo,
		endPageNo: endPageNo,
		viewFrom: viewFrom,
		viewTo: viewTo,
		total: total,
		viewSummary: `${viewFrom} - ${viewTo} of ${total} items`,
		selectedSummary: selectedList && selectedList.length > 0 ? `${selectedCount(selectedList)} of ${total} Selected` : null,
	}
}

export function isWhiteBgColor(r, g, b, a) {
	return (r >= 254) & (g >= 254) & (b >= 254) || a === 0
}

export function convertRGBStrToJsonObj(rgbaStr) {
	//const regexRGBValues = /\((.*?)\)/g;
	var result = {}
	const rgbaVals = rgbaStr.match(/(\d(\.*))+/g)
	const rgbaText = rgbaStr.match(/^([a-z]*)/g)[0]
	var idx = 0
	while (rgbaText && rgbaText[idx]) {
		result[rgbaText[idx]] = parseFloat(rgbaVals[idx])
		idx++
	}
	return result
}

export function convertJsonObjToRGBAStr(rgbaObj) {
	var rgbaText = []
	var rgbaVals = []
	const sortFunc = (key) => {
		let sortRank = { r: 1, g: 2, b: 3, a: 4 }
		return sortRank[key]
	}
	_.sortBy(_.keys(rgbaObj), sortFunc).forEach((key) => {
		rgbaText.push(key)
		rgbaVals.push(rgbaObj[key])
	})
	return `${rgbaText.join('')}(${rgbaVals.join(', ')})`
}

export function rgba2Hex(rgb) {
	rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i)
	return rgb && rgb.length === 4
		? '#' +
				('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
				('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
				('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
		: ''
}

export function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const compileTemplateString = function (templateString, templateVars) {
	// eslint-disable-next-line no-new-func
	return new Function('return `' + templateString + '`;').call(templateVars)
}

export const isAxiosCancelError = (errObj) => {
	return Axios.isCancel(errObj) || JSON.stringify(errObj) === '{}'
}

export const getImgUrl = (accessToken, imgUrl, imgWidth) => {
	const imgWidthQuery = _.isNumber(imgWidth) ? '&width=' + imgWidth : ''
	return `${API_ENDPOINTS.VIEW_IMAGE}${encodeURIComponent(imgUrl)}&Authorization=Bearer ${accessToken}${imgWidthQuery}`
}

export const isModuleAccessGranted = (moduleName, permissionType, permissions) => {
	if (_.isEmpty(moduleName)) return true
	else {
		const modulePerms = _.find(permissions, { module_name: moduleName })
		return _.get(modulePerms, `permissions.${permissionType}`, false)
	}
}

export const getModulePerms = (moduleName, permissions) => {
	if (_.isEmpty(moduleName)) return {}
	else {
		const modulePerms = _.find(permissions, { module_name: moduleName })
		return _.get(modulePerms, 'permissions', {})
	}
}

export const getInfoFromResp = (response, defaultMsg) => {
	return _.get(response, 'data.message', _.get(response, 'data.more_info', defaultMsg))
}

export const getErrMsg = (err) => {
	const errMsg = getInfoFromResp(err.response, 'Something Went Wrong!')
	return errMsg
}

export const getSuccessMsg = (response, defaultMsg) => {
	const successMsg = getInfoFromResp(response, defaultMsg)
	return successMsg
}

export const getUserPermissions = (state) => _.get(state, 'session.user.userDetails.module_permissions', [])

export function stringToHslColor(str, s = 50, l = 50, a = 1) {
	let hash = 0
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 6) - hash)
	}
	var h = Math.abs(hash % 360)
	return 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + a + ')'
}

export async function readFileAsDataURL(file) {
	return await new Promise((resolve) => {
		let fileReader = new FileReader()
		fileReader.onload = (e) => resolve(fileReader.result)
		fileReader.readAsDataURL(file)
	})
}

export const renderTextOrEmpty = (textStr) => {
	if (_.isEmpty(textStr)) {
		return ''
	} else {
		return textStr
	}
}

export const formTheName = (firstName, lastName) => `${firstName} ${renderTextOrEmpty(lastName)}`

export const jsonToFormData = (jsonFormData, filesKey) => {
	return _.reduce(
		jsonFormData,
		(acc, v, k) => {
			if (!_.isEmpty(v)) {
				if (_.isEqual(filesKey, k)) _.forEach(v, (file) => acc.append(filesKey, file))
				else acc.append(k, v)
			}
			return acc
		},
		new FormData()
	)
}

export const relativeDateTime = (refDay, prefix = 'Due ', preposition = { past: 'by ', now: 'by ', future: 'by ', else: 'on ' }) => {
	if (moment(refDay).isValid()) {
		return moment(refDay).calendar(moment(), {
			sameDay: `[${prefix}${preposition?.now || ''}Today] hh:mm A`,
			nextDay: `[${prefix}${preposition?.now || ''}Tomorrow ] hh:mm A`,
			nextWeek: `[${prefix}${preposition?.future || ''}]dddd hh:mm A`,
			lastDay: `[${prefix}${preposition?.past || ''}Yesterday] hh:mm A`,
			lastWeek: `[${prefix}${preposition?.now || ''}Last] dddd hh:mm A`,
			sameElse: `[${prefix}${preposition?.else || ''}]MMM DD, hh:mm A`,
		})
	} else {
		return false
	}
}

export const relativeDateOnly = (refDay, prefix = 'Due ', preposition = { past: 'by ', now: 'by ', future: 'by ', else: 'on ' }) => {
	if (moment(refDay).isValid()) {
		return moment(refDay).calendar(moment(), {
			sameDay: `[${prefix}${preposition?.now || ''}Today]`,
			nextDay: `[${prefix}${preposition?.now || ''}Tomorrow ]`,
			// nextWeek: `[${prefix}${preposition?.future || ''}]dddd`,
			lastDay: `[${prefix}${preposition?.past || ''}Yesterday]`,
			// lastWeek: `[${prefix}${preposition?.now || ''}Last] dddd`,
			sameElse: `[${prefix}${preposition?.else || ''}]MMM DD`,
		})
	} else {
		return false
	}
}

export const convertLocalTimeToGmtStr = (timeString) => {
	let t = !!timeString && moment(timeString, 'YYYY-MM-DD HH:mm:ss').utc().format('ddd, DD MMM YYYY HH:mm:ss [GMT]')
	return t
}

export const convertGmtToLocalTime = (timeString, format = 'YYYY-MM-DD HH:mm:ss') => {
	return moment(timeString).local().format(format)
}

export const isModuleHasPerm = (modulePerms, permsType) => _.get(modulePerms, permsType, false)

export const getAvatarTxt = (fullTxt) => _.slice(_.words(fullTxt), 0, 2).reduce((a, t) => (a += t[0]), '')

export const getFileSize = (bytes, decimals = 2) => {
	if (bytes === 0) return '0 Bytes'
	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const msToDurationTxt = (duration, isTextFormat = false) => {
	var seconds = Math.floor((duration / 1000) % 60),
		minutes = Math.floor((duration / (1000 * 60)) % 60),
		hours = Math.floor(duration / (1000 * 60 * 60))

	if (!isTextFormat) {
		hours = hours < 10 ? '0' + hours : hours
		minutes = minutes < 10 ? '0' + minutes : minutes
		seconds = seconds < 10 ? '0' + seconds : seconds
	}

	return isTextFormat ? `${hours > 0 ? hours + 'h ' : ''}${minutes}m ${seconds}s` : hours + ':' + minutes + ':' + seconds
}

export const addHttp = (url) => {
	if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
		url = 'http://' + url
	}
	return url
}

export const getFileFormatIconAndColor = (format) => {
	const fileFormat = _.toLower(format)
	switch (true) {
		case fileFormat === 'pdf':
			return { fileIcon: 'picture_as_pdf', background: '#b22a00' }
		case ['jpeg', 'png', 'jpg'].indexOf(fileFormat) > -1:
			return { fileIcon: 'image', background: '#d4d4d4' }
		case ['zip', 'rar', 'tar', '7z'].indexOf(fileFormat) > -1:
			return { fileIcon: 'folder_zip', background: '#3d5afe' }
		case ['mp4', 'wmv', 'mkv', 'webm', 'avi', 'avchd', 'flv', 'swf', 'f4v', 'mpeg-2'].indexOf(fileFormat) > -1:
			return { fileIcon: 'videocam', background: '#ff5722' }
		case ['mp3', '3gp', 'flac', 'cda', 'wav'].indexOf(fileFormat) > -1:
			return { fileIcon: 'audiotrack', background: '#1de9b6' }
		case ['xls', 'xlsx', 'csv', 'odf', 'ods'].indexOf(fileFormat) > -1:
			return { fileIcon: 'grid_on', background: '#357a38' }
		case ['doc', 'docx', 'odt'].indexOf(fileFormat) > -1:
			return { fileIcon: 'description', background: '#2196f3' }
		case ['txt', 'rtf', 'md'].indexOf(fileFormat) > -1:
			return { fileIcon: 'article', background: '#af52bf' }
		case ['gif'].indexOf(fileFormat) > -1:
			return { fileIcon: 'gif', background: '#b28900' }
		default:
			return { fileIcon: 'description', background: '#d4d4d4' }
	}
}

export const userHasAdminRole = (userRoles = []) => {
	return _.some(userRoles, (role) => _.lowerCase(role.role_name) === _.lowerCase(APP_DEFAULT_ROLES.ADMIN))
}
