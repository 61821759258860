import { Avatar, Box, Button, Grid, Tooltip, Icon, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Field } from 'formik'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import SelectBox from '../../../../components/SelectBox'
import { RETAILER_MATCH_TYPE } from '../../../../constants'
import Api from '../../../../services/aiqa/dimension/api'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import Validator from './validator'
import { hideLoader, showLoader } from '../../../../services/loader/actions'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	textField: {
		marginTop: theme.spacing(2.5),
		maxWidth: 600,
		margin: '0 auto',
	},
	selectField: {
		marginTop: '10px',
		maxWidth: 600,
		margin: '0 auto',
	},
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	paperHead: {
		// maxWidth: 900,
		margin: '16px auto',
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	key: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	gridContainer: {
		marginTop: theme.spacing(2.5),
		maxWidth: 600,
		margin: '0 auto',
		// padding: '12px',
	},
	subGrid: {
		marginBottom: '20px',
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
}))

function ChangePassword({ userId, history, dialog, close }) {
	const classes = useStyles()
	const [isPwdUpdated] = useState(false)
	const renderAfterCalled = useRef(false)
	const [distributors, setDistributors] = useState([])
	const dispatch = useDispatch()
	const formRef = useRef(null)
	const [initialData, setinitialData] = useState({
		title: '',
		url: '',
		country: '',
		region: '',
		currency: '',
		product_pages: '',
		min_mandatory_params: '',
	})
	const [formInfo, setformInfo] = useState({})
	const formFields = [
		// { name: 'title', label: 'Title*', type: 'text' },
		// { name: 'url', label: 'URL*', type: 'text' },
		// { name: 'country', label: 'Country*', type: 'text' },
		// { name: 'region', label: 'Region*', type: 'text' },
		{ name: 'currency', label: 'Currency*', type: 'text' },
		{ name: 'product_pages', label: 'Product Pages*', type: 'text' },
		{ name: 'min_mandatory_params', label: 'Min Mandatory Parameters*', type: 'text' },
	]

	const getFormData = (idx) => {
		dispatch(showLoader('Please wait, getting details'))
		Api.fetchDistForm(
			idx,
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', {})
				setformInfo(testList)
				setinitialData((prevState) => ({
					...prevState,
					...testList,
				}))
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
	}

	useEffect(() => {
		if (!renderAfterCalled.current) {
			let idx = _.isEmpty(userId) ? 'all' : userId
			getFormData(idx)
		}
		renderAfterCalled.current = true
	}, [])

	const onSubmitForm = (values) => {
		Api.putDistForm(
			values,
			values.id,
			(resp) => {
				dispatch(showSnackbarWithTimeout(resp.data.message, 2000))
				formRef.current.resetForm()
				if (dialog) {
					close()
				} else {
					if (_.isEmpty(userId)) {
						getFormData('all')
					} else {
						history.push(`/aiqa_tool/dimension/distributors/form`)
					}
				}
			},
			(err) => {
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	const onClickRst = () => {
		formRef.current && formRef.current.resetForm()
	}
	const onClickSubmit = () => {
		formRef.current && formRef.current.submitForm()
	}

	return (
		<>
			<Paper className={classes.paper}>
				<Grid container spacing={3}>
					<Grid container xs={12} lg={12} className={classes.gridContainer}>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								Title:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography noWrap className={classes.title} variant='body1'>
								{!_.isNull(formInfo?.title) || !_.isEmpty(formInfo?.title) ? formInfo?.title : '-'}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								URL:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Tooltip title={!_.isNull(formInfo?.url) || !_.isEmpty(formInfo?.url) ? formInfo?.url : '-'} classes={{ tooltip: classes.tooltip }}>
								<Typography noWrap className={classes.title} variant='body1'>
									<a style={{ textDecoration: 'none' }} target='_blank' href={formInfo?.url}>
										{!_.isNull(formInfo?.url) || !_.isEmpty(formInfo?.url) ? formInfo?.url : '-'}
									</a>
								</Typography>
							</Tooltip>
						</Grid>

						{/* <Grid item xs={12} lg={6} className={classes.subGrid}></Grid> */}
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								Country:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography noWrap className={classes.title} variant='body1'>
								{!_.isNull(formInfo?.country) || !_.isEmpty(formInfo?.country) ? formInfo?.country : '-'}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								Region:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={2} className={classes.subGrid}>
							<Typography noWrap className={classes.title} variant='body1'>
								{!_.isNull(formInfo?.region) || !_.isEmpty(formInfo?.region) ? formInfo?.region : '-'}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
			<Paper className={classes.paperHead} variant='outlined' square>
				<Grid container className={classes.gridWrapper}>
					<Grid item xs={12} className={classes.tabPanelWrapper}>
						<Box className={classes.contentWrapper} component='div'>
							{isPwdUpdated ? (
								<>
									<Avatar alt={'success'} className={classes.successIco}>
										<Icon>done</Icon>
									</Avatar>
									<Typography variant='body1' gutterBottom>
										Password Updated Successfully
									</Typography>
								</>
							) : (
								<>
									<Validator initialFormData={initialData} onSubmit={onSubmitForm} ref={formRef}>
										{(formik) => {
											return (
												<form>
													<Grid container spacing={3}>
														{_.map(formFields, (formField) => (
															<Grid item xs={12} lg={6}>
																<Field key={formField.name} type='text' name={formField.name}>
																	{({ form, field }) => {
																		return (
																			<TextField
																				variant='outlined'
																				margin='none'
																				size='small'
																				fullWidth
																				name={field.name}
																				label={formField.label}
																				type={formField.type}
																				autoComplete='off'
																				onChange={(e) => {
																					e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
																				}}
																				onBlur={field.onBlur}
																				value={field.value || ''}
																				className={classes.textField}
																				helperText={form.touched[field.name] && form.errors[field.name]}
																				error={Boolean(form.errors[field.name] && form.touched[field.name])}
																			/>
																		)
																	}}
																</Field>
															</Grid>
														))}
													</Grid>
												</form>
											)
										}}
									</Validator>
									<div style={{ textAlign: 'center', marginTop: '40px' }}>
										<Button
											onClick={onClickRst}
											color='primary'
											variant='text'
											size='medium'
											disableElevation
											style={{ padding: 'inherit', marginRight: '40px', fontWeight: 'bold' }}
										>
											Reset
										</Button>
										<Button
											onClick={onClickSubmit}
											color='primary'
											variant='text'
											size='medium'
											disableElevation
											style={{ padding: 'inherit', fontWeight: 'bold' }}
										>
											Save
										</Button>
									</div>
								</>
							)}
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default ChangePassword
