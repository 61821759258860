

import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	fetchAllRoles: (filterParams, cancelSource) => {
		const filters = filterParams || {}
		return Axios.post(
			API_ENDPOINTS.FETCH_ALL_ROLES,
			{ ...filters },
			{ cancelToken: cancelSource.token, headers: { isAuthRequired: true, 'Content-Type': 'application/json' } }
		)
	},
	createRole: (roleDetails) => {
		return Axios.post(API_ENDPOINTS.ROLE_API, roleDetails, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	fetchRole: (roleId) => {
		return Axios.get(API_ENDPOINTS.ROLE_PATH_API, {
			headers: { isAuthRequired: true, path: { roleId } },
		})
	},
	updateRole: (roleId, roleDetails) => {
		return Axios.put(API_ENDPOINTS.ROLE_PATH_API, roleDetails, {
			headers: {
				isAuthRequired: true,
				path: { roleId },
				'Content-Type': 'application/json',
			},
		})
	},
	deleteRole: (roleId) => {
		return Axios.delete(API_ENDPOINTS.ROLE_PATH_API, {
			headers: { isAuthRequired: true, path: { roleId } },
		})
	},
}
