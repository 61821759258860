import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import classnames from 'classnames'
import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	Badge,
	Fab,
	Grow,
	Divider,
	TableSortLabel,
	Button,
	ButtonGroup,
	Box,
} from '@material-ui/core'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import FilterMenuList from './FilterMenu'
import api from '../../services/bot/api'
import { showSnackbarWithTimeout } from '../../services/snackbar/actions'
import { hideLoader, showLoader } from '../../services/loader/actions'
import LoadingIndicator from '../../components/LoadingIndicator'
import FlagComponent from '../../components/Flags'
import { data1, data2 } from './data'
import RetailerChart from './Chart/Retailers'
import PageChart from './Chart/Pages'
import UserChart from './Chart/Users'
import RoundDot from './RoundDot'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// overflowX: 'auto',
		margin: '16px auto',
	},
	table: {
		minWidth: 650,
		'& .MuiTableCell-root': {
			// borderLeft: '2px solid #ececec',
			padding: '8px',
		},
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
	head: {
		backgroundColor: '#fbfbfd',
		color: 'gray',
		minWidth: '150px',
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 2,
		// '& .MuiTableCell-root': {
		// 	borderLeft: '2px solid #41e886',
		// 	borderBottom: '1px solid #41e886',
		// },
	},
	body: {
		// backgroundColor: '#ddd',
		minWidth: '150px',
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 1,
		// '& .MuiTableCell-root': {
		// 	borderLeft: '2px solid #ddd',
		// 	borderBottom: '1px solid #ddd',
		// },
	},
	cellWidth: {
		width: '120px',
	},
	usercellWidth: {
		width: '140px',
		whiteSpace: 'normal',
		wordBreak: 'break-word',
	},
	timecellWidth: {
		width: '240px',
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
		margin: '16px auto',
	},
	Title: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		// textDecoration: 'underline',
		margin: '16px auto',
	},
	tableHead: {
		backgroundColor: '#fbfbfd',
		color: '#8181a5',
		height: '2.5rem',
		fontSize: '.8rem',
	},
	divide: {
		borderTop: '1px solid #F6F6F6',
	},
}))

const StickyTableCell = withStyles((theme) => ({
	// head: {
	// 	backgroundColor: theme.palette.common.black,
	// 	color: theme.palette.common.white,
	// 	left: 0,
	// 	position: 'sticky',
	// 	zIndex: theme.zIndex.appBar + 2,
	// },
	// body: {
	// 	backgroundColor: '#ddd',
	// 	minWidth: '50px',
	// 	left: 0,
	// 	position: 'sticky',
	// 	zIndex: theme.zIndex.appBar + 1,
	// },
}))(TableCell)

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}))

const TablePaginationActions = (props) => {
	const classes = useStyles1()
	const theme = useTheme()
	const { count, page, rowsPerPage, onPageChange } = props

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0)
	}

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1)
	}

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1)
	}

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<div className={classes.root}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='next page'>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='last page'>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	)
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
}

export default function SimpleTable() {
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const [filter, setFilter] = useState({ searchText: '', filterData: { layer: 'l1' } })
	const { filterData } = filter
	const dispatch = useDispatch()
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(filterData, _.isEmpty)), [filterData])

	const [cardData, setCardData] = useState([])
	const [tableData, settableData] = useState([])
	const [currentPage, setCurrentPage] = React.useState(0)
	const [TblState, setTblState] = useState({
		loading: true,
		offset: 0,
		limit: 10,
		totalCount: 0,
	})
	const { loading, offset, totalCount, limit } = TblState
	const [count, setCount] = React.useState(0)
	const [resAll, setresAll] = React.useState(false)
	const [value, setValue] = React.useState(1)
	const [chartData, setchartData] = React.useState({})
	const [summaryData, setsummaryData] = React.useState({})
	const [enupdate, setEnupdate] = React.useState(false)
	const [Okfilter, setOkfilter] = React.useState({})
	const [matchtype, setMatchtype] = React.useState('OEM')
	const [order, setOrder] = React.useState('')
	const [orderBy, setOrderBy] = React.useState('')
	const [sort, setSort] = React.useState({
		key: '',
		type: '',
	})
	const [filters, setFilters] = React.useState({
		layer: data2,
		status: data1,
		user_name: [],
		distributor_id: [],
	})
	const [incExc, setincExc] = React.useState({
		layer: 'include',
		status: 'include',
		user_name: 'include',
		distributor_id: 'include',
		processed_date: 'include',
	})
	const renderAfterCalled = useRef(false)

	const addFilterAndRefreshTbl = (filterParams) => {
		setTblState((prevState) => ({
			...prevState,
			loading: true,
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const formatNumber = (n) => {
		if (n < 1e3) return n
		if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
		if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
		if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
		if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
	}

	const formatColor = (n) => {
		if (n >= 80) return '#13bb70'
		if (n >= 60 && n < 80) return '#f6b73e'
		if (n > 60) return 'red'
	}

	const ScrapperLog = () => {
		let pay_values = {
			// search_text: '',

			filters: [
				{
					column: 'processed_date',
					data: filterData.processed_date ? [JSON.stringify(filterData.processed_date)] : [],
					method: incExc.processed_date,
					type: 'range',
				},
				{
					column: 'layer',
					data: filterData.layer ? [filterData.layer] : ['l1'],
					method: incExc.layer,
					type: 'str',
				},
				{
					column: 'status',
					data: filterData.status ? filterData.status : [],
					method: incExc.status,
					type: 'str',
				},
				{
					column: 'user_name',
					data: filterData.user_name ? filterData.user_name : [],
					method: incExc.user_name,
					type: 'str',
				},
				{
					column: 'distributor_id',
					data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
					method: incExc.distributor_id,
					type: 'int',
				},
			],
			all: _.isEmpty(filterData) ? true : false,
			offset: offset,
			limit: limit,
		}

		api.ChartData(
			pay_values,
			(res) => {
				let resp = res.data.data
				// setmatchmissData(resp)
				setchartData((prevState) => ({
					...prevState,
					...resp,
				}))
				// console.log(resp, res.data, 'resp')
			},
			(err) => {}
		)
		dispatch(showLoader('Please wait, getting details'))
		api.SummaryData(
			pay_values,
			(res) => {
				let resp = res.data.data
				// setmatchmissData(resp)
				// setsummaryData((prevState) => ({
				// 	...prevState,
				// 	...resp,
				// }))
				setsummaryData(resp)
				dispatch(hideLoader())
				// console.log(resp, res.data, 'resp')
			},
			(err) => {
				dispatch(hideLoader())
			}
		)
	}

	const updateClick = () => {
		let pay_values = {
			// search_text: '',

			filters: [
				{
					column: 'processed_date',
					data: filterData.processed_date ? [JSON.stringify(filterData.processed_date)] : [],
					method: incExc.processed_date,
					type: 'range',
				},
				{
					column: 'layer',
					data: filterData.layer ? [filterData.layer] : ['l1'],
					method: incExc.layer,
					type: 'str',
				},
				{
					column: 'status',
					data: filterData.status ? filterData.status : [],
					method: incExc.status,
					type: 'str',
				},
				{
					column: 'user_name',
					data: filterData.user_name ? filterData.user_name : [],
					method: incExc.user_name,
					type: 'str',
				},
				{
					column: 'distributor_id',
					data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
					method: incExc.distributor_id,
					type: 'int',
				},
			],
			all: _.isEmpty(filterData) ? true : false,
			offset: offset,
			limit: limit,
			sort: sort.key === '' ? {} : sort,
		}
		addFilterAndRefreshTbl({ loading: true })
		api.GetTable(
			pay_values,
			(res) => {
				let resp = res.data.data.layer_data
				settableData(resp)

				if (_.isEmpty(resp)) {
					addFilterAndRefreshTbl({ totalCount: res.data.data.total_count, loading: false })
					dispatch(showSnackbarWithTimeout('No data found.', 2000))
				} else {
					addFilterAndRefreshTbl({ totalCount: res.data.data.total_count, loading: false })
				}
				setEnupdate(false)
			},
			(err) => {
				addFilterAndRefreshTbl({ loading: false })
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	const initialFilter = () => {
		let filtArray = ['user_name', 'distributor_id']
		filtArray.forEach((key) => {
			let pay_values = {
				search_text: '',
				all: true,
			}

			api.GetFilters(
				pay_values,
				key,
				(res) => {
					let resp = res.data.data
					// setmatchmissData(resp)
					setFilters((prevState) => ({
						...prevState,
						...resp,
					}))
				},
				(err) => {}
			)
		})
		// })
		// renderAfterCalled.current = true
		// console.log('calling...')
	}

	useEffect(() => {
		ScrapperLog()
	}, [resAll])

	useEffect(() => {
		updateClick()
	}, [resAll, offset, limit, sort])

	useEffect(() => {
		if (!renderAfterCalled.current) {
			// alert('1')
			initialFilter()
		}
		renderAfterCalled.current = true
	}, [])

	const updateFilter = useCallback(
		(value) => {
			let formatedData = {
				...value,
			}
			setFilter((prevState) => ({ ...prevState, filterData: formatedData }))
			// setOkfilter((prevState) => ({ ...prevState, ...value }))
			let data = _.omitBy(formatedData, _.isEmpty)
		},
		[dispatch]
	)
	const updateOKFilter = useCallback(
		(value, path) => {
			let formatedData = {
				...value,
			}
			// setFilter((prevState) => ({ ...prevState, filterData: formatedData }))

			setOkfilter(value)

			if (value === '') {
				setEnupdate(true)
			}
			let data = _.omitBy(formatedData, _.isEmpty)
		},
		[dispatch]
	)
	const classes = useStyles()

	const onChangeFilter = (path, fn) => {
		// if (path === 'processed_date') {
		// 	if (fn === 'reset') {
		// 		setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['layer']) }))
		// 	} else {
		// 		setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date','layer']) }))
		// 	}

		// 	// updateClick()
		// }
		if (path === 'layer') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer']) }))
			}

			// updateClick()
		}
		if (path === 'status') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer', 'status']) }))
			}

			// updateClick()
		}
		if (path === 'user_name') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer', 'status']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer', 'status', 'user_name']) }))
			}

			// updateClick()
		}
		if (path === 'distributor_id') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer', 'status', 'user_name']) }))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['processed_date', 'layer', 'status', 'user_name', 'distributor_id']),
				}))
			}
			// updateClick()
		}
	}

	const onOkClick = (path) => {
		// if (path === 'processed_date' || path === 'distributor_id') {
		ScrapperLog()
		addFilterAndRefreshTbl({ limit: limit, offset: 0 })
		updateClick()

		// }
		let pay_values = {}
		let filtArray = []
		let initial_values = [
			{
				column: 'processed_date',
				data: filterData.processed_date ? [JSON.stringify(filterData.processed_date)] : [],
				method: incExc.processed_date,
				type: 'range',
			},
			{
				column: 'layer',
				data: filterData.layer ? [filterData.layer] : ['l1'],
				method: incExc.layer,
				type: 'str',
			},
			{
				column: 'status',
				data: filterData.status ? filterData.status : [],
				method: incExc.status,
				type: 'str',
			},
			{
				column: 'user_name',
				data: filterData.user_name ? filterData.user_name : [],
				method: incExc.user_name,
				type: 'str',
			},
			{
				column: 'distributor_id',
				data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
				method: incExc.distributor_id,
				type: 'int',
			},
		]
		if (path === 'processed_date') {
			pay_values = {
				onchange_for: 'processed_date',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['processed_date', 'layer'], column)])),
			}
			filtArray = ['distributor_id', 'user_name']
		}
		if (path === 'layer') {
			pay_values = {
				onchange_for: 'layer',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['processed_date', 'layer'], column)])),
			}
			filtArray = ['distributor_id', 'user_name']
		}
		if (path === 'status') {
			pay_values = {
				onchange_for: 'status',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['processed_date', 'layer', 'status'], column)])),
			}
			filtArray = ['distributor_id', 'user_name']
		}
		if (path === 'user_name') {
			pay_values = {
				onchange_for: 'user_name',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['processed_date', 'layer', 'status', 'user_name'], column)])),
			}
			filtArray = ['distributor_id']
		}

		filtArray.forEach((key) => {
			api.filterOnChange(
				pay_values,
				key,
				(res) => {
					let resp = res.data.data
					// setmatchmissData(resp)
					setFilters((prevState) => ({
						...prevState,
						...resp,
					}))
					// setValue(!_.isEmpty(resp.mpn) ? resp.mpn : !_.isEmpty(resp.ean) ? resp.ean : resp.product_title)
					// console.log(resp, key)
				},
				(err) => {}
			)
		})
	}

	const flag = (text) => {
		if (text === 'Completed') {
			return <FlagComponent text={text} color='secondary' />
		}
		if (text === 'In progress') {
			return <FlagComponent text={text} color='success' />
		}
	}

	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
		setSort({
			key: property,
			type: isAsc ? 'desc' : 'asc',
		})
	}

	return (
		<div className={classnames('container-no-fluid', 'page-content')}>
			<Paper className={classes.root}>
				<div style={{ padding: '16px' }}>
					{/* <Typography className={classes.paperTitle} variant='body1'>
						Scrapper Log:
					</Typography> */}
					<div style={{ display: 'flex', justifyContent: 'right' }}>
						<ButtonGroup color='primary' aria-label='outlined primary button group'>
							<Button color={value === 0 ? 'secondary' : 'black'} onClick={(e) => handleChange(e, 0)}>
								Retailer
							</Button>
							<Button color={value === 1 ? 'secondary' : 'black'} onClick={(e) => handleChange(e, 1)}>
								Pages
							</Button>
							<Button color={value === 2 ? 'secondary' : 'black'} onClick={(e) => handleChange(e, 2)}>
								User
							</Button>
						</ButtonGroup>
					</div>
					<div role='tabpanel' hidden={value !== 0} id={`nav-tabpanel-${0}`} aria-labelledby={`nav-tab-${0}`}>
						{value === 0 && (
							<Box p={3}>
								<RetailerChart Data={chartData?.retailer} />
							</Box>
						)}
					</div>
					<div role='tabpanel' hidden={value !== 1} id={`nav-tabpanel-${1}`} aria-labelledby={`nav-tab-${1}`}>
						{value === 1 && (
							<Box p={3}>
								<PageChart Data={chartData?.pages} />
							</Box>
						)}
					</div>
					<div role='tabpanel' hidden={value !== 2} id={`nav-tabpanel-${2}`} aria-labelledby={`nav-tab-${2}`}>
						{value === 2 && (
							<Box p={3}>
								<UserChart Data={chartData?.user} />
							</Box>
						)}
					</div>
					<Divider className={classes.divide} />

					{/* <Grid container spacing={1}>
						<Grid item xs={1} />
						<Grid item xs={1}> */}
					<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-around' }}>
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								{_.isEmpty(summaryData) ? '0' : summaryData?.duration}
							</p>
							Run Time{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div title={summaryData?.success_page}>
									{/* <span style={{ color: '#13bb70' }}>● </span> */}
									<span
										style={{ height: '10px', width: '10px', display: 'inline-block', borderRadius: '50%', backgroundColor: '#13bb70' }}
									></span>{' '}
									{_.isEmpty(summaryData) ? '0' : formatNumber(summaryData?.success_page)}
								</div>
							</p>
							Success{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div title={summaryData?.missed_page}>
									<span style={{ height: '10px', width: '10px', display: 'inline-block', borderRadius: '50%', backgroundColor: '#e45251' }}> </span>{' '}
									{_.isEmpty(summaryData) ? '0' : formatNumber(summaryData?.missed_page)}
								</div>
							</p>
							Missed{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div title={summaryData?.item_scrapped}>{_.isEmpty(summaryData) ? '0' : formatNumber(summaryData?.item_scrapped)}</div>
							</p>
							Items{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div>
									<span
										style={{
											height: '10px',
											width: '10px',
											display: 'inline-block',
											borderRadius: '50%',
											backgroundColor: formatColor(summaryData?.coverage),
										}}
									></span>{' '}
									{_.isEmpty(summaryData) ? '0' : summaryData?.coverage}%
								</div>
							</p>
							Coverage{' '}
						</p>
						{/* </Grid> */}
						{/* <Grid item xs={2}>
							<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
								<p>
									<div>88.59%</div>
								</p>
								Success Rate{' '}
							</p>
						</Grid> */}
						{/* <Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div>0/0</div>
							</p>
							Checks{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div title={summaryData?.log_info}>
									<span style={{ height: '10px', width: '10px', display: 'inline-block', borderRadius: '50%', backgroundColor: '#5aa9f3' }}> </span>{' '}
									{_.isEmpty(summaryData) ? '0' : formatNumber(summaryData?.log_info)}
								</div>
							</p>
							Info{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div title={summaryData?.log_warn}>
									<span style={{ height: '10px', width: '10px', display: 'inline-block', borderRadius: '50%', backgroundColor: '#f6b73e' }}> </span>{' '}
									{_.isEmpty(summaryData) ? '0' : formatNumber(summaryData?.log_warn)}
								</div>
							</p>
							Warnings{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div title={summaryData?.log_debug}>
									<span style={{ height: '10px', width: '10px', display: 'inline-block', borderRadius: '50%', backgroundColor: '#a19899' }}> </span>{' '}
									{_.isEmpty(summaryData) ? '0' : formatNumber(summaryData?.log_debug)}
								</div>
							</p>
							Debug{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1}> */}
						<p style={{ textAlign: 'center', fontSize: '.75rem', color: 'gray' }}>
							<p style={{ fontSize: '1.15rem', color: 'black', margin: 0, fontFamily: 'system-ui', fontWeight: '500' }}>
								<div title={summaryData?.log_error}>
									<span style={{ height: '10px', width: '10px', display: 'inline-block', borderRadius: '50%', backgroundColor: 'red' }}> </span>{' '}
									{_.isEmpty(summaryData) ? '0' : formatNumber(summaryData?.log_error)}
								</div>
							</p>
							Errors{' '}
						</p>
						{/* </Grid>
						<Grid item xs={1} />
					</Grid> */}
					</div>
				</div>
			</Paper>
			<Paper className={classes.root}>
				<div style={{ padding: '16px' }}>
					{/* <Typography className={classes.paperTitle} variant='body1'>
						Statistics Report:
					</Typography> */}
					<TableContainer>
						<Table className={classes.table} aria-label='simple table'>
							<TableHead className={classes.tableHead}>
								<TableRow>
									{/* <StickyTableCell className={classes.head}> */}
									<TableCell
										key='retailer_name'
										sortDirection={orderBy === 'retailer_name' ? order : false}
										style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}
									>
										<TableSortLabel
											active={orderBy === 'retailer_name'}
											direction={orderBy === 'retailer_name' ? order : 'asc'}
											onClick={createSortHandler('retailer_name', order)}
										>
											Retailer
										</TableSortLabel>
									</TableCell>
									{/* <TableCell style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
										Retailer
										</TableCell> */}
									<TableCell
										key='user_name'
										sortDirection={orderBy === 'user_name' ? order : false}
										style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}
									>
										<TableSortLabel
											active={orderBy === 'user_name'}
											direction={orderBy === 'user_name' ? order : 'asc'}
											onClick={createSortHandler('user_name', order)}
										>
											User
										</TableSortLabel>
									</TableCell>
									{/* </StickyTableCell> */}
									<TableCell
										key='start_time'
										sortDirection={orderBy === 'start_time' ? order : false}
										align='left'
										style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}
									>
										<TableSortLabel
											active={orderBy === 'start_time'}
											direction={orderBy === 'start_time' ? order : 'asc'}
											onClick={createSortHandler('start_time', order)}
										>
											Time
										</TableSortLabel>
									</TableCell>
									<TableCell
										key='status'
										sortDirection={orderBy === 'status' ? order : false}
										align='center'
										style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}
									>
										<TableSortLabel
											active={orderBy === 'status'}
											direction={orderBy === 'status' ? order : 'asc'}
											onClick={createSortHandler('status', order)}
										>
											Status
										</TableSortLabel>
									</TableCell>
									<TableCell
										key='duration'
										sortDirection={orderBy === 'duration' ? order : false}
										align='left'
										style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}
									>
										<TableSortLabel
											active={orderBy === 'duration'}
											direction={orderBy === 'duration' ? order : 'asc'}
											onClick={createSortHandler('duration', order)}
										>
											RunTime
										</TableSortLabel>
									</TableCell>
									<TableCell
										key='req_page'
										sortDirection={orderBy === 'req_page' ? order : false}
										align='left'
										style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}
									>
										<TableSortLabel
											active={orderBy === 'req_page'}
											direction={orderBy === 'req_page' ? order : 'asc'}
											onClick={createSortHandler('req_page', order)}
										>
											Request Pages (Success/Failure)
										</TableSortLabel>
									</TableCell>
									<TableCell
										key='item_scrapped'
										sortDirection={orderBy === 'item_scrapped' ? order : false}
										align='left'
										style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}
									>
										<TableSortLabel
											active={orderBy === 'item_scrapped'}
											direction={orderBy === 'item_scrapped' ? order : 'asc'}
											onClick={createSortHandler('item_scrapped', order)}
										>
											Items
										</TableSortLabel>
									</TableCell>
									<TableCell align='center' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
										Checks
									</TableCell>
									<TableCell align='center' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
										Errors
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{loading ? (
									<TableRow>
										<TableCell colSpan={8}>
											<LoadingIndicator></LoadingIndicator>
										</TableCell>
									</TableRow>
								) : _.isEmpty(tableData) ? (
									<TableRow>
										<TableCell colSpan={5}></TableCell>
										<TableCell>No Data Found</TableCell>
									</TableRow>
								) : (
									tableData?.map((row) => (
										<TableRow key={row.name}>
											{/* <StickyTableCell className={classes.body}> */}
											<TableCell
												align='left'
												style={{ fontSize: '.75rem', fontFamily: 'system-ui', fontWeight: '600' }}
												className={classes.cellWidth}
											>
												{row.retailer_name}
											</TableCell>
											<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.usercellWidth}>
												{row.user_name}
											</TableCell>
											{/* </StickyTableCell> */}
											<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
												Start: {row.start_time}
												<br />
												{/* <p style={{ textAlign: 'left' }}>Finish: {row.end_time}</p> */}
												Finish: {row.end_time}
											</TableCell>
											<TableCell align='center' className={classes.cellWidth}>
												{flag(row.status)}
											</TableCell>

											<TableCell align='left' className={classes.cellWidth}>
												<strong>{row.duration}:00</strong>
												<p style={{ textAlign: 'left', fontSize: '.6rem', color: '#929292cb' }}>HH:MM:SS</p>
											</TableCell>
											<TableCell align='left' className={classes.cellWidth}>
												<strong style={{ marginLeft: '30px' }}>{row.req_page}</strong>
											</TableCell>
											<TableCell align='left'>
												<strong>{row.item_scrapped}</strong>
											</TableCell>
											<TableCell align='center' className={classes.cellWidth}>
												<strong style={{ fontSize: '.1.75rem', fontFamily: 'system-ui', fontWeight: '500' }}>
													<span
														style={{ height: '10px', width: '10px', display: 'inline-block', borderRadius: '50%', backgroundColor: '#13bb70' }}
													></span>{' '}
													0/0
												</strong>
												<p style={{ textAlign: 'center', fontSize: '.6rem', color: '#929292cb' }}>Passed</p>
											</TableCell>
											<TableCell align='center' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
												<Grid container>
													<Grid item xs={6}>
														<p style={{ textAlign: 'left' }}>
															<p style={{ fontSize: '.1.75rem', fontFamily: 'system-ui' }}>
																<span style={{ color: '#5aa9f3' }}>● </span>
																<strong title={row.log_info} style={{ color: 'black' }}>
																	{formatNumber(row.log_info)}{' '}
																</strong>
																Info
															</p>
														</p>
													</Grid>
													<Grid item xs={6}>
														<p style={{ textAlign: 'left' }}>
															<p style={{ fontSize: '.1.75rem', fontFamily: 'system-ui' }}>
																<span style={{ color: '#f6b73e' }}>● </span>
																<strong title={row.log_warn} style={{ color: 'black' }}>
																	{formatNumber(row.log_warn)}{' '}
																</strong>
																Warnings{' '}
															</p>
														</p>
													</Grid>
													<Grid item xs={6}>
														<p style={{ textAlign: 'left' }}>
															<p style={{ fontSize: '.1.75rem', fontFamily: 'system-ui' }}>
																<span style={{ color: '#a19899' }}>● </span>
																<strong title={row.log_debug} style={{ color: 'black' }}>
																	{formatNumber(row.log_debug)}{' '}
																</strong>
																Debug{' '}
															</p>
														</p>
													</Grid>
													<Grid item xs={6}>
														<p style={{ textAlign: 'left' }}>
															<p style={{ fontSize: '.1.75rem', fontFamily: 'system-ui' }}>
																<span style={{ color: 'red' }}>● </span>
																<strong title={row.log_error} style={{ color: 'black' }}>
																	{formatNumber(row.log_error)}{' '}
																</strong>
																Errors{' '}
															</p>
														</p>
													</Grid>
												</Grid>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 50]}
						component='div'
						count={totalCount}
						rowsPerPage={limit}
						page={offset / limit}
						onPageChange={onChangePage}
						onRowsPerPageChange={onChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</div>
			</Paper>
			<div>
				<Grow in={true} unmountOnExit>
					<Fab
						className={classes.fab}
						color='primary'
						size='small'
						onClick={() => {
							setMenuProps((prev) => ({
								...prev,
								isOpen: true,
							}))
						}}
					>
						{isFilterActive ? (
							<Badge color='error' badgeContent={''} variant='dot'>
								<Icon>filter_list</Icon>
							</Badge>
						) : (
							<Icon>filter_list</Icon>
						)}
					</Fab>
				</Grow>
				{filterMenuProps.isOpen && (
					<div className={classnames(classes.menuContainer, { [classes.openMenu]: filterMenuProps.isOpen })}>
						<FilterMenuList
							// token={authToken}
							incExc={incExc}
							setincExc={setincExc}
							activeFilters={filter?.filterData}
							activeOKFilters={Okfilter}
							setMenuProps={setMenuProps}
							filters={filters}
							updateFunc={updateFilter}
							updateOKFunc={updateOKFilter}
							setEnupdate={setEnupdate}
							onOkClick={onOkClick}
							onChangeFilter={onChangeFilter}
							InitialFilter={initialFilter}
							setFilter={setFilter}
							updateClick={updateClick}
							resAll={resAll}
							setresAll={setresAll}
						/>
					</div>
				)}
			</div>
		</div>
	)
}
