export const data1 = [
	{
		value: 'In progress',
		title: 'In progress',
	},
	{
		value: 'Completed',

		title: 'Completed',
	},
]

export const data2 = [
	{
		value: 'l1',
		title: 'L1',
	},
	{
		value: 'l2',

		title: 'L2',
	},
]
