import { Box, Grid, makeStyles, Typography, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Axios from 'axios'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import MultiSelect from '../../../../components/AbTestMultiSelect'
import api from '../../../../services/abtesttool/api'
import ProductTable from './ProductTable'
import DisplayTable from './table'

const CancelToken = Axios.CancelToken

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	gridWrapper: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: theme.spacing(0, 3),
		maxWidth: 977,
	},
	textField: {
		// maxWidth: 400,
		// margin: `${theme.spacing(1)}px auto`,
		padding: theme.spacing(2),
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
		fontFamily: 'FS-Jack',
		fontStyle: 'normal',
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paperTitle: {
		fontWeight: 'bold',
		fontFamily: 'FS-Jack',
		fontStyle: 'normal',
	},
}))

function StepTwo(props) {
	const { values, errors, touched, handleBlur, handleChange, disabled } = props
	const classes = useStyles()
	const searchBoxTimeout = useRef(undefined)
	const cancelDashLstReq = useRef(null)
	const [distriSrLoad, setdistriSrLoad] = useState(false)
	const [data, setData] = useState({
		brand: [],
		brand_list: [],
		country_list: [],
		retailer_list: [],
	})
	const [searchkey, setSearchkey] = useState('')

	const { brand, brand_list, country_list, retailer_list } = data

	useEffect(() => {
		if (values.test_level === 'brand' || values.test_level === 'product' || values.test_level === 'retailer_brand') {
			api
				.fetchBrands(
					new CancelToken((exec) => {
						cancelDashLstReq.current = exec
					})
				)
				.then((res) => {
					let brands = _.map(res.data.data.brand, (brand) => ({
						id: brand.brand_id,
						name: brand.brand_title,
					}))
					setData((prevState) => ({ ...prevState, brand_list: brands }))
				})
		}
		if (values.test_level !== 'brand') {
			api
				.fetchCountries(
					new CancelToken((exec) => {
						cancelDashLstReq.current = exec
					})
				)
				.then((res) => {
					let countries = _.map(res.data.data.country, (country) => ({
						id: country.country,
						name: country.country,
					}))
					setData((prevState) => ({ ...prevState, country_list: countries }))
				})
		}
		// console.log('calling...')
	}, [values.test_level])

	const RetailerCall = () => {
		let pay_values = {
			search_text: '',
			filters: [
				{
					column: 'country',
					includes: values.country !== [] ? _.map(values.country, (country) => country.id) : [],
					type: 'str',
					all: false,
				},
			],
			all: values.country !== [] ? false : true,
		}
		setdistriSrLoad(true)
		api.fetchRetailers(
			pay_values,
			(res) => {
				let retailers = _.map(res.data.data.retailers, (retailer) => ({
					id: retailer.retailer_id,
					name: retailer.retailer_name,
				}))
				setData((prevState) => ({ ...prevState, retailer_list: retailers }))
				setdistriSrLoad(false)
			},
			(err) => {
				setdistriSrLoad(false)
			}
		)
	}

	useEffect(() => {
		if (values.test_level !== 'brand') {
			RetailerCall()
		}
		// console.log('calling...')
	}, [values.country, values.test_level])

	const onInputSRChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					// let product_id = e.target.value
					// setProdSearch(e.target.value)
					let prevText = _.map(values.retailer, (retailer) => retailer.name)
					setSearchkey(e.target.value)
					let areThereAnyCommas = e.target.value?.includes(',')
					// if (areThereAnyCommas) {
					let pay_values = {
						// search_text: !_.isEmpty(prevText) ? `${_.toString(prevText)},${e.target.value}` : `${e.target.value}`,
						search_text: e.target.value === undefined ? '' : `${e.target.value}`,
						filters: [
							{
								column: 'country',
								includes: values.country !== [] ? _.map(values.country, (country) => country.id) : [],
								type: 'str',
								all: false,
							},
						],
						all: values.country !== [] ? false : true,
					}
					setdistriSrLoad(true)
					api.fetchRetailers(
						pay_values,
						(res) => {
							// if (_.isEmpty(res.data.data.retailers)) {
							// 	handleChange({
							// 		target: { name: 'retailer', value: values.retailer },
							// 	})
							// 	RetailerCall()
							// } else {
							let retailers = _.map(res.data.data.retailers, (retailer) => ({
								id: retailer.retailer_id,
								name: retailer.retailer_name,
							}))
							// handleChange({
							// 	target: { name: 'retailer', value: retailers },
							// })
							setData((prevState) => ({ ...prevState, retailer_list: retailers }))
							// }
							setdistriSrLoad(false)
						},
						(err) => {
							setdistriSrLoad(false)
						}
					)
					// }
				}, 1500)
			}
		}
	}

	return (
		<Box className={classes.contentWrapper} component='div'>
			<Grid container spacing={2} className={classes.gridWrapper}>
				{/* <Typography className={classes.title} variant='h6'>
					Scope:
				</Typography> */}
				<Grid item xs={12} lg={12}>
					<p className={classes.title} style={{ textAlign: 'left' }}>
						Scope
					</p>
					<FormControl component='fieldset' disabled={disabled}>
						<RadioGroup row aria-label='position' {...props} name='test_level' value={values.test_level} onChange={handleChange}>
							<FormControlLabel
								value='brand'
								control={<Radio size='small' style={{ color: 'black' }} />}
								label={<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: '400', fontSize: '16px' }}>Brand Level</div>}
							/>
							<FormControlLabel
								value='product'
								control={<Radio size='small' style={{ color: 'black' }} />}
								label={<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: '400', fontSize: '16px' }}>Product Level</div>}
							/>
							<FormControlLabel
								value='retailer'
								control={<Radio size='small' style={{ color: 'black' }} />}
								label={<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: '400', fontSize: '16px' }}>Retailer Level</div>}
							/>
							<FormControlLabel
								value='retailer_brand'
								control={<Radio size='small' style={{ color: 'black' }} />}
								label={<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: '400', fontSize: '16px' }}>Retailer & Brand Level</div>}
							/>
							<FormControlLabel
								value='retailer_product'
								control={<Radio size='small' style={{ color: 'black' }} />}
								label={
									<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: '400', fontSize: '16px' }}>Retailer & Product Level</div>
								}
							/>
						</RadioGroup>
					</FormControl>
				</Grid>
				{(values.test_level === 'brand' || values.test_level === 'product') && (
					<Grid item xs={12} lg={12} className={classes.textField}>
						<p className={classes.title} style={{ textAlign: 'left' }}>
							Brand
						</p>
						<MultiSelect
							items={brand_list || []}
							// getOptionDisabled={getOptionDisabled}
							// label='Brand'
							placeholder='Choose a Brand'
							name='brand'
							selectAllLabel=''
							onChange={(e) =>
								handleChange({
									target: { name: 'brand', value: e },
								})
							}
							value={values?.brand}
							onBlur={handleBlur}
							error={errors?.brand && touched.brand}
							helperText={errors?.brand && touched.brand && errors?.brand}
							disabled={disabled}
						/>
					</Grid>
				)}
				{values.test_level === 'retailer_brand' && (
					<>
						<Grid item xs={12} lg={12} className={classes.textField}>
							<p className={classes.title} style={{ textAlign: 'left' }}>
								Country
							</p>
							<MultiSelect
								items={country_list || []}
								// getOptionDisabled={getOptionDisabled}
								// label='Country'
								placeholder='Choose a Country'
								name='country'
								selectAllLabel=''
								onChange={(e) =>
									handleChange({
										target: { name: 'country', value: e },
									})
								}
								value={values?.country}
								onBlur={handleBlur}
								error={errors?.country && touched.country}
								helperText={errors?.country && touched.country && errors?.country}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} lg={12} className={classes.textField}>
							<p className={classes.title} style={{ textAlign: 'left' }}>
								Retailer
							</p>
							<MultiSelect
								items={retailer_list || []}
								// getOptionDisabled={getOptionDisabled}
								// label='Retailer'
								placeholder='Choose a Retailer'
								name='retailer'
								selectAllLabel=''
								onChange={(e) => {
									if (_.isEmpty(e)) {
										RetailerCall()
										handleChange({
											target: { name: 'retailer', value: [] },
										})
									} else {
										handleChange({
											target: { name: 'retailer', value: e },
										})
									}
								}}
								isLoading={distriSrLoad}
								onInputChange={(e) => onInputSRChange(e)}
								value={values?.retailer}
								onBlur={handleBlur}
								error={errors?.retailer && touched.retailer}
								helperText={errors?.retailer && touched.retailer && errors?.retailer}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} lg={12} className={classes.textField}>
							<p className={classes.title} style={{ textAlign: 'left' }}>
								Brand
							</p>
							<MultiSelect
								items={brand_list || []}
								// getOptionDisabled={getOptionDisabled}
								// label='Brand'
								placeholder='Choose a Brand'
								name='brand'
								selectAllLabel=''
								onChange={(e) =>
									handleChange({
										target: { name: 'brand', value: e },
									})
								}
								value={values?.brand}
								onBlur={handleBlur}
								error={errors?.brand && touched.brand}
								helperText={errors?.brand && touched.brand && errors?.brand}
								disabled={disabled}
							/>
						</Grid>
					</>
				)}
				{(values.test_level === 'product' || values.test_level === 'retailer' || values.test_level === 'retailer_product') && (
					<>
						<Grid item xs={12} lg={12} className={classes.textField}>
							<p className={classes.title} style={{ textAlign: 'left' }}>
								Country
							</p>
							<MultiSelect
								items={country_list || []}
								// getOptionDisabled={getOptionDisabled}
								// label='Country'
								placeholder='Choose a Country'
								name='country'
								selectAllLabel=''
								onChange={(e) =>
									handleChange({
										target: { name: 'country', value: e },
									})
								}
								value={values?.country}
								onBlur={handleBlur}
								error={errors?.country && touched.country}
								helperText={errors?.country && touched.country && errors?.country}
								disabled={disabled}
							/>
						</Grid>
						<Grid item xs={12} lg={12} className={classes.textField}>
							<p className={classes.title} style={{ textAlign: 'left' }}>
								Retailer
							</p>
							<MultiSelect
								items={retailer_list || []}
								// getOptionDisabled={getOptionDisabled}
								// label='Retailer'
								placeholder='Choose a Retailer'
								name='retailer'
								selectAllLabel=''
								onChange={(e) => {
									if (_.isEmpty(e)) {
										RetailerCall()
										handleChange({
											target: { name: 'retailer', value: [] },
										})
									} else {
										handleChange({
											target: { name: 'retailer', value: e },
										})
									}
								}}
								isLoading={distriSrLoad}
								onInputChange={(e) => onInputSRChange(e)}
								value={values?.retailer}
								onBlur={handleBlur}
								error={errors?.retailer && touched.retailer}
								helperText={errors?.retailer && touched.retailer && errors?.retailer}
								disabled={disabled}
							/>
						</Grid>
					</>
				)}
				{(values.test_level === 'product' || values.test_level === 'retailer_product') && (
					<>
						{!disabled && (
							<>
								<Grid item xs={12} lg={12} style={{ marginTop: '30px', textAlign: 'left' }}>
									<Typography className={classes.paperTitle} variant='h6'>
										Select available products
									</Typography>
								</Grid>
								<Grid item xs={12} lg={12}>
									<ProductTable values={values} handleChange={handleChange} />
								</Grid>
							</>
						)}
						{disabled && (
							<Grid item xs={12} lg={12}>
								<DisplayTable tableList={values.product} />
								{/* <ProductTable values={values} handleChange={handleChange} disabled={disabled} /> */}
								{/* <MaterialTable values={values} handleChange={handleChange} /> */}
							</Grid>
						)}
					</>
				)}
			</Grid>
		</Box>
	)
}

export default StepTwo
