// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.heading {
  color: #2196f3;
  border-bottom: 1px solid #2196f3;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
input {
  width: 40%;
  /* min-width: 200px; */
  border-radius: 10px;
  border: 2px solid #65b17f;
  padding: 10px;
  font-size: 14px;
}
/* button {
  height: 40px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  user-select: none;
  font-weight: 600;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #2196f3;
  background-color: #2196f3;
  cursor: pointer;
  margin: 0px 15px;
  min-width: 100px;
}

button:hover {
  background: #2196f3;
  color: #ffffff;
} */

table {
  width: 100%;
  padding: 1em;
  margin: 20px 0;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
}

thead {
  background-color: #41e886;
  /* font-size: 16px; */
  color: white;
  font-weight: bold;
}

th {
  text-align: left;
  padding: 8px;
  background-color: 1px solid #ffffff;
  color: #2196f3;
}
td {
  /* text-align: left; */
  padding: 8px;
}
/* 
tr {
  height: 50px;
} */

/* tr:nth-child(even) {
  background-color: #eeeeee;
} */
`, "",{"version":3,"sources":["webpack://./src/scenes/AbTestTool/ViewTest/StepOne/styles.css"],"names":[],"mappings":";AACA;EACE,cAAc;EACd,gCAAgC;EAChC,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,UAAU;EACV,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,eAAe;AACjB;AACA;;;;;;;;;;;;;;;;;;;;;GAqBG;;AAEH;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,yBAAyB;EACzB,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,mCAAmC;EACnC,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,YAAY;AACd;AACA;;;GAGG;;AAEH;;GAEG","sourcesContent":["\n.heading {\n  color: #2196f3;\n  border-bottom: 1px solid #2196f3;\n  margin-bottom: 30px;\n  padding-bottom: 10px;\n}\ninput {\n  width: 40%;\n  /* min-width: 200px; */\n  border-radius: 10px;\n  border: 2px solid #65b17f;\n  padding: 10px;\n  font-size: 14px;\n}\n/* button {\n  height: 40px;\n  border-radius: 4px;\n  margin-top: 10px;\n  font-size: 15px;\n  display: inline-block;\n  text-align: center;\n  user-select: none;\n  font-weight: 600;\n  border-radius: 4px;\n  color: #ffffff;\n  border: 1px solid #2196f3;\n  background-color: #2196f3;\n  cursor: pointer;\n  margin: 0px 15px;\n  min-width: 100px;\n}\n\nbutton:hover {\n  background: #2196f3;\n  color: #ffffff;\n} */\n\ntable {\n  width: 100%;\n  padding: 1em;\n  margin: 20px 0;\n  border-collapse: collapse;\n  box-shadow: 0 1px 2px 0 #c6e8c9;\n}\n\nthead {\n  background-color: #41e886;\n  /* font-size: 16px; */\n  color: white;\n  font-weight: bold;\n}\n\nth {\n  text-align: left;\n  padding: 8px;\n  background-color: 1px solid #ffffff;\n  color: #2196f3;\n}\ntd {\n  /* text-align: left; */\n  padding: 8px;\n}\n/* \ntr {\n  height: 50px;\n} */\n\n/* tr:nth-child(even) {\n  background-color: #eeeeee;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
