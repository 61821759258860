

import React from 'react'
import { Formik } from 'formik'
import _ from 'lodash'

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData) ? { is_transfer_data: true, transfer_user_id: '' } : props.initialFormData

	const validateForm = (values) => {
		let errors = {}
		const isTransferData = _.get(values, 'is_transfer_data')
		if (isTransferData) {
			if (!_.get(values, 'transfer_user_id')) {
				errors.transfer_user_id = 'Please select a user for the Data Transfer'
			}
		}
		return errors
	}

	return (
		<Formik innerRef={ref} initialValues={initialValues} validate={validateForm} onSubmit={props.onSubmit || null}>
			{props.children}
		</Formik>
	)
})

export default Validator
