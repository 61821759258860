import React from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'

// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

const passwordSchema = Yup.object().shape({
	batch_ids: Yup.string().trim().required('Please select the Batch Numbers.'),
})

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData) ? { batch_ids: '' } : props.initialFormData
	return (
		<Formik innerRef={ref} enableReinitialize initialValues={initialValues} validationSchema={passwordSchema} onSubmit={props.onSubmit || null}>
			{props.children}
		</Formik>
	)
})

export default Validator
