import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormLabel,
	TextField,
	Button,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import FormDialog from './FormDialog'
import { isAxiosCancelError } from '../../../utils'
import { fetchAllUsers } from '../../../services/users/actions'
import UsersApi from '../../../services/users/api'
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withCellRenderState, ActionCellRenderer, RoleCellRenderer, NameCellRenderer, StatusCellRenderer, EmailCellRenderer } from './CustomCells'
import AgGridCustom from '../../../components/AgGridCustom'
import { PAGE_MODES } from '../.././../constants'
import ListPageFilterSection from '../../../components/ListPageFilterSection'
import SpecialPermDialog from './SpecialPermDialog'
import DeleteDialog from './DeleteDialog'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from '../../../services/loader/actions'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { PERMISSION_TYPE } from '../../../constants/modules'
import SelectBox from '../../../components/SelectBox'
import Api from '../../../services/prmatching/api'

const USER_STATUS_FILTER = {
	ALL: 'all',
	ACTIVE: 'active',
	INACTIVE: 'inactive',
}

const getUsrStatusFilterVal = (usrStatusVal) => {
	switch (usrStatusVal) {
		case USER_STATUS_FILTER.ALL:
			return undefined
		case USER_STATUS_FILTER.ACTIVE:
			return true
		case USER_STATUS_FILTER.INACTIVE:
			return false
		default:
			return undefined
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	switchLabel: {
		fontSize: '14px',
	},
	radioFormLabel: {
		fontSize: '15px',
		marginBottom: theme.spacing(0.5),
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	statusCellRoot: {
		verticalAlign: 'middle',
		'& span': {
			width: '8px',
			height: '8px',
			display: 'inline-block',
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		},
	},
	emailCellRoot: {
		verticalAlign: 'middle',
		'& > span': {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(1),
			fontSize: '18px',
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
		padding: '20px',
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	gridWrapper: {
		// padding: theme.spacing(2.5, 2),
		// [theme.breakpoints.down('xs')]: {
		// 	padding: theme.spacing(1.5, 2),
		// },
	},
}))

const Users = ({ width, action, userId, history, perms }) => {
	const initialState = {
		loading: true,
		searchText: '',
		offset: 0,
		limit: 10,
		roleFilter: [],
		distributor_id: '',
		product_id: '',
		usrStatusFilter: USER_STATUS_FILTER.ALL,
		totalCount: 0,
		userList: [],
	}
	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		searchText: '',
		offset: 0,
		limit: 10,
		roleFilter: [],
		distributor_id: '',
		product_id: '',
		usrStatusFilter: USER_STATUS_FILTER.ALL,
		totalCount: 0,
		userList: [],
	})
	const [roleListState, setRoleListState] = useState({
		isFetchRoles: true,
		roleList: null,
	})
	const [currentUser, setCurrentUser] = useState('All')
	const [distributors, setDistributors] = useState([])
	const [products, setProducts] = useState([])
	const [prodSrLoad, setprodSrLoad] = useState(false)
	const [distriSrLoad, setdistriSrLoad] = useState(false)
	const [searchkey, setSearchkey] = useState('')
	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		selectedData: null,
	})
	const { open, anchorEl, selectedData } = popOverState

	const isCreateAllowed = _.get(perms, PERMISSION_TYPE.CREATE, false)
	const isEditAllowed = _.get(perms, PERMISSION_TYPE.EDIT, false)
	// const isDeleteAllowed = _.get(perms, PERMISSION_TYPE.DELETE, false)

	const [openSpecPermDialog, setOpenSpecPermDialog] = useState(false)
	const [openDelDialog, setOpenDelDialog] = useState(false)
	const [openFormDialog, setopenFormDialog] = useState(false)

	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const { searchText, loading, offset, totalCount, limit, roleFilter, usrStatusFilter, userList, distributor_id, product_id } = usersTblState
	const { isFetchRoles, roleList } = roleListState

	const isMounted = useRef(true)
	const searchBoxTimeout = useRef(undefined)
	const dispatch = useDispatch()
	const renderAfterCalled = useRef(false)

	useEffect(() => {
		// if (!renderAfterCalled.current) {
		let pay_values = {
			search_text: searchkey,
		}
		setdistriSrLoad(true)
		Api.matchDistributor(pay_values).then((res) => {
			let resp = _.map(res.data.data.retailers, (retailer) => ({
				id: _.toString(retailer.distributor_id),
				name: `${retailer.title} ---- (${retailer.distributor_id})`,
			}))
			setDistributors(resp)
			setdistriSrLoad(false)
		})
		// }
		// renderAfterCalled.current = true
	}, [searchkey])

	const onInputProductChange = (e) => {
		if (e === '') {
			addFilterAndRefreshTbl({ distributor_id: '', product_id: '' })
			setProducts([])
		} else {
			addFilterAndRefreshTbl({ distributor_id: e, product_id: '' })
		}
	}

	// const openFormDialog = !_.isEmpty(action)
	const handleCloseFormDialog = (e, isRefreshList) => {
		setopenFormDialog(false)
		history.replace({
			isRefreshList: true,
		})
	}

	const classes = useStyles()

	const closePopover = () => {
		setPopOverState({ ...popOverState, open: false })
	}

	const closeSpecPermDialog = () => {
		setOpenSpecPermDialog(false)
	}

	const closeDelDialog = (e, isRefreshList) => {
		setOpenDelDialog(false)
		history.replace({
			pathname: `/usermanagement/users`,
			isRefreshList: _.isEqual(isRefreshList, true),
		})
	}

	const onClickMoreOptions = useCallback((event, data) => {
		setPopOverState({
			open: true,
			anchorEl: event.currentTarget,
			selectedData: data,
		})
	}, [])

	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		return [
			{
				headerName: 'Retailer ID',
				field: 'retailer_id',
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Retailer',
				field: 'retailer',
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Product ID',
				field: 'product_id',
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Product',
				field: 'product_title',
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'MPN',
				field: 'mpn',
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Brand',
				field: 'brand',
				hide: !isWidthUp('md', width),
			},

			{
				headerName: '',
				field: '',
				maxWidth: 80,
				cellRenderer: 'actionCellRenderer',
				cellRendererParams: {
					containerClass: actionCellContainer,
					onClickMoreOptions,
				},
			},
		]
	}, [width, classes, authToken, onClickMoreOptions, roleList])

	const onRowClicked = (e) => {
		const isSuppressed = e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection
		if (!isSuppressed) openExistingUser(_.get(e, 'data.user_id'), PAGE_MODES.VIEW)
	}

	const addBtnClick = () => {
		setopenFormDialog(true)
		// history.replace({ pathname: `/usermanagement/users/create` })
	}

	const resetBtn = () => {
		setUsersTblState(initialState)
	}

	const onClickEditMenu = () => {
		closePopover()
		changeToEditMode(_.get(selectedData, 'user_id'))
	}

	const onClickPermMenu = () => {
		closePopover()
		setOpenSpecPermDialog(true)
	}

	const onClickDelMenu = () => {
		let prod_id = selectedData?.product_id
		let dist_id = selectedData?.retailer_id
		let Page = offset / limit + 1
		let pay_values = {
			mpn: '',
			product_id: _.toString(prod_id),
			distributor_id: _.toString(dist_id),
			match_for: 'product_restriction',
			action_type: 'remove',
		}
		closePopover()
		dispatch(
			showConfirmMessage(
				`Are you sure, you want to delete this restriction?`,
				'',
				'Confirm',
				(e) => {
					dispatch(showLoader('Please wait...'))
					Api.matchSubmit(
						pay_values,
						(resp) => {
							dispatch(hideLoader())
							fetchUsersList({
								limit,
								Page,
								distributor_id,
								product_id,
							})
							dispatch(showSnackbarWithTimeout(resp.data.message, 2000))
						},
						(err) => {
							dispatch(hideLoader())
							dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
						}
					)
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}

	const changeToEditMode = (userId) => {
		history.replace({
			pathname: `/usermanagement/users/${PAGE_MODES.EDIT}/${userId}`,
		})
	}

	const openExistingUser = (userId, action) => {
		history.replace({ pathname: `/usermanagement/users/${action}/${userId}` })
	}

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const onChangeSearchBox = (e) => {
		const searchText = e.target.value
		if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
		searchBoxTimeout.current = setTimeout(() => {
			addFilterAndRefreshTbl({ searchText, offset: 0 })
		}, 300)
	}

	const onChangeUserStatusRb = (event) => {
		addFilterAndRefreshTbl({ usrStatusFilter: event.target.value, offset: 0 })
		setCurrentUser(event.target.value)
	}

	const onChangeRoleSelectBox = (event, roles) => {
		addFilterAndRefreshTbl({ roleFilter: roles, offset: 0 })
	}

	const fetchUsersList = useCallback(({ limit, Page, distributor_id, product_id }) => {
		Api.fetchListPR(
			{
				limit,
				offset: Page,
				distributor_id,
				product_id,
			},
			(resp) => {
				const totalCount = _.get(resp, 'data.data.total_count', 0)
				const userList = _.get(resp, 'data.data.pr_data', [])
				if (isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount,
						userList,
					}))
				}
			},
			(err) => {
				if (!isAxiosCancelError(err) && isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: 0,
						userList: [],
					}))
				}
			}
		)
	}, [])

	const fetchProductList = useCallback(({ Limit, distributor_id, product_id }) => {
		setprodSrLoad(true)
		Api.fetchListPR(
			{
				limit: Limit,
				offset: 1,
				distributor_id,
				product_id,
			},
			(resp) => {
				const totalCount = _.get(resp, 'data.data.total_count', 0)
				const userList = _.get(resp, 'data.data.pr_data', [])
				if (isMounted.current) {
					let res = _.map(userList, (list) => ({
						id: _.toString(list.product_id),
						name: `${list.product_title} ---- (${list.product_id})`,
					}))
					setProducts(res)
					setprodSrLoad(false)
				}
			},
			(err) => {
				if (!isAxiosCancelError(err) && isMounted.current) {
					setprodSrLoad(false)
					// setUsersTblState((prevState) => ({
					// 	...prevState,
					// 	loading: false,
					// 	totalCount: 0,
					// 	userList: [],
					// }))
				}
			}
		)
	}, [])

	useEffect(() => {
		let Page = offset / limit + 1
		fetchUsersList({
			limit,
			Page,
			distributor_id,
			product_id,
		})
	}, [limit, offset, searchText, distributor_id, product_id, usrStatusFilter, roleFilter, roleList, fetchUsersList])
	useEffect(() => {
		if (_.isEmpty(distributor_id)) {
			let Page = offset / limit + 1
			let Limit = 1000
			fetchProductList({
				Limit,
				Page,
				distributor_id,
				product_id,
			})
		}
		if (!_.isEmpty(distributor_id)) {
			let Page = offset / limit + 1
			let Limit = 0
			fetchProductList({
				Limit,
				Page,
				distributor_id,
				product_id,
			})
		}
	}, [distributor_id])

	const onInputProductAPIChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					let product_id = e.target.value
					// setProdSearch(e.target.value)
					if (_.isEmpty(distributor_id)) {
						let Page = offset / limit + 1
						let Limit = 1000
						fetchProductList({
							Limit,
							Page,
							distributor_id,
							product_id,
						})
					}
					if (!_.isEmpty(distributor_id)) {
						let Page = offset / limit + 1
						let Limit = 0
						fetchProductList({
							Limit,
							Page,
							distributor_id,
							product_id,
						})
					}
				}, 2000)
			}
		}
	}

	//Refresh function start
	const isNeedRefreshList = _.get(history, 'location.isRefreshList', false)
	useEffect(() => {
		if (isNeedRefreshList) {
			// setUsersTblState((prevState) => {
			let Page = offset / limit + 1
			fetchUsersList({
				limit,
				Page,
				distributor_id,
				product_id,
			})
			_.set(history, 'location.isRefreshList', false)
		}
	}, [isNeedRefreshList, fetchUsersList, history])
	//Refresh function end

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	const onInputSRChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					// let product_id = e.target.value
					// setProdSearch(e.target.value)
					setSearchkey(e.target.value)
				}, 2000)
			}
		}
	}

	return (
		<Grid container className={classes.root} item xs={12}>
			<Typography gutterBottom variant='h6' component='h6'>
				<strong>Product Restriction:</strong>
			</Typography>
			<ListPageFilterSection
				disabled={true}
				searchPlaceholder={'Search Products...'}
				addBtnClick={addBtnClick}
				hideAddBtn={!isCreateAllowed}
				hideSearch={true}
				onChangeSearchBox={onChangeSearchBox}
				addBtnTxt={'Add Restriction'}
			>
				<div className={classes.advFilterContent}>
					<div>
						<FormLabel component='legend' className={classes.radioFormLabel}>
							User Status
						</FormLabel>
						<RadioGroup row aria-label='position' onChange={onChangeUserStatusRb} value={usrStatusFilter} name='position' defaultValue='top'>
							<FormControlLabel
								value='top'
								classes={{ label: classes.switchLabel }}
								control={<Radio size='small' value={USER_STATUS_FILTER.ALL} color='primary' />}
								label='All'
								labelPlacement='end'
							/>
							<FormControlLabel
								value='top'
								classes={{ label: classes.switchLabel }}
								control={<Radio size='small' value={USER_STATUS_FILTER.ACTIVE} color='primary' />}
								label='Active'
								labelPlacement='end'
							/>
							<FormControlLabel
								value='top'
								classes={{ label: classes.switchLabel }}
								control={<Radio size='small' value={USER_STATUS_FILTER.INACTIVE} color='primary' />}
								label='Inactive'
								labelPlacement='end'
							/>
						</RadioGroup>
					</div>
					<div>
						<Autocomplete
							multiple
							id='tags-outlined'
							options={roleList || []}
							size='small'
							value={roleFilter || []}
							getOptionLabel={(option) => option.role_name}
							onChange={onChangeRoleSelectBox}
							filterSelectedOptions
							renderInput={(params) => <TextField {...params} variant='outlined' label='User Roles' />}
						/>
					</div>
					{/* <div style={{padding:'inherit'}}> */}
					<Button onClick={resetBtn} color='primary' variant='text' size='medium' disableElevation style={{ padding: 'inherit', fontWeight: 'bold' }}>
						Reset
					</Button>
					{/* </div> */}
				</div>
			</ListPageFilterSection>
			<Paper className={classes.paper}>
				<Grid container className={classes.gridWrapper}>
					<Grid item xs={6} className={classes.tabPanelWrapper}>
						<SelectBox
							label='Retailer'
							value={distributor_id}
							onChange={(e) => onInputProductChange(e)}
							onInputChange={(e) => onInputSRChange(e)}
							isLoading={distriSrLoad}
							inputClass={classes.selectField}
							disableClearable={false}
							name='Retailer'
							options={distributors}
							noOptionsText={'No Options'}
						></SelectBox>
					</Grid>
					<Grid item xs={6} className={classes.tabPanelWrapper}>
						<SelectBox
							label='Product'
							value={product_id}
							onChange={(e) => {
								e === ' ' ? addFilterAndRefreshTbl({ product_id: '' }) : addFilterAndRefreshTbl({ product_id: e })
							}}
							onInputChange={(e) => onInputProductAPIChange(e)}
							inputClass={classes.selectField}
							disableClearable={false}
							isLoading={prodSrLoad}
							name='Retailer'
							options={products}
							noOptionsText={'No Options'}
						></SelectBox>
					</Grid>
				</Grid>
			</Paper>
			<Grid item xs={12}>
				<div>
					<AgGridCustom
						columnDefs={gridHeaders}
						rowData={userList}
						loading={loading}
						enableCellTextSelection={true}
						frameworkComponents={{
							nameCellRenderer: withCellRenderState(NameCellRenderer),
							roleCellRenderer: withCellRenderState(RoleCellRenderer),
							statusCellRenderer: withCellRenderState(StatusCellRenderer),
							emailCellRenderer: withCellRenderState(EmailCellRenderer),
							actionCellRenderer: withCellRenderState(ActionCellRenderer),
						}}
						disableClickSelectionRenderers={['actionCellRenderer']}
						// onRowClicked={onRowClicked}
						rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
						noDataTxt={!_.isEqual(currentUser, 'All') ? `No ${currentUser} restrictions found` : 'No restrictions found'}
					></AgGridCustom>
				</div>
				<div>
					{!loading && (
						<TablePagination
							component='div'
							count={totalCount}
							page={offset / limit}
							onChangePage={onChangePage}
							rowsPerPage={limit}
							onChangeRowsPerPage={onChangeRowsPerPage}
						/>
					)}
					<Popover
						id={'more_options'}
						open={open}
						anchorEl={anchorEl}
						onClose={closePopover}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuList autoFocusItem={open} id='options_list'>
							{/* {isDeleteAllowed && ( */}
							<MenuItem dense={true} onClick={onClickDelMenu}>
								<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
									<Icon className={classes.menuIcon} fontSize='small'>
										delete
									</Icon>
								</ListItemIcon>
								<Typography className={classes.menuText} variant='inherit'>
									Delete
								</Typography>
							</MenuItem>
							{/* )} */}
						</MenuList>
					</Popover>
					<FormDialog
						open={openFormDialog}
						userId={userId}
						action={action}
						distributors={distributors}
						products={products}
						isRoleListLoading={isFetchRoles}
						roleList={roleList}
						isEditAllowed={isEditAllowed}
						changeToEditMode={changeToEditMode}
						handleClose={handleCloseFormDialog}
					></FormDialog>
					<SpecialPermDialog
						open={openSpecPermDialog}
						handleClose={closeSpecPermDialog}
						userId={_.get(selectedData, 'user_id')}
						email={_.get(selectedData, 'email')}
						firstName={_.get(selectedData, 'first_name')}
						lastName={_.get(selectedData, 'last_name')}
						accessToken={authToken}
						isEditAllowed={isEditAllowed}
						profilePic={_.get(selectedData, 'profile_pic_url')}
					/>
					<DeleteDialog
						open={openDelDialog}
						handleClose={closeDelDialog}
						userId={_.get(selectedData, 'user_id')}
						email={_.get(selectedData, 'email')}
						firstName={_.get(selectedData, 'first_name')}
						lastName={_.get(selectedData, 'last_name')}
						accessToken={authToken}
						profilePic={_.get(selectedData, 'profile_pic_url')}
					/>
				</div>
			</Grid>
		</Grid>
	)
}

Users.propTypes = {
	action: PropTypes.string,
	userId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Users)
