import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Typography, Radio, RadioGroup } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import api from '../../../services/aiqa/bb/api'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	contentWrapper: {
		width: '100%',
		// textAlign: 'center',
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	// gridWrapper: {
	// 	flexGrow: 1,
	// 	overflow: 'hidden',
	// 	padding: theme.spacing(0, 3),
	// 	maxWidth: 800,
	// },
	textField: {
		// maxWidth: 400,
		// margin: `${theme.spacing(1)}px auto`,
		// padding: theme.spacing(2),
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		'& .MuiGrid-spacing-xs-3': {
			margin: 0,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	actionButton: {
		textAlign: 'center',
		fontWeight: 'bold',
		// textDecoration: 'underline',
	},
	key: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	table: {
		'& .MuiTableRow-head': {
			background: 'rgba(3, 169, 244, 0.44)',
		},
	},
	mobileBoardContainerOpened: {
		[theme.breakpoints.down('xs')]: {
			'&::after': {
				position: 'absolute',
				width: '100%',
				height: '100%',
				content: '" "',
				background: 'rgba(0,0,0,0.5)',
				zIndex: 1,
			},
		},
		paddingBottom: theme.spacing(0),
	},
	searchBox: {
		marginTop: '15px',
	},
	totalLength: {
		marginTop: '20px',
		textAlign: 'center',
	},
	prevNext: {
		marginTop: '11px',
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
}))

export default function AlertDialog({ data }) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [open, setOpen] = React.useState(false)
	const initial = {
		avg_price_gbp: data.aipr_avg_price_gbp || '',
		ai_prediction_id: data.aipr_id || 0,
		img: data.aipr_img || '',
		page_name: data.aipr_page_name || '',
		ptitle: data.aipr_ptitle || '',
		page_name_err_log: '404',
		img_err_log: 'Logo Image',
		price_err_log: '',
		ptitle_err_log: '',
	}
	const [initialdata, setInitialdata] = React.useState({
		avg_price_gbp: data.aipr_avg_price_gbp || '',
		ai_prediction_id: data.aipr_id || 0,
		img: data.aipr_img || '',
		page_name: data.aipr_page_name || '',
		ptitle: data.aipr_ptitle || '',
		page_name_err_log: '404',
		img_err_log: 'Logo Image',
		price_err_log: '',
		ptitle_err_log: '',
	})

	const { avg_price_gbp, ai_prediction_id, img, page_name, ptitle, page_name_err_log, img_err_log, price_err_log, ptitle_err_log } = initialdata

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleChange = (e) => {
		const { name, value } = e.target
		setInitialdata((prevState) => ({
			...prevState,
			[name]: value,
		}))
	}

	const handleCheck = (name, value) => {
		// const { name, value } = e.target
		setInitialdata((prevState) => ({
			...prevState,
			[name]: value,
		}))
	}

	const onSubmit = () => {
		api.ErrorRpt(
			initialdata,
			(res) => {
				setInitialdata(initial)
				dispatch(showSnackbarWithTimeout(res.data.message, 2000))
			},
			(err) => {
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	return (
		<div>
			<IconButton size='small' title='Report Error' onClick={handleClickOpen} style={{ marginLeft: '60px', color: 'red' }}>
				<i className='material-icons'>report</i>
			</IconButton>
			<Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title' className={classes.paperTitle}>
					{'Report Error'}
				</DialogTitle>
				<DialogContent>
					{/* <DialogContentText id='alert-dialog-description'> */}
					<Typography variant='body1' className={classes.key}>
						Product Title:
					</Typography>
					<DialogContentText id='alert-dialog-description'>
						<Typography variant='body1' className={classes.title}>
							{data.aipr_ptitle}
						</Typography>
					</DialogContentText>
					<FormControlLabel
						control={
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
								checkedIcon={<CheckBoxIcon fontSize='small' />}
								// value='Error In Product Title'
								name='ptitle_err_log'
								checked={ptitle_err_log === '' ? false : true}
								onChange={() => handleCheck('ptitle_err_log', ptitle_err_log === '' ? 'Error In Product Title' : '')}
							/>
						}
						style={{ marginBottom: '25px' }}
						label='Error in Product Title'
					/>
					<Typography variant='body1' className={classes.key}>
						Price:
					</Typography>
					<DialogContentText id='alert-dialog-description'>
						<Typography variant='body1' className={classes.title}>
							{data.aipr_avg_price_gbp}
						</Typography>
					</DialogContentText>
					<FormControlLabel
						control={
							<Checkbox
								icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
								checkedIcon={<CheckBoxIcon fontSize='small' />}
								// value='Error in Price'
								name='price_err_log'
								checked={price_err_log === '' ? false : true}
								onChange={() => handleCheck('price_err_log', price_err_log === '' ? 'Error In Price' : '')}
							/>
						}
						style={{ marginBottom: '25px' }}
						label='Error in Price'
						// onClick={handleChange}
					/>
					<Typography variant='body1' className={classes.key}>
						Page URL:
					</Typography>
					<DialogContentText id='alert-dialog-description'>
						<Typography variant='body1' className={classes.title}>
							{data.aipr_page_name}
						</Typography>
					</DialogContentText>
					<RadioGroup
						row
						aria-label='position'
						name='page_name_err_log'
						value={page_name_err_log}
						style={{ marginBottom: '25px' }}
						onChange={handleChange}
					>
						<FormControlLabel
							value='404'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' color='primary' />}
							label='404'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='Page Not Open'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' color='primary' />}
							label='Page Not Open'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='Not Product Page'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' color='primary' />}
							label='Not Product Page'
							labelPlacement='end'
						/>
					</RadioGroup>
					<Typography variant='body1' className={classes.key}>
						Image:
					</Typography>

					<Typography variant='body1' className={classes.title}>
						<img src={data.aipr_img} width={180} height={180} />
					</Typography>
					<RadioGroup row aria-label='position' name='img_err_log' value={img_err_log} onChange={handleChange}>
						<FormControlLabel
							value='Logo Image'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' color='primary' />}
							label='Logo Image'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='No Image Found'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' color='primary' />}
							label='No Image found'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='Wrong Image'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' color='primary' />}
							label='Wrong Image'
							labelPlacement='end'
						/>
					</RadioGroup>
					{/* </DialogContentText> */}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						Cancel
					</Button>
					<Button onClick={onSubmit} color='primary' autoFocus>
						Send Report
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
