import { ACTION_TYPES } from '../../../constants'
import * as localStorage from '../../localstorage'
import currentUserReducer from './currentUser'

// function removeAuthTokenInLocalStorage(){
//     localStorage.removeLocalStorageItem('authToken');
//     localStorage.removeLocalStorageItem('isAuthenticated');
//     localStorage.removeLocalStorageItem('userId');
// }

function addAuthDetailsToLocalStorage(authToken, sisenseToken, userId) {
	localStorage.setLocalStorageItem('authToken', authToken)
	localStorage.setLocalStorageItem('sisenseToken', sisenseToken)
	localStorage.setLocalStorageItem('isAuthenticated', !!authToken)
	localStorage.setLocalStorageItem('userId', userId)
}

function getDefaultState() {
	//FETCH ALL SESSION PROPERTIES FROM LOCAL STORAGE TO AFFECT STORE
	return {
		authToken: localStorage.getLocalStorageItem('authToken') || undefined,
		sisenseToken: localStorage.getLocalStorageItem('sisenseToken') || undefined,
		isAuthenticated: localStorage.getLocalStorageItem('isAuthenticated') === 'true' || false,
		userId: localStorage.getLocalStorageItem('userId') || '',
		user: {},
	}
}

const session = (state = getDefaultState(), action) => {
	switch (action.type) {
		case ACTION_TYPES.AUTH_LOGIN_PENDING: {
			//REMOVE EVERYTHING IN LOCAL STORAGE
			localStorage.clearAll()
			return {
				...state,
				isLoading: true,
				isAuthenticated: false,
				authToken: '',
				sisenseToken: '',
				error: undefined,
				userId: '',
			}
		}
		case ACTION_TYPES.AUTH_LOGIN_REJECTED: {
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				error: { message: action.payload.response.data.more_info },
				authToken: '',
				sisenseToken: '',
				userId: '',
			}
		}
		case ACTION_TYPES.AUTH_LOGIN_FULFILLED: {
			//SETTING AUTH TOKEN FROM RESPONSE TO LOCAL STORAGE
			let sisenseToken = action.payload.data.data.sisense_token
			let authToken = action.payload.data.data.auth_token
			let rediURL = action.payload.data.data.redirect_url
			let userId = action.payload.data.data.user_id
			addAuthDetailsToLocalStorage(authToken, sisenseToken, userId)
			return {
				...state,
				isLoading: false,
				isAuthenticated: true,
				error: undefined,
				authToken: authToken,
				rediURL: rediURL,
				sisenseToken: sisenseToken,
				userId: userId,
			}
		}
		case ACTION_TYPES.AUTH_LOGOUT_PENDING: {
			return {
				...state,
				isLoading: true,
				error: undefined,
				showLogOutError: false,
			}
		}
		case ACTION_TYPES.AUTH_LOGOUT_FULFILLED: {
			//REMOVE EVERYTHING IN LOCAL STORAGE
			localStorage.clearAll()
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				authToken: '',
				sisenseToken: '',
				error: undefined,
				userId: '',
			}
		}
		case ACTION_TYPES.AUTH_LOGOUT_REJECTED: {
			return {
				...state,
				isLoading: false,
				error: { message: action.payload.response.data.more_info },
				showLogOutError: true,
			}
		}
		case ACTION_TYPES.AUTH_LOGOUT_DISMISS_ERROR: {
			return {
				...state,
				showLogOutError: false,
			}
		}
		case ACTION_TYPES.UNAUTH_ERROR: {
			//REMOVE EVERYTHING IN LOCAL STORAGE
			localStorage.clearAll()
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				authToken: '',
				sisenseToken: '',
				error: { message: 'Session Timeout! Please signin again' },
				showLogOutError: false,
				userId: '',
			}
		}
		case ACTION_TYPES.FETCH_CURRENT_USER_PENDING:
			return {
				...state,
				user: currentUserReducer(state?.user, action),
			}
		case ACTION_TYPES.FETCH_CURRENT_USER_FULFILLED:
			return {
				...state,
				user: currentUserReducer(state?.user, action),
			}
		case ACTION_TYPES.FETCH_USER_REJECTED:
			return {
				...state,
				user: currentUserReducer(state?.user, action),
			}
		case ACTION_TYPES.RESET_CURRENT_USER:
			return {
				...state,
				user: currentUserReducer(state?.user, action),
			}
		default:
			return state
	}
}

export default session
