import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	Badge,
	Fab,
	Grow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FilterMenuList from './FilterMenu'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgGridCustom from '../../../components/AgGridCustom'
import ListPageFilterSection from '../../../components/ListPageFilterSection'
import { hideLoader, showLoader } from '../../../services/loader/actions'
import Api from '../../../services/prmatching/api'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { isAxiosCancelError } from '../../../utils'
import { data1, data2 } from './data'
import classnames from 'classnames'
import Query from './QueryBuilder'

import MaterialConfig from 'react-awesome-query-builder/lib/config/material'

const REPORT_STATUS = {
	ALL: 'all',
	MATCHED: 'matched',
	SKIPPED: 'skipped',
}

const getRptStatusFilterVal = (rptStatusVal) => {
	switch (rptStatusVal) {
		case REPORT_STATUS.ALL:
			return undefined
		case REPORT_STATUS.MATCHED:
			return true
		case REPORT_STATUS.SKIPPED:
			return false
		default:
			return undefined
	}
}

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	switchLabel: {
		fontSize: '14px',
	},
	radioFormLabel: {
		fontSize: '15px',
		// marginBottom: theme.spacing(0.5),
		marginTop: '11px',
	},
	groupBorder: {
		border: 'ridge',
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		justifyContent: 'flex-end',
	},
	filterInc: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		flexDirection: 'row-reverse',
		// border: 'ridge',
	},
	grpInc: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		flexDirection: 'row-reverse',
		border: 'ridge',
	},
	groupButton: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		marginLeft: '450px',
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	statusCellRoot: {
		verticalAlign: 'middle',
		'& span': {
			width: '8px',
			height: '8px',
			display: 'inline-block',
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		},
	},
	emailCellRoot: {
		verticalAlign: 'middle',
		'& > span': {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(1),
			fontSize: '18px',
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
}))

const Report = ({ width, action, userId, history, perms }) => {
	const dispatch = useDispatch()

	const classes = useStyles()
	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		searchText: '',
		offset: 0,
		limit: 10,
		roleFilter: [],
		rptStatusFilter: REPORT_STATUS.ALL,
		totalCount: 0,
		userList: [],
	})

	const [roleListState, setRoleListState] = useState({
		isFetchRoles: true,
		roleList: null,
	})
	const [currentUser, setCurrentUser] = useState('all')
	const [searchQuery, setsearchQuery] = useState('')
	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		selectedData: null,
	})

	//Fiilters
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const [filter, setFilter] = useState({ searchText: '', filterData: {} })
	const { filterData } = filter
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(filterData, _.isEmpty)), [filterData])

	const [cardData, setCardData] = useState([])
	const [matchmissData, setmatchmissData] = useState([])
	const [currentPage, setCurrentPage] = React.useState(0)

	const [count, setCount] = React.useState(0)
	const [value, setValue] = React.useState('')
	const [resAll, setresAll] = React.useState(false)
	const [matchData, setmatchData] = React.useState('')
	const [enupdate, setEnupdate] = React.useState(false)
	const [Okfilter, setOkfilter] = React.useState({})
	const [matchtype, setMatchtype] = React.useState('OEM')
	const [filters, setFilters] = React.useState({
		match_type: data1,
		match_for: data2,
		distributor_id: [],
		brand: [],
		mpn: [],
		ean: [],
		sku: [],
		// impressions: data,
	})
	const [incExc, setincExc] = React.useState({
		match_date: 'include',
		match_type: 'include',
		match_for: 'include',
		distributor_id: 'include',
		brand: 'include',
		mpn: 'include',
		ean: 'include',
		sku: 'include',
	})
	const renderAfterCalled = useRef(false)

	const updateFilter = useCallback(
		(value) => {
			let formatedData = {
				...value,
			}
			setFilter((prevState) => ({ ...prevState, filterData: formatedData }))
			// setOkfilter((prevState) => ({ ...prevState, ...value }))
			let data = _.omitBy(formatedData, _.isEmpty)
		},
		[dispatch]
	)
	const updateOKFilter = useCallback(
		(value, path) => {
			let formatedData = {
				...value,
			}
			// setFilter((prevState) => ({ ...prevState, filterData: formatedData }))

			setOkfilter(value)

			if (value === '') {
				setEnupdate(true)
			}
			let data = _.omitBy(formatedData, _.isEmpty)
		},
		[dispatch]
	)

	const initialFilter = () => {
		let filtArray = ['distributor_id', 'brand', 'mpn', 'ean', 'sku']
		filtArray.forEach((key) => {
			let pay_values = {
				search_text: '',
				all: true,
			}

			Api.MatchFilterGet(
				pay_values,
				key,
				(res) => {
					let resp = res.data.data
					// setmatchmissData(resp)
					setFilters((prevState) => ({
						...prevState,
						...resp,
					}))
				},
				(err) => {}
			)
		})
		// })
		// renderAfterCalled.current = true
		// console.log('calling...')
	}

	useEffect(() => {
		initialFilter()
	}, [])

	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const { searchText, loading, offset, totalCount, limit, roleFilter, rptStatusFilter, userList } = usersTblState
	const { isFetchRoles, roleList } = roleListState

	const isMounted = useRef(true)
	const searchBoxTimeout = useRef(undefined)

	const onClickMoreOptions = useCallback((event, data) => {
		setPopOverState({
			open: true,
			anchorEl: event.currentTarget,
			selectedData: data,
		})
	}, [])

	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		return [
			{
				headerName: 'Retailer Name',
				field: 'retailer_name',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Product Id',
				field: 'product_id',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Brand',
				field: 'brand',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'MPN',
				field: 'mpn',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'EAN',
				field: 'ean',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'SKU',
				field: 'sku',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Match For',
				field: 'match_for',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Match Type',
				field: 'match_type',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'File Name',
				field: 'file_name',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Match By',
				field: 'match_by',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Match Date',
				field: 'match_date',
				minWidth: 200,
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
		]
	}, [width, classes, authToken, onClickMoreOptions, roleList])

	const addBtnClick = () => {
		history.replace({ pathname: `/usermanagement/users/create` })
	}

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const onChangeSearchBox = (e) => {
		const searchText = e.target.value
		if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
		searchBoxTimeout.current = setTimeout(() => {
			addFilterAndRefreshTbl({ searchText, offset: 0 })
		}, 300)
	}

	const fetchReportList = ({ limit, offset }) => {
		dispatch(showLoader('Please wait, getting reports'))
		let pay_values = {
			// search_text: '',

			filters: [
				{
					column: 'match_date',
					data: filterData.match_date ? [JSON.stringify(filterData.match_date)] : [],
					method: incExc.match_date,
					type: 'range',
				},
				{
					column: 'match_type',
					data: filterData.match_type ? filterData.match_type.map(String) : [],
					method: incExc.match_type,
					type: 'str',
				},
				{
					column: 'match_for',
					data: filterData.match_for ? filterData.match_for.map(String) : [],
					method: incExc.match_for,
					type: 'str',
				},
				{
					column: 'distributor_id',
					data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
					method: incExc.distributor_id,
					type: 'int',
				},
				{
					column: 'brand',
					data: filterData.brand ? filterData.brand.map(String) : [],
					method: incExc.brand,
					type: 'str',
				},
				{
					column: 'mpn',
					data: filterData.mpn ? filterData.mpn.map(String) : [],
					method: incExc.mpn,
					type: 'str',
				},
				{
					column: 'ean',
					data: filterData.ean ? filterData.ean.map(String) : [],
					method: incExc.ean,
					type: 'str',
				},
				{
					column: 'sku',
					data: filterData.sku ? filterData.sku.map(String) : [],
					method: incExc.sku,
					type: 'str',
				},
			],
			all: _.isEmpty(filterData) ? true : false,
			offset: offset,
			limit: limit,
		}
		Api.MatchHistoryData(
			pay_values,
			(resp) => {
				const totalCount = _.get(resp, 'data.data.total_count', 0)
				const userList = _.get(resp, 'data.data.matched_data', [])
				// const userList = results
				if (isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount,
						userList,
					}))
				}
				dispatch(hideLoader())
			},
			(err) => {
				if (!isAxiosCancelError(err) && isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: 0,
						userList: [],
					}))
				}
				dispatch(hideLoader())
			}
		)
	}

	useEffect(() => {
		// if (_.isNull(roleList)) fetchRolesList()
		// else
		fetchReportList({
			limit,
			offset,
		})
	}, [resAll, limit, offset])

	const onChangeFilter = (path, fn) => {
		if (path === 'match_date') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: {} }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['match_date']) }))
			}

			// updateClick()
		}
		if (path === 'match_type') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['match_date']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['match_date', 'match_type']) }))
			}

			// updateClick()
		}
		if (path === 'match_for') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['match_date', 'match_type']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for']) }))
			}

			// updateClick()
		}
		if (path === 'distributor_id') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for']) }))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id']),
				}))
			}
			// updateClick()
		}
		if (path === 'brand') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id']) }))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id', 'brand']),
				}))
			}
			// updateClick()
		}
		if (path === 'mpn') {
			if (fn === 'reset') {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id', 'brand']),
				}))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id', 'brand', 'mpn']),
				}))
			}
			// updateClick()
		}
		if (path === 'ean') {
			if (fn === 'reset') {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id', 'brand', 'mpn']),
				}))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id', 'brand', 'mpn', 'ean']),
				}))
			}
			// updateClick()
		}
		if (path === 'sku') {
			if (fn === 'reset') {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id', 'brand', 'mpn', 'ean']),
				}))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['match_date', 'match_type', 'match_for', 'distributor_id', 'brand', 'mpn', 'ean', 'sku']),
				}))
			}
			// updateClick()
		}
	}

	const onOkClick = (path) => {
		// if (path === 'match_date' || path === 'match_type' || path === 'match_for' || path === 'sku') {

		addFilterAndRefreshTbl({ limit: 10, offset: 0 })
		fetchReportList({
			limit: 10,
			offset: 0,
		})
		// }
		let pay_values = {}
		let filtArray = []
		let initial_values = [
			{
				column: 'match_date',
				data: filterData.match_date ? [JSON.stringify(filterData.match_date)] : [],
				method: incExc.match_date,
				type: 'range',
			},
			{
				column: 'match_type',
				data: filterData.match_type ? filterData.match_type.map(String) : [],
				method: incExc.match_type,
				type: 'str',
			},
			{
				column: 'match_for',
				data: filterData.match_for ? filterData.match_for.map(String) : [],
				method: incExc.match_for,
				type: 'str',
			},
			{
				column: 'distributor_id',
				data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
				method: incExc.distributor_id,
				type: 'int',
			},
			{
				column: 'brand',
				data: filterData.brand ? filterData.brand.map(String) : [],
				method: incExc.brand,
				type: 'str',
			},
			{
				column: 'mpn',
				data: filterData.mpn ? filterData.mpn.map(String) : [],
				method: incExc.mpn,
				type: 'str',
			},
			{
				column: 'ean',
				data: filterData.ean ? filterData.ean.map(String) : [],
				method: incExc.ean,
				type: 'str',
			},
			{
				column: 'sku',
				data: filterData.sku ? filterData.sku.map(String) : [],
				method: incExc.sku,
				type: 'str',
			},
		]
		if (path === 'match_date') {
			pay_values = {
				onchange_for: 'match_date',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['match_date'], column)])),
			}
			filtArray = ['distributor_id', 'brand', 'mpn', 'ean', 'sku']
		}
		if (path === 'match_type') {
			pay_values = {
				onchange_for: 'match_type',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['match_date', 'match_type'], column)])),
			}
			filtArray = ['distributor_id', 'brand', 'mpn', 'ean', 'sku']
		}
		if (path === 'match_for') {
			pay_values = {
				onchange_for: 'match_for',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['match_date', 'match_type', 'match_for'], column)])),
			}
			filtArray = ['distributor_id', 'brand', 'mpn', 'ean', 'sku']
		}
		if (path === 'distributor_id') {
			pay_values = {
				onchange_for: 'distributor_id',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['match_date', 'match_type', 'match_for', 'distributor_id'], column)])),
			}
			filtArray = ['brand', 'mpn', 'ean', 'sku']
		}
		if (path === 'brand') {
			pay_values = {
				onchange_for: 'brand',
				filters: _.filter(initial_values, ({ column }) =>
					_.every([_.includes(['match_date', 'match_type', 'match_for', 'distributor_id', 'brand'], column)])
				),
			}
			filtArray = ['mpn', 'ean', 'sku']
		}
		if (path === 'mpn') {
			pay_values = {
				onchange_for: 'mpn',
				filters: _.filter(initial_values, ({ column }) =>
					_.every([_.includes(['match_date', 'match_type', 'match_for', 'distributor_id', 'brand', 'mpn'], column)])
				),
			}
			filtArray = ['ean', 'sku']
		}
		if (path === 'ean') {
			pay_values = {
				onchange_for: 'ean',
				filters: _.filter(initial_values, ({ column }) =>
					_.every([_.includes(['match_date', 'match_type', 'match_for', 'distributor_id', 'brand', 'mpn', 'ean'], column)])
				),
			}
			filtArray = ['sku']
		}

		filtArray.forEach((key) => {
			Api.MatchFilterOnchange(
				pay_values,
				key,
				(res) => {
					let resp = res.data.data
					// setmatchmissData(resp)
					setFilters((prevState) => ({
						...prevState,
						...resp,
					}))
					// fetchReportList({
					// 	limit,
					// 	offset,
					// })
					// setValue(!_.isEmpty(resp.mpn) ? resp.mpn : !_.isEmpty(resp.ean) ? resp.ean : resp.product_title)
					// console.log(resp, key)
				},
				(err) => {}
			)
		})
	}

	//Refresh function start
	const isNeedRefreshList = _.get(history, 'location.isRefreshList', false)
	useEffect(() => {
		if (isNeedRefreshList) {
			setUsersTblState((prevState) => {
				fetchReportList({
					limit: 10,
					offset: 0,
					searchText: prevState.searchText,
					roleFilter: [],
					rptStatusFilter: REPORT_STATUS.ALL,
				})
				return {
					...prevState,
					loading: true,
					offset: 0,
					userList: [],
					roleFilter: [],
					rptStatusFilter: REPORT_STATUS.ALL,
				}
			})
			_.set(history, 'location.isRefreshList', false)
		}
	}, [isNeedRefreshList, fetchReportList, history])
	//Refresh function end

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	const onChangeUserStatusRb = (event) => {
		// addFilterAndRefreshTbl({ rptStatusFilter: event.target.value, offset: 0 })
		setCurrentUser(event.target.value)
	}

	return (
		<Grid container className={classes.root} item xs={12}>
			{/* <ListPageFilterSection
				// disabled={isFetchRoles}
				searchPlaceholder={'Search Users...'}
				addBtnClick={addBtnClick}
				// hideAddBtn={!isCreateAllowed}
				onChangeSearchBox={onChangeSearchBox}
				addBtnTxt={'Add User'}
				hideAddBtn={true}
				hideSearch={true}
			>
				<div className={classes.groupBorder}>
					<Query
						config={config}
						searchQuery={searchQuery}
						setsearchQuery={setsearchQuery}
						onClearClick={onClearClick}
						onFilterClick={onFilterClick}
						onExportClick={onExportClick}
					/>
				</div>
			</ListPageFilterSection> */}
			{/* <div> */}
			{/* </div> */}
			{currentUser === 'all' && (
				<Grid item xs={12}>
					<div>
						<AgGridCustom
							columnDefs={gridHeaders}
							rowData={userList}
							loading={loading}
							enableCellTextSelection={true}
							// onRowClicked={onRowClicked}
							rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
							noDataTxt={'No reports found'}
						></AgGridCustom>
					</div>
					<div>
						{!loading && (
							<TablePagination
								component='div'
								count={totalCount}
								page={offset / limit}
								onChangePage={onChangePage}
								rowsPerPage={limit}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						)}
					</div>
				</Grid>
			)}

			<div>
				<Grow in={true} unmountOnExit>
					<Fab
						className={classes.fab}
						color='primary'
						size='small'
						onClick={() => {
							setMenuProps((prev) => ({
								...prev,
								isOpen: true,
							}))
						}}
					>
						{isFilterActive ? (
							<Badge color='error' badgeContent={''} variant='dot'>
								<Icon>filter_list</Icon>
							</Badge>
						) : (
							<Icon>filter_list</Icon>
						)}
					</Fab>
				</Grow>
				{filterMenuProps.isOpen && (
					<div className={classnames(classes.menuContainer, { [classes.openMenu]: filterMenuProps.isOpen })}>
						<FilterMenuList
							token={authToken}
							incExc={incExc}
							setincExc={setincExc}
							activeFilters={filter?.filterData}
							activeOKFilters={Okfilter}
							setMenuProps={setMenuProps}
							filters={filters}
							updateFunc={updateFilter}
							updateOKFunc={updateOKFilter}
							setEnupdate={setEnupdate}
							onOkClick={onOkClick}
							onChangeFilter={onChangeFilter}
							InitialFilter={initialFilter}
							setFilter={setFilter}
							resAll={resAll}
							setresAll={setresAll}
						/>
					</div>
				)}
			</div>
		</Grid>
	)
}

Report.propTypes = {
	action: PropTypes.string,
	userId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Report)
