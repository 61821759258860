import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Grid, Paper } from '@material-ui/core'
import StepOne from './StepOne'
import { DEVICE_TYPE, INPAGE_SCENERIOS, TEST_LEVEL } from '../../../constants'
import moment from 'moment'
import api from '../../../services/abtesttool/api'
import { hideLoader, showErrorMessage, showLoader } from '../../../services/loader/actions'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		// width: '100%',
		margin: '20px',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
}))

export default function CreateTest(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	let test_id = _.get(props.match, 'params.id', '')

	const [data, setData] = React.useState({
		test_name: '',
		flix_product_type: '',
		split_range: '',
		test_type: '',
		device_type: _.values(DEVICE_TYPE),
		variant1: '',
		variant2: '',
		test_level: 'brand',
		s3_jsons: [],
		start_date: new Date(),
		end_date: new Date(moment().add(1, 'days')),
	})

	useEffect(() => {
		if (!_.isEmpty(test_id)) {
			dispatch(showLoader('Please wait, getting test details'))
			api.viewTest(
				test_id,
				(res) => {
					let resp = res.data.data
					setData({
						...data,
						test_name: resp?.test_name,

						flix_product_type: resp?.flix_product_type,
						split_range: resp?.split_range,
						test_type: _.find(_.values(INPAGE_SCENERIOS), ['id', resp?.test_type]).name,
						device_type: _.filter(_.values(DEVICE_TYPE), (v) => _.includes(resp?.device_type, v.id)),
						s3_jsons: resp?.s3_jsons.test_files,

						variant1: resp?.variant1 === null ? '' : resp?.variant1,
						variant2: resp?.variant2 === null ? '' : resp?.variant2,
						test_level: _.find(_.values(TEST_LEVEL), ['id', resp?.test_level]).name,

						start_date: new Date(resp?.start_date),
						end_date: new Date(resp?.end_date),
					})
					dispatch(hideLoader())
				},
				(err) => {
					// setFetchingTemplate(false)
					// handleClose()
					dispatch(
						showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again'), 'Close', () => {
							dispatch(hideLoader())
						})
					)
				}
			)
		}
	}, [])

	return (
		<div className={classes.root}>
			{/* <Button style={{ fontWeight: 'bold' }} onClick={() => props.history.push('/ab-test-tool/dashboard/pre_live')} color='primary'>
				Back to test results
			</Button> */}
			<Paper className={classes.paper}>
				<Grid container className={classes.gridWrapper}>
					{/* <Grid item xs={12} lg={2}></Grid> */}
					<Grid item xs={12} lg={12} className={classes.tabPanelWrapper}>
						<StepOne history={props.history} disabled={true} values={data} />
					</Grid>
					{/* <Grid item xs={12} lg={2}></Grid> */}
				</Grid>
			</Paper>
		</div>
	)
}
