
import { ACTION_TYPES } from '../../../constants'

const snackbarAdvance = (
	state = {
		showAdvanceSnackbar: false,
		msg: '',
		severity: 'info',
		onClose: false,
	},
	action
) => {
	switch (action.type) {
		case ACTION_TYPES.SHOW_ADVANCE_SNACKBAR: {
			return {
				...state,
				showAdvanceSnackbar: true,
				snackbarText: action.payload.msg,
				severity: action.payload.severity,
				onClose: action.payload.onClose,
			}
		}
		case ACTION_TYPES.HIDE_ADVANCE_SNACKBAR: {
			return {
				...state,
				showAdvanceSnackbar: false,
			}
		}
		default:
			return state
	}
}

export default snackbarAdvance
