import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import classnames from 'classnames'
import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	Badge,
	Fab,
	Grow,
	Divider,
} from '@material-ui/core'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
// import FilterMenuList from './FilterMenu'
import api from '../../../../services/bot/api'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import { hideLoader, showLoader } from '../../../../services/loader/actions'
import LoadingIndicator from '../../../../components/LoadingIndicator'
import FlagComponent from '../../../../components/Flags'
// import { data1, data2 } from './data'
// import BarChart from './Chart'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// overflowX: 'auto',
		margin: '16px auto',
	},
	table: {
		minWidth: 650,
		'& .MuiTableCell-root': {
			// borderLeft: '2px solid #ececec',
			padding: '8px',
		},
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
	head: {
		backgroundColor: '#fbfbfd',
		color: 'gray',
		minWidth: '150px',
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 2,
		// '& .MuiTableCell-root': {
		// 	borderLeft: '2px solid #41e886',
		// 	borderBottom: '1px solid #41e886',
		// },
	},
	body: {
		// backgroundColor: '#ddd',
		minWidth: '150px',
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 1,
		// '& .MuiTableCell-root': {
		// 	borderLeft: '2px solid #ddd',
		// 	borderBottom: '1px solid #ddd',
		// },
	},
	cellWidth: {
		width: '120px',
	},
	timecellWidth: {
		width: '200px',
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
		margin: '16px auto',
	},
	Title: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		// textDecoration: 'underline',
		margin: '16px auto',
	},
	tableHead: {
		backgroundColor: '#f2f4f7',
		color: '#8181a5',
		height: '2.5rem',
		// fontSize: '.8rem',
	},
	divide: {
		borderTop: '1px solid #F6F6F6',
	},
}))

const StickyTableCell = withStyles((theme) => ({
	// head: {
	// 	backgroundColor: theme.palette.common.black,
	// 	color: theme.palette.common.white,
	// 	left: 0,
	// 	position: 'sticky',
	// 	zIndex: theme.zIndex.appBar + 2,
	// },
	// body: {
	// 	backgroundColor: '#ddd',
	// 	minWidth: '50px',
	// 	left: 0,
	// 	position: 'sticky',
	// 	zIndex: theme.zIndex.appBar + 1,
	// },
}))(TableCell)

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}))

const TablePaginationActions = (props) => {
	const classes = useStyles1()
	const theme = useTheme()
	const { count, page, rowsPerPage, onPageChange } = props

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0)
	}

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1)
	}

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1)
	}

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<div className={classes.root}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='next page'>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='last page'>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	)
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
}

export default function SimpleTable({ data, loading, onClickMoreOptions }) {
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const [filter, setFilter] = useState({ searchText: '', filterData: { layer: 'l1' } })
	const { filterData } = filter
	const dispatch = useDispatch()
	const classes = useStyles()
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(filterData, _.isEmpty)), [filterData])

	const [cardData, setCardData] = useState([])
	const [tableData, settableData] = useState([])
	const [currentPage, setCurrentPage] = React.useState(0)
	const [TblState, setTblState] = useState({
		// loading: true,
		offset: 0,
		limit: 10,
		totalCount: 0,
	})
	const { offset, totalCount, limit } = TblState
	const [count, setCount] = React.useState(0)
	const [resAll, setresAll] = React.useState(false)
	const [chartData, setchartData] = React.useState({})
	const [summaryData, setsummaryData] = React.useState({})
	const [enupdate, setEnupdate] = React.useState(false)
	const [Okfilter, setOkfilter] = React.useState({})
	const [matchtype, setMatchtype] = React.useState('OEM')
	const [filters, setFilters] = React.useState({
		// layer: data2,
		// status: data1,
		user_id: [],
		distributor_id: [],
	})
	const [incExc, setincExc] = React.useState({
		layer: 'include',
		status: 'include',
		user_id: 'include',
		distributor_id: 'include',
		processed_date: 'include',
	})
	const renderAfterCalled = useRef(false)

	const addFilterAndRefreshTbl = (filterParams) => {
		setTblState((prevState) => ({
			...prevState,
			loading: true,
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const formatNumber = (n) => {
		if (n < 1e3) return n
		if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
		if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
		if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
		if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
	}

	const formatColor = (n) => {
		if (n >= 80) return '#13bb70'
		if (n >= 60 && n < 80) return '#f6b73e'
		if (n > 60) return 'red'
	}

	const onClickAnchorEl = (event, row) => {
		onClickMoreOptions(event, row)
		console.log(event, 'e')
	}

	return (
		<div>
			<div style={{ padding: '0px 16px 0px 16px' }}>
				<TableContainer>
					<Table className={classes.table} aria-label='simple table'>
						<TableHead className={classes.tableHead}>
							<TableRow>
								<TableCell align='left' style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>
									A/B Test ID
								</TableCell>
								<TableCell align='left' style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>
									Test Name
								</TableCell>
								<TableCell align='left' style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>
									Test Type
								</TableCell>
								<TableCell align='left' style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>
									Date Added
								</TableCell>
								<TableCell align='left' style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>
									Date Modified
								</TableCell>
								<TableCell align='left' style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>
									Updated Date
								</TableCell>
								<TableCell align='left' style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>
									Updated By
								</TableCell>
								<TableCell
									align='center'
									style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
								></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={8}>
										<LoadingIndicator></LoadingIndicator>
									</TableCell>
								</TableRow>
							) : _.isEmpty(data) ? (
								<TableRow>
									<TableCell colSpan={5}></TableCell>
									<TableCell>No Data Found</TableCell>
								</TableRow>
							) : (
								data?.map((row) => (
									<TableRow key={row.name}>
										<TableCell
											align='left'
											style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
											className={classes.timecellWidth}
										>
											{row.test_id}
										</TableCell>
										<TableCell
											align='left'
											style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
											className={classes.timecellWidth}
										>
											{row.test_name}
										</TableCell>
										<TableCell
											align='left'
											style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
											className={classes.timecellWidth}
										>
											{row.flix_product_type}
										</TableCell>
										<TableCell
											align='left'
											style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
											className={classes.timecellWidth}
										>
											{new Date(row.created_date).toDateString()}
										</TableCell>
										<TableCell
											align='left'
											style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
											className={classes.timecellWidth}
										>
											{new Date(row.created_date).toDateString()}
										</TableCell>
										<TableCell
											align='left'
											style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
											className={classes.timecellWidth}
										>
											{new Date(row.modified_date).toDateString()}
										</TableCell>
										<TableCell
											align='left'
											style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
											className={classes.timecellWidth}
										>
											{row.modified_by.first_name} {row.modified_by.last_name}
										</TableCell>
										<TableCell
											align='center'
											style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}
											// className={classes.timecellWidth}
										>
											<IconButton size='small' onClick={(e) => onClickAnchorEl(e, row)} title='Show Actions'>
												<i className='material-icons'>more_horiz</i>
											</IconButton>
										</TableCell>
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	)
}
