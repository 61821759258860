import { Button, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import api from '../../../../services/abtesttool/api'
import { isAxiosCancelError } from '../../../../utils'
import MaterialTable from './MaterialTable'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	switchLabel: {
		fontSize: '14px',
	},
	radioFormLabel: {
		fontSize: '15px',
		marginBottom: theme.spacing(0.5),
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	statusCellRoot: {
		verticalAlign: 'middle',
		'& span': {
			width: '8px',
			height: '8px',
			display: 'inline-block',
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		},
	},
	emailCellRoot: {
		verticalAlign: 'middle',
		'& > span': {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(1),
			fontSize: '18px',
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
	urlBox: {
		marginTop: '15px',
		'& .MuiOutlinedInput-root': {
			paddingTop: '1px',
		},
		'& .MuiInputLabel-root': {
			top: '-4px !important',
		},
		'& .MuiChip-root': {
			margin: '4px 8px 2px 0px',
		},
		'& .MuiFormHelperText-root': {
			paddingBottom: '32px',
		},
		'& .MuiButtonBase-root': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
}))

const Results = ({ width, action, values, history, handleChange, perms, result }) => {
	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		search_text: [],
		offset: 0,
		limit: 10,
		status: 'pre_live',
		search_field: ['test_name'],
		totalCount: 0,
		testList: [],
	})
	const [inputValue, setInputValue] = React.useState('')

	const { search_text, loading, offset, totalCount, limit, testList } = usersTblState

	const isMounted = useRef(true)

	const classes = useStyles()

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const onClickSearch = () => {
		// const search_text = e.target.value
		// if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
		// searchBoxTimeout.current = setTimeout(() => {
		let pay_values = {
			query_param: {
				search_text: _.toString(search_text),
				offset: offset,
				limit: limit,
			},
			filters: [
				{
					column: 'distributor_id',
					includes: _.map(values.retailer, (value) => _.toString(value.id)),
					type: 'int',
					all: false,
				},
				{
					column: 'country',
					includes: _.map(values.country, (value) => _.toString(value.id)),
					type: 'str',
					all: false,
				},
				{
					column: 'manufacturer_id',
					includes: _.map(values.brand, (value) => _.toString(value.id)),
					type: 'int',
					all: false,
				},
			],
		}
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			// totalCount: 0,
			// testList: [],
		}))
		api.fetchProducts(
			pay_values,
			(resp) => {
				const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data.products', [])
				if (isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount,
						testList,
					}))
					handleChange({
						target: { name: 'product', value: testList },
					})
				}
			},
			(err) => {
				if (!isAxiosCancelError(err) && isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: 0,
						testList: [],
					}))
				}
			}
		)
	}

	const fetchUsersList = useCallback(({ limit, offset, search_text, search_field, status }) => {
		let pay_values = {
			query_param: {
				search_text: _.toString(search_text),
				offset: offset,
				limit: limit,
			},
			filters: [
				{
					column: 'distributor_id',
					includes: [],
					type: 'string',
					all: true,
				},
			],
		}

		api.fetchProducts(
			pay_values,
			(resp) => {
				const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data.products', [])
				if (isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount,
						testList,
					}))
				}
			},
			(err) => {
				if (!isAxiosCancelError(err) && isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: 0,
						testList: [],
					}))
				}
			}
		)
	}, [])

	useEffect(() => {
		if (!_.isEmpty(values.retailer)) {
			let pay_values = {
				query_param: {
					search_text: _.toString(search_text),
					offset: offset,
					limit: limit,
				},
				filters: [
					{
						column: 'distributor_id',
						includes: _.map(values.retailer, (value) => _.toString(value.id)),
						type: 'int',
						all: false,
					},
					{
						column: 'country',
						includes: _.map(values.country, (value) => _.toString(value.id)),
						type: 'str',
						all: false,
					},
					{
						column: 'manufacturer_id',
						includes: _.map(values.brand, (value) => _.toString(value.id)),
						type: 'int',
						all: false,
					},
				],
			}
			setUsersTblState((prevState) => ({
				...prevState,
				loading: true,
				// totalCount: 0,
				// testList: [],
			}))
			api.fetchProducts(
				pay_values,
				(resp) => {
					const totalCount = _.get(resp, 'data.data.total_count', 0)
					const testList = _.get(resp, 'data.data.products', [])
					if (isMounted.current) {
						setUsersTblState((prevState) => ({
							...prevState,
							loading: false,
							totalCount,
							testList,
						}))
					}
				},
				(err) => {
					if (!isAxiosCancelError(err) && isMounted.current) {
						setUsersTblState((prevState) => ({
							...prevState,
							loading: false,
							totalCount: 0,
							testList: [],
						}))
					}
				}
			)
		} else {
			setUsersTblState((prevState) => ({
				...prevState,
				loading: false,
				totalCount: 0,
				testList: [],
			}))
		}
	}, [limit, offset, result, values?.retailer, values?.country, values?.brand, fetchUsersList])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<>
			<Grid container className={classes.root} item xs={12}>
				<Grid item xs={6} className={classes.urlBox} style={{ marginBottom: '20px', marginLeft: '200px' }}>
					<Autocomplete
						multiple
						freeSolo
						id='tags-standard'
						options={[]}
						value={search_text}
						inputValue={inputValue}
						onChange={(event, newValue) => {
							setUsersTblState({ ...usersTblState, search_text: newValue })
						}}
						onInputChange={(event, newInputValue) => {
							const options = newInputValue.split(',')

							if (options.length > 1) {
								setUsersTblState({
									...usersTblState,
									search_text: search_text
										.concat(options)
										.map((x) => x.trim())
										.filter((x) => x),
								})
								//   setValue(
								//     value
								//       .concat(options)
								//       .map((x) => x.trim())
								//       .filter((x) => x)
								//   );
							} else {
								setInputValue(newInputValue)
							}
						}}
						renderInput={(params) => <TextField {...params} variant='outlined' label='Search' autoComplete='off' />}
					/>
				</Grid>
				<Grid item xs={2} style={{ marginTop: '20px' }}>
					<Button size='small' style={{ fontWeight: 'bold' }} color='primary' onClick={onClickSearch}>
						Search
					</Button>
				</Grid>
				<Grid item xs={12}>
					<div>
						<MaterialTable
							values={values}
							count={totalCount}
							page={offset / limit}
							onChangePage={onChangePage}
							rowsPerPage={limit}
							onChangeRowsPerPage={onChangeRowsPerPage}
							rowData={testList}
							loading={loading}
							handleChange={handleChange}
						/>
					</div>
					<div></div>
				</Grid>
			</Grid>
		</>
	)
}

Results.propTypes = {
	action: PropTypes.string,
	userId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Results)
