import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Divider, Badge } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import MatchingProcess from './MatchingProcess'
import Report from './Report'
import BulkMatchReport from './BulkMatchReport'
import DistributorMatch from './DistributorMatch'
import Users from './Users'
import _ from 'lodash'
import { usePermission } from '../../components/hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from '../../constants/modules'
import { isModuleAccessGranted, getImgUrl, stringToHslColor, addHttp } from '../../utils'

const mapStateToProps = (state) => ({
	user: state.session.user,
})

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	}
}

function LinkTab(props) {
	return (
		<Tab
			component='a'
			onClick={(event) => {
				event.preventDefault()
			}}
			{...props}
		/>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
	},
}))

const NavTabs = ({ user, history }) => {
	const classes = useStyles()
	const [value, setValue] = React.useState(1)
	const userPermissions = _.get(user, 'userDetails.module_permissions', [])
	const userPerms = usePermission(ROLE_MODULES.PRODUCT_MATCHING)
	let pr_match_report_access = isModuleAccessGranted('pr_matching', PERMISSION_TYPE.EXPORT, userPermissions)
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<div className={classnames('container-no-fluid', 'page-content', classes.root)}>
			{/* <AppBar position='static'> */}
			<Tabs variant='fullWidth' value={value} onChange={handleChange} aria-label='nav tabs example'>
				<LinkTab label='Product Match' href='/drafts' {...a11yProps(0)} />
				{/* <LinkTab label='Coverage' href='/trash' {...a11yProps(1)} /> */}
				<LinkTab label='Retailer Match' href='/trash' {...a11yProps(1)} />
				{/* <LinkTab label='Complementary Match' href='/spam' {...a11yProps(2)} /> */}
				<LinkTab label='Product Restriction' href='/spam' {...a11yProps(2)} />
				<LinkTab label='Match History' href='/spam' {...a11yProps(3)} />
				{pr_match_report_access === true && <LinkTab label='Report' href='/spam' {...a11yProps(4)} />}
			</Tabs>
			<Divider />
			{/* </AppBar> */}
			<TabPanel value={value} index={0}>
				<MatchingProcess />
			</TabPanel>

			<TabPanel value={value} index={1}>
				<DistributorMatch />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<Users perms={userPerms} history={history} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<BulkMatchReport />
			</TabPanel>
			{pr_match_report_access === true && (
				<TabPanel value={value} index={4}>
					<Report />
				</TabPanel>
			)}
		</div>
	)
}

export default withRouter(connect(mapStateToProps)(NavTabs))
