import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Button, Grid, Icon, Paper, TextField } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Divider, Badge } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import SingleMatch from './SingleMatch'
import BulkMatch from './BulkMatch'
import _ from 'lodash'
import { usePermission } from '../../../components/hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from '../../../constants/modules'
import { isModuleAccessGranted, getImgUrl, stringToHslColor, addHttp } from '../../../utils'

const mapStateToProps = (state) => ({
	user: state.session.user,
})

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	}
}

function LinkTab(props) {
	return (
		<Tab
			component='a'
			onClick={(event) => {
				event.preventDefault()
			}}
			{...props}
		/>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
	},
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	textField: {
		marginTop: theme.spacing(2.5),
		maxWidth: 300,
		margin: '0 auto',
	},
	selectField: {
		marginTop: '10px',
		maxWidth: 300,
		margin: '0 auto',
	},
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	paper: {
		maxWidth: 500,
		margin: '16px auto',
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
}))

const NavTabs = ({ user, history }) => {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)
	const userPermissions = _.get(user, 'userDetails.module_permissions', [])
	const userPerms = usePermission(ROLE_MODULES.PRODUCT_MATCHING)
	let pr_match_report_access = isModuleAccessGranted('pr_matching', PERMISSION_TYPE.EXPORT, userPermissions)
	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<Paper className={classes.paper} variant='outlined' square>
			<Grid container className={classes.gridWrapper}>
				<Grid item xs={12} className={classes.tabPanelWrapper}>
					<Box className={classes.contentWrapper} component='div'>
						<div>
							{/* <AppBar position='static'> */}
							<ButtonGroup color='primary' aria-label='outlined primary button group'>
								<Button color={value === 0 ? 'secondary' : 'black'} onClick={(e) => handleChange(e, 0)}>
									Single Match
								</Button>
								<Button color={value === 1 ? 'secondary' : 'black'} onClick={(e) => handleChange(e, 1)}>
									Bulk Match
								</Button>
							</ButtonGroup>
							{/* </AppBar> */}
							<TabPanel value={value} index={0}>
								<SingleMatch />
							</TabPanel>

							<TabPanel value={value} index={1}>
								<BulkMatch />
							</TabPanel>
						</div>
					</Box>
				</Grid>
			</Grid>
		</Paper>
	)
}

export default withRouter(connect(mapStateToProps)(NavTabs))
