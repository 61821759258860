import { Avatar, Box, Button, Grid, Icon, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Field } from 'formik'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import SelectBox from '../../../../components/SelectBox'
import { RETAILER_MATCH_TYPE } from '../../../../constants'
import Api from '../../../../services/prmatching/api'
import { hideLoader, showLoader } from '../../../../services/loader/actions'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import Validator from './validator'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	textField: {
		marginTop: theme.spacing(2.5),
		maxWidth: 300,
		margin: '0 auto',
	},
	selectField: {
		marginTop: '10px',
		maxWidth: 300,
		margin: '0 auto',
	},
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	paper: {
		maxWidth: 500,
		margin: '16px auto',
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
}))

function ChangePassword() {
	const classes = useStyles()
	const [isPwdUpdated] = useState(false)
	const renderAfterCalled = useRef(false)
	const [distributors, setDistributors] = useState([])
	const [showProceed, setshowProceed] = useState(false)
	const [showError, setshowError] = useState({
		msg: '',
		error: false,
	})
	const { msg, error } = showError
	const dispatch = useDispatch()
	const formRef = useRef(null)
	const formFields = [
		{ name: 'mpn', label: 'Matching SKU*', type: 'text' },
		{ name: 'product_id', label: 'Product Id*', type: 'text' },
	]

	const [fileData, setFile] = useState()
	const fileref = useRef(null)

	const reset = () => {
		fileref.current.value = null
		if (error === true) {
			setshowError({ msg: '', error: false })
		}
		setFile()
	}

	function handleChange(event) {
		const fileSize = event.target.files[0].size / 1048.58 / 1048.58 // in MiB
		if (fileSize > 2) {
			dispatch(showSnackbarWithTimeout('File size do not exceed 2MB', 2000))
			fileref.current.value = null
			// $(file).val(''); //for clearing with Jquery
		} else {
			// Proceed further
		}
		setshowProceed(false)
		if (error === true) {
			setshowError({ msg: '', error: false })
		}
		setFile(event.target.files[0])
	}

	// useEffect(() => {
	// 	if (!renderAfterCalled.current) {
	// 		Api.matchDistributor().then((res) => {
	// 			let resp = _.map(res.data.data.retailers, (retailer) => ({
	// 				id: _.toString(retailer.distributor_id),
	// 				name: `${retailer.title} ---- (${retailer.distributor_id})`,
	// 			}))
	// 			setDistributors(resp)
	// 		})
	// 	}
	// 	renderAfterCalled.current = true
	// }, [])

	const onClickValidate = (e) => {
		e.preventDefault()
		const formData = new FormData()
		formData.append('file', fileData)
		let file = formData.get('file')
		dispatch(showLoader('Please wait, validating your file'))
		Api.BulkMatchValid(
			file,
			(resp) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(resp.data.message, 2000))
				setshowProceed(true)
				setshowError({ msg: '', error: false })
				// formRef.current.resetForm()
			},
			(err) => {
				dispatch(hideLoader())
				setshowProceed(false)
				setshowError({ msg: err.response.data.message, error: true })
				// dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}
	const onClickSubmit = (e) => {
		e.preventDefault()
		const formData = new FormData()
		formData.append('file', fileData)
		let file = formData.get('file')
		dispatch(showLoader('Please wait, uploading your file'))
		Api.BulkMatchSubmit(
			file,
			(resp) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(resp.data.message, 2000))
				setshowProceed(false)
				fileref.current.value = null
				setFile()
			},
			(err) => {
				dispatch(hideLoader())
				setshowError({ msg: err.response.data.message, error: true })
				// dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	return (
		// <Paper className={classes.paper} variant='outlined' square>
		// 	<Grid container className={classes.gridWrapper}>
		// 		<Grid item xs={12} className={classes.tabPanelWrapper}>
		// 			<Box className={classes.contentWrapper} component='div'>
		<>
			{isPwdUpdated ? (
				<>
					<Avatar alt={'success'} className={classes.successIco}>
						<Icon>done</Icon>
					</Avatar>
					<Typography variant='body1' gutterBottom>
						Password Updated Successfully
					</Typography>
				</>
			) : (
				<>
					<Validator ref={formRef}>
						{(formik) => {
							return (
								<form>
									<h2>Match File Upload</h2>
									<input
										type='file'
										ref={fileref}
										accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
										onChange={handleChange}
										style={{ width: '80%' }}
									/>
									<p style={{ fontSize: 'x-small', color: '#adadb3' }}> Supported File Format: (xls,xlsx). File Size: Not more than (2MB)</p>
									{error === true && <p style={{ fontSize: 'x-small', color: 'red' }}> {msg}</p>}
									{/* <button type='submit'>Upload</button> */}
								</form>
							)
						}}
					</Validator>
					<div style={{ textAlign: 'center', marginTop: '30px' }}>
						<Button
							onClick={onClickValidate}
							disabled={fileData === undefined}
							color='primary'
							variant='text'
							size='medium'
							disableElevation
							style={{ padding: 'inherit', marginRight: '40px', fontWeight: 'bold' }}
						>
							Validate
						</Button>
						<Button
							onClick={onClickSubmit}
							disabled={showProceed === false}
							color='primary'
							variant='text'
							size='medium'
							disableElevation
							style={{ padding: 'inherit', marginRight: '40px', fontWeight: 'bold' }}
						>
							Proceed
						</Button>
						<Button
							onClick={reset}
							disabled={fileData === undefined}
							color='primary'
							variant='text'
							size='medium'
							disableElevation
							style={{ padding: 'inherit', fontWeight: 'bold' }}
						>
							Reset
						</Button>
					</div>
				</>
			)}
		</>

		// 		</Grid>
		// 	</Grid>
		// </Paper>
	)
}

export default ChangePassword
