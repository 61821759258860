export const data1 = [
	{
		value: 'single',
		title: 'Single',
	},
	{
		value: 'bulk',

		title: 'Bulk',
	},
]

export const data2 = [
	{
		value: 'dist_match',
		title: 'OEM',
	},
	{
		value: 'comp_match',

		title: 'COMP',
	},
	{
		value: 'adj_match',

		title: 'ADJACENCY',
	},
]
