import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'

export default function SelectBox(props) {
	const {
		label = '',
		inputClass = '',
		isLoading = false,
		disabled = false,
		disableClearable = true,
		isError = false,
		errTxt = '',
		helperText = '',
		options = [],
		onOpen = () => null,
		onBlur = () => null,
		onChange = () => null,
		getSelectedLabel = () => null,
		onInputChange = () => null,
		name = '',
		value = '',
		size = 'small',
		startAdornment = null,
		freeSolo = false, //defaultValue
		placeholder = '',
		margin = 'normal',
		labelKey = 'name',
		valueKey = 'id',
		noOptionsText = '',
		textFieldStyle = null,
	} = props
	const valueReturner = (value) => (value ? value[valueKey] : '')
	const labelReturner = (label) => (label ? label[labelKey] : '')
	const defaultOption = !_.isEmpty(options) && !_.isEmpty(value) ? _.filter(options, (option) => option[valueKey] === value)[0] : null //{name:"", id:""};

	return (
		<Autocomplete
			id={name}
			name={name}
			freeSolo={freeSolo || false}
			noOptionsText={noOptionsText}
			onOpen={onOpen}
			onBlur={onBlur}
			size={size || 'small'}
			onChange={(_, value) => {
				onChange(valueReturner(value), _)
				getSelectedLabel(labelReturner(value), _)
			}}
			onInputChange={(e) => {
				onInputChange(e)
			}}
			getOptionSelected={(option, value) => option[labelKey] === _.get(value, labelKey, '') || option[labelKey] === value}
			getOptionLabel={(option) => option[labelKey]}
			options={options}
			loading={isLoading}
			value={defaultOption}
			disableClearable={disableClearable}
			disabled={isLoading || disabled}
			className={inputClass}
			renderInput={(params) => (
				<TextField
					{...params}
					error={isError}
					size={size || 'small'}
					style={textFieldStyle}
					name={name}
					helperText={isError && errTxt ? errTxt : helperText}
					label={label}
					placeholder={placeholder}
					variant='outlined'
					margin={margin}
					InputProps={{
						...params.InputProps,
						disabled: isLoading || disabled,
						readOnly: disabled,
						startAdornment: startAdornment,
						endAdornment: (
							<React.Fragment>
								{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
					fullWidth
				/>
			)}
		/>
	)
}
