import { Avatar, Box, Button, Grid, Icon, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Field } from 'formik'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import SelectBox from '../../../../components/SelectBox'
import { RETAILER_MATCH_TYPE } from '../../../../constants'
import Api from '../../../../services/aiqa/master/api'
import api from '../../../../services/aiqa/dimension/api'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import { addHttp } from '../../../../utils'
import Validator from './validator'
import { hideLoader, showLoader } from '../../../../services/loader/actions'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		// textAlign: 'center',
	},
	textField: {
		marginTop: theme.spacing(2.5),
		maxWidth: 400,
		margin: '0 auto',
	},
	selectField: {
		marginTop: '10px',
		maxWidth: 600,
		margin: '0 auto',
	},
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	paperHead: {
		// maxWidth: 900,
		margin: '16px auto',
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	key: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	gridContainer: {
		// marginTop: theme.spacing(2.5),
		// maxWidth: 600,
		margin: '0 auto',
		padding: '12px',
	},
	subGrid: {
		marginBottom: '20px',
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
}))

function ChangePassword({ userId, history, searchQuery, dialog, close }) {
	const classes = useStyles()
	const [isPwdUpdated] = useState(false)
	const renderAfterCalled = useRef(false)
	const [initialData, setinitialData] = useState({
		parent_cat: '',
		sub_cat_lvl1: '',
		sub_cat_lvl2: '',
	})
	const [formInfo, setformInfo] = useState({})
	const [parentCat, setparentCat] = useState([])
	const [subCat1, setsubCat1] = useState([])
	const [subCat2, setsubCat2] = useState([])
	const [parent_cat, setParent_cat] = useState('')
	const [sub_catlvl1, setSub_catlvl1] = useState('')
	const [sub_catlvl2, setSub_catlvl2] = useState('')
	const dispatch = useDispatch()
	const formRef = useRef(null)

	const getFormData = (idx) => {
		dispatch(showLoader('Please wait, getting details'))
		api.fetchProdForm(
			idx,
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', {})
				setformInfo(testList)
				setParent_cat(testList?.parent_cat)
				setSub_catlvl1(testList?.sub_cat_lvl1)
				setSub_catlvl2(testList?.sub_cat_lvl2)
				setinitialData((prevState) => ({
					...prevState,
					parent_cat: testList?.parent_cat,
					sub_cat_lvl1: testList?.sub_cat_lvl1,
					sub_cat_lvl2: testList?.sub_cat_lvl2,
				}))
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
	}

	useEffect(() => {
		if (!renderAfterCalled.current) {
			let pay_values = {
				cat_id: '',
			}
			Api.GetParentCategoryList(
				pay_values,
				'parent_cat',
				(resp) => {
					// const totalCount = _.get(resp, 'data.data.total_count', 0)
					const testList = _.get(resp, 'data.data', [])

					let res = _.map(testList, (list) => ({
						id: _.toString(list.cat_id),
						name: list.cat_name,
					}))
					setparentCat(res)
				},
				(err) => {
					// if (!isAxiosCancelError(err) && isMounted.current) {
					// setUsersTblState((prevState) => ({
					// 	...prevState,
					// 	loading: false,
					// 	totalCount: 0,
					// 	testList: [],
					// }))
					// }
				}
			)

			let idx = _.isEmpty(userId) ? 'all' : userId

			getFormData(idx)
		}
		renderAfterCalled.current = true
	}, [])

	useEffect(() => {
		if (!_.isEmpty(parent_cat)) {
			let pay_values = {
				cat_id: parent_cat,
			}
			Api.GetParentCategoryList(
				pay_values,
				'sub_cat_lvl1',
				(resp) => {
					// const totalCount = _.get(resp, 'data.data.total_count', 0)
					const testList = _.get(resp, 'data.data', [])

					let res = _.map(testList, (list) => ({
						id: _.toString(list.cat_id),
						name: list.sub_cat_name,
					}))
					setsubCat1(res)
				},
				(err) => {
					// if (!isAxiosCancelError(err) && isMounted.current) {
					// setUsersTblState((prevState) => ({
					// 	...prevState,
					// 	loading: false,
					// 	totalCount: 0,
					// 	testList: [],
					// }))
					// }
				}
			)
		}
	}, [parent_cat])

	useEffect(() => {
		if (!_.isEmpty(sub_catlvl1)) {
			let pay_values = {
				cat_id: sub_catlvl1,
			}
			Api.GetParentCategoryList(
				pay_values,
				'sub_cat_lvl2',
				(resp) => {
					// const totalCount = _.get(resp, 'data.data.total_count', 0)
					const testList = _.get(resp, 'data.data', [])

					let res = _.map(testList, (list) => ({
						id: _.toString(list.cat_id),
						name: list.sub_cat_name,
					}))
					setsubCat2(res)
				},
				(err) => {
					// if (!isAxiosCancelError(err) && isMounted.current) {
					// setUsersTblState((prevState) => ({
					// 	...prevState,
					// 	loading: false,
					// 	totalCount: 0,
					// 	testList: [],
					// }))
					// }
				}
			)
		}
	}, [sub_catlvl1])

	const onSubmitForm = () => {
		let pay_values = {
			action_type: 'update',
			search_query: searchQuery,
			parent_cat_id: parent_cat,
			sub_cat_lvl1_id: sub_catlvl1,
			sub_cat_lvl2_id: sub_catlvl2,
		}

		api.putProdForm(
			pay_values,
			formInfo?.id,
			(res) => {
				dispatch(showSnackbarWithTimeout(res.data.message, 2000))
				formRef.current && formRef.current.resetForm()
				if (dialog) {
					close()
				} else {
					if (_.isEmpty(userId)) {
						getFormData('all')
					} else {
						history.push(`/aiqa_tool/dimension/products/form`)
					}
				}
			},
			(err) => {
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	const onClickRst = () => {
		formRef.current && formRef.current.resetForm()
	}
	const onClickSubmit = () => {
		formRef.current && formRef.current.submitForm()
	}

	const onClipboardClick = (data, key) => {
		navigator.clipboard.writeText(data)
		dispatch(showSnackbarWithTimeout(`${key} Copied Into Clipboard`, 2000))
	}

	const onSearchClick = (data, key) => {
		window.open(addHttp(`www.google.com/search?q=${data}`), '_blank')
	}

	const onSkipSaveClick = (key) => {
		if (key === 'skip') {
			let pay_values = {
				action_type: key,
				search_query: searchQuery,
				parent_cat_id: parent_cat,
				sub_cat_lvl1_id: sub_catlvl1,
				sub_cat_lvl2_id: sub_catlvl2,
			}

			api.putProdForm(
				pay_values,
				formInfo?.id,
				(res) => {
					dispatch(showSnackbarWithTimeout(res.data.message, 2000))
					formRef.current && formRef.current.resetForm()
					if (dialog) {
						close()
					} else {
						if (_.isEmpty(userId)) {
							getFormData('all')
						} else {
							history.push(`/aiqa_tool/dimension/products/form`)
						}
					}
				},
				(err) => {
					dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
				}
			)
		}
		if (key === 'update') {
			formRef.current && formRef.current.submitForm()
		}
	}

	return (
		<>
			<Paper className={classes.paper}>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={12}>
						<Typography className={classes.paperTitle} variant='body1'>
							Product Information:
						</Typography>
					</Grid>
					<Grid container xs={12} lg={12} className={classes.gridContainer}>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								Product Id:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography noWrap className={classes.title} variant='body1'>
								{!_.isNull(formInfo?.product_id) || !_.isEmpty(formInfo?.product_id) ? formInfo?.product_id : '-'}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								Manufacture Id:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography noWrap className={classes.title} variant='body1'>
								{!_.isNull(formInfo?.manufacturer_id) || !_.isEmpty(formInfo?.manufacturer_id) ? formInfo?.manufacturer_id : '-'}
							</Typography>
						</Grid>

						{/* <Grid item xs={12} lg={6} className={classes.subGrid}></Grid> */}
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								Brand Title:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography noWrap className={classes.title} variant='body1'>
								{!_.isNull(formInfo?.brand_title) || !_.isEmpty(formInfo?.brand_title) ? formInfo?.brand_title : '-'}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								Product Title:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={2} className={classes.subGrid}>
							<Tooltip
								title={!_.isNull(formInfo?.product_title) || !_.isEmpty(formInfo?.product_title) ? formInfo?.product_title : '-'}
								classes={{ tooltip: classes.tooltip }}
							>
								<Typography noWrap className={classes.title} variant='body1'>
									{!_.isNull(formInfo?.product_title) || !_.isEmpty(formInfo?.product_title) ? formInfo?.product_title : '-'}
								</Typography>
							</Tooltip>
						</Grid>
						<Grid item xs={12} lg={1} className={classes.subGrid}>
							{(!_.isNull(formInfo?.product_title) || !_.isEmpty(formInfo?.product_title)) && (
								<>
									<Icon fontSize='small' onClick={() => onClipboardClick(formInfo?.product_title, 'Product Title')}>
										content_copy
									</Icon>
									<span>
										<Icon fontSize='small' style={{ marginLeft: '8px' }} onClick={() => onSearchClick(formInfo?.product_title, 'Product Title')}>
											pageview
										</Icon>
									</span>
								</>
							)}
						</Grid>
						<Grid item xs={12} lg={3} className={classes.subGrid}>
							<Typography className={classes.key} variant='body1'>
								MPN List:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={2} className={classes.subGrid}>
							<Tooltip
								title={!_.isNull(formInfo?.mpn_list) || !_.isEmpty(formInfo?.mpn_list) ? formInfo?.mpn_list : '-'}
								classes={{ tooltip: classes.tooltip }}
							>
								<Typography noWrap className={classes.title} variant='body1'>
									{!_.isNull(formInfo?.mpn_list) || !_.isEmpty(formInfo?.mpn_list) ? formInfo?.mpn_list : '-'}
								</Typography>
							</Tooltip>
						</Grid>
						<Grid item xs={12} lg={1} className={classes.subGrid}>
							{(!_.isNull(formInfo?.mpn_list) || !_.isEmpty(formInfo?.mpn_list)) && (
								<>
									<Icon fontSize='small' onClick={() => onClipboardClick(formInfo?.mpn_list, 'MPN List')}>
										content_copy
									</Icon>
									<span>
										<Icon fontSize='small' style={{ marginLeft: '8px' }} onClick={() => onSearchClick(formInfo?.mpn_list, 'MPN List')}>
											pageview
										</Icon>
									</span>
								</>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Paper>

			<Paper className={classes.paperHead} variant='outlined' square>
				<Grid container className={classes.gridWrapper}>
					<Grid item xs={12} className={classes.tabPanelWrapper}>
						<Box className={classes.contentWrapper} component='div'>
							{isPwdUpdated ? (
								<>
									<Avatar alt={'success'} className={classes.successIco}>
										<Icon>done</Icon>
									</Avatar>
									<Typography variant='body1' gutterBottom>
										Password Updated Successfully
									</Typography>
								</>
							) : (
								<>
									<Grid item xs={12} lg={12}>
										<Typography className={classes.paperTitle} variant='body1'>
											Product Category Selection:
										</Typography>
									</Grid>
									<Validator initialFormData={initialData} onSubmit={onSubmitForm} ref={formRef}>
										{(formik) => {
											return (
												<form>
													<Field key={'parent_cat'} name={'parent_cat'}>
														{({ form, field }) => {
															return (
																<SelectBox
																	label='Parent Category'
																	value={field.value}
																	onChange={(e) => {
																		e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
																		e === ' ' ? setParent_cat('') : setParent_cat(e)
																	}}
																	inputClass={classes.selectField}
																	name='parent_cat'
																	onBlur={field.onBlur}
																	// disabled={disabled}
																	helperText={form.touched[field.name] && form.errors[field.name]}
																	isError={Boolean(form.errors[field.name] && form.touched[field.name])}
																	options={parentCat}
																	noOptionsText={'No Options'}
																></SelectBox>
															)
														}}
													</Field>
													<Field key={'sub_cat_lvl1'} name={'sub_cat_lvl1'}>
														{({ form, field }) => {
															return (
																<SelectBox
																	label='Sub Category Level 1'
																	value={field.value}
																	onChange={(e) => {
																		e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
																		e === ' ' ? setSub_catlvl1('') : setSub_catlvl1(e)
																	}}
																	inputClass={classes.selectField}
																	name='sub_cat_lvl1'
																	onBlur={field.onBlur}
																	// disabled={disabled}
																	helperText={form.touched[field.name] && form.errors[field.name]}
																	isError={Boolean(form.errors[field.name] && form.touched[field.name])}
																	options={subCat1}
																	noOptionsText={_.isEmpty(parent_cat) ? 'Please Select Any Parent Category...' : 'No Options'}
																></SelectBox>
															)
														}}
													</Field>
													<Field key={'sub_cat_lvl2'} name={'sub_cat_lvl2'}>
														{({ form, field }) => {
															return (
																<SelectBox
																	label='Sub Category Level 2'
																	value={field.value}
																	onChange={(e) => {
																		e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
																		e === ' ' ? setSub_catlvl2('') : setSub_catlvl2(e)
																	}}
																	inputClass={classes.selectField}
																	name='sub_cat_lvl2'
																	onBlur={field.onBlur}
																	// disabled={disabled}
																	helperText={form.touched[field.name] && form.errors[field.name]}
																	isError={Boolean(form.errors[field.name] && form.touched[field.name])}
																	options={subCat2}
																	noOptionsText={_.isEmpty(sub_catlvl1) ? 'Please Select Any Sub Category Level 1...' : 'No Options'}
																></SelectBox>
															)
														}}
													</Field>
												</form>
											)
										}}
									</Validator>
									<div style={{ textAlign: 'center', marginTop: '40px' }}>
										<Button
											onClick={onClickRst}
											color='primary'
											variant='text'
											size='medium'
											disableElevation
											style={{ padding: 'inherit', marginRight: '40px', fontWeight: 'bold' }}
										>
											Reset
										</Button>
										<Button
											onClick={() => onSkipSaveClick('skip')}
											color='primary'
											variant='text'
											size='medium'
											disableElevation
											style={{ padding: 'inherit', marginRight: '40px', fontWeight: 'bold' }}
										>
											Skip
										</Button>
										<Button
											onClick={() => onSkipSaveClick('update')}
											color='primary'
											type='submit'
											variant='text'
											size='medium'
											disableElevation
											style={{ padding: 'inherit', fontWeight: 'bold' }}
										>
											Save
										</Button>
									</div>
								</>
							)}
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default ChangePassword
