import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { IconButton } from '@material-ui/core'
import 'react-pivottable/pivottable.css'
import Api from '../../../../services/aiqa/dimension/api'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import { hideLoader, showLoader } from '../../../../services/loader/actions'

export default function App() {
	const [settings, setSettings] = useState({})
	const dispatch = useDispatch()
	const renderAfterCalled = useRef(false)
	const [data, setData] = useState([])

	useEffect(() => {
		if (!renderAfterCalled.current) {
			dispatch(showLoader('Please wait, getting summary'))
			Api.GetRptPivot(
				'product',
				(resp) => {
					// const totalCount = _.get(resp, 'data.data.total_count', 0)
					const testList = _.get(resp, 'data.data', [])
					setData(testList)
					dispatch(hideLoader())
				},
				(err) => {
					dispatch(hideLoader())
					dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
					// if (!isAxiosCancelError(err) && isMounted.current) {
					// setUsersTblState((prevState) => ({
					// 	...prevState,
					// 	loading: false,
					// 	totalCount: 0,
					// 	testList: [],
					// }))
					// }
				}
			)
		}
		renderAfterCalled.current = true
	}, [])

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={12} lg={1}>
					<Typography style={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px', textDecoration: 'underline' }} variant='body1'>
						Summary:
					</Typography>
				</Grid>
				{!_.isEmpty(settings) && (
					<Grid item xs={12} lg={1} style={{ marginTop: '8px' }}>
						<IconButton size='small' onClick={() => setSettings({})} title='Reset'>
							<i className='material-icons' style={{ color: '#41e886' }}>
								restore
							</i>
						</IconButton>
					</Grid>
				)}
			</Grid>
			<PivotTableUI
				data={data}
				style={{
					width: '100%',
					height: '100%',
				}}
				onChange={(s) => setSettings(s)}
				// cols={['Updated Date', 'Updated Month']}
				rows={['Updated Month']}
				// vals={['aos_pax_total']}
				aggregatorName='Count'
				// renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
				{...settings}
				hiddenAttributes={['pvtRenderers', 'pvtAxisContainer', 'pvtVals', 'pvtAxisContainer']}
				// hiddenFromAggregators={['id', 'companyid']}
			/>
		</div>
	)
}
