import React, { Component } from 'react'
import { Query, Builder, BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder'
import { Button } from '@material-ui/core'
import _ from 'lodash'

import 'react-awesome-query-builder/lib/css/styles.css'
import 'react-awesome-query-builder/lib/css/compact_styles.css' //optional, for more compact styles

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue = { id: QbUtils.uuid(), type: 'group' }

export default class DemoQueryBuilder extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			userOptions: {},
			filterOptions: {
				users: [],
				emails: [],
				roles: [],
			},
			filters: {
				users: [],
				emails: [],
				roles: [],
				isLoggedIn: [],
			},
			tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), this.props.config),
			config: this.props.config,
		}
	}

	render = () => (
		<div>
			<Query {...this.props.config} value={this.state.tree} onChange={this.onChange} renderBuilder={this.renderBuilder} />
			{/* {this.renderResult(this.state)} */}
			<div style={{ textAlign: 'center' }}>
				<Button
					onClick={this.clearValue}
					color='primary'
					variant='text'
					size='medium'
					disableElevation
					style={{ padding: 'inherit', marginRight: '40px', fontWeight: 'bold' }}
					disabled={_.isEmpty(this.props.searchQuery)}
				>
					Clear
				</Button>
				<Button
					onClick={this.filterValue}
					disabled={_.isEmpty(this.props.searchQuery)}
					color='primary'
					variant='text'
					size='medium'
					disableElevation
					style={{ padding: 'inherit', fontWeight: 'bold' }}
				>
					Filter
				</Button>
				<Button
					onClick={this.exportValue}
					disabled={_.isEmpty(this.props.searchQuery)}
					color='primary'
					variant='text'
					size='medium'
					disableElevation
					style={{ padding: 'inherit', marginLeft: '40px', fontWeight: 'bold' }}
				>
					Export
				</Button>
			</div>
		</div>
	)

	renderBuilder = (props) => (
		<div className='query-builder-container' style={{ padding: '10px' }}>
			<div className='query-builder qb-lite'>
				<Builder {...props} />
			</div>
		</div>
	)

	clearValue = () => {
		this.setState({
			tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), this.props.config),
		})
		let query = ''
		this.props.onClearClick(query)
	}
	filterValue = () => {
		this.props.onFilterClick()
	}
	exportValue = () => {
		this.props.onExportClick()
	}

	renderResult = ({ tree: immutableTree, config }) => (
		<div className='query-builder-result'>
			<div>
				Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre>
			</div>
			<div>
				MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre>
			</div>
			<div>
				SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre>
			</div>
			<div>
				JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre>
			</div>
		</div>
	)

	onChange = (immutableTree, config) => {
		// Tip: for better performance you can apply `throttle` - see `examples/demo`
		this.setState({ tree: immutableTree, config: config })

		// const jsonTree = QbUtils.getTree(immutableTree)
		this.props.setsearchQuery(JSON.stringify(QbUtils.mongodbFormat(immutableTree, config)))
		// console.log(JSON.stringify(QbUtils.mongodbFormat(immutableTree, config)))
		// `jsonTree` can be saved to backend, and later loaded to `queryValue`
	}
}
