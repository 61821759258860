import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ImageStepper from './ImageStepper'
import { Icon } from '@material-ui/core'
import NoImage from './No.png'
import _ from 'lodash'

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},
})

export default function MediaCard({ data, onMatchClick, Match }) {
	const classes = useStyles()
	return (
		<Card className={classes.root}>
			<CardActionArea>
				<ImageStepper images={data.product_image !== null ? data.product_image : [NoImage]} />
				<CardContent>
					<Typography noWrap gutterBottom variant='subtitle2' component='h6'>
						<strong>{data.product_title}</strong>
					</Typography>
					<Typography gutterBottom variant='subtitle2' component='h6'>
						Product Id: <strong>{data.product_id !== null ? data.product_id : '-'}</strong>
					</Typography>
					<Typography noWrap gutterBottom variant='subtitle2' component='h6'>
						MPN: <strong>{data.mpn !== null ? data.mpn : '-'}</strong>
					</Typography>
					<Typography noWrap gutterBottom variant='subtitle2' component='h6'>
						EAN: <strong>{data.ean !== null ? data.ean : '-'}</strong>
					</Typography>
					<Typography gutterBottom variant='subtitle2' component='h6'>
						Lang: <strong>{data.language_code !== null ? data.language_code : '-'}</strong>
					</Typography>
					<Typography noWrap gutterBottom variant='subtitle2' component='h6'>
						Category : <strong>{data.category !== null ? data.category[0] : '-'}</strong>
					</Typography>
					<Typography gutterBottom variant='subtitle2' component='h6'>
						Inpage: <strong>{data.inpage_status !== null ? data.inpage_status : '-'}</strong>
					</Typography>
					<Typography gutterBottom variant='subtitle2' component='h6' style={{ marginTop: 10, textAlign: 'center' }}>
						<a target='_blank' href={data?.inpage_link !== null ? data?.inpage_link : ''}>
							<Icon>link</Icon>
						</a>
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Button size='small' style={{ marginLeft: '44px', fontWeight: 'bold' }} onClick={() => onMatchClick(data?.product_id)} color='primary'>
					{Match === data?.product_id ? 'Matched' : 'Match'}
				</Button>
			</CardActions>
		</Card>
	)
}
