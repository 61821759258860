import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import ImageStepper from './ImageStepper'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { Icon } from '@material-ui/core'
import NoImage from './No.png'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 140,
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
})

export default function MediaCard({ data, onMatchClick, Match }) {
	const classes = useStyles()
	const dispatch = useDispatch()

	const onClipboardClick = (data, key) => {
		navigator.clipboard.writeText(data)
		dispatch(showSnackbarWithTimeout(`${key} Copied Into Clipboard`, 2000))
	}

	return (
		<Card className={classes.root}>
			<CardActionArea>
				<ImageStepper images={data.product_image !== null ? data.product_image : NoImage} />
				<CardContent>
					<Typography noWrap gutterBottom variant='subtitle2' component='h6'>
						<strong>{data.product_title}</strong>
					</Typography>
					<Typography gutterBottom variant='subtitle2' component='h6'>
						Product Id: <strong>{data.product_id !== null ? data.product_id : '-'}</strong>
					</Typography>
					<Grid container>
						<Grid item xs={12} lg={10} className={classes.textField}>
							<Typography noWrap gutterBottom variant='subtitle2' component='h6'>
								MPN:
								<Tooltip title={data.mpn !== null ? data.mpn : '-'} classes={{ tooltip: classes.tooltip }}>
									<strong>{data.mpn !== null ? data.mpn : '-'}</strong>
								</Tooltip>
							</Typography>
						</Grid>
						{data.mpn !== null && (
							<Grid item xs={12} lg={2} className={classes.textField}>
								<Icon fontSize='small' onClick={() => onClipboardClick(data.mpn, 'MPN')}>
									content_copy
								</Icon>
							</Grid>
						)}
					</Grid>
					<Grid container>
						<Grid item xs={12} lg={10} className={classes.textField}>
							<Typography noWrap gutterBottom variant='subtitle2' component='h6'>
								EAN:
								<Tooltip title={data.ean !== null ? data.ean : '-'} classes={{ tooltip: classes.tooltip }}>
									<strong>{data.ean !== null ? data.ean : '-'}</strong>
								</Tooltip>
							</Typography>
						</Grid>
						{data.ean !== null && (
							<Grid item xs={12} lg={2} className={classes.textField}>
								<Icon fontSize='small' onClick={() => onClipboardClick(data.ean, 'EAN')}>
									content_copy
								</Icon>
							</Grid>
						)}
					</Grid>
					<Typography gutterBottom variant='subtitle2' component='h6'>
						Lang: <strong>{data.language_code !== null ? data.language_code : '-'}</strong>
					</Typography>
					<Typography noWrap gutterBottom variant='subtitle2' component='h6'>
						Category : <strong>{data.category !== null ? data.category[0] : '-'}</strong>
					</Typography>
					<Typography gutterBottom variant='subtitle2' component='h6'>
						Inpage: <strong>{data.inpage_status !== null ? data.inpage_status : '-'}</strong>
					</Typography>
					<Typography gutterBottom variant='subtitle2' component='h6' style={{ marginTop: 10, textAlign: 'center' }}>
						<a target='_blank' href={data?.inpage_link !== null ? data?.inpage_link : ''}>
							<Icon>link</Icon>
						</a>
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Button size='small' style={{ marginLeft: '44px', fontWeight: 'bold' }} onClick={() => onMatchClick(data?.product_id)} color='primary'>
					{Match === data?.product_id ? 'Matched' : 'Match'}
				</Button>
			</CardActions>
		</Card>
	)
}
