import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { Grid, Popover, Typography, Button, MenuList, MenuItem, ListItemIcon, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import ListPageFilterSection from '../../../components/ListPageFilterSection'
import { fetchAllRoles, deleteRole } from '../../../services/roles/actions'
import { useDispatch } from 'react-redux'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import _ from 'lodash'
import AgGridCustom from '../../../components/AgGridCustom'
import { withCellRenderState, ActionCellRenderer, NameCellRenderer, DateCellRenderer } from './CustomCells'
import RoleFormDialog from './RoleFormDialog'
import { PAGE_MODES } from '../../../constants'
import PermFilterDialog from './PermFilterDialog'
import { hideLoader, showConfirmMessage } from '../../../services/loader/actions'
import { PERMISSION_TYPE } from '../../../constants/modules'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1.5),
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center',
			paddingLeft: 0,
		},
	},
}))

const Roles = ({ action, width, roleId, history, perms }) => {
	const [roleListState, setRoleListState] = useState({
		loading: true,
		roleList: [],
		searchText: '',
		modulesFilter: [],
	})
	const [permFilterState, setPermFilterState] = useState({
		openPermDialog: false,
		permFilterVal: [],
	})

	const isMounted = useRef(true)
	const dispatch = useDispatch()
	const classes = useStyles()

	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		selectedData: null,
	})
	const { open, anchorEl, selectedData } = popOverState

	const { loading, roleList, searchText, modulesFilter } = roleListState
	const { openPermDialog, permFilterVal } = permFilterState

	const isCreateAllowed = _.get(perms, PERMISSION_TYPE.CREATE, false)
	const isEditAllowed = _.get(perms, PERMISSION_TYPE.EDIT, false)
	const isDeleteAllowed = _.get(perms, PERMISSION_TYPE.DELETE, false)

	const openFormDialog = !_.isEmpty(action)

	const handleCloseFormDialog = useCallback(
		(e, isRefreshList) => {
			history.replace({
				pathname: `/usermanagement/roles`,
				isRefreshList: _.isEqual(isRefreshList, true),
			})
		},
		[history]
	)

	const changeToEditMode = (roleId) => {
		openExistingRole(roleId, PAGE_MODES.EDIT)
	}

	const openExistingRole = (roleId, action) => {
		history.replace({ pathname: `/usermanagement/roles/${action}/${roleId}` })
	}

	const addFilterAndRefreshTbl = (filterParams) => {
		if (isMounted.current)
			setRoleListState((prevState) => ({
				...prevState,
				loading: true,
				roleList: [],
				...filterParams,
			}))
	}

	const closePopover = useCallback(() => {
		setPopOverState((popOverState) => ({ ...popOverState, open: false }))
	}, [])

	const onClickEditMenu = () => {
		closePopover()
		changeToEditMode(_.get(selectedData, 'role_id'))
	}

	const deleteRoleFunc = useCallback(
		(roleId) => {
			dispatch(
				deleteRole(
					roleId,
					() => {
						//success
						dispatch(hideLoader())
						setRoleListState((prevState) => ({
							...prevState,
							loading: true,
							roleList: [],
						}))
						// dispatch(showSnackbarWithTimeout('Deleted successfully!', 2000))
					},
					() => {
						dispatch(hideLoader())
					}
				)
			)
		},
		[dispatch]
	)

	const onClickDeleteMenu = useCallback(() => {
		closePopover()
		dispatch(
			showConfirmMessage(
				// `Do you want to delete the "${_.get(selectedData, 'role_name')}" role?`,
				`Are you sure you want to delete this Role?`,
				'',
				'Confirm',
				(e) => {
					dispatch(hideLoader())
					deleteRoleFunc(_.get(selectedData, 'role_id'))
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}, [dispatch, closePopover, deleteRoleFunc, selectedData])

	const onClickMoreOptions = useCallback((event, data) => {
		setPopOverState({
			open: true,
			anchorEl: event.currentTarget,
			selectedData: data,
		})
	}, [])

	const gridHeaders = useMemo(() => {
		return [
			{
				headerName: 'Role Name',
				field: 'role_name',
				cellRenderer: 'nameCellRenderer',
				cellRendererParams: {},
			},
			{
				headerName: 'Created Date & Time',
				field: 'created_date',
				hide: !isWidthUp('md', width),
				cellRenderer: 'dateCellRenderer',
				cellRendererParams: {},
			},
			(isEditAllowed || isDeleteAllowed) && {
				headerName: '',
				field: '',
				width: 60,
				cellRenderer: 'actionCellRenderer',
				cellRendererParams: {
					containerClass: classes.actionCellContainer,
					onClickMoreOptions,
				},
			},
		].filter(Boolean)
	}, [width, classes, onClickMoreOptions, isEditAllowed, isDeleteAllowed])

	const fetchRolesList = useCallback(
		(searchText, modulesFilter) => {
			dispatch(
				fetchAllRoles(
					{ search_text: searchText, modules: modulesFilter },
					(resp) => {
						setRoleListState((prevState) => ({
							...prevState,
							loading: false,
							roleList: _.get(resp, 'data.data.roles', []),
						}))
					},
					(err) => {
						setRoleListState((prevState) => ({
							...prevState,
							loading: false,
							roleList: [],
						}))
					}
				)
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (loading) fetchRolesList(searchText, modulesFilter)
	}, [searchText, modulesFilter, loading, fetchRolesList])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onChangeSearchBox = useCallback(
		_.debounce((e) => {
			let searchText = e.target.value
			addFilterAndRefreshTbl({ searchText })
		}, 300),
		[]
	)

	const addBtnClick = () => {
		history.replace({ pathname: `/usermanagement/roles/create` })
	}

	const onRowClicked = (e) => {
		const isSuppressed = e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection
		if (!isSuppressed) openExistingRole(_.get(e, 'data.role_id'), PAGE_MODES.VIEW)
	}

	//Refresh function start
	const isNeedRefresh = _.get(history, 'location.isRefreshList', false)
	useEffect(() => {
		if (isNeedRefresh) {
			setRoleListState((prevState) => ({
				loading: true,
				roleList: [],
				searchText: prevState.searchText,
				modulesFilter: [],
			}))
			_.set(history, 'location.isRefreshList', false)
		}
	}, [isNeedRefresh, history])
	//Refresh function end

	//Perm filter dialog start
	const permFilterBtnClick = () => {
		setPermFilterState((prevState) => ({ ...prevState, openPermDialog: true }))
	}
	const handleClosePermDialog = () => {
		setPermFilterState((prevState) => ({
			...prevState,
			openPermDialog: false,
		}))
		addFilterAndRefreshTbl({ modulesFilter: permFilterVal })
	}
	const onChangePermFilter = (e) => {
		setPermFilterState((prevState) => ({
			...prevState,
			permFilterVal: e.target.value,
		}))
	}
	const resetPermFilter = () => {
		setPermFilterState((prevState) => ({
			...prevState,
			permFilterVal: [],
		}))
		addFilterAndRefreshTbl({ modulesFilter: [] })
	}
	const permCount = useMemo(
		() =>
			_.reduce(
				modulesFilter,
				(result, module) => {
					const modPermCount = _.filter(_.values(_.get(module, 'permissions')), (value) => value).length
					return result + modPermCount
				},
				0
			),
		[modulesFilter]
	)

	//Perm filter dialog end

	return (
		<Grid className={classes.root} container item xs={12}>
			<ListPageFilterSection
				disabled={false}
				searchPlaceholder={'Search Roles...'}
				addBtnClick={addBtnClick}
				onChangeSearchBox={onChangeSearchBox}
				addBtnTxt={'Add Role'}
				hideAddBtn={!isCreateAllowed}
			>
				<div className={classes.advFilterContent}>
					<Button variant='outlined' style={{ fontWeight: 'bold' }} onClick={permFilterBtnClick} size='small' color='primary'>
						Select Permissions ({permCount})
					</Button>
				</div>
			</ListPageFilterSection>
			<Grid item xs={12}>
				<div>
					<AgGridCustom
						columnDefs={gridHeaders}
						rowData={roleList}
						loading={loading}
						frameworkComponents={{
							actionCellRenderer: withCellRenderState(ActionCellRenderer),
							nameCellRenderer: withCellRenderState(NameCellRenderer),
							dateCellRenderer: withCellRenderState(DateCellRenderer),
						}}
						disableClickSelectionRenderers={['actionCellRenderer']}
						onRowClicked={onRowClicked}
						rowHeight={50}
					></AgGridCustom>
				</div>
			</Grid>
			<Popover
				id={'more_options'}
				open={open}
				anchorEl={anchorEl}
				onClose={closePopover}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuList autoFocusItem={open} id='options_list'>
					{isEditAllowed && (
						<MenuItem dense={true} onClick={onClickEditMenu}>
							<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
								<Icon className={classes.menuIcon} fontSize='small'>
									edit
								</Icon>
							</ListItemIcon>
							<Typography className={classes.menuText} variant='inherit'>
								Edit
							</Typography>
						</MenuItem>
					)}
					{isDeleteAllowed && (
						<MenuItem dense={true} onClick={onClickDeleteMenu}>
							<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
								<Icon className={classes.menuIcon} fontSize='small'>
									delete
								</Icon>
							</ListItemIcon>
							<Typography className={classes.menuText} variant='inherit'>
								Delete
							</Typography>
						</MenuItem>
					)}
				</MenuList>
			</Popover>
			<RoleFormDialog
				open={openFormDialog}
				handleClose={handleCloseFormDialog}
				changeToEditMode={changeToEditMode}
				roleId={roleId}
				action={action}
				isEditAllowed={isEditAllowed}
			/>
			<PermFilterDialog
				open={openPermDialog}
				handleClose={handleClosePermDialog}
				filterVal={permFilterVal}
				resetFilterVal={resetPermFilter}
				onChangeVal={onChangePermFilter}
			/>
		</Grid>
	)
}

Roles.propTypes = {
	action: PropTypes.string,
	roleId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Roles)
