import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
		marginTop: theme.spacing(1),
	},
}))

export default function CustomSeparator(props) {
	const classes = useStyles()
	let hasNumber = /\d/
	return (
		<div className={classes.root}>
			<Breadcrumbs separator='›' aria-label='breadcrumb'>
				{_.map(
					props?.path,
					(path) =>
						!hasNumber.test(path) &&
						(path === 'usermanagement' ? (
							<Typography color='textPrimary'>{_.startCase('User Management')}</Typography>
						) : path === 'ab-test-tool' ? (
							<Typography color='textPrimary'>{_.startCase('AB Test Tool')}</Typography>
						) : path === 'aiqa_tool' ? (
							<Typography color='textPrimary'>{_.startCase('AIQA Tool')}</Typography>
						) : path === 'bb_category' ? (
							<Typography color='textPrimary'>{_.startCase('BB Category')}</Typography>
						) : path === 'bb_brand' ? (
							<Typography color='textPrimary'>{_.startCase('BB Brand')}</Typography>
						) : path === 'bot' ? (
							<Typography color='textPrimary'>{_.startCase('BOT')}</Typography>
						) : path === 'bb_prediction' ? (
							<Typography color='textPrimary'>{_.startCase('BB Prediction')}</Typography>
						) : (
							<Typography color='textPrimary'>{_.startCase(path)}</Typography>
						))
				)}
			</Breadcrumbs>
		</div>
	)
}
