import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	allTest: (filterParams, cancelSource) => {
		return Axios.post(
			API_ENDPOINTS.AB_TEST_TABLE,
			filterParams,
			// cancelToken: cancelSource.token,
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json' } }
		)
	},
	moveStat: (testId, result, successCallback, errorCallback) => {
		return Axios.put(
			API_ENDPOINTS.MOVE_STATUS,
			{ status: result },
			{
				headers: {
					isAuthRequired: true,
					path: { testId },
					'Content-Type': 'application/json',
				},
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	deleteTst: (testId, successCallback, errorCallback) => {
		return Axios.delete(API_ENDPOINTS.DELETE_TEST, {
			headers: { isAuthRequired: true, path: { testId } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchDashboard: (cancelSource) => {
		return Axios.get(API_ENDPOINTS.AB_TEST_DASH, { cancelToken: cancelSource.token, headers: { isAuthRequired: true } })
	},
}

export default api
