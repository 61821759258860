

import React from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'

// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

const passwordSchema = Yup.object().shape({
	current_password: Yup.string().required('Please enter current Password'),
	new_password: Yup.string()
		.min(8, 'The password should contain minimum 8 characters')
		.matches(passwordRegex, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character')
		.required('Please enter your New Password'),
	re_type_password: Yup.string()
		.oneOf([Yup.ref('new_password'), null], `New Password & Re-Enter password does not match`)
		.required('Please re-enter your New Password'),
})

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData) ? { current_password: '', new_password: '', re_type_password: '' } : props.initialFormData

	return (
		<Formik innerRef={ref} initialValues={initialValues} validationSchema={passwordSchema} onSubmit={props.onSubmit || null}>
			{props.children}
		</Formik>
	)
})

export default Validator
