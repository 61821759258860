
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import classnames from 'classnames'
import _ from 'lodash'

class SearchBar extends PureComponent {
	constructor() {
		super()
		this.state = {
			searchText: '',
			isFocused: false,
		}
	}

	onChange = (e) => {
		this.setState({ searchText: e.target.value })
		this.props.onChange && this.props.onChange(e)
	}

	onFocus = (e) => {
		this.setState({ isFocused: true })
	}

	onBlur = (e) => {
		this.setState({ isFocused: false })
	}

	componentDidMount() {
		this.setState({ searchText: this?.props?.value || '' })
	}

	componentDidUpdate() {
		if (this.props?.value) {
			this.setState({ searchText: this?.props?.value || '' })
		}
	}

	render() {
		const { placeholder, containerClassName, name, size, searchBarClassName, fullWidth } = this.props

		const isSmallInput = _.isEqual(size, 'small')

		return (
			<div
				className={classnames('searchbar-container', {
					[containerClassName]: !_.isEmpty(containerClassName),
				})}
			>
				<Paper
					className={classnames(
						'searchbar',
						{ focused: this.state.isFocused },
						{ [searchBarClassName]: !_.isEmpty(searchBarClassName) },
						{ small: isSmallInput }
					)}
				>
					<InputBase
						fullWidth={fullWidth}
						name={name}
						className='search-input'
						placeholder={placeholder}
						onChange={this.onChange}
						value={this.state.searchText}
						onBlur={this.onBlur}
						onFocus={this.onFocus}
						inputProps={{ 'aria-label': { placeholder } }}
					/>
					<IconButton className='icon-button' aria-label='Search' size='small'>
						<Icon fontSize={isSmallInput ? 'small' : 'default'}>search</Icon>
					</IconButton>
				</Paper>
			</div>
		)
	}
}

SearchBar.propTypes = {
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	containerClassName: PropTypes.string,
	size: PropTypes.oneOf(['small', 'default']),
}

export default SearchBar
