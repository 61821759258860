
import { ACTION_TYPES } from '../../../constants'

export const showAdvanceSnackbar = (payload) => (dispatch) => {
	//show snackbar
	dispatch({
		type: ACTION_TYPES.SHOW_ADVANCE_SNACKBAR,
		payload: { ...payload },
	})
}

export const hideAdvaceSnackbar = () => (dispatch) => {
	dispatch({ type: ACTION_TYPES.HIDE_ADVANCE_SNACKBAR })
}
