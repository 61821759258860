import React, { Component } from 'react'
import InputField from '../../../components/TextField'
import Button from '@material-ui/core/Button'
import { Form } from 'formik'
import Field from '../../../components/FormikFieldWrapper'
import Validator from './validator'
import logo from '../../../images/logo.jpg'
import loginBG from '../../../images/loginBG.png'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'

class LoginForm extends Component {
	constructor() {
		super()
		this.state = {}
	}

	performLogin = (values, { setSubmitting }) => {
		this.props.login(values.username, values.password)
	}
	onForgotClick = () => {
		this.props.onClickForgotLink()
	}

	render() {
		const { isLoading, error } = this.props.session
		return (
			<div className='page-container'>
				<div className='image-container'>
					<img src={loginBG} alt={loginBG} className='loginBG' />
				</div>
				<Paper elevation={3}>
					<div className='form-container'>
						<img src={logo} className={'logo'} alt='SIO logo' />
						<p className='sio'>SIO Analytics</p>
						<Validator onSubmit={this.performLogin}>
							{({ isSubmitting }) => (
								<Form id='loginForm' className='login-form'>
									<Field
										type='text'
										name='username'
										render={({ field, form }) => {
											return <InputField size='small' type='text' label='Email Address' autoFocus={false} field={field} form={form} />
										}}
									/>
									<Field
										type='password'
										name='password'
										render={({ field, form }) => {
											return <InputField size='small' type='password' label='Password' field={field} form={form} />
										}}
									/>

									{error && <div className='error-div'>{error.message}</div>}

									<Button type='submit' className='btn-login' variant='contained' color='secondary' disableElevation disabled={isLoading}>
										{isLoading ? <CircularProgress size={26} className='progressbar' /> : 'Login'}
									</Button>
								</Form>
							)}
						</Validator>
					</div>
				</Paper>
			</div>
		)
	}
}

export default LoginForm
