import React, { useEffect, useRef, useState } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LogarithmicScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LogarithmicScale)

export const options = {
	layout: {
		padding: {
			top: 30,
			right: 40,
			bottom: 40,
		},
	},
	plugins: {
		title: {
			display: true,
			//   text: 'Chart.js Bar Chart - Stacked',
		},
		tooltip: {
			mode: 'index',
		},
		legend: {
			display: true,
			position: 'bottom',
			align: 'center',
			labels: {
				// usePointStyle: true,
			},
		},
	},
	responsive: true,
	scales: {
		x: {
			// stacked: true,
			grid: {
				display: false,
			},
		},
		y: {
			// stacked: false,
			grid: {
				display: false,
			},
			type: 'logarithmic',
			ticks: {
				min: 1,
				max: 1000000,
				callback: function (value, index, values) {
					if (value === 1000000) return '1M'
					if (value === 100000) return '100K'
					if (value === 10000) return '10K'
					if (value === 1000) return '1K'
					if (value === 100) return '100'
					if (value === 10) return '10'
					if (value === 1) return '1'
					return null
				},
			},
		},
	},
}

export default function App({ Data }) {
	const labels = Data?.label || []
	const data = {
		labels,
		responsive: true,
		offset: true,
		datasets: [
			{
				label: 'Pages Scraped',
				barThickness: 40,
				pointStyle: 'rectRounded',
				data: Data?.pages_scrapped || [],
				backgroundColor: '#79d8ae',
			},
			{
				label: 'Pages Missed',
				barThickness: 40,
				pointStyle: 'rectRounded',
				data: Data?.pages_missed || [],
				backgroundColor: '#f1464d',
			},
		],
	}

	return <Bar options={options} height={100} data={data} />
}
