import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { CircularProgress } from '@material-ui/core'
import { PERMISSION_TYPE } from '../../constants/modules'
import PageNotAllowed from '../../scenes/403'
import { isModuleAccessGranted } from '../../utils'

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { isAuthenticated, moduleMapper, moduleName, injectComponentProps = {} } = rest

	const currentSession = useSelector((state) => _.get(state, 'session', {}))
	const currentUser = useMemo(() => _.get(currentSession, 'user', {}), [currentSession])

	const getModuleNameFromPath = (modulePath) => {
		return _.get(moduleMapper, modulePath, '')
	}

	const checkPermAndRenderScreen = (props) => {
		const { isFetchingUser, userDetails } = currentUser
		const { isLoading } = currentSession
		if (isFetchingUser || isLoading)
			return (
				<div
					style={{
						width: '100vw',
						height: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<CircularProgress></CircularProgress>
				</div>
			)
		else {
			const pageMode = _.get(props, 'match.params.action', PERMISSION_TYPE.VIEW)
			const finalModuleName = moduleName || getModuleNameFromPath(_.get(props, 'match.params.module'))
			const userPermissions = _.get(userDetails, 'module_permissions', [])
			const isAccessGranted = isModuleAccessGranted(finalModuleName, pageMode, userPermissions)
			return isAccessGranted ? <Component {...{ ...props, ...injectComponentProps, moduleName }} /> : <PageNotAllowed isGoBack={true} />
		}
	}

	const renderScreen = (props) => {
		if (isAuthenticated) return checkPermAndRenderScreen(props)
		// return <Redirect to='/' />
		else return window.location.replace(process.env.REACT_APP_SISENSE_URL)
	}

	return <Route {...rest} render={renderScreen} />
}

PrivateRoute.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
	moduleName: PropTypes.string,
}

function mapStateToProps(state) {
	return {
		isAuthenticated: state.session.isAuthenticated || false,
	}
}

export default connect(mapStateToProps)(PrivateRoute)
