import { API_ENDPOINTS } from '../../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	GetCategoryList: (data, cat, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.CAT_LIST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { cat } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetParentCategoryList: (data, cat, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.PARENT_CAT_LIST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { cat } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	AddCategory: (data, cat, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.ADD_NEW_CAT, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { cat } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetBBCategoryList: (data, cat, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.BB_CAT_LIST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { cat } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetParentBBCategoryList: (data, cat, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.PARENT_BB_CAT_LIST,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { cat } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	AddBBCategory: (data, cat, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.ADD_NEW_BB_CAT, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { cat } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetBrandList: (data, brand, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.BRAND_LIST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { brand } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetParentBrandList: (data, brand, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.PARENT_BRAND_LIST,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { brand } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	AddBrand: (data, brand, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.ADD_NEW_BRAND, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { brand } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}

export default api
