import { API_ENDPOINTS } from '../../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	GetProdList: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_PROD_LIST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchProdForm: (id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_PROD_FORM, {
			headers: { isAuthRequired: true, path: { id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	putProdForm: (data, id, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.GET_PROD_FORM,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetDistList: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_DIST_LIST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchDistForm: (id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_DIST_FORM, {
			headers: { isAuthRequired: true, path: { id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	putDistForm: (data, id, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.GET_DIST_FORM,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetRptSummary: (report, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_RPT_SUMMARY, {
			headers: { isAuthRequired: true, path: { report } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetRptPivot: (report, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_RPT_PIVOT, {
			headers: { isAuthRequired: true, path: { report } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	PrdExport: (data, exp, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.PRD_EXPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { exp } }, responseType: 'blob' }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	DistExport: (data, exp, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.DIST_EXPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { exp } }, responseType: 'blob' }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}

export default api
