import React, { useCallback, useMemo } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import { PAGE_MODES } from '../../../../../constants'

const getFileExtension = (file) => {
	const fileName = file.name
	return fileName.substring(fileName.lastIndexOf('.'), fileName.length)
}

const validateProfilePic = async (file, acceptsExts, acceptRes, isTestRatio, isMandatory) => {
	return new Promise((resolve, reject) => {
		const checkResolution = (file, acceptRes) => {
			return new Promise((resolve, reject) => {
				let img = new Image()
				img.src = URL.createObjectURL(file)
				img.onload = () => {
					if (isTestRatio) {
						let neededRatio = acceptRes[0] / acceptRes[1]
						let currRatio = img.width / img.height
						let ratioMatchPercentage = currRatio / neededRatio
						let isRatioTestPass = ratioMatchPercentage <= 1.05 && ratioMatchPercentage >= 0.95
						resolve(isRatioTestPass && img.width >= acceptRes[0] && img.height >= acceptRes[1])
					} else {
						resolve(img.width === acceptRes[0] && img.height === acceptRes[1])
					}
				}
			})
		}

		//If file is already uploaded
		if (file && typeof file === 'string') resolve()
		else if (!file) {
			if (isMandatory) resolve('Choose an Image!')
			else resolve('')
		} else if (!_.isEmpty(acceptsExts) && acceptsExts.indexOf(getFileExtension(file).toLowerCase()) === -1) {
			resolve(`Image file should be ${_.join(acceptsExts, ' (or) ')} format!`)
		} else if (!_.isEmpty(acceptRes)) {
			checkResolution(file, acceptRes).then((isValidRes) => {
				if (!isValidRes) {
					resolve(`Image file should be minimum ${_.join(acceptRes, 'X')} or higher resolution with 1:1 Aspect ratio`)
				} else {
					resolve()
				}
			})
		}
	})
}
// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData)
		? {
				brand_id: '',
				brand: '',
				cat_id: '',
				category: '',
		  }
		: props.initialFormData

	const validateField = (fieldName, fieldLabel, value, regex, errors, type) => {
		if (_.isEmpty(value) || _.isEmpty(_.trim(value))) {
			if (type === 'select') {
				_.set(errors, fieldName, `Please select the ${fieldLabel}.`)
			} else {
				_.set(errors, fieldName, `Please enter the ${fieldLabel}.`)
			}
		}
		// else {
		// 	if (regex && !regex.test(value)) {
		// 		let message = customMessage || `${fieldLabel} is Invalid`
		// 		_.set(errors, fieldName, message)
		// 		// if (_.isEqual(fieldName, 'retype_password')) {
		// 		// 	_.set(errors, fieldName, customMessage || `${fieldLabel} is Invalid`)
		// 		// } else {
		// 		// 	_.set(
		// 		// 		errors,
		// 		// 		fieldName,
		// 		// 		customMessage || _.isEqual(fieldName, 'password')
		// 		// 			? `${fieldLabel} Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character`
		// 		// 			: `${fieldLabel} is Invalid`
		// 		// 	)
		// 		// }
		// 	}
		// }
	}

	const validateForm = useCallback(
		async (values) => {
			return validateProfilePic(values.profile_picture, ['.jpg', '.png', '.jpeg'], [240, 240], true, false)
				.then((profilePicErr) => {
					let errors = {}

					if (profilePicErr) {
						errors.profile_picture = profilePicErr
						ref.current.setFieldValue('profile_picture', null, false)
					}
					validateField('brand_id', 'Brand', _.get(values, 'brand_id'), /^\d+$/, errors, 'select')
					validateField('cat_id', 'Category', _.get(values, 'cat_id'), /^\d+$/, errors, 'select')

					throw errors
				})
				.catch((errors) => {
					return errors
				})
		},
		[ref]
	)

	//Initial validation solution for edit screen
	const formExtraProps = useMemo(() => {
		return props.formMode === PAGE_MODES.EDIT ? { validateOnMount: validateForm(initialValues) } : {}
	}, [props.formMode, initialValues, validateForm])

	return (
		<Formik innerRef={ref} initialValues={initialValues} validate={validateForm} onSubmit={props.onSubmit || null} {...formExtraProps}>
			{props.children}
		</Formik>
	)
})

export default Validator

// import React from 'react'
// import { Formik } from 'formik'
// import _ from 'lodash'
// import * as Yup from 'yup'

// // eslint-disable-next-line no-useless-escape
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

// const passwordSchema = Yup.object().shape({
// 	product_id: Yup.string().required('Please enter Product Id.'),
// 	distributor_id: Yup.string().trim().required('Please select the Distributor.'),
// })

// const Validator = React.forwardRef((props, ref) => {
// 	const initialValues = _.isEmpty(props.initialFormData) ? { product_id: '', distributor_id: '' } : props.initialFormData

// 	return (
// 		<Formik innerRef={ref} initialValues={initialValues} validationSchema={passwordSchema} onSubmit={props.onSubmit || null}>
// 			{props.children}
// 		</Formik>
// 	)
// })

// export default Validator
