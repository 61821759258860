import { TablePagination } from '@material-ui/core'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import DownloadIcon from '@material-ui/icons/GetApp'
import Pagination from '@material-ui/lab/Pagination'
import _ from 'lodash'
import XLSX from 'sheetjs-style'

const tableHead = {
	file_name: 'File Name',
	file_url: 'File URL',
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// overflowX: 'auto',
		margin: '16px auto',
	},
	table: {
		minWidth: 650,
		'& .MuiTableCell-root': {
			// borderLeft: '2px solid #ececec',
			padding: '8px',
		},
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		// width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
	head: {
		backgroundColor: '#fbfbfd',
		color: 'gray',
		minWidth: '150px',
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 2,
		// '& .MuiTableCell-root': {
		// 	borderLeft: '2px solid #41e886',
		// 	borderBottom: '1px solid #41e886',
		// },
	},
	body: {
		// backgroundColor: '#ddd',
		minWidth: '150px',
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 1,
		// '& .MuiTableCell-root': {
		// 	borderLeft: '2px solid #ddd',
		// 	borderBottom: '1px solid #ddd',
		// },
	},
	cellWidth: {
		width: '120px',
	},
	timecellWidth: {
		width: '200px',
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
		margin: '16px auto',
	},
	Title: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		// textDecoration: 'underline',
		margin: '16px auto',
	},
	tableHead: {
		backgroundColor: '#F9FAFB',
		color: '#8181a5',
		height: '2.5rem',
		// fontSize: '.8rem',
	},
	divide: {
		borderTop: '1px solid #F6F6F6',
	},
}))

const InfoTable = ({ tableList }) => {
	// const countPerPage = 10
	const classes = useStyles()
	const path = _.split(window?.location.pathname, '/')
	const [countPerPage, setcountPerPage] = React.useState(10)
	const [value, setValue] = React.useState('')
	const [currentPage, setCurrentPage] = React.useState(1)
	const [page, setPage] = React.useState(0)
	const [collection, setCollection] = React.useState(cloneDeep(tableList.slice(0, countPerPage)))

	React.useEffect(() => {
		if (!value) {
			updatePage(0, 1)
		} else {
			// searchData(value)
			// throttle((val) => {
			const query = value.toLowerCase()
			setCurrentPage(1)
			setPage(0)
			const data = cloneDeep(tableList.filter((item) => item.file_name.toLowerCase().indexOf(query) > -1).slice(0, countPerPage))
			setCollection(data)
			// }, 400)
		}
	}, [value, tableList])

	React.useEffect(() => {
		setCollection(cloneDeep(tableList.slice(0, countPerPage)))
	}, [tableList])

	const updatePage = (p, pageNo) => {
		let page = pageNo - 1
		setCurrentPage(pageNo)
		setPage(page)
		const from = countPerPage * page
		const to = from + countPerPage
		setCollection(cloneDeep(tableList.slice(from, to)))
	}

	const handleChangeRowsPerPage = (event) => {
		setcountPerPage(parseInt(event.target.value, 10))
		setCurrentPage(1)
		setPage(0)
		setCollection(cloneDeep(tableList.slice(0, parseInt(event.target.value, 10))))
	}

	const tableRows = (rowData) => {
		const { key, index } = rowData
		const tableCell = Object.keys(tableHead)
		const columnData = tableCell.map((keyD, i) => {
			function validURL(str) {
				var pattern = new RegExp(
					'^(https?:\\/\\/)?' + // protocol
						'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
						'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
						'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
						'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
						'(\\#[-a-z\\d_]*)?$',
					'i'
				) // fragment locator
				return !!pattern.test(str)
			}
			return validURL(key[keyD]) === true ? (
				<TableCell style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }} key={i}>
					<a style={{ textDecoration: 'none' }} target='_blank' href={key[keyD]}>
						{key[keyD]}
					</a>
				</TableCell>
			) : (
				<TableCell style={{ fontSize: '14px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }} key={i}>
					{key[keyD]}
				</TableCell>
			)
		})

		return <TableRow key={index}>{columnData}</TableRow>
	}

	const tableData = () => {
		// console.log(collection, 'list1')
		return collection.map((key, index) => tableRows({ key, index }))
	}

	const headRow = () => {
		return Object.values(tableHead).map((title, index) => (
			<TableCell style={{ fontSize: '16px', color: '#374151', fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }} key={index}>
				{title}
			</TableCell>
		))
	}

	const downloadExcel = () => {
		const Heading = [['File Name', 'File URL']]
		const worksheet = XLSX.utils.json_to_sheet(tableList, { origin: 'A2', skipHeader: true })
		const workbook = XLSX.utils.book_new()
		const colWidths = [{ wch: 20 }, { wch: 70 }]
		worksheet['!cols'] = colWidths
		XLSX.utils.sheet_add_aoa(worksheet, Heading, { origin: 'A1' })
		worksheet['A1'].s = {
			font: {
				name: 'calibri',
				sz: 12,
				bold: true,
				color: '#F2F2F2',
			},
			border: {
				right: {
					style: 'thin',
					color: '000000',
				},
				left: {
					style: 'thin',
					color: '000000',
				},
			},
		}
		worksheet['A1'].s.fill = {
			// background color
			patternType: 'solid',
			fgColor: { rgb: 'b2b2b2' },
			bgColor: { rgb: 'b2b2b2' },
		}
		worksheet['B1'].s = {
			font: {
				name: 'calibri',
				sz: 12,
				bold: true,
				color: '#F2F2F2',
			},
			border: {
				right: {
					style: 'thin',
					color: '000000',
				},
				left: {
					style: 'thin',
					color: '000000',
				},
			},
		}
		worksheet['B1'].s.fill = {
			// background color
			patternType: 'solid',
			fgColor: { rgb: 'b2b2b2' },
			bgColor: { rgb: 'b2b2b2' },
		}
		let ws_links = []

		_.forEach(tableList, function (value) {
			ws_links.push(value.file_url)
		})

		for (let i = 1; i <= tableList.length; i++) {
			worksheet[
				XLSX.utils.encode_cell({
					c: 1,
					r: i,
				})
			].l = { Target: ws_links[i - 1] }
		}
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
		//let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
		//XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
		XLSX.writeFile(workbook, `AB_Test_${path[3]}.xlsx`)
	}

	return (
		<>
			{/* <div class='search' style={{ textAlign: 'right' }}>
				<input placeholder='Search File...' value={value} onChange={(e) => setValue(e.target.value)} />
			</div> */}
			<div
				style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', marginTop: '10px', marginLeft: '16px' }}
			>
				<div style={{ border: '1px solid #E5E8EB', borderRadius: '4px' }}>
					<InputBase
						className={classes.input}
						placeholder='Search...'
						value={value}
						onChange={(e) => setValue(e.target.value)}
						style={{ width: '205px', height: '40px', padding: '10px' }}
						inputProps={{ 'aria-label': 'search google maps' }}
					/>
					<IconButton type='submit' className={classes.iconButton} aria-label='search'>
						<SearchIcon />
					</IconButton>
				</div>

				<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
					<div style={{ marginTop: '10px' }}>
						{/* <IconButton
							size='small'
							title='Export S3 File Information'
							style={{ backgroundColor: '#374151', color: 'white', width: '32px', height: '32px' }}
							onClick={downloadExcel}
						>
							<DownloadIcon />
						</IconButton> */}
					</div>
					<div>
						<TablePagination
							component='div'
							count={tableList.length}
							page={page}
							// style={{ fontFamily: 'FS-Jack', fontStyle: 'normal' }}
							onPageChange={updatePage}
							rowsPerPage={countPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={
								<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: '400', fontSize: '14px' }}>Results Per Page</div>
							}
							labelDisplayedRows={({ from, to, count }) => ``}
							nextIconButtonProps={{ style: { display: 'none' } }}
							backIconButtonProps={{ style: { display: 'none' } }}
							// rowsPerPageOptions={[]}
						/>
					</div>
				</div>
			</div>
			<div style={{ padding: '0px 16px ' }}>
				<TableContainer>
					<Table className={classes.table}>
						<TableHead className={classes.tableHead}>
							<TableRow>{headRow()}</TableRow>
						</TableHead>
						<TableBody>{tableData()}</TableBody>
					</Table>
				</TableContainer>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
				<TablePagination
					component='div'
					count={tableList?.length}
					page={page}
					// style={{ fontFamily: 'FS-Jack', fontStyle: 'normal' }}
					onPageChange={updatePage}
					rowsPerPage={countPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage='Results Per Page'
					labelDisplayedRows={({ from, to, count }) => (
						<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: '400', fontSize: '14px' }}>
							Showing {from}-{to} results of {count !== -1 ? count : `MORE THAN {to}`}
						</div>
					)}
					nextIconButtonProps={{ style: { display: 'none' } }}
					backIconButtonProps={{ style: { display: 'none' } }}
					rowsPerPageOptions={[]}
				/>
				<div style={{ marginTop: '10px' }}>
					<Pagination
						count={Math.ceil(tableList.length / countPerPage)}
						page={currentPage}
						variant='outlined'
						onChange={updatePage}
						shape='rounded'
						siblingCount={1}
						boundaryCount={1}
					/>
				</div>
			</div>
		</>
	)
}
export default InfoTable
