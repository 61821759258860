import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Icon, Box, Grid, Paper, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Results from './Results'
import _ from 'lodash'
import { usePermission } from '../../components/hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from '../../constants/modules'
import Dashboard from '../AbTestTool/Dashboard/index'
import AB from '../../images/Logo-AB.svg'
import ABIcon from '../../images/ABIcon.png'
import Header from './Header'

const RESULTS = {
	LIVE: 'live',
	PRELIVE: 'pre_live',
	COMPLETED: 'completed',
}

const useStyles = makeStyles((theme) => ({
	tabs: {
		margin: '16px auto',
		// minHeight: 'unset',
		boxSizing: 'border-box',
		// height: '38px',
	},
	tabsContainer: {
		'& > button:first-child': {
			borderLeft: `1px solid ${theme.palette.primary.main}86!important`,
			borderTop: `1px solid ${theme.palette.primary.main}86`,
			borderBottom: `1px solid ${theme.palette.primary.main}86`,
			borderRight: '0px',
		},
		'& > button:last-child': {
			borderRight: `1px solid ${theme.palette.primary.main}86`,
			borderTop: `1px solid ${theme.palette.primary.main}86`,
			borderBottom: `1px solid ${theme.palette.primary.main}86`,
			borderLeft: '0px',
		},
		'& > button:not(:last-child):not(:first-child)': {
			border: `1px solid ${theme.palette.primary.main}86`,
		},
	},
	tabsIndicator: {
		display: 'none',
	},
	tabRoot: {
		letterSpacing: '2px',
		textTransform: 'none',
		minHeight: 'unset',
		minWidth: '100px',
		width: 'auto',
		background: '#E1E3E8',
		// borderRadius: '0px 6px 6px 0px',
		'&:hover:not($tabSelected)': {
			// color:theme.palette.background.paper,
			background: `${theme.palette.primary.light}52`,
		},
	},
	tabIco: {
		verticalAlign: 'top',
	},
	tabPanel: {
		display: 'block',
		width: '100%',
	},
	tabSelected: {
		// background: theme.palette.primary.dark,
		// color: theme.palette.common.white,
		// '&:hover': {
		// 	color: theme.palette.common.white,
		// },

		background: '#374151',
		color: theme.palette.common.white,
		'&:hover': {
			color: theme.palette.common.white,
		},
	},
	paper: {
		width: '200px',
		height: '700px',
		// background: 'green',
		padding: '16px 20px 8px',
		borderRadius: '10px',
	},
	gradientColor: {
		background: 'linear-gradient(180deg, rgb(131 231 172 / 65%) 0%, rgba(66, 232, 134, 0) 94.28%)',
		// opacity: '0.4',
		height: '301.24px',
	},
	noFluid: {
		maxWidth: '1300px',
		margin: '0px auto',
	},
}))

const getModuleIdx = (result) => {
	switch (result) {
		case RESULTS.LIVE:
			return 0
		case RESULTS.PRELIVE:
			return 1
		case RESULTS.COMPLETED:
			return 2
		default:
			return -1
	}
}

const getModuleNameByIdx = (moduleIdx) => {
	switch (moduleIdx) {
		case 0:
			return RESULTS.LIVE
		case 1:
			return RESULTS.PRELIVE
		case 2:
			return RESULTS.COMPLETED
		default:
			return undefined
	}
}

const AbTestToolResults = ({ history, match }) => {
	const userPerms = usePermission(ROLE_MODULES.AB_TEST)
	const [activeTabIdx, setactiveTabIdx] = React.useState(1)
	// const result = _.get(match, 'params.result')
	const result = getModuleNameByIdx(activeTabIdx)
	const module = _.get(match, 'params.module')
	const action = _.get(match, 'params.action', '')
	const dataId = _.get(match, 'params.id', '')
	// const activeTabIdx = getModuleIdx(result)

	const classes = useStyles()
	const tabClasses = {
		root: classes.tabRoot,
		selected: classes.tabSelected,
	}

	const onChangeTab = (e, tabIdx) => {
		// history.push(`/ab-test-tool/${module}/${getModuleNameByIdx(tabIdx)}`)
		setactiveTabIdx(tabIdx)
	}
	console.log(module, 'active')
	//IF result IS EMPTY
	if (!module) return null

	return (
		<div className={classes.gradientColor}>
			<div className={classnames(classes.noFluid, 'page-content')}>
				<Grid container>
					<Grid item xs={3}>
						<Paper className={classes.paper}>
							<img src={AB} alt={AB} className={classes.img} />
							<Button
								style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
									marginTop: '10px',
									textTransform: 'none',
								}}
							>
								<Icon fontSize='small'>home</Icon>
								<span style={{ marginLeft: '10px' }}>A/B Home</span>
							</Button>
							<Button
								style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
									textTransform: 'none',
								}}
							>
								<img src={ABIcon} alt={ABIcon} style={{ width: '20px', height: '20px' }} />
								<span style={{ marginLeft: '10px' }}>Create New Test</span>
							</Button>
						</Paper>
					</Grid>
					<Grid item xs={9}>
						<Header module={module} />
						<Dashboard history={history} match={match} />

						<Tabs
							orientation='horizontal'
							value={activeTabIdx}
							// variant='fullWidth'
							centered
							classes={{
								indicator: classes.tabsIndicator,
								flexContainer: classes.tabsContainer,
							}}
							className={classes.tabs}
							onChange={onChangeTab}
							aria-label='User Management Tabs'
						>
							<Tab classes={tabClasses} style={{ borderRadius: '6px 0px 0px 6px' }} value={0} label='Live' disableRipple></Tab>

							<Tab classes={tabClasses} value={1} label='Pre Live' disableRipple></Tab>

							<Tab classes={tabClasses} style={{ borderRadius: '0px 6px 6px 0px' }} value={2} label='Completed' disableRipple></Tab>
						</Tabs>
						<TabPanel value={activeTabIdx} index={0} className={classes.tabPanel}>
							<Results action={action} result={result} userId={dataId} history={history} perms={userPerms}></Results>
						</TabPanel>
						<TabPanel value={activeTabIdx} index={1} className={classes.tabPanel}>
							<Results action={action} result={result} userId={dataId} history={history} perms={userPerms}></Results>
						</TabPanel>
						<TabPanel value={activeTabIdx} index={2} className={classes.tabPanel}>
							<Results action={action} result={result} userId={dataId} history={history} perms={userPerms}></Results>
						</TabPanel>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
			{value === index && (
				<Box marginLeft={1} marginRight={1}>
					{children}
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

export default AbTestToolResults
