import React, { useRef, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { Grid, Paper } from '@material-ui/core'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import { validationSchema } from './validator'
import { PLATFORM, DEVICE_TYPE, KPIS } from '../../../constants'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'
import api from '../../../services/abtesttool/api'
import { hideLoader, showErrorMessage, showLoader } from '../../../services/loader/actions'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { useDispatch } from 'react-redux'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		margin: '16px auto',
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	button: {
		backgroundColor: '#374151',
		color: 'white',
		textTransform: 'none',
		fontFamily: 'FS-Jack',
		fontStyle: 'normal',
		fontWeight: 400,
		'&:hover': {
			backgroundColor: '#374151',
			color: 'white',
		},
	},
	icon: {
		fill: ' #374151',
	},
	text: {
		fill: 'white',
	},
}))

function getSteps() {
	return ['Define', 'Scope', 'Schedule', 'Confirm']
}

export default function CreateTest(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [activeStep, setActiveStep] = React.useState(0)
	let test_id = _.get(props.match, 'params.id', '')

	const [data, setData] = React.useState({
		test_name: '',
		hypothesis: '',
		sf_case: '',
		flix_product_type: '',
		split_range: '',
		test_type: '',
		device_type: _.values(DEVICE_TYPE),
		platform_type: _.values(PLATFORM),
		primary_kpi: '',
		secondary_kpi: [],
		expected_lift: 0,
		confident_level: 95,
		variant1: '',
		variant2: '',
		test_level: 'brand',
		brand: [],
		country: [],
		retailer: [],
		product: [],
		feasibility_analysis: '',
		start_date: new Date(),
		end_date: new Date(moment().add(1, 'days')),
		selectAll: false,
		excluded: [],
		pr_search_txt: '',
	})

	useEffect(() => {
		if (!_.isEmpty(test_id)) {
			dispatch(showLoader('Please wait, getting test details'))
			api.fetchTest(
				test_id,
				(res) => {
					let resp = res.data.data
					setData({
						...data,
						test_name: resp?.test_name,
						hypothesis: resp?.hypothesis,
						sf_case: resp?.sf_case,
						flix_product_type: resp?.flix_product_type,
						split_range: resp?.split_range,
						test_type: resp?.test_type,
						device_type: _.filter(_.values(DEVICE_TYPE), (v) => _.includes(resp?.device_type, v.id)),
						platform_type: _.filter(_.values(PLATFORM), (v) => _.includes(resp?.platform_type, v.id)),
						primary_kpi: resp?.primary_kpi,
						secondary_kpi: _.filter(_.values(KPIS), (v) => _.includes(resp?.secondary_kpi, v.id)),
						expected_lift: _.toNumber(resp?.expected_lift),
						confident_level: _.toNumber(resp?.confident_level),
						variant1: resp?.variant1 === null ? '' : resp?.variant1,
						variant2: resp?.variant2 === null ? '' : resp?.variant2,
						test_level: resp?.test_level,
						brand:
							resp?.brand === []
								? []
								: _.map(resp?.brand, (brand) => ({
										id: _.toInteger(brand.brand_id),
										name: brand.brand_name,
								  })),
						country:
							resp?.country === []
								? []
								: _.map(resp?.country, (country) => ({
										id: country.country_name,
										name: country.country_name,
								  })),
						retailer:
							resp?.retailer === []
								? []
								: _.map(resp?.retailer, (retailer) => ({
										id: _.toInteger(retailer.retailer_id),
										name: retailer.retailer_name,
								  })),
						product: resp?.product || [],
						// selectAll: resp?.select_all_pr || false,
						// excluded: resp?.exclude_pr || [],
						// pr_search_txt: resp?.pr_search_txt === '' ? '' : _.split(resp?.pr_search_txt, ',') || '',
						feasibility_analysis: resp?.feasibility_analysis,
						start_date: new Date(resp?.start_date),
						end_date: new Date(resp?.end_date),
					})
					dispatch(hideLoader())
				},
				(err) => {
					// setFetchingTemplate(false)
					// handleClose()
					dispatch(
						showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again'), 'Close', () => {
							dispatch(hideLoader())
						})
					)
				}
			)
		}
	}, [])

	const _renderStepContent = (stepIndex, props) => {
		switch (stepIndex) {
			case 0:
				return (
					<Grid container className={classes.gridWrapper}>
						<Grid item xs={12} lg={2}></Grid>
						<Grid item xs={12} lg={8} className={classes.tabPanelWrapper}>
							<StepOne history={props.history} secondary={data.secondary_kpi} {...props} />
						</Grid>
						<Grid item xs={12} lg={2}></Grid>
					</Grid>
				)
			case 1:
				return (
					<Grid container className={classes.gridWrapper}>
						<Grid item xs={12} lg={1}></Grid>
						<Grid item xs={12} lg={10} className={classes.tabPanelWrapper}>
							<StepTwo history={props.history} {...props} />
						</Grid>
						<Grid item xs={12} lg={1}></Grid>
					</Grid>
				)
			case 2:
				return (
					<Grid container className={classes.gridWrapper}>
						<Grid item xs={12} lg={2}></Grid>
						<Grid item xs={12} lg={8} className={classes.tabPanelWrapper}>
							<StepThree history={props.history} {...props} />
						</Grid>
						<Grid item xs={12} lg={2}></Grid>
					</Grid>
				)
			case 3:
				return (
					// <Paper className={classes.paper} elevation={1} square>
					<Grid container className={classes.gridWrapper}>
						<Grid item xs={12} lg={2}></Grid>
						<Grid item xs={12} lg={8} className={classes.tabPanelWrapper}>
							<StepOne history={props.history} disabled={true} {...props} />
						</Grid>
						<Grid item xs={12} lg={2}></Grid>
						<Grid item xs={12} lg={1}></Grid>
						<Grid item xs={12} lg={10} className={classes.tabPanelWrapper}>
							<StepTwo history={props.history} disabled={true} {...props} />
						</Grid>
						<Grid item xs={12} lg={1}></Grid>
						<Grid item xs={12} lg={2}></Grid>
						<Grid item xs={12} lg={8} className={classes.tabPanelWrapper}>
							<StepThree history={props.history} disabled={true} {...props} />
						</Grid>
						<Grid item xs={12} lg={2}></Grid>
					</Grid>
					// </Paper>
				)
			default:
				return 'Unknown stepIndex'
		}
	}
	const steps = getSteps()
	const formikRef = useRef()

	const currentValidationSchema = validationSchema[activeStep]
	const isLastStep = activeStep === steps.length - 1

	function _sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms))
	}

	async function _submitForm(values, actions) {
		await _sleep(1000)
		// alert(JSON.stringify(values, null, 2))
		let range = []
		if (values?.test_type === 'template_variation' && values?.split_range === '50%:50%') {
			range.push(
				{
					variant_name: 'c',
					traffic_split: '50%',
					variant_url: '',
				},
				{
					variant_name: '1',
					traffic_split: '50%',
					variant_url: values?.variant1,
				}
			)
		} else if (values?.test_type === 'template_variation' && values?.split_range === '33%:33%:33%') {
			range.push(
				{
					variant_name: 'c',
					traffic_split: '33%',
					variant_url: '',
				},
				{
					variant_name: '1',
					traffic_split: '33%',
					variant_url: values?.variant1,
				},
				{
					variant_name: '2',
					traffic_split: '33%',
					variant_url: values?.variant2,
				}
			)
		} else if (values?.test_type === 'template_variation' && values?.split_range === '80%:20%') {
			range.push(
				{
					variant_name: 'c',
					traffic_split: '80%',
					variant_url: '',
				},
				{
					variant_name: '1',
					traffic_split: '20%',
					variant_url: values?.variant1,
				}
			)
		} else if (values?.split_range === '80%:20%') {
			range.push(
				{
					variant_name: 'c',
					traffic_split: '80%',
					variant_url: '',
				},
				{
					variant_name: '1',
					traffic_split: '20%',
					variant_url: '',
				}
			)
		} else if (values?.split_range === '50%:50%') {
			range.push(
				{
					variant_name: 'c',
					traffic_split: '50%',
					variant_url: '',
				},
				{
					variant_name: '1',
					traffic_split: '50%',
					variant_url: '',
				}
			)
		}

		const form_values = {
			define: {
				test_name: values?.test_name,
				hypothesis: values?.hypothesis,
				sf_case: values?.sf_case,
				flix_product_type: values?.flix_product_type,

				test_type: values?.test_type,
				device_type: _.map(values?.device_type, 'id'),
				platform_type: _.map(values?.platform_type, 'id'),
				primary_kpi: values?.primary_kpi,
				secondary_kpi: _.map(values?.secondary_kpi, 'id'),
				expected_lift: values?.expected_lift,
				confident_level: values?.confident_level,
				split_range: values?.split_range,
				split_data: range,
			},
			scope: {
				test_level: values?.test_level,
				brand:
					values?.test_level === 'retailer'
						? []
						: _.map(values?.brand, (brand) => ({
								brand_id: brand.id,
								brand_name: brand.name,
						  })),
				country: values?.test_level === 'brand' ? [] : _.map(values?.country, (country) => country.id),
				retailer:
					values?.test_level === 'brand'
						? []
						: _.map(values?.retailer, (retailer) => ({
								retailer_id: retailer.id,
								retailer_name: retailer.name,
						  })),
				product:
					values?.product === []
						? []
						: _.map(values?.product, (product) => ({
								product_id: _.toInteger(product.product_id),
								product_title: product.product_title,
						  })),
				// select_all_pr: values?.selectAll,
				// exclude_pr:
				// 	values?.excluded === []
				// 		? []
				// 		: _.map(values?.excluded, (product) => ({
				// 				product_id: _.toInteger(product.product_id),
				// 				product_title: product.product_title,
				// 		  })),
				// pr_search_txt: _.toString(values?.pr_search_txt) || '',
			},
			schedule: {
				feasibility_analysis: values?.feasibility_analysis,
				start_date: values?.start_date,
				end_date: values?.end_date,
			},
		}
		// console.log(form_values, 'value')
		if (_.isEmpty(test_id)) {
			api.createTest(
				form_values,
				(res) => {
					dispatch(hideLoader())
					// handleClose()
					let idx = _.get(res, 'data.data.test_id', '')
					api.s3Upload(idx)
					dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Successfully Test Created.'), 2000))
					setData({
						test_name: '',
						hypothesis: '',
						sf_case: '',
						flix_product_type: '',
						split_range: '',
						test_type: '',
						device_type: _.values(DEVICE_TYPE),
						platform_type: _.values(PLATFORM),
						primary_kpi: '',
						secondary_kpi: [],
						expected_lift: 0,
						confident_level: 95,
						variant1: '',
						variant2: '',
						test_level: 'brand',
						brand: [],
						feasibility_analysis: '',
						start_date: new Date(),
						end_date: new Date(moment().add(1, 'days')),
					})
					formikRef.current?.resetForm()
					setActiveStep(0)
				},
				(err) => {
					dispatch(hideLoader())
					dispatch(
						showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
							dispatch(hideLoader())
						})
					)
				}
			)
		} else {
			api.updateTest(
				test_id,
				form_values,
				(res) => {
					dispatch(hideLoader())
					// handleClose()
					let status = _.get(res, 'data.data.test_status', 'pre_live')
					api.s3Upload(test_id)
					dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Successfully Test Updated.'), 2000))
					// setActiveStep(0)
					props.history.push(`/ab_test_tool/home`)
				},
				(err) => {
					dispatch(hideLoader())
					dispatch(
						showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
							dispatch(hideLoader())
							props.history.push(`/ab_test_tool/home`)
						})
					)
				}
			)
		}
		actions.setSubmitting(false)
	}

	function _handleSubmit(values, actions) {
		if (isLastStep) {
			_submitForm(values, actions)
		} else {
			setActiveStep(activeStep + 1)
			actions.setTouched({})
			actions.setSubmitting(false)
		}
	}

	function _handleBack() {
		setActiveStep(activeStep - 1)
	}
	function _handleCancel() {
		props.history.push(`/ab_test_tool/home`)
	}

	return (
		<Formik innerRef={formikRef} enableReinitialize initialValues={data} validationSchema={currentValidationSchema} onSubmit={_handleSubmit}>
			{(props) => (
				<div className={classes.root}>
					<Stepper activeStep={activeStep} alternativeLabel style={{ background: 'none', margin: '0px 170px 0px 160px' }}>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel
									StepIconProps={{
										classes: {
											active: classes.icon,
											// text:   classes.text,
										},
									}}
								>
									<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: '400', fontSize: '14px' }}>{label}</div>
								</StepLabel>
							</Step>
						))}
					</Stepper>
					<Form>
						<Paper style={{ margin: '20px' }}>
							{!_.isEmpty(test_id) ? !_.isEmpty(data.secondary_kpi) && _renderStepContent(activeStep, props) : _renderStepContent(activeStep, props)}
						</Paper>
						<div className={classes.buttons} style={{ marginBottom: 40 }}>
							<Grid container>
								<Grid item xs={12} lg={9}></Grid>
								<Grid item xs={12} lg={1}>
									{activeStep !== 0 && (
										<Button onClick={_handleBack} variant='contained' className={classes.button}>
											Back
										</Button>
									)}
									{activeStep === 0 && (
										<Button onClick={_handleCancel} className={classes.button}>
											Cancel
										</Button>
									)}
								</Grid>
								<Grid item xs={12} lg={2}>
									<div className={classes.wrapper}>
										<Button disabled={props.isSubmitting} type='submit' variant='contained' className={classes.button}>
											{isLastStep ? 'Submit' : 'Next Step'}
										</Button>
										{props.isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
									</div>
								</Grid>
							</Grid>
						</div>
					</Form>
				</div>
			)}
		</Formik>
	)
}
