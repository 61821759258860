
import { ACTION_TYPES } from '../../../constants'

const snackbar = (state = { showSnackbar: false, msg: '' }, action) => {
	switch (action.type) {
		case ACTION_TYPES.SHOW_SNACKBAR: {
			return {
				...state,
				showSnackbar: true,
				msgText: action.payload.msg,
			}
		}
		case ACTION_TYPES.HIDE_SNACKBAR: {
			return {
				...state,
				showSnackbar: false,
			}
		}
		default:
			return state
	}
}

export default snackbar
