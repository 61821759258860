import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import PermissionInput from '../../../../components/PermissionInput'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	resetBtn: {
		color: theme.palette.error.main,
		fontWeight: 'bold',
	},
}))

function PermFilterDialog({ open, resetFilterVal, handleClose, filterVal, onChangeVal }) {
	const theme = useTheme()
	const classes = useStyles()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth={'md'} aria-labelledby='module-permission-filter-dialog'>
			<DialogTitle id='permission-filter-dialog-title'>Select Permissions</DialogTitle>
			<DialogContent style={{ display: 'flex' }}>
				<PermissionInput keepOnlyGrantedPerm={true} isMobileView={fullScreen} value={filterVal} onChange={onChangeVal} />
			</DialogContent>
			<DialogActions>
				<Button onClick={resetFilterVal} disabled={_.isEmpty(filterVal)} className={classes.resetBtn} autoFocus>
					Reset
				</Button>
				<Button onClick={handleClose} style={{ fontWeight: 'bold' }} color='primary' autoFocus>
					Apply
				</Button>
			</DialogActions>
		</Dialog>
	)
}

PermFilterDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	onChangeVal: PropTypes.func.isRequired,
	resetFilterVal: PropTypes.func.isRequired,
	filterVal: PropTypes.arrayOf(
		PropTypes.shape({
			module_name: PropTypes.string,
			permissions: PropTypes.object,
		})
	),
}

export default PermFilterDialog
