import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Divider, Badge } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import classnames from 'classnames'
import PendingProducts from './PendingProducts'
import UpdatedProducts from './UpdatedProducts'
import Form from './Form'
import _ from 'lodash'
import { usePermission } from '../../../components/hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from '../../../constants/modules'
import { isModuleAccessGranted, getImgUrl, stringToHslColor, addHttp } from '../../../utils'

const mapStateToProps = (state) => ({
	user: state.session.user,
})

const MODULES = {
	PENDING_DISTRIBUTORS: 'pending_distributors',
	FORM: 'form',
	UPDATED_DISTRIBUTORS: 'updated_distributors',
	SKIPPED_DISTRIBUTORS: 'skipped_products',
}

const getModuleIdx = (moduleName) => {
	switch (moduleName) {
		case MODULES.PENDING_DISTRIBUTORS:
			return 0
		case MODULES.FORM:
			return 1
		case MODULES.UPDATED_DISTRIBUTORS:
			return 2
		case MODULES.SKIPPED_DISTRIBUTORS:
			return 3
		default:
			return -1
	}
}

const getModuleNameByIdx = (moduleIdx) => {
	switch (moduleIdx) {
		case 0:
			return MODULES.PENDING_DISTRIBUTORS
		case 1:
			return MODULES.FORM
		case 2:
			return MODULES.UPDATED_DISTRIBUTORS
		case 3:
			return MODULES.SKIPPED_DISTRIBUTORS
		default:
			return undefined
	}
}

const isModuleHasViewPerm = (modulePerms) => _.get(modulePerms, PERMISSION_TYPE.VIEW, false)

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`nav-tabpanel-${index}`} aria-labelledby={`nav-tab-${index}`} {...other}>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	}
}

function LinkTab(props) {
	return (
		<Tab
			component='a'
			onClick={(event) => {
				event.preventDefault()
			}}
			{...props}
		/>
	)
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
	},
}))

const NavTabs = ({ user, history, match }) => {
	const classes = useStyles()
	const [value, setValue] = React.useState(0)
	const userPerms = usePermission(ROLE_MODULES.AIQA_DIMENSION)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}
	const onChangeTab = (e, tabIdx) => {
		history.push(`/dimension_category/${getModuleNameByIdx(tabIdx)}`)
	}

	const module = _.get(match, 'params.module')
	const tab = _.get(match, 'params.tab')
	const action = _.get(match, 'params.action', '')
	const dataId = _.get(match, 'params.id', '')

	const activeTabIdx = getModuleIdx(tab)

	if (!tab) return null

	return (
		<div>
			{/* <AppBar position='static'> */}
			<Tabs variant='fullWidth' value={activeTabIdx} onChange={onChangeTab} aria-label='nav tabs example'>
				<Tab component={Link} label='Pending Distributors' to='/aiqa_tool/dimension/distributors/pending_distributors' {...a11yProps(0)} />
				<Tab component={Link} label='Form' to='/aiqa_tool/dimension/distributors/form' {...a11yProps(1)} />
				<Tab component={Link} label='Updated Distributors' to='/aiqa_tool/dimension/distributors/updated_distributors' {...a11yProps(2)} />
			</Tabs>
			<Divider />
			{/* </AppBar> */}

			<TabPanel value={activeTabIdx} index={0}>
				<PendingProducts action={action} userId={dataId} history={history} perms={userPerms} />
			</TabPanel>
			<TabPanel value={activeTabIdx} index={1}>
				<Form action={action} userId={dataId} history={history} perms={userPerms} />
			</TabPanel>

			<TabPanel value={activeTabIdx} index={2}>
				<UpdatedProducts action={action} userId={dataId} history={history} perms={userPerms} />
			</TabPanel>
		</div>
	)
}

export default withRouter(connect(mapStateToProps)(NavTabs))
