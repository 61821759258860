import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import api from '../api'

export const login = (username, pwd) => ({
	type: ACTION_TYPES.AUTH_LOGIN,
	payload: api.login(username, pwd),
})

export const sisenseLogin = (params) => ({
	type: ACTION_TYPES.AUTH_LOGIN,
	// payload: api.login(username, pwd),
	payload: api.sisenseLogin(params),
})

export const logout = (sisToken) => ({
	type: ACTION_TYPES.AUTH_LOGOUT,
	payload: api.logout(sisToken),
})

export const dismissLogOutError = () => ({
	type: ACTION_TYPES.AUTH_LOGOUT_DISMISS_ERROR,
})

export const unauthError = () => ({
	type: ACTION_TYPES.UNAUTH_ERROR,
})

export const fetchProfile = () => ({
	type: SAGA_ACTIONS.FETCH_PROFILE,
})

export const resetProfile = () => ({
	type: ACTION_TYPES.RESET_CURRENT_USER,
})

export const changeCurrentUsrPwd = (formData, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.UPDATE_USER_PASSWORD,
	payload: { formData, successCallback, dialogCloseBtnCallback },
})
