import React from 'react'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'

export default function InputField(props) {
	const {
		field,
		form,
		type,
		label,
		disabled,
		inputProps,
		autoFocus,
		margin,
		multiple,
		rows,
		placeholder,
		InputProps,
		size = 'medium',
		fieldValue,
	} = props
	const err = _.get(form.errors, field.name)
	const isTouched = _.get(form.touched, field.name)
	const value = _.get(form.values, field.name)

	return (
		<TextField
			name={field.name}
			error={Boolean(err && isTouched)}
			type={type}
			className='input-field'
			id={field.name}
			label={label}
			onChange={field.onChange}
			onBlur={field.onBlur}
			value={fieldValue ? fieldValue : value}
			autoFocus={autoFocus}
			helperText={err && isTouched && String(err)}
			margin={!!margin ? margin : 'normal'}
			size={size}
			variant='outlined'
			placeholder={placeholder}
			multiline={multiple ? true : false}
			rows={rows ? rows : ''}
			inputProps={{
				readOnly: Boolean(disabled),
				...inputProps,
			}}
			InputProps={InputProps}
		/>
	)
}
