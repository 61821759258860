import { TablePagination } from '@material-ui/core'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'

const tableHead = {
	file_name: 'FILE NAME',
	file_url: 'FILE URL',
}

const Table = ({ tableList }) => {
	// const countPerPage = 10
	const [countPerPage, setcountPerPage] = React.useState(10)
	const [value, setValue] = React.useState('')
	const [currentPage, setCurrentPage] = React.useState(0)
	const [collection, setCollection] = React.useState(cloneDeep(tableList.slice(0, countPerPage)))

	React.useEffect(() => {
		if (!value) {
			updatePage(0, 0)
		} else {
			// searchData(value)
			// throttle((val) => {
			const query = value.toLowerCase()
			setCurrentPage(0)
			const data = cloneDeep(tableList.filter((item) => item.file_name.toLowerCase().indexOf(query) > -1).slice(0, countPerPage))
			setCollection(data)
			// }, 400)
		}
	}, [value, tableList])

	React.useEffect(() => {
		setCollection(cloneDeep(tableList.slice(0, countPerPage)))
	}, [tableList])

	const updatePage = (p, pageNo) => {
		setCurrentPage(pageNo)
		const from = countPerPage * pageNo
		const to = from + countPerPage
		setCollection(cloneDeep(tableList.slice(from, to)))
	}

	const handleChangeRowsPerPage = (event) => {
		setcountPerPage(parseInt(event.target.value, 10))
		setCurrentPage(0)
		setCollection(cloneDeep(tableList.slice(0, parseInt(event.target.value, 10))))
	}

	const tableRows = (rowData) => {
		const { key, index } = rowData
		const tableCell = Object.keys(tableHead)
		const columnData = tableCell.map((keyD, i) => {
			function validURL(str) {
				var pattern = new RegExp(
					'^(https?:\\/\\/)?' + // protocol
						'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
						'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
						'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
						'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
						'(\\#[-a-z\\d_]*)?$',
					'i'
				) // fragment locator
				return !!pattern.test(str)
			}
			return validURL(key[keyD]) === true ? (
				<a style={{ textDecoration: 'none' }} target='_blank' href={key[keyD]}>
					<td key={i}>{key[keyD]}</td>
				</a>
			) : (
				<td key={i}>{key[keyD]}</td>
			)
		})

		return <tr key={index}>{columnData}</tr>
	}

	const tableData = () => {
		// console.log(collection, 'list1')
		return collection.map((key, index) => tableRows({ key, index }))
	}

	const headRow = () => {
		return Object.values(tableHead).map((title, index) => <td key={index}>{title}</td>)
	}

	return (
		<>
			<div class='search' style={{ textAlign: 'right' }}>
				<input placeholder='Search File...' value={value} onChange={(e) => setValue(e.target.value)} />
			</div>
			<table>
				<thead>
					<tr>{headRow()}</tr>
				</thead>
				<tbody className='trhover'>{tableData()}</tbody>
			</table>

			<TablePagination
				rowsPerPageOptions={[10, 25, 50]}
				component='div'
				rowsPerPage={countPerPage}
				page={currentPage}
				count={tableList.length}
				onChangePage={updatePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</>
	)
}
export default Table
