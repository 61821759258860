import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	login: (username, pwd) => {
		return Axios.post(
			API_ENDPOINTS.LOGIN,
			{ email_id: username, pwd },
			{
				headers: {
					Authorization: 'Bearer ' + btoa(`${username}:${pwd}`),
					'Content-Type': 'application/json',
				},
			}
		)
	},
	sisenseLogin: (Params) => {
		return Axios.get(API_ENDPOINTS.SISENSE_LOGIN, {
			params: Params,
			headers: { isAuthRequired: true },
		})
	},
	logout: (sisToken) => {
		return Axios.post(API_ENDPOINTS.LOGOUT, sisToken, { headers: { isAuthRequired: true } })
	},
	changeCurrentUserPassword: (formData) => {
		return Axios.put(API_ENDPOINTS.USER_CHANGE_PASSWORD_API, formData, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	fetchProfile: (cancelSource) => {
		return Axios.get(API_ENDPOINTS.PROFILE, { cancelToken: cancelSource.token, headers: { isAuthRequired: true } })
	},
}
