import { API_ENDPOINTS } from '../../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	fetchSummary: (prdFor, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_SUMMARY_PREDICT, { headers: { isAuthRequired: true, path: { for: prdFor } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchPrediction: (prdFor, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_PREDICTION_QA, { headers: { isAuthRequired: true, path: { for: prdFor } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	skipPrediction: (prdFor, id, data, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.SKIP_SAVE_PREDICT,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { for: prdFor, id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	savePrediction: (prdFor, id, data, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.SKIP_SAVE_PREDICT,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { for: prdFor, id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	prevPrediction: (prdFor, id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.PREV_PREDICT, {
			headers: { isAuthRequired: true, path: { for: prdFor, id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchBatch: (prdFor, batch, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_BATCH, { headers: { isAuthRequired: true, path: { for: prdFor, batch } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	putbatch: (prdFor, data, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.PUT_BATCH,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { for: prdFor } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetDistList: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_DIST_LIST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	ErrorRpt: (prdFor, data, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.ERROR_REPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { for: prdFor } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchDistForm: (id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_DIST_FORM, {
			headers: { isAuthRequired: true, path: { id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	putDistForm: (data, id, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.GET_DIST_FORM,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetBucDetails: (prdFor, id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_BUC_DETAILS, {
			headers: { isAuthRequired: true, path: { for: prdFor, id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	PutBucDetails: (prdFor, id, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.GET_BUC_DETAILS,
			{},
			{
				headers: { isAuthRequired: true, path: { for: prdFor, id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetRptPivot: (report, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_RPT_PIVOT, {
			headers: { isAuthRequired: true, path: { report } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	PrdExport: (data, exp, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.PRD_EXPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { exp } }, responseType: 'blob' }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	DistExport: (data, exp, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.DIST_EXPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { exp } }, responseType: 'blob' }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchReptUsrBatch: (predFor, filter, filterParams) => {
		return Axios.get(API_ENDPOINTS.GET_RPT_USR_BATCH, {
			params: filterParams,
			// cancelToken: cancelSource.token,
			headers: { isAuthRequired: true, path: { for: predFor, filter } },
		})
	},
	fetchReport: (predFor, data, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.GET_BB_REPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { for: predFor } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	reportExport: (predFor, data, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.BB_REPORT_EXPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { for: predFor } }, responseType: 'blob' }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}

export default api
