// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/FS-Jack/FS-Jack-Regular.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: 'FS-Jack';
	src: local('FS-Jack'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
  }
  
  /* @font-face {
	font-family: 'FS-Jack';
	src: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Medium.otf) format('opentype');

  }
  
  @font-face {
	font-family: 'FS-Jack';
	src: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Light.otf) format('opentype');

  } */
  /* @font-face {
	font-family: 'FS-Jack';
	src: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Italic.otf) format('opentype');

  } */
  /* @font-face {
	font-family: 'FS-Jack';
	src: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Bold.otf) format('opentype');
	font-weight: 900;
  } */

`, "",{"version":3,"sources":["webpack://./src/scenes/AbTestNavigation/index.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,iFAAsF;EACrF;;EAEA;;;;;;;;;;KAUG;EACH;;;;KAIG;EACH;;;;KAIG","sourcesContent":["@font-face {\n\tfont-family: 'FS-Jack';\n\tsrc: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Regular.otf) format('opentype');\n  }\n  \n  /* @font-face {\n\tfont-family: 'FS-Jack';\n\tsrc: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Medium.otf) format('opentype');\n\n  }\n  \n  @font-face {\n\tfont-family: 'FS-Jack';\n\tsrc: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Light.otf) format('opentype');\n\n  } */\n  /* @font-face {\n\tfont-family: 'FS-Jack';\n\tsrc: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Italic.otf) format('opentype');\n\n  } */\n  /* @font-face {\n\tfont-family: 'FS-Jack';\n\tsrc: local('FS-Jack'), url(../../fonts/FS-Jack/FS-Jack-Bold.otf) format('opentype');\n\tfont-weight: 900;\n  } */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
