import { SAGA_ACTIONS } from '../../../constants'

export const moveStatus = (testId, testDetails, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.MOVE_STATUS,
	payload: { testId, testDetails, successCallback, dialogCloseBtnCallback },
})

export const fetchAllTests = (filters, successCallback, errorCallback) => ({
	type: SAGA_ACTIONS.AB_TEST_TABLE,
	payload: { filters, successCallback, errorCallback },
})

export const deleteTest = (testId, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.DELETE_TEST,
	payload: { testId, successCallback, dialogCloseBtnCallback },
})

export const fetchDashboard = () => ({
	type: SAGA_ACTIONS.AB_TEST_DASH,
})
