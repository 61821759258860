import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'



export const AccessDeniedPage = (props) => (
	<SvgIcon viewBox={'0 0 803 480'} {...props}>
		<path
			d='M614.691 474.572C604.412 465.887 598.169 452.166 599.806 438.809C601.443 425.452 611.585 413.173 624.739 410.336C637.894 407.5 652.89 415.352 656.885 428.202C659.084 403.427 661.618 377.614 674.79 356.516C686.716 337.413 707.374 323.743 729.762 321.298C752.149 318.852 775.721 328.089 789.684 345.759C803.646 363.43 807.065 389.119 797.286 409.406C790.082 424.351 776.81 435.473 762.904 444.521C717.97 473.514 664.077 485.304 611.141 477.722L614.691 474.572Z'
			fill='#F2F2F2'
		/>
		<path
			d='M767.544 328.601C749.072 343.647 732.125 360.475 716.949 378.842C693.199 407.487 673.98 439.603 659.962 474.072C658.936 476.587 663.019 477.681 664.034 475.195C686.97 419.176 723.585 369.801 770.53 331.586C772.638 329.87 769.635 326.898 767.544 328.601Z'
			fill='white'
		/>
		<path
			d='M258.455 472.212C272.573 460.283 281.148 441.437 278.899 423.092C276.65 404.746 262.721 387.88 244.653 383.984C226.585 380.088 205.989 390.873 200.501 408.522C197.481 374.494 194.001 339.041 175.909 310.063C159.528 283.824 131.155 265.049 100.405 261.69C69.6554 258.331 37.2798 271.017 18.1023 295.288C-1.07519 319.558 -5.77072 354.842 7.6607 382.706C17.5552 403.233 35.7842 418.509 54.8838 430.937C115.818 470.587 191.407 487.124 263.33 476.539'
			fill='#F2F2F2'
		/>
		<path
			d='M48.5104 271.72C73.8827 292.386 97.1592 315.5 118.003 340.726C138.85 365.927 157.204 393.09 172.806 421.835C181.527 437.959 189.362 454.547 196.274 471.526C197.684 474.979 192.075 476.483 190.682 473.068C178.438 443.135 163.29 414.474 145.455 387.496C127.706 360.616 107.392 335.521 84.7982 312.564C72.0188 299.585 58.5355 287.319 44.4093 275.821C41.5139 273.464 45.6388 269.382 48.5104 271.72L48.5104 271.72Z'
			fill='white'
		/>
		<path
			d='M576.308 478.949H239.475V133.349C239.475 59.82 299.295 0 372.823 0H442.959C516.488 0 576.308 59.82 576.308 133.349V478.949Z'
			fill='#F2F2F2'
		/>
		<path
			d='M506.173 478.949H239.475V133.349C239.401 105.174 248.323 77.7118 264.941 54.9598C265.553 54.1248 266.164 53.3006 266.795 52.4855C274.833 41.9431 284.398 32.6586 295.175 24.9385C295.784 24.4955 296.395 24.0602 297.016 23.625C306.034 17.3842 315.789 12.2826 326.059 8.43609C326.669 8.20493 327.29 7.97332 327.911 7.75116C337.199 4.43642 346.828 2.16584 356.619 0.981345C357.226 0.898429 357.849 0.833546 358.472 0.768662C368.013 -0.2515 377.636 -0.2515 387.177 0.768662C387.798 0.833546 388.422 0.898421 389.036 0.982232C398.824 2.16681 408.45 4.43711 417.736 7.7512C418.357 7.97336 418.978 8.20496 419.59 8.43657C429.733 12.2345 439.374 17.2601 448.296 23.401C448.916 23.8269 449.536 24.2621 450.147 24.6974C456.188 29.0096 461.863 33.8136 467.112 39.0603C471.322 43.2688 475.245 47.7549 478.855 52.4883C479.483 53.3007 480.094 54.1248 480.705 54.9585C497.324 77.7107 506.246 105.174 506.173 133.349V478.949Z'
			fill='#CCCCCC'
		/>
		<path
			d='M484.486 324.375C492.641 324.375 499.251 317.764 499.251 309.61C499.251 301.455 492.641 294.844 484.486 294.844C476.331 294.844 469.721 301.455 469.721 309.61C469.721 317.764 476.331 324.375 484.486 324.375Z'
			fill='#2F2E41'
			fillOpacity='0.54'
		/>
		<path d='M578.714 468.598H567.4L562.018 424.959L578.716 424.96L578.714 468.598Z' fill='#FFB8B8' />
		<path
			d='M559.319 465.365H581.138V479.103H545.581C545.581 477.299 545.936 475.513 546.627 473.846C547.317 472.179 548.329 470.665 549.605 469.389C550.881 468.113 552.395 467.101 554.062 466.411C555.729 465.72 557.515 465.365 559.319 465.365Z'
			fill='#2F2E41'
		/>
		<path d='M635.007 468.598H623.693L618.311 424.959L635.009 424.96L635.007 468.598Z' fill='#FFB8B8' />
		<path
			d='M615.612 465.365H637.431V479.103H601.874C601.874 477.299 602.229 475.513 602.919 473.846C603.61 472.179 604.622 470.665 605.897 469.389C607.173 468.113 608.688 467.101 610.354 466.411C612.021 465.72 613.808 465.365 615.612 465.365Z'
			fill='#2F2E41'
		/>
		<path
			d='M521.959 312.535C521.145 311.336 520.604 309.973 520.374 308.542C520.144 307.111 520.23 305.647 520.627 304.253C521.024 302.859 521.721 301.569 522.671 300.474C523.62 299.379 524.798 298.505 526.121 297.914L564.581 191.912L583.506 202.24L539.593 303.539C540.438 305.829 540.414 308.348 539.525 310.621C538.637 312.893 536.945 314.761 534.772 315.87C532.598 316.978 530.093 317.251 527.732 316.637C525.37 316.022 523.316 314.563 521.959 312.535Z'
			fill='#FFB8B8'
		/>
		<path
			d='M629.228 332.453C627.97 331.732 626.886 330.744 626.052 329.559C625.218 328.374 624.654 327.021 624.399 325.594C624.145 324.167 624.206 322.702 624.579 321.301C624.952 319.901 625.628 318.599 626.558 317.488L614.644 205.355L636.18 206.339L641.132 316.636C642.895 318.323 643.978 320.598 644.176 323.03C644.374 325.463 643.672 327.883 642.205 329.832C640.738 331.782 638.606 333.126 636.214 333.609C633.823 334.092 631.337 333.681 629.228 332.453Z'
			fill='#FFB8B8'
		/>
		<path
			d='M594.87 172.277C607.388 172.277 617.536 162.13 617.536 149.612C617.536 137.094 607.388 126.946 594.87 126.946C582.352 126.946 572.205 137.094 572.205 149.612C572.205 162.13 582.352 172.277 594.87 172.277Z'
			fill='#FFB8B8'
		/>
		<path
			d='M629.083 306.951H560.75L560.833 306.419C560.956 305.624 573.011 226.65 564.12 200.814C563.227 198.275 563.306 195.496 564.34 193.011C565.374 190.526 567.29 188.512 569.721 187.356H569.721C582.43 181.371 606.826 174.001 627.416 191.886C630.446 194.561 632.812 197.905 634.327 201.652C635.842 205.399 636.465 209.447 636.146 213.477L629.083 306.951Z'
			fill='#3B93CD'
		/>
		<path
			d='M573.874 234.322L543.11 228.08L557.531 193.908C558.394 190.598 560.534 187.766 563.483 186.032C566.431 184.298 569.947 183.805 573.259 184.66C576.571 185.515 579.408 187.649 581.149 190.593C582.89 193.537 583.392 197.052 582.545 200.366L573.874 234.322Z'
			fill='#3B93CD'
		/>
		<path
			d='M613.276 249.91L611.426 207.514C610.024 199.544 614.586 192.01 621.603 190.779C628.621 189.552 635.474 195.079 636.883 203.108L643.835 242.723L613.276 249.91Z'
			fill='#3B93CD'
		/>
		<path
			d='M626.81 301.875C637.805 343.75 639.004 396.991 636.038 455.065L621.273 453.22L594.511 342.48L579.745 455.065L563.134 454.142C558.172 393.208 553.34 340.897 561.289 306.489L626.81 301.875Z'
			fill='#2F2E41'
		/>
		<path
			d='M609.805 167.128C605.576 171.633 597.724 169.215 597.173 163.061C597.129 162.584 597.133 162.103 597.182 161.626C597.466 158.9 599.041 156.425 598.664 153.548C598.578 152.831 598.312 152.149 597.889 151.565C594.519 147.053 586.609 153.583 583.429 149.498C581.48 146.993 583.772 143.05 582.275 140.251C580.3 136.556 574.451 138.379 570.783 136.355C566.701 134.104 566.945 127.842 569.632 124.033C572.908 119.388 578.653 116.909 584.326 116.552C590 116.195 595.634 117.728 600.93 119.793C606.948 122.138 612.916 125.379 616.619 130.67C621.123 137.106 621.556 145.757 619.304 153.281C617.934 157.859 613.258 163.45 609.805 167.128Z'
			fill='#2F2E41'
		/>
		<path
			d='M775.161 479.543H93.8402C93.5494 479.542 93.2707 479.426 93.0653 479.22C92.86 479.014 92.7446 478.735 92.7446 478.444C92.7446 478.153 92.86 477.874 93.0653 477.668C93.2707 477.463 93.5494 477.346 93.8402 477.346H775.161C775.452 477.346 775.73 477.463 775.936 477.668C776.141 477.874 776.256 478.153 776.256 478.444C776.256 478.735 776.141 479.014 775.936 479.22C775.73 479.426 775.452 479.542 775.161 479.543Z'
			fill='#3F3D56'
		/>
		<path
			d='M432.808 378.822H312.84C311.26 378.831 309.741 378.212 308.616 377.102C307.492 375.992 306.854 374.482 306.841 372.902V105.125C306.854 103.545 307.492 102.034 308.616 100.924C309.741 99.8143 311.26 99.1958 312.84 99.2043H432.808C434.388 99.1958 435.907 99.8143 437.031 100.924C438.156 102.034 438.794 103.545 438.806 105.125V372.902C438.794 374.482 438.156 375.992 437.031 377.102C435.907 378.212 434.388 378.831 432.808 378.822Z'
			fill='white'
		/>
		<path d='M438.806 185.849H306.841V187.694H438.806V185.849Z' fill='#CCCCCC' />
		<path d='M439.268 291.153H307.303V292.999H439.268V291.153Z' fill='#CCCCCC' />
		<path d='M350.214 99.2043H348.369V379.745H350.214V99.2043Z' fill='#CCCCCC' />
		<path d='M396.356 101.204H394.51V381.745H396.356V101.204Z' fill='#CCCCCC' />
		<path d='M345.5 172.5H400.5L373 125L345.5 172.5ZM375.5 165H370.5V160H375.5V165ZM375.5 155H370.5V145H375.5V155Z' fill='#FF0505' />
	</SvgIcon>
)

