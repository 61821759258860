import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormLabel,
	TextField,
	Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgGridCustom from '../../../components/AgGridCustom'
import ListPageFilterSection from '../../../components/ListPageFilterSection'
import { hideLoader, showLoader } from '../../../services/loader/actions'
import Api from '../../../services/prmatching/api'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { isAxiosCancelError } from '../../../utils'
import Query from './QueryBuilder'

import MaterialConfig from 'react-awesome-query-builder/lib/config/material'

const REPORT_STATUS = {
	ALL: 'all',
	MATCHED: 'matched',
	SKIPPED: 'skipped',
}

const getRptStatusFilterVal = (rptStatusVal) => {
	switch (rptStatusVal) {
		case REPORT_STATUS.ALL:
			return undefined
		case REPORT_STATUS.MATCHED:
			return true
		case REPORT_STATUS.SKIPPED:
			return false
		default:
			return undefined
	}
}

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	switchLabel: {
		fontSize: '14px',
	},
	radioFormLabel: {
		fontSize: '15px',
		// marginBottom: theme.spacing(0.5),
		marginTop: '11px',
	},
	groupBorder: {
		border: 'ridge',
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		justifyContent: 'flex-end',
	},
	filterInc: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		flexDirection: 'row-reverse',
		// border: 'ridge',
	},
	grpInc: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		flexDirection: 'row-reverse',
		border: 'ridge',
	},
	groupButton: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		marginLeft: '450px',
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	statusCellRoot: {
		verticalAlign: 'middle',
		'& span': {
			width: '8px',
			height: '8px',
			display: 'inline-block',
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		},
	},
	emailCellRoot: {
		verticalAlign: 'middle',
		'& > span': {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(1),
			fontSize: '18px',
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
}))

const Report = ({ width, action, userId, history, perms }) => {
	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		searchText: '',
		offset: 0,
		limit: 10,
		roleFilter: [],
		rptStatusFilter: REPORT_STATUS.ALL,
		totalCount: 0,
		userList: [],
	})

	const [roleListState, setRoleListState] = useState({
		isFetchRoles: true,
		roleList: null,
	})
	const [currentUser, setCurrentUser] = useState('all')
	const [searchQuery, setsearchQuery] = useState('')
	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		selectedData: null,
	})

	const InitialConfig = MaterialConfig

	InitialConfig.settings = {
		...InitialConfig.settings,
		...{
			addRuleLabel: 'Add Filter',
			showNot: false,
		},
		customFieldSelectProps: {
			showSearch: true,
		},
	}

	const config = {
		...InitialConfig,
		fields: {
			user_id: {
				label: 'User',
				type: 'select',
				valueSources: ['value'],
				defaultValue: undefined,
				fieldSettings: {
					asyncFetch: async (search) => {
						const toSearch = _.isEmpty(search) ? '' : search
						let pay_values = { search_text: toSearch }
						const usr = await Api.fetchReportUsr(pay_values)
						const usrValues = usr.data.data.map((usrs) => ({
							title: usrs.email,
							value: usrs.user_id,
						}))

						return {
							values: usrValues,
							// hasMore: true,
						}
					},
					useAsyncSearch: true,
					// useLoadMore: true,
					forceAsyncSearch: true,
					showSearch: true,
					validateValue: (val) => !!val,
				},
			},
			processed_date: {
				label: 'Date',
				type: 'date',
				valueSources: ['value'],
				preferWidgets: ['date'],
				operators: ['between'],
				fieldSettings: {
					dateFormat: 'DD-MM-YYYY',
					valueFormat: 'YYYY-MM-DD',
					validateValue: (val) => !!val,
				},
			},
		},
	}

	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const { searchText, loading, offset, totalCount, limit, roleFilter, rptStatusFilter, userList } = usersTblState
	const { isFetchRoles, roleList } = roleListState

	const isMounted = useRef(true)
	const searchBoxTimeout = useRef(undefined)
	const dispatch = useDispatch()

	const classes = useStyles()

	const onClickMoreOptions = useCallback((event, data) => {
		setPopOverState({
			open: true,
			anchorEl: event.currentTarget,
			selectedData: data,
		})
	}, [])

	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		return [
			{
				headerName: 'User',
				field: 'email',
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Date',
				field: 'processed_date',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Total Skipped',
				field: 'skipped_count',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Total Matched',
				field: 'matched_count',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
		]
	}, [width, classes, authToken, onClickMoreOptions, roleList])

	const gridHeaders1 = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		return [
			{
				headerName: 'User',
				field: 'email',
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Date',
				field: 'processed_date',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Total Matched',
				field: 'matched_count',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
		]
	}, [width, classes, authToken, onClickMoreOptions, roleList])

	const gridHeaders2 = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		return [
			{
				headerName: 'User',
				field: 'email',
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Date',
				field: 'processed_date',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Total Skipped',
				field: 'skipped_count',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
		]
	}, [width, classes, authToken, onClickMoreOptions, roleList])

	const addBtnClick = () => {
		history.replace({ pathname: `/usermanagement/users/create` })
	}

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const onChangeSearchBox = (e) => {
		const searchText = e.target.value
		if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
		searchBoxTimeout.current = setTimeout(() => {
			addFilterAndRefreshTbl({ searchText, offset: 0 })
		}, 300)
	}

	const fetchReportList = ({ limit, offset, searchQuery }) => {
		dispatch(showLoader('Please wait, getting reports'))
		Api.fetchReport(
			{
				limit,
				offset,
				search_query: searchQuery,
				all: _.isEmpty(searchQuery) ? true : false,
				// role_id: roleFilterVal,
			},
			(resp) => {
				const totalCount = _.get(resp, 'data.data.total_count', 0)
				const userList = _.get(resp, 'data.data.report_data', [])
				// const userList = results
				if (isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount,
						userList,
					}))
				}
				dispatch(hideLoader())
			},
			(err) => {
				if (!isAxiosCancelError(err) && isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: 0,
						userList: [],
					}))
				}
				dispatch(hideLoader())
			}
		)
	}

	const exportList = ({ searchQuery, currentUser }) => {
		dispatch(
			showLoader('The Exporting process has been started and the Exported details of the report will be downloaded once it is exported completely')
		)
		Api.reportExport(
			{
				search_query: searchQuery,
				report_for: currentUser,
			},
			(response) => {
				const filename = response.headers['content-disposition'].split('filename=')[1]
				const contentType = response.headers['content-type']
				// response.data.then((blob) => {
				let blob = new Blob([response.data], { type: contentType })
				let url = window.URL.createObjectURL(blob)
				let a = document.createElement('a')
				a.href = url
				a.download = filename
				a.click()
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout('Something went wrong.', 2000))
			}
		)
	}

	const usePrevious = (value) => {
		const ref = useRef()
		useEffect(() => {
			ref.current = value
		})
		return ref.current
	}

	const prevQuery = usePrevious({ searchQuery })

	if (!_.isEmpty(prevQuery?.searchQuery) && _.isEmpty(searchQuery)) {
		fetchReportList({
			limit,
			offset,
			searchQuery: '',
		})
	}

	useEffect(() => {
		// if (_.isNull(roleList)) fetchRolesList()
		// else
		fetchReportList({
			limit,
			offset,
			searchQuery,
		})
	}, [limit, offset])

	const onFilterClick = () => {
		fetchReportList({
			limit,
			offset,
			searchQuery,
		})
	}
	const onClearClick = (searchQuery) => {
		setsearchQuery(searchQuery)
		fetchReportList({
			limit,
			offset,
			searchQuery,
		})
	}

	const onExportClick = () => {
		exportList({
			searchQuery,
			currentUser,
		})
	}

	//Refresh function start
	const isNeedRefreshList = _.get(history, 'location.isRefreshList', false)
	useEffect(() => {
		if (isNeedRefreshList) {
			setUsersTblState((prevState) => {
				fetchReportList({
					limit: 10,
					offset: 0,
					searchText: prevState.searchText,
					roleFilter: [],
					rptStatusFilter: REPORT_STATUS.ALL,
				})
				return {
					...prevState,
					loading: true,
					offset: 0,
					userList: [],
					roleFilter: [],
					rptStatusFilter: REPORT_STATUS.ALL,
				}
			})
			_.set(history, 'location.isRefreshList', false)
		}
	}, [isNeedRefreshList, fetchReportList, history])
	//Refresh function end

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	const onChangeUserStatusRb = (event) => {
		// addFilterAndRefreshTbl({ rptStatusFilter: event.target.value, offset: 0 })
		setCurrentUser(event.target.value)
	}

	return (
		<Grid container className={classes.root} item xs={12}>
			<Typography gutterBottom variant='h6' component='h6'>
				<strong>Report:</strong>
			</Typography>
			<ListPageFilterSection
				// disabled={isFetchRoles}
				searchPlaceholder={'Search Users...'}
				addBtnClick={addBtnClick}
				// hideAddBtn={!isCreateAllowed}
				onChangeSearchBox={onChangeSearchBox}
				addBtnTxt={'Add User'}
				hideAddBtn={true}
				hideSearch={true}
			>
				<div className={classes.groupBorder}>
					<Query
						config={config}
						searchQuery={searchQuery}
						setsearchQuery={setsearchQuery}
						onClearClick={onClearClick}
						onFilterClick={onFilterClick}
						onExportClick={onExportClick}
					/>
				</div>
			</ListPageFilterSection>
			{/* <div> */}
			<Grid container>
				<Grid item xs={1}>
					{/* <Typography variant='body1' component='h6'>
						Report For:
					</Typography> */}
					<FormLabel component='legend' className={classes.radioFormLabel}>
						Report For:
					</FormLabel>
				</Grid>
				<Grid item xs={9}>
					<RadioGroup row aria-label='position' onChange={onChangeUserStatusRb} value={currentUser} name='position' defaultValue='top'>
						<FormControlLabel
							value='top'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' value={REPORT_STATUS.ALL} color='primary' />}
							label='All'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='top'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' value={REPORT_STATUS.MATCHED} color='primary' />}
							label='Matched'
							labelPlacement='end'
						/>
						<FormControlLabel
							value='top'
							classes={{ label: classes.switchLabel }}
							control={<Radio size='small' value={REPORT_STATUS.SKIPPED} color='primary' />}
							label='Skipped'
							labelPlacement='end'
						/>
					</RadioGroup>
				</Grid>
			</Grid>
			{/* </div> */}
			{currentUser === 'all' && (
				<Grid item xs={12}>
					<div>
						<AgGridCustom
							columnDefs={gridHeaders}
							rowData={userList}
							loading={loading}
							// onRowClicked={onRowClicked}
							rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
							noDataTxt={'No reports found'}
						></AgGridCustom>
					</div>
					<div>
						{!loading && (
							<TablePagination
								component='div'
								count={totalCount}
								page={offset / limit}
								onChangePage={onChangePage}
								rowsPerPage={limit}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						)}
					</div>
				</Grid>
			)}
			{currentUser === 'matched' && (
				<Grid item xs={12}>
					<div>
						<AgGridCustom
							columnDefs={gridHeaders1}
							rowData={userList}
							loading={loading}
							// onRowClicked={onRowClicked}
							rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
							noDataTxt={'No reports found'}
						></AgGridCustom>
					</div>
					<div>
						{!loading && (
							<TablePagination
								component='div'
								count={totalCount}
								page={offset / limit}
								onChangePage={onChangePage}
								rowsPerPage={limit}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						)}
					</div>
				</Grid>
			)}
			{currentUser === 'skipped' && (
				<Grid item xs={12}>
					<div>
						<AgGridCustom
							columnDefs={gridHeaders2}
							rowData={userList}
							loading={loading}
							// onRowClicked={onRowClicked}
							rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
							noDataTxt={'No reports found'}
						></AgGridCustom>
					</div>
					<div>
						{!loading && (
							<TablePagination
								component='div'
								count={totalCount}
								page={offset / limit}
								onChangePage={onChangePage}
								rowsPerPage={limit}
								onChangeRowsPerPage={onChangeRowsPerPage}
							/>
						)}
					</div>
				</Grid>
			)}
		</Grid>
	)
}

Report.propTypes = {
	action: PropTypes.string,
	userId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Report)
