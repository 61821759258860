import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	GetProducts: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_PRODUCTS, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetMatchMiss: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_MATCHMISS, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetFilters: (data, filter, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_FILTERS, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { filter } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	filterOnChange: (data, filter, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.FILTER_ONCHANGE,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { filter } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	matchmissSave: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.MISS_SAVE, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	matchmissSkip: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.MISS_SKIP, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchReportUsr: (filterParams) => {
		return Axios.get(API_ENDPOINTS.GET_REPORT_USR, {
			params: filterParams,
			// cancelToken: cancelSource.token,
			headers: { isAuthRequired: true },
		})
	},
	fetchReport: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_REPORT, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	reportExport: (data, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.REPORT_EXPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true }, responseType: 'blob' }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	matchDistributor: (data) => {
		return Axios.post(API_ENDPOINTS.MATCH_DISTRIBUTOR, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
	},
	matchSubmit: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.MATCH_SUBMIT, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchProducts: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.FETCH_PRODUCTS_PR, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchListPR: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.LIST_PR, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	BulkMatchValid: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.BULK_MAT_VALID, { file: data }, { headers: { 'Content-Type': 'multipart/form-data', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	BulkMatchSubmit: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.BULK_MAT_SUBMIT, { file: data }, { headers: { 'Content-Type': 'multipart/form-data', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	MatchFilterGet: (data, filter, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.MATCH_FILTER_GET,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { filter } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	MatchFilterOnchange: (data, filter, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.MATCH_FILTER_ONCHANGE,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { filter } } }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	MatchHistoryData: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.MATCH_HISTORY_DATA, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}

export default api
