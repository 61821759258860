import React, { useState, useImperativeHandle } from 'react'
import _ from 'lodash'
import { Avatar, Typography, Badge, Icon, IconButton } from '@material-ui/core'
import { getImgUrl, stringToHslColor } from '../../../../utils'

export const withCellRenderState = (CellRenderer) => {
	return React.forwardRef((props, ref) => {
		const [value, setValue] = useState(props.value)
		useImperativeHandle(ref, () => {
			return {
				refresh: (params) => {
					if (params.value !== value) {
						setValue(params.value)
					}
					return true
				},
			}
		})
		return <CellRenderer {...props}></CellRenderer>
	})
}

export const ActionCellRenderer = (props) => {
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const onClickMoreOptions = _.get(props, 'colDef.cellRendererParams.onClickMoreOptions')

	const onClickAnchorEl = (event) => {
		onClickMoreOptions(event, _.get(props, 'data', {}))
	}

	return (
		<div className={containerClassName}>
			<IconButton size='small' onClick={onClickAnchorEl} title='Show Actions'>
				<i className='material-icons'>more_horiz</i>
			</IconButton>
		</div>
	)
}

export const RoleCellRenderer = (props) => {
	const { data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	let displayName = _.join(
		_.map(_.get(data, 'roles', []), (role) => _.startCase(_.get(role, 'role_name', ''))),
		', '
	)
	return (
		<div className={containerClassName} title={displayName}>
			{displayName}
		</div>
	)
}

export const StatusCellRenderer = (props) => {
	const { value } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	return (
		<div className={containerClassName}>
			<span style={{ backgroundColor: value ? '#82c43c' : '#fc5a5a' }}></span>
			{value ? 'Active' : 'Inactive'}
		</div>
	)
}

export const EmailCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isEmailVerified = _.get(data, 'is_email_verified')
	return (
		<div className={containerClassName}>
			{value}
			<Icon
				style={{ color: isEmailVerified ? 'green' : '#FFAD36' }}
				title={isEmailVerified ? 'Email address is verified.' : 'Email address is not verified!'}
				fontSize='small'
			>
				{isEmailVerified ? 'verified' : 'new_releases'}
			</Icon>
		</div>
	)
}

export const CreatedCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isRenderMobView = _.get(props, 'colDef.cellRendererParams.isRenderMobView')
	const authToken = _.get(props, 'colDef.cellRendererParams.authToken')
	const fullName = _.join([_.get(data, 'created_by.first_name'), _.get(data, 'created_by.last_name')], ' ')
	const profilePic = _.get(data, 'profile_pic_url')
	const getPicUrl = (profilePic) => getImgUrl(authToken, profilePic, 50)
	const profilePicUrl = profilePic && getPicUrl(profilePic)
	const isActive = _.get(data, 'is_active', false)
	const avatarStyle = isActive ? { backgroundColor: stringToHslColor(`${fullName}`) } : {}

	if (isRenderMobView) {
		const isEmailVerified = _.get(data, 'is_email_verified')
		return (
			<div className='m-details'>
				<Typography variant='body2' component='div'>
					{fullName}
				</Typography>
			</div>
		)
	} else
		return (
			<div className={containerClassName}>
				<span className='details-span'>{_.startCase(fullName)}</span>
			</div>
		)
}

export const UpdatedCellRenderer = (props) => {
	const { value, data } = props
	const containerClassName = _.get(props, 'colDef.cellRendererParams.containerClass')
	const isRenderMobView = _.get(props, 'colDef.cellRendererParams.isRenderMobView')
	const authToken = _.get(props, 'colDef.cellRendererParams.authToken')
	const fullName = _.join([_.get(data, 'modified_by.first_name'), _.get(data, 'modified_by.last_name')], ' ')
	const profilePic = _.get(data, 'profile_pic_url')
	const getPicUrl = (profilePic) => getImgUrl(authToken, profilePic, 50)
	const profilePicUrl = profilePic && getPicUrl(profilePic)
	const isActive = _.get(data, 'is_active', false)
	const avatarStyle = isActive ? { backgroundColor: stringToHslColor(`${fullName}`) } : {}

	if (isRenderMobView) {
		const isEmailVerified = _.get(data, 'is_email_verified')
		return (
			<div className='m-details'>
				<Typography variant='body2' component='div'>
					{fullName}
				</Typography>
			</div>
		)
	} else
		return (
			<div className={containerClassName}>
				<span className='details-span'>{_.startCase(fullName)}</span>
			</div>
		)
}
