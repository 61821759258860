import React, { useEffect } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Icon from '@material-ui/core/Icon'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Badge } from '@material-ui/core'

import clsx from 'clsx'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ListAltIcon from '@material-ui/icons/ListAlt'
import Collapse from '@material-ui/core/Collapse'

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing.unit * 2,
	},
	customBadge: {
		backgroundColor: '#65b17f',
		color: 'black',
	},
}))

const MobileDrawer = (props) => {
	return (
		// <Drawer anchor='left' open={props.isOpened} onClose={props.toggleDrawer} className={classes.drawer}>
		<NavList
			profilePicture={props.profilePicture}
			username={props.username}
			tenentName={props.tenentName}
			email={props.email}
			menushome={props.menushome}
			menusaiqa={props.menusaiqa}
			menusabtest={props.menusabtest}
			menususer={props.menususer}
			menuspr={props.menuspr}
			activeLink={props.activeLink}
			logoutFunc={props.logoutFunc}
			redirectToLink={props.redirectToLink}
			pr_match_report_access={props.pr_match_report_access}
			isOpened={props.isOpened}
		/>
		// </Drawer>
	)
}

const NavList = (props) => {
	const open = false
	const redirectToLink = props.redirectToLink
	const classes = useStyles()

	const [openIndex, setOpenIndex] = React.useState(-1)

	const handleClick = (index) => () => {
		if (index === openIndex) {
			setOpenIndex(-1)
		} else {
			setOpenIndex(index)
		}
	}

	useEffect(() => {
		if (openIndex !== -1) {
			setOpenIndex(-1)
		}
	}, [props.isOpened])

	let report_show
	if (props.pr_match_report_access === true) {
		report_show = props.menuspr
	} else {
		report_show = props.menuspr.filter((menu) => menu.menuText !== 'Report')
	}
	return (
		<div>
			<Divider></Divider>

			{/* <Divider></Divider> */}
			{!_.isEmpty(props.menuspr) && (
				<>
					<List
						component='nav'
						aria-labelledby='nested-list-subheader'
						// subheader={
						// 	<ListSubheader component='div' id='nested-list-subheader' disableSticky>
						// 		<div className='separator3'>Product Matching</div>
						// 	</ListSubheader>
						// }
					>
						{report_show.map((menu) => {
							return (
								<ListItem
									button
									key={`mobile-menu-${menu.linkTo}`}
									selected={menu.linkTo === props.activeLink}
									onClick={() => {
										redirectToLink(menu.linkTo, menu?.openNewTab)
									}}
									style={{ color: menu.linkTo === props.activeLink ? 'white' : 'white' }}
								>
									<ListItemIcon style={{ color: menu.linkTo === props.activeLink ? 'white' : 'white' }}>
										<Icon fontSize='small'>{menu.menuIcon}</Icon>
									</ListItemIcon>
									<ListItemText
										primaryTypographyProps={{ variant: 'body2', style: { color: 'white', fontWeight: 'bold', fontSize: '15px' } }}
										primary={menu.menuText}
									/>
								</ListItem>
							)
						})}
					</List>
					{/* <Divider></Divider> */}
				</>
			)}
			{!_.isEmpty(props.menushome) && (
				<List
					component='nav'
					aria-labelledby='nested-list-subheader'
					subheader={
						<ListSubheader component='div' id='nested-list-subheader' disableSticky>
							<div className='separator3' style={{ color: 'black' }}>
								AIQA
							</div>
						</ListSubheader>
					}
				>
					{props.menushome.map(({ title, titleIcon, dropdown, menus = [], menuText, openNewTab, menuIcon, linkTo }, index) =>
						dropdown === true ? (
							<React.Fragment key={index}>
								<ListItem
									className={clsx(classes.listItem, {
										[classes.listItemClose]: !open,
									})}
									button
									onClick={handleClick(index)}
								>
									<ListItemIcon style={{ color: 'white' }}>
										<Icon fontSize='small'>{titleIcon}</Icon>
									</ListItemIcon>
									<ListItemText
										primary={title}
										primaryTypographyProps={{ variant: 'body2', style: { color: 'white', fontWeight: 'bold', fontSize: '15px' } }}
									/>
									{openIndex === index ? <ExpandLess /> : <ExpandMore />}
								</ListItem>

								<Collapse in={openIndex === index} timeout='auto' unmountOnExit>
									<>
										<List component='div' disablePadding>
											{menus.map(({ menuText, linkTo, openNewTab, menuIcon }, i) => (
												<ListItem
													className={clsx(classes.listItem, {
														[classes.nested]: open,
														[classes.listItemClose]: !open,
													})}
													key={i}
													button
													selected={linkTo === props.activeLink}
													onClick={() => {
														redirectToLink(linkTo, openNewTab)
													}}
												>
													<ListItemIcon style={{ color: 'white' }}>
														<Icon fontSize='small'>{menuIcon}</Icon>
													</ListItemIcon>
													<ListItemText
														primary={menuText}
														primaryTypographyProps={{ variant: 'body2', style: { color: 'white', fontWeight: 'bold', fontSize: '15px' } }}
													/>
												</ListItem>
											))}
										</List>
									</>
								</Collapse>
							</React.Fragment>
						) : (
							<ListItem
								button
								key={`mobile-menu-${linkTo}`}
								selected={linkTo === props.activeLink}
								onClick={() => {
									redirectToLink(linkTo, openNewTab)
								}}
								style={{ color: linkTo === props.activeLink ? 'white' : 'white' }}
							>
								<ListItemIcon style={{ color: linkTo === props.activeLink ? 'white' : 'white' }}>
									<Icon fontSize='small'>{menuIcon}</Icon>
								</ListItemIcon>
								<ListItemText
									primaryTypographyProps={{ variant: 'body2', style: { color: 'white', fontWeight: 'bold', fontSize: '15px' } }}
									primary={menuText}
								/>
							</ListItem>
						)
					)}
				</List>
			)}
			{!_.isEmpty(props.menususer) && (
				<>
					<List
						component='nav'
						aria-labelledby='nested-list-subheader'
						// subheader={
						// 	<ListSubheader component='div' id='nested-list-subheader' disableSticky>
						// 		<div className='separator3'>User Management</div>
						// 	</ListSubheader>
						// }
						style={{ position: 'absolute', bottom: 0, width: '250px' }}
					>
						{props.menususer.map((menu) => {
							return (
								<ListItem
									button
									key={`mobile-menu-${menu.linkTo}`}
									selected={menu.linkTo === props.activeLink}
									onClick={() => {
										redirectToLink(menu.linkTo, menu?.openNewTab)
									}}
									style={{ color: menu.linkTo === props.activeLink ? 'white' : 'white' }}
								>
									<ListItemIcon style={{ color: menu.linkTo === props.activeLink ? 'white' : 'white' }}>
										<Icon fontSize='small'>{menu.menuIcon}</Icon>
									</ListItemIcon>
									<ListItemText
										primaryTypographyProps={{ variant: 'body2', style: { color: 'white', fontWeight: 'bold', fontSize: '15px' } }}
										primary={menu.menuText}
									/>
								</ListItem>
							)
						})}
					</List>
					{/* <Divider></Divider> */}
				</>
			)}
		</div>
	)
}

MobileDrawer.propTypes = {
	// menusList: PropTypes.arrayOf(
	// 	PropTypes.shape({
	// 		menuText: PropTypes.string.isRequired,
	// 		menuIcon: PropTypes.string.isRequired,
	// 		linkTo: PropTypes.string.isRequired,
	// 	}).isRequired
	// ).isRequired,
	activeLink: PropTypes.string,
	isOpened: PropTypes.bool.isRequired,
	toggleDrawer: PropTypes.func.isRequired,
	redirectToLink: PropTypes.func.isRequired,
	logoutFunc: PropTypes.func.isRequired,
	username: PropTypes.string,
	email: PropTypes.string,
	profilePicture: PropTypes.any,
}

export default MobileDrawer
