export const ACTION_TYPES = {
	//SNACKBAR
	SHOW_SNACKBAR: 'SHOW_SNACKBAR',
	HIDE_SNACKBAR: 'HIDE_SNACKBAR',
	SHOW_ADVANCE_SNACKBAR: 'SHOW_ADVANCE_SNACKBAR',
	HIDE_ADVANCE_SNACKBAR: 'HIDE_ADVANCE_SNACKBAR',

	//LOGIN ACTIONS CONSTANTS
	AUTH_LOGIN: 'AUTH_LOGIN',
	AUTH_LOGIN_PENDING: 'AUTH_LOGIN_PENDING',
	AUTH_LOGIN_FULFILLED: 'AUTH_LOGIN_FULFILLED',
	AUTH_LOGIN_REJECTED: 'AUTH_LOGIN_REJECTED',

	//LOGOUT ACTIONS CONSTANTS
	AUTH_LOGOUT: 'AUTH_LOGOUT',
	AUTH_LOGOUT_PENDING: 'AUTH_LOGOUT_PENDING',
	AUTH_LOGOUT_FULFILLED: 'AUTH_LOGOUT_FULFILLED',
	AUTH_LOGOUT_REJECTED: 'AUTH_LOGOUT_REJECTED',
	AUTH_LOGOUT_DISMISS_ERROR: 'AUTH_LOGOUT_DISMISS_ERROR',

	//LOGOUT ACTIONS CONSTANTS
	FETCH_LOGGED_IN_USER: 'FETCH_LOGGED_IN_USER',
	FETCH_LOGGED_IN_USER_PENDING: 'FETCH_LOGGED_IN_USER_PENDING',
	FETCH_LOGGED_IN_USER_FULFILLED: 'AFETCH_LOGGED_IN_USER_FULFILLED',
	FETCH_LOGGED_IN_USER_REJECTED: 'FETCH_LOGGED_IN_USER_REJECTED',

	//UNAUTHORIZED ERROR ACTION (INVALID TOKEN)
	UNAUTH_ERROR: 'UNAUTH_ERROR',

	//LOGGED IN USER ACTIONS
	FETCH_CURRENT_USER_PENDING: 'FETCH_CURRENT_USER_PENDING',
	FETCH_CURRENT_USER_FULFILLED: 'FETCH_CURRENT_USER_FULFILLED',
	FETCH_CURRENT_USER_REJECTED: 'FETCH_CURRENT_USER_REJECTED',
	RESET_CURRENT_USER: 'RESET_CURRENT_USER',
	UPDATE_USER_HOME_DASHBOARD: 'UPDATE_USER_HOME_DASHBOARD',

	//LOADER
	SHOW_LOADER_CONFIRM: 'SHOW_LOADER_CONFIRM',
	SHOW_LOADER: 'SHOW_LOADER',
	SHOW_LOADER_SUCCESS: 'SHOW_LOADER_SUCCESS',
	HIDE_LOADER: 'HIDE_LOADER',
	SHOW_LOADER_ERROR: 'SHOW_LOADER_ERROR',
	SHOW_LOADER_WARNING: 'SHOW_LOADER_WARNING',

	//AB TEST DASHBOARD ACTIONS
	FETCH_AB_TEST_DASH_PENDING: 'FETCH_AB_TEST_DASH_PENDING',
	FETCH_AB_TEST_DASH_FULFILLED: 'FETCH_AB_TEST_DASH_FULFILLED',
	FETCH_AB_TEST_DASH_REJECTED: 'FETCH_AB_TEST_DASH_REJECTED',
}

export const PAGE_MODES = {
	CREATE: 'create',
	EDIT: 'edit',
	VIEW: 'view',
	DELETE: 'delete',
}

export const FLIX_PRODUCTS = {
	INPAGE: { name: 'Inpage', id: 'Inpage' },
	HOTSPOTS: { name: 'Hotspots', id: 'Hotspots' },
	FLIX_AR: { name: 'Flix AR', id: 'Flix AR' },
	COMPARISION_TABLE: { name: 'Comparison Table', id: 'Comparison Table' },
	REVIEWS: { name: 'Reviews', id: 'Reviews' },
}
export const SCENERIOS = {
	ON_OFF: { name: 'On/Off', id: 'on_off' },
}
export const INPAGE_SCENERIOS = {
	ON_OFF: { name: 'On/Off', id: 'on_off' },
	TEMPLATE_VARIATION: { name: 'Template Variation', id: 'template_variation' },
}
export const KPIS = {
	TRAFFIC: { name: 'Traffic', id: 'traffic' },
	CLICK_RATE: { name: 'Click Rate', id: 'click_rate' },
	ADD_TO_CART_RATE: { name: 'Add To Cart Rate', id: 'add_to_cart_rate' },
	DWELL_TIME: { name: ' Dwell Time', id: 'dwell_time' },
}
export const PLATFORM = {
	// ALL: { name: 'All', id: 'all' },
	IOS: { name: 'Ios', id: 'ios' },
	ANDROID: { name: 'Android', id: 'android' },
}
export const DEVICE_TYPE = {
	// ALL: { name: 'All', id: 'all' },
	MOBILE: { name: 'Mobile', id: 'mobile' },
	DESKTOP: { name: 'Desktop', id: 'desktop' },
	TABLET: { name: 'Tablet', id: 'tablet' },
}
export const TRAFFIC_SPLIT_RULE = {
	FIFTY: { name: '50%:50%', id: '50%:50%' },
	DOUBLE: { name: '80%:20%', id: '80%:20%' },
}
export const TEMPLATE_TRAFFIC_SPLIT_RULE = {
	FIFTY: { name: '50%:50%', id: '50%:50%' },
	TRIPLE: { name: '33%:33%:33%', id: '33%:33%:33%' },
	DOUBLE: { name: '80%:20%', id: '80%:20%' },
}

export const TEST_LEVEL = {
	BRAND: { name: 'Brand', id: 'brand' },
	PRODUCT: { name: 'Product', id: 'product' },
	RETAILER: { name: 'Retailer', id: 'retailer' },
	RETAILER_BRAND: { name: 'Retailer & Brand', id: 'retailer_brand' },
	RETAILER_PRODUCT: { name: 'Retailer & Product', id: 'retailer_product' },
}
export const PRODUCT_MATCH_TYPE = {
	OEM: { name: 'OEM', id: 'OEM' },
	COMP: { name: 'COMP', id: 'COMP' },
}

export const RETAILER_MATCH_TYPE = {
	OEM: { name: 'OEM', id: 'dist_match' },
	COMP: { name: 'COMP', id: 'comp_match' },
}

export const RETAILER_MATCH_TYPE_PM = {
	OEM: { name: 'OEM', id: 'dist_match' },
	COMP: { name: 'COMP', id: 'comp_match' },
	ADJ: { name: 'ADJACENCY', id: 'adj_match' },
}

export const PRODUCT_REPORT_COL = {
	USER: { name: 'User', id: 'user' },
	Date: { name: 'Date', id: 'date' },
}
export const PRODUCT_REPORT_CONDI = {
	EQUAL: { name: 'Equal', id: 'equal' },
	NOT_EQUAL: { name: 'Not Equal', id: 'not_equal' },
	IS_EMP: { name: 'Is Empty', id: 'is_empty' },
	NOT_IS_EMP: { name: 'Not Is Empty', id: 'not_is_empty' },
	ANY_IN: { name: 'Any In', id: 'any_in' },
	NOT_IN: { name: 'Not In', id: 'not_in' },
}

export const API_ENDPOINTS = {
	//SESSION
	LOGIN: '/api/login',
	SISENSE_LOGIN: '/api/sisense_auth',
	LOGOUT: '/api/logout',
	PROFILE: '/api/profile',
	USER_CHANGE_PASSWORD_API: '/api/profile/change-password',
	//ROLES
	ROLE_PATH_API: '/api/roles/:roleId',
	FETCH_ALL_ROLES: '/api/roles/search',
	ROLE_API: '/api/roles',
	//USERS
	USER_PATH_API: '/api/users/:userId',
	USER_API: '/api/users',
	USER_SEARCH: '/api/users/search',
	USER_ALL_ROLES: '/api/users/roles',
	USER_ALL_TEAM_UNITS: '/api/users/team-unit',
	USER_PERMISSIONS_API: '/api/users/permissions/:userId',
	USER_DEPENDENCIES_API: '/api/users/:userId/dependencies',
	//AB Test Results
	AB_TEST_TABLE: '/api/abtest/dt',
	MOVE_STATUS: '/api/abtest/movestatus/:testId',
	DELETE_TEST: '/api/abtest/:testId',
	AB_TEST_DASH: '/api/abtest/dash',

	//AB Test Tool
	CREATE_TEST: '/api/abtest',
	EDIT_TEST: '/api/abtest/:id',
	FETCH_BRANDS: '/api/abtest_config/brand',
	FETCH_COUNTRIES: '/api/abtest_config/country',
	FETCH_RETAILERS: '/api/abtest_config/retailers',
	FETCH_PRODUCTS: '/api/abtest_config/products',
	VIEW_TEST: '/api/abtest/view/:id',
	S3_UPLOAD: '/api/abtest/s3_upload/:id',

	//PR Matching
	GET_PRODUCTS: '/api/pr_matching/es_search',
	GET_MATCHMISS: '/api/pr_matching/match_miss',
	GET_FILTERS: '/api/pr_matching/filter_data/:filter',
	FILTER_ONCHANGE: '/api/pr_matching/filter_onchange/:filter',
	MISS_SAVE: '/api/pr_matching/matchmiss_saved',
	MISS_SKIP: '/api/pr_matching/matchmiss_skip',
	GET_REPORT_USR: '/api/pr_matching/pr_report_usr',
	GET_REPORT: '/api/pr_matching/pr_report',
	REPORT_EXPORT: '/api/pr_matching/pr_report_export',
	MATCH_DISTRIBUTOR: '/api/pr_matching/tech_sync_retailers',
	MATCH_SUBMIT: '/api/pr_matching/tech_match_sync',
	FETCH_PRODUCTS_PR: '/api/pr_matching/products',
	LIST_PR: '/api/pr_matching/products-restrictions',
	BULK_MAT_VALID: '/api/pr_matching/bulk-match-sync/validation',
	BULK_MAT_SUBMIT: '/api/pr_matching/bulk-match-sync',
	MATCH_FILTER_GET: '/api/pr_matching/match/filter_data/:filter',
	MATCH_FILTER_ONCHANGE: '/api/pr_matching/match/filter_onchange/:filter',
	MATCH_HISTORY_DATA: '/api/pr_matching/fetch_match_sync',

	//AIQA
	//Master
	ADD_NEW_CAT: '/api/aiqa_config/dimension_cat/:cat',
	CAT_LIST: '/api/aiqa_config/dimension_cat/dt/:cat',
	PARENT_CAT_LIST: '/api/aiqa_config/dimension_cat/list/:cat',

	ADD_NEW_BB_CAT: '/api/aiqa_config/bb_cat/:cat',
	BB_CAT_LIST: '/api/aiqa_config/bb_cat/dt/:cat',
	PARENT_BB_CAT_LIST: '/api/aiqa_config/bb_cat/list/:cat',

	ADD_NEW_BRAND: '/api/aiqa_config/bb_brand/:brand',
	BRAND_LIST: '/api/aiqa_config/bb_brand/dt/:brand',
	PARENT_BRAND_LIST: '/api/aiqa_config/bb_brand/list/:brand',
	//Dimension
	GET_PROD_LIST: '/api/aiqa_dimensions/product_master/dt',
	GET_PROD_FORM: '/api/aiqa_dimensions/pr_edit/:id',
	GET_DIST_LIST: '/api/aiqa_dimensions/dist_master/dt',
	GET_DIST_FORM: '/api/aiqa_dimensions/dist_edit/:id',
	GET_RPT_SUMMARY: '/api/aiqa_dimensions/report_summary/:report',
	GET_RPT_PIVOT: '/api/aiqa_dimensions/weekly_report/:report',
	PRD_EXPORT: '/api/aiqa_dimensions/product_master/export/:exp',
	DIST_EXPORT: '/api/aiqa_dimensions/dist_master/export/:exp',
	//BB
	GET_BUC_DETAILS: '/api/aiqa_bb/prediction/data-flow-settings/:for/:id',
	GET_BATCH: '/api/aiqa_bb/prediction/data-batch-settings/:for/:batch',
	PUT_BATCH: '/api/aiqa_bb/prediction/data-batch-settings/:for',
	GET_SUMMARY_PREDICT: '/api/aiqa_bb/prediction/summary/:for',
	GET_PREDICTION_QA: '/api/aiqa_bb/qa_prediction/:for',
	ERROR_REPORT: '/api/aiqa_bb/error_report/:for',
	SKIP_SAVE_PREDICT: '/api/aiqa_bb/qa_prediction/:for/:id',
	PREV_PREDICT: '/api/aiqa_bb/prev_qa_prediction/:for/:id',
	GET_RPT_USR_BATCH: '/api/aiqa_bb/prediction_report/filter/:for/:filter',
	GET_BB_REPORT: '/api/aiqa_bb/prediction_report/:for',
	BB_REPORT_EXPORT: '/api/aiqa_bb/prediction_report_export/:for',
	//PR
	GET_PR_BUC_DETAILS: '/api/aiqa_pr_matching/data-flow-settings/:id',
	GET_PR_BATCH: '/api/aiqa_pr_matching/data-batch-settings/:batch',
	PUT_PR__BATCH: '/api/aiqa_pr_matching/data-batch-settings',
	GET_PR__SUMMARY_PREDICT: '/api/aiqa_bb/prediction/summary',
	GET_PR__PREDICTION_QA: '/api/aiqa_pr_matching/matchmiss_prediction',
	PR_ERROR_REPORT: '/api/aiqa_bb/error_report',
	PR_SKIP_SAVE_PREDICT: '/api/aiqa_pr_matching/qa_prediction/:id',
	PR_PREV_PREDICT: '/api/aiqa_pr_matching/prev_qa/:id',
	PARENT_PR_CAT_LIST: '/api/aiqa_config/pr_matching_qa_cat',
	//BOT
	GET_BOT_FILTERS: '/api/bot_dash/filter_data/:filter',
	GET_BOT_TABLE: '/api/bot_dash/dt',
	BOT_FILTER_ONCHANGE: '/api/bot_dash/filter_onchange/:filter',
	CHART_DATA: '/api/bot_dash',
	SUMMARY_DATA: '/api/bot_dash/summary',
}

export const SAGA_ACTIONS = {
	//FETCH CURRENT USER PROFILE
	FETCH_PROFILE: 'FETCH_PROFILE',
	UPDATE_USER_PASSWORD: 'SAGA_UPDATE_USER_PASSWORD',

	FETCH_ALL_ROLES: 'SAGA_FETCH_ALL_ROLES',
	CREATE_ROLE: 'SAGA_CREATE_ROLE',
	FETCH_ROLE: 'SAGA_FETCH_ROLE',
	UPDATE_ROLE: 'SAGA_UPDATE_ROLE',
	DELETE_ROLE: 'SAGA_DELETE_ROLE',

	CREATE_NEW_USER: 'CREATE_NEW_USER',
	UPDATE_USER: 'SAGA_UPDATE_USER',
	UPDATE_USER_PERMISSIONS: 'SAGA_UPDATE_USER_PERMISSIONS',
	FETCH_ALL_USERS: 'SAGA_FETCH_ALL_USERS',
	FETCH_USER: 'SAGA_FETCH_USER',
	FETCH_USER_PERMISSIONS: 'SAGA_FETCH_USER_PERMISSIONS',
	DELETE_USER: 'SAGA_DELETE_USER',

	//AB Test Results
	AB_TEST_TABLE: 'SAGA_AB_TEST_TABLE',
	MOVE_STATUS: 'SAGA_MOVE_STATUS',
	DELETE_TEST: 'SAGA_DELETE_TEST',
	AB_TEST_DASH: 'SAGA_AB_TEST_DASH',
}

export const LOADER_MODE = {
	CONFIRM: 'confirm',
	LOADING: 'loading',
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
}

export const APP_DEFAULT_ROLES = {
	ADMIN: 'admin',
}
