import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import { put, call, takeLatest ,takeEvery} from 'redux-saga/effects'
import { cancelled } from 'redux-saga/effects'
import api from '../api'
import Axios from 'axios'
import { hideLoader, showErrorMessage, showLoader} from '../../../services/loader/actions'
import { getErrMsg} from '../../../utils'


function* fetchProfile(action) {
	const cancelSource = Axios.CancelToken.source()
	yield put({ type: ACTION_TYPES.FETCH_CURRENT_USER_PENDING })
	try {
		const resp = yield call(api.fetchProfile, cancelSource)
		yield put({ type: ACTION_TYPES.FETCH_CURRENT_USER_FULFILLED, payload: resp })
	} catch (err) {
		yield put({ type: ACTION_TYPES.FETCH_CURRENT_USER_REJECTED, payload: err })
	} finally {
		if (yield cancelled()) {
			cancelSource.cancel('cancelled')
		}
	}
}

function* fetchProfileWatcher() {
	yield takeLatest(SAGA_ACTIONS.FETCH_PROFILE, fetchProfile)
}

function* changeCurrentUserPwd(action) {
	yield put(showLoader('Please wait changing password...'))
	const { formData, successCallback, dialogCloseBtnCallback } = action.payload
	try {
		const resp = yield call(api.changeCurrentUserPassword, formData)
		yield put(hideLoader())
		yield call(successCallback, resp)
	} catch (err) {
		yield put(showErrorMessage(getErrMsg(err), 'Close', dialogCloseBtnCallback))
	}
}

function* changeCurrentUserPwdWatcher() {
	yield takeEvery(SAGA_ACTIONS.UPDATE_USER_PASSWORD, changeCurrentUserPwd)
}


const sagaGenerators = [fetchProfileWatcher(),changeCurrentUserPwdWatcher(),]

export default sagaGenerators
