
import React, { useCallback, useMemo } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import { PAGE_MODES } from '../../../../constants'

const getFileExtension = (file) => {
	const fileName = file.name
	return fileName.substring(fileName.lastIndexOf('.'), fileName.length)
}

const validateProfilePic = async (file, acceptsExts, acceptRes, isTestRatio, isMandatory) => {
	return new Promise((resolve, reject) => {
		const checkResolution = (file, acceptRes) => {
			return new Promise((resolve, reject) => {
				let img = new Image()
				img.src = URL.createObjectURL(file)
				img.onload = () => {
					if (isTestRatio) {
						let neededRatio = acceptRes[0] / acceptRes[1]
						let currRatio = img.width / img.height
						let ratioMatchPercentage = currRatio / neededRatio
						let isRatioTestPass = ratioMatchPercentage <= 1.05 && ratioMatchPercentage >= 0.95
						resolve(isRatioTestPass && img.width >= acceptRes[0] && img.height >= acceptRes[1])
					} else {
						resolve(img.width === acceptRes[0] && img.height === acceptRes[1])
					}
				}
			})
		}

		//If file is already uploaded
		if (file && typeof file === 'string') resolve()
		else if (!file) {
			if (isMandatory) resolve('Choose an Image!')
			else resolve('')
		} else if (!_.isEmpty(acceptsExts) && acceptsExts.indexOf(getFileExtension(file).toLowerCase()) === -1) {
			resolve(`Image file should be ${_.join(acceptsExts, ' (or) ')} format!`)
		} else if (!_.isEmpty(acceptRes)) {
			checkResolution(file, acceptRes).then((isValidRes) => {
				if (!isValidRes) {
					resolve(`Image file should be minimum ${_.join(acceptRes, 'X')} or higher resolution with 1:1 Aspect ratio`)
				} else {
					resolve()
				}
			})
		}
	})
}
// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData)
		? {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				role_id: [],
				// profile_picture: null,
				is_active: true,
				password: '',
				retype_password: '',
		  }
		: props.initialFormData

	const validateField = (fieldName, fieldLabel, value, regex, errors, customMessage) => {
		if (_.isEmpty(value) || _.isEmpty(_.trim(value))) {
			_.set(errors, fieldName, `Please enter the ${fieldLabel}.`)
		} else {
			if (regex && !regex.test(value)) {
				let message = customMessage || `${fieldLabel} is Invalid`
				_.set(errors, fieldName, message)
				// if (_.isEqual(fieldName, 'retype_password')) {
				// 	_.set(errors, fieldName, customMessage || `${fieldLabel} is Invalid`)
				// } else {
				// 	_.set(
				// 		errors,
				// 		fieldName,
				// 		customMessage || _.isEqual(fieldName, 'password')
				// 			? `${fieldLabel} Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character`
				// 			: `${fieldLabel} is Invalid`
				// 	)
				// }
			}
		}
	}

	const validateForm = useCallback(
		async (values) => {
			return validateProfilePic(values.profile_picture, ['.jpg', '.png', '.jpeg'], [240, 240], true, false)
				.then((profilePicErr) => {
					let errors = {}

					if (profilePicErr) {
						errors.profile_picture = profilePicErr
						ref.current.setFieldValue('profile_picture', null, false)
					}

					validateField('first_name', 'First name', _.get(values, 'first_name'), /^[a-zA-Z ]{2,30}$/, errors)

					const lastName = _.get(values, 'last_name')
					if (lastName) validateField('last_name', 'Last name', lastName, /^[a-zA-Z ]{1,30}$/, errors)

					validateField('email', 'Email address', _.get(values, 'email'), /\S+@\S+\.\S+/, errors)

					const phoneNo = _.get(values, 'phone')
					if (phoneNo) validateField('phone', 'Phone number', _.get(values, 'phone'), /^\d+$/, errors)

					const password = _.get(values, 'password')
					const retypePassword = _.get(values, 'retype_password')
					if (password)
						validateField(
							'password',
							'Password',
							_.get(values, 'password'),
							passwordRegex,
							errors,
							'Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
						)
					if (retypePassword) {
						validateField(
							'retype_password',
							'Re-type Password',
							_.get(values, 'retype_password'),
							new RegExp(password + '$'),
							errors,
							'Password & Re-type password does not match'
						)
					}

					throw errors
				})
				.catch((errors) => {
					return errors
				})
		},
		[ref]
	)

	//Initial validation solution for edit screen
	const formExtraProps = useMemo(() => {
		return props.formMode === PAGE_MODES.EDIT ? { validateOnMount: validateForm(initialValues) } : {}
	}, [props.formMode, initialValues, validateForm])

	return (
		<Formik innerRef={ref} initialValues={initialValues} validate={validateForm} onSubmit={props.onSubmit || null} {...formExtraProps}>
			{props.children}
		</Formik>
	)
})

export default Validator
