import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles({
	tag: {
		//   height: 24,
		//   position: "relative",
		//   zIndex: 0,
		//   "& .MuiChip-label": {
		// 	color: "#fff",
		//   },
		'& .MuiChip-deleteIcon': {
			color: 'black',
		},
		//   "&:after": {
		// 	content: '""',
		// 	right: 10,
		// 	top: 6,
		// 	height: 12,
		// 	width: 12,
		// 	position: "absolute",
		// 	backgroundColor: "white",
		// 	zIndex: -1,
		//   },
	},
	inputText: {
		'&::placeholder': {
			fontFamily: 'FS-Jack',
			fontStyle: 'normal',
			fontWeight: 400,
		},
	},
})

export default function SelectBox(props) {
	const {
		label = '',
		inputClass = '',
		isLoading = false,
		disabled = false,
		disableClearable = true,
		isError = false,
		errTxt = '',
		helperText = '',
		options = [],
		onOpen = () => null,
		onBlur = () => null,
		onChange = () => null,
		getSelectedLabel = () => null,
		onInputChange = () => null,
		name = '',
		value = '',
		size = 'small',
		startAdornment = null,
		freeSolo = false, //defaultValue
		placeholder = '',
		margin = 'normal',
		labelKey = 'name',
		valueKey = 'id',
		noOptionsText = '',
		textFieldStyle = null,
	} = props
	const valueReturner = (value) => (value ? value[valueKey] : '')
	const classes = useStyles()
	const labelReturner = (label) => (label ? label[labelKey] : '')
	const defaultOption = !_.isEmpty(options) && !_.isEmpty(value) ? _.filter(options, (option) => option[valueKey] === value)[0] : null //{name:"", id:""};

	return (
		<Autocomplete
			id={name}
			name={name}
			freeSolo={freeSolo || false}
			noOptionsText={noOptionsText}
			onOpen={onOpen}
			onBlur={onBlur}
			size={size || 'small'}
			onChange={(_, value) => {
				onChange(valueReturner(value), _)
				getSelectedLabel(labelReturner(value), _)
			}}
			onInputChange={(e) => {
				onInputChange(e)
			}}
			getOptionSelected={(option, value) => option[labelKey] === _.get(value, labelKey, '') || option[labelKey] === value}
			getOptionLabel={(option) => option[labelKey]}
			options={options}
			loading={isLoading}
			value={defaultOption}
			disableClearable={disableClearable}
			disabled={isLoading || disabled}
			className={inputClass}
			renderOption={(option, props) => {
				const { name } = option
				return (
					<div style={{ fontFamily: 'FS-Jack', fontStyle: 'normal', fontWeight: 400 }}>
						{/* {labelReturner(option)} */}
						{name}
					</div>
				)
			}}
			// renderOption={(props, option) => <>{option.name}</>}
			renderInput={(params) => (
				<TextField
					{...params}
					error={isError}
					size={size || 'small'}
					style={textFieldStyle}
					name={name}
					helperText={isError && errTxt ? errTxt : helperText}
					label={label}
					placeholder={placeholder}
					variant='outlined'
					margin={margin}
					InputProps={{
						classes: { input: classes.inputText },
						...params.InputProps,
						disabled: isLoading || disabled,
						readOnly: disabled,
						startAdornment: startAdornment,
						endAdornment: (
							<React.Fragment>
								{isLoading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
					fullWidth
				/>
			)}
		/>
	)
}
