import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormLabel,
	TextField,
	Button,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgGridCustom from '../../../../components/AgGridCustom'
import { PAGE_MODES } from '../../../../constants'
import { PERMISSION_TYPE } from '../../../../constants/modules'
import ListPageFilterSection from '../../../../components/ListPageFilterSection'
import { hideLoader, showLoader, showConfirmMessage } from '../../../../services/loader/actions'
import Api from '../../../../services/aiqa/dimension/api'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import { isAxiosCancelError } from '../../../../utils'
import Query from './QueryBuilder'
import {
	ActionCellRenderer,
	CreatedCellRenderer,
	UpdatedCellRenderer,
	EmailCellRenderer,
	RoleCellRenderer,
	StatusCellRenderer,
	withCellRenderState,
} from './CustomCells'
import MultiSelect from '../../../../components/MultiSelect'

import MaterialConfig from 'react-awesome-query-builder/lib/config/material'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	switchLabel: {
		fontSize: '14px',
	},
	radioFormLabel: {
		fontSize: '15px',
		// marginBottom: theme.spacing(0.5),
		marginTop: '11px',
	},
	groupBorder: {
		border: 'ridge',
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		justifyContent: 'flex-end',
	},
	filterInc: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		flexDirection: 'row-reverse',
		// border: 'ridge',
	},
	grpInc: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		flexDirection: 'row-reverse',
		border: 'ridge',
	},
	groupButton: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		marginLeft: '450px',
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	statusCellRoot: {
		verticalAlign: 'middle',
		'& span': {
			width: '8px',
			height: '8px',
			display: 'inline-block',
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		},
	},
	emailCellRoot: {
		verticalAlign: 'middle',
		'& > span': {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(1),
			fontSize: '18px',
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
}))

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}))

const TablePaginationActions = (props) => {
	const classes = useStyles1()
	const theme = useTheme()
	const { count, page, rowsPerPage, onChangePage } = props

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0)
	}

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1)
	}

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1)
	}

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<div className={classes.root}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='next page'>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='last page'>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	)
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
}

const Report = ({ width, action, userId, history, perms }) => {
	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		search_text: '',
		offset: 0,
		limit: 10,
		roleFilter: [],
		totalCount: 0,
		userList: [],
	})

	const [roleListState, setRoleListState] = useState({
		isFetchRoles: true,
		roleList: null,
	})
	const [currentUser, setCurrentUser] = useState('All')
	const [searchQuery, setsearchQuery] = useState('')
	const [actionType, setactionType] = useState('create')
	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		selectedData: null,
	})

	const { open, anchorEl, selectedData } = popOverState

	const isEditAllowed = _.get(perms, PERMISSION_TYPE.EDIT, false)
	const isDeleteAllowed = _.get(perms, PERMISSION_TYPE.DELETE, false)

	const InitialConfig = MaterialConfig

	InitialConfig.settings = {
		...InitialConfig.settings,
		...{
			addRuleLabel: 'Add Filter',
			showNot: false,
		},
		customFieldSelectProps: {
			showSearch: true,
		},
	}

	const config = {
		...InitialConfig,
		fields: {
			product_id: { label: 'Product Id', excludeOperators: ['not_between'], valueSources: ['value'], type: 'number' },
			manufacturer_id: { label: 'Manufacture Id', excludeOperators: ['not_between'], valueSources: ['value'], type: 'number' },
			product_title: { label: 'Product Title', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			brand_title: { label: 'Brand title', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			mpn_list: { label: 'MPN List', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			cat_name_root: { label: 'Cat Root', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			cat_name_level1: { label: 'Cat Lvl 1', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			cat_name_level2: { label: 'Cat Lvl 2', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			cat_name_level3: { label: 'Cat Lvl 3', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			cat_name_level4: { label: 'Cat Lvl 4', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			parent_cat: { label: 'Parent Cat', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			sub_cat_lvl1: { label: 'Sub Cat Lvl 1', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
			sub_cat_lvl2: { label: 'Sub Cat Lvl 2', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },

			updated_date: {
				label: 'Updated Date',
				type: 'date',
				valueSources: ['value'],
				preferWidgets: ['date'],
				operators: ['between'],
				fieldSettings: {
					dateFormat: 'DD-MM-YYYY',
					valueFormat: 'YYYY-MM-DD',
					validateValue: (val) => !!val,
				},
			},
			updated_by: { label: 'Updated By', excludeOperators: ['proximity'], valueSources: ['value'], type: 'text' },
		},
	}

	const HIDE_SHOW = {
		ID: { name: 'Id', id: 'id' },
		PROD_ID: { name: 'Product Id', id: 'product_id' },
		MANU_ID: { name: 'Manufacture Id', id: 'manufacturer_id' },
		PROD_TITLE: { name: 'Product Title', id: 'product_title' },
		DESC: { name: 'Description', id: 'description' },
		BRAND_TITLE: { name: 'Brand Title', id: 'brand_title' },
		MPN: { name: 'MPN List', id: 'mpn_list' },
		CAT: { name: 'Cat.Root', id: 'cat_root' },
		CAT1: { name: 'Cat Lvl 1', id: 'cat_lvl1' },
		CAT2: { name: 'Cat Lvl 2', id: 'cat_lvl2' },
		CAT3: { name: 'Cat Lvl 3', id: 'cat_lvl3' },
		CAT4: { name: 'Cat Lvl 4', id: 'cat_lvl4' },
		CAT5: { name: 'Cat Lvl 5', id: 'cat_lvl5' },
		CAT6: { name: 'Cat Lvl 6', id: 'cat_lvl6' },
		CAT7: { name: 'Cat Lvl 7', id: 'cat_lvl7' },
		PARENT_CAT: { name: 'Parent Category', id: 'parent_cat' },
		SUB1: { name: 'Sub Cat Lvl 1', id: 'sub_cat_lvl1' },
		SUB2: { name: 'Sub Cat Lvl 2', id: 'sub_cat_lvl2' },
		UPD_DATE: { name: 'Updated Date', id: 'updated_date' },
		UPD_BY: { name: 'Updated By', id: 'updated_by' },
	}

	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))
	const [showVal, setshowVal] = useState(_.values(HIDE_SHOW))
	// const [openFormDialog, setopenFormDialog] = useState(false)
	const { search_text, loading, offset, totalCount, limit, roleFilter, rptStatusFilter, userList } = usersTblState
	const { isFetchRoles, roleList } = roleListState

	const isMounted = useRef(true)
	const searchBoxTimeout = useRef(undefined)
	const dispatch = useDispatch()

	const openFormDialog = !_.isEmpty(action)

	const changeToEditMode = (userId) => {
		history.replace({
			pathname: `/dimension_category/parent_category/${PAGE_MODES.EDIT}/${userId}`,
		})
	}

	const handleCloseFormDialog = (e, isRefreshList) => {
		history.replace({
			pathname: `/dimension_category/parent_category`,
			isRefreshList: _.isEqual(isRefreshList, true),
		})
	}

	const classes = useStyles()

	const closePopover = () => {
		setPopOverState({ ...popOverState, open: false })
	}

	const onClickMoreOptions = useCallback((event, data) => {
		setPopOverState({
			open: true,
			anchorEl: event.currentTarget,
			selectedData: data,
		})
	}, [])

	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		let gridData = [
			{
				headerName: 'Id',
				field: 'id',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Product Id',
				field: 'product_id',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Manufacture Id',
				field: 'manufacturer_id',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Product Title',
				field: 'product_title',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Description',
				field: 'description',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Brand Title',
				field: 'brand_title',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'MPN List',
				field: 'mpn_list',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Cat.Root',
				field: 'cat_root',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Cat Lvl 1',
				field: 'cat_lvl1',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Cat Lvl 2',
				field: 'cat_lvl2',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Cat Lvl 3',
				field: 'cat_lvl3',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Cat Lvl 4',
				field: 'cat_lvl4',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Cat Lvl 5',
				field: 'cat_lvl5',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Cat Lvl 6',
				field: 'cat_lvl6',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Cat Lvl 7',
				field: 'cat_lvl7',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Parent Category',
				field: 'parent_cat',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Sub Cat Lvl 1',
				field: 'sub_cat_lvl1',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Sub Cat Lvl 2',
				field: 'sub_cat_lvl2',
				minWidth: 200,
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			// {
			// 	headerName: 'Created Date',
			// 	field: 'created_date',
			// 	minWidth: 200,
			// 	// cellRenderer: 'roleCellRenderer',
			// 	cellRendererParams: { roleList },
			// 	hide: !isWidthUp('md', width),
			// },
			// {
			// 	headerName: 'Created By',
			// 	field: 'processed_date',
			// 	minWidth: 200,
			// 	cellRenderer: 'createdCellRenderer',
			// 	cellRendererParams: {
			// 		containerClass: nameCellContent,
			// 		authToken: authToken,
			// 		isRenderMobView: _.isEqual('xs', width),
			// 	},
			// 	cellRendererParams: { roleList },
			// 	hide: !isWidthUp('md', width),
			// },
			{
				headerName: 'Updated Date',
				field: 'updated_date',
				minWidth: 200,
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Updated By',
				field: 'updated_by',
				minWidth: 200,
				// cellRenderer: 'updatedCellRenderer',
				// cellRendererParams: {
				// 	containerClass: nameCellContent,
				// 	authToken: authToken,
				// 	isRenderMobView: _.isEqual('xs', width),
				// },
				hide: !isWidthUp('md', width),
			},
			// {
			// 	headerName: '',
			// 	field: '',
			// 	minWidth: 100,
			// 	cellRenderer: 'actionCellRenderer',
			// 	cellRendererParams: {
			// 		containerClass: actionCellContainer,
			// 		onClickMoreOptions,
			// 	},
			// },
		]

		var lookup = _.keyBy(showVal, function (o) {
			return o.id
		})

		return _.filter(gridData, function (u) {
			return lookup[u.field] !== undefined
		})
	}, [width, classes, authToken, onClickMoreOptions, roleList, showVal])

	const addBtnClick = () => {
		history.replace({ pathname: `/dimension_category/parent_category/create` })
	}

	const onClickEditMenu = () => {
		closePopover()
		let idx = _.get(selectedData, 'id')
		// setactionType('edit')
		// changeToEditMode(_.get(selectedData, 'id'))
		history.replace({
			pathname: `/aiqa_tool/dimension/products/form/${PAGE_MODES.EDIT}/${idx}`,
		})
	}

	const onClickPermMenu = () => {
		closePopover()
		let idx = selectedData?.test_id
		history.push(`/ab_test_tool/view_test/${idx}`)
	}

	const deleteRoleFunc = useCallback(
		// (roleId) => {
		// 	dispatch(
		// 		deleteRole(
		// 			roleId,
		// 			() => {
		// 				//success
		// 				dispatch(hideLoader())
		// 				setRoleListState((prevState) => ({
		// 					...prevState,
		// 					loading: true,
		// 					roleList: [],
		// 				}))
		// 				// dispatch(showSnackbarWithTimeout('Deleted successfully!', 2000))
		// 			},
		// 			() => {
		// 				dispatch(hideLoader())
		// 			}
		// 		)
		// 	)
		// },
		[dispatch]
	)

	const onClickDeleteMenu = useCallback(() => {
		closePopover()
		dispatch(
			showConfirmMessage(
				// `Do you want to delete the "${_.get(selectedData, 'role_name')}" role?`,
				`Are you sure you want to delete this Sub Category?`,
				'',
				'Confirm',
				(e) => {
					dispatch(hideLoader())
					deleteRoleFunc(_.get(selectedData, 'role_id'))
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}, [dispatch, closePopover, deleteRoleFunc, selectedData])

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const onChangeSearchBox = (e) => {
		const search_text = e.target.value
		if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
		searchBoxTimeout.current = setTimeout(() => {
			addFilterAndRefreshTbl({ search_text, offset: 0 })
		}, 300)
	}

	const fetchReportList = ({ limit, offset, searchQuery }) => {
		// dispatch(showLoader('Please wait, getting reports'))
		Api.GetProdList(
			{
				limit,
				offset,
				search_query: searchQuery,
				is_skipped: false,
				is_updated: true,
			},
			(resp) => {
				const totalCount = _.get(resp, 'data.data.total_count', 0)
				const userList = _.get(resp, 'data.data.pr_datas', [])
				// const userList = results
				if (isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount,
						userList,
					}))
				}
				// dispatch(hideLoader())
			},
			(err) => {
				if (!isAxiosCancelError(err) && isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: 0,
						userList: [],
					}))
				}
				// dispatch(hideLoader())
			}
		)
	}

	const exportList = ({ searchQuery, currentUser }) => {
		dispatch(
			showLoader('The Exporting process has been started and the Exported details of the product will be downloaded once it is exported completely')
		)
		Api.PrdExport(
			{
				search_query: searchQuery,
			},
			'updated',
			(response) => {
				const filename = response.headers['content-disposition'].split('filename=')[1]
				const contentType = response.headers['content-type']
				// response.data.then((blob) => {
				let blob = new Blob([response.data], { type: contentType })
				let url = window.URL.createObjectURL(blob)
				let a = document.createElement('a')
				a.href = url
				a.download = filename
				a.click()
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout('Something went wrong.', 2000))
			}
		)
	}

	const usePrevious = (value) => {
		const ref = useRef()
		useEffect(() => {
			ref.current = value
		})
		return ref.current
	}

	const prevQuery = usePrevious({ searchQuery })

	if (!_.isEmpty(prevQuery?.searchQuery) && _.isEmpty(searchQuery)) {
		fetchReportList({
			limit,
			offset,
			searchQuery: '',
		})
	}

	useEffect(() => {
		// if (_.isNull(roleList)) fetchRolesList()
		// else
		fetchReportList({
			limit,
			offset,
			searchQuery,
		})
	}, [limit, offset])

	const onFilterClick = () => {
		fetchReportList({
			limit,
			offset,
			searchQuery,
		})
	}
	const onClearClick = (searchQuery) => {
		setsearchQuery(searchQuery)
		fetchReportList({
			limit,
			offset,
			searchQuery,
		})
	}

	const onExportClick = () => {
		exportList({
			searchQuery,
			currentUser,
		})
	}

	//Refresh function start
	const isNeedRefreshList = _.get(history, 'location.isRefreshList', false)
	useEffect(() => {
		if (isNeedRefreshList === true) {
			setUsersTblState((prevState) => {
				fetchReportList({
					limit: 10,
					offset: 0,
					search_text: prevState.search_text,
					roleFilter: [],
				})
				return {
					...prevState,
					loading: true,
					offset: 0,
					userList: [],
					roleFilter: [],
				}
			})
			_.set(history, 'location.isRefreshList', false)
		}
	}, [isNeedRefreshList, fetchReportList, history])
	//Refresh function end

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<Grid container className={classes.root} item xs={12}>
			<ListPageFilterSection
				// disabled={true}
				searchPlaceholder={'Search By Category Name...'}
				addBtnClick={addBtnClick}
				// hideAddBtn={!isCreateAllowed}
				onChangeSearchBox={onChangeSearchBox}
				addBtnTxt={'Add Category'}
				hideAddBtn={true}
				hideSearch={true}
			>
				<div className={classes.groupBorder}>
					<Query
						config={config}
						searchQuery={searchQuery}
						setsearchQuery={setsearchQuery}
						onClearClick={onClearClick}
						onFilterClick={onFilterClick}
						onExportClick={onExportClick}
					/>
				</div>
			</ListPageFilterSection>
			<Grid item xs={4}>
				<MultiSelect
					items={_.values(HIDE_SHOW)}
					// getOptionDisabled={getOptionDisabled}
					label='Hide/Show'
					disableClearable={true}
					// onBlur={handleBlur}
					name='secondary_kpi'
					selectAllLabel=''
					// error={errors?.secondary_kpi && touched.secondary_kpi}
					// helperText={errors?.secondary_kpi && touched.secondary_kpi && errors?.secondary_kpi}
					// value={secondary}
					value={showVal}
					onChange={(e) => setshowVal(e)}
					// disabled={disabled}
				/>
				<br />
			</Grid>
			<Grid item xs={12}>
				<div>
					<AgGridCustom
						columnDefs={gridHeaders}
						rowData={userList}
						loading={loading}
						enableCellTextSelection={true}
						frameworkComponents={{
							createdCellRenderer: withCellRenderState(CreatedCellRenderer),
							updatedCellRenderer: withCellRenderState(UpdatedCellRenderer),
							roleCellRenderer: withCellRenderState(RoleCellRenderer),
							statusCellRenderer: withCellRenderState(StatusCellRenderer),
							emailCellRenderer: withCellRenderState(EmailCellRenderer),
							actionCellRenderer: withCellRenderState(ActionCellRenderer),
						}}
						disableClickSelectionRenderers={['actionCellRenderer']}
						// onRowClicked={onRowClicked}
						rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
						noDataTxt={'No products found'}
					></AgGridCustom>
				</div>
				<div>
					{!loading && (
						<TablePagination
							component='div'
							count={totalCount}
							page={offset / limit}
							onChangePage={onChangePage}
							rowsPerPage={limit}
							onChangeRowsPerPage={onChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					)}
					<Popover
						id={'more_options'}
						open={open}
						anchorEl={anchorEl}
						onClose={closePopover}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuList autoFocusItem={open} id='options_list'>
							{isEditAllowed && (
								<MenuItem dense={true} onClick={onClickEditMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											edit
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Edit
									</Typography>
								</MenuItem>
							)}
						</MenuList>
					</Popover>
				</div>
			</Grid>
		</Grid>
	)
}

Report.propTypes = {
	action: PropTypes.string,
	userId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Report)
