
import { ACTION_TYPES } from '../../../constants'

export const showSnackbarWithTimeout = (msg, timeoutMillisec) => (dispatch) => {
	//show snackbar
	dispatch({ type: ACTION_TYPES.SHOW_SNACKBAR, payload: { msg } })
	setTimeout(() => {
		dispatch({ type: ACTION_TYPES.HIDE_SNACKBAR })
	}, timeoutMillisec)
}
