import React, { useEffect, useState } from 'react'
import { FormControlLabel, Radio } from '@material-ui/core'
import moment from 'moment'
import _ from 'lodash'
import DateInput from '../../../../components/DateInput'
import ListItemLabel from '../Components/ListItemLabel'

const getProperDate = (d, isEnd = false) =>
	!!d ? (moment(d).isValid() ? d : moment()) : moment()?.[isEnd ? 'endOf' : 'startOf']('day').format('YYYY-MM-DD HH:mm:ss')

const DateRangePicker = ({ updateFunc = () => null, isSelected = false, selected, selectedKey = '', classes, path = '' }) => {
	const [dates, setDates] = useState({
		startDate: undefined,
		endDate: undefined,
		key: 'custom_dates_start',
		clickedTimes: 0,
	})
	const [isPickerOpen, setIsPickerOpen] = useState(false)

	const handleClick = (e) => {
		if (isPickerOpen) {
			setIsPickerOpen(false)
		} else {
			setIsPickerOpen(true)
		}
	}

	const onDateInputChange = (dates) => {
		if (dates?.clickedTimes !== 1) {
			updateFunc(
				path,
				{
					from: moment(dates?.startDate).startOf('d').format('YYYY-MM-DD HH:mm:ss'),
					to: moment(dates?.endDate).endOf('d').format('YYYY-MM-DD HH:mm:ss'),
				},
				false,
				false
			)
			setIsPickerOpen(false)
		}
		setDates((prevState) => ({
			...prevState,
			startDate: getProperDate(_.get(dates, 'startDate')),
			endDate: getProperDate(_.get(dates, 'endDate'), true),
		}))
	}

	useEffect(() => {
		setDates({
			startDate: getProperDate(_.get(selected, 'startDate', _.get(selected, 'from')) || undefined),
			endDate: getProperDate(_.get(selected, 'endDate', _.get(selected, 'to')) || undefined, true),
			key: 'custom_dates_start',
			clickedTimes: 0,
		})
	}, [selected])

	return (
		<>
			<div key={`custom_date_picker_in_filter_panel`} className={classes.optionListItemContainer} role='button' onClick={handleClick}>
				<FormControlLabel
					classes={{ label: classes.optionItemLabel }}
					checked={isSelected}
					value='end'
					control={<Radio className={classes.optionItemRadio} color='primary' />}
					label={<ListItemLabel classes={classes} text='Custom' />}
				/>
				{(Boolean(isPickerOpen) || !!dates?.startDate) && (
					<DateInput
						noDateText=''
						initialDates={dates}
						isOpen={Boolean(isPickerOpen)}
						isSelected={isSelected}
						handleDateChange={(d) => {
							onDateInputChange(d)
							// setDates(d)
						}}
						handleCalendarClose={() => {
							setIsPickerOpen(false)
						}}
					/>
				)}
			</div>
		</>
	)
}
export default DateRangePicker
