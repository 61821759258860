import React from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'

// eslint-disable-next-line no-useless-escape
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

const passwordSchema = Yup.object().shape({
	// title: Yup.string().required('Please enter Title.'),
	// url: Yup.string().required('Please enter URL.'),
	// country: Yup.string().required('Please enter Country.'),
	// region: Yup.string().required('Please enter Region.'),
	currency: Yup.string().required('Please enter Currency.'),
	product_pages: Yup.string().required('Please enter Product Pages.'),
	// min_mandatory_params: Yup.string().required('Please enter Min Mandatory Parameters.'),
})

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData)
		? { title: '', url: '', country: '', region: '', currency: '', product_pages: '', min_mandatory_params: '' }
		: props.initialFormData

	return (
		<Formik innerRef={ref} enableReinitialize initialValues={initialValues} validationSchema={passwordSchema} onSubmit={props.onSubmit || null}>
			{props.children}
		</Formik>
	)
})

export default Validator
