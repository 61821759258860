import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import clsx from 'clsx'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import LoadingIndicator from '../../../../components/LoadingIndicator'

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein }
}

const rows = [
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Donut', 452, 25.0, 51, 4.9),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
	createData('Honeycomb', 408, 3.2, 87, 6.5),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Jelly Bean', 375, 0.0, 94, 0.0),
	createData('KitKat', 518, 26.0, 65, 7.0),
	createData('Lollipop', 392, 0.2, 98, 0.0),
	createData('Marshmallow', 318, 0, 81, 2.0),
	createData('Nougat', 360, 19.0, 9, 37.0),
	createData('Oreo', 437, 18.0, 63, 4.0),
]

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const headCells = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
	{ id: 'calories', numeric: true, disablePadding: false, label: 'Calories' },
	{ id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)' },
	{ id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
	{ id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
]

function EnhancedTableHead(props) {
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell padding='checkbox'>
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
	title: {
		flex: '1 1 100%',
	},
}))

const EnhancedTableToolbar = (props) => {
	const classes = useToolbarStyles()
	const { numSelected } = props

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: numSelected > 0,
			})}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color='inherit' variant='subtitle1' component='div'>
					{numSelected} selected
				</Typography>
			) : (
				<Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
					Nutrition
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title='Delete'>
					<IconButton aria-label='delete'>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title='Filter list'>
					<IconButton aria-label='filter list'>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	)
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}))

export default function EnhancedTable({ values, handleChange, rowData, count, page, onChangePage, rowsPerPage, onChangeRowsPerPage, loading }) {
	const classes = useStyles()
	let preSelectedData = _.map(values?.product, (product) => _.toInteger(product.product_id))
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('calories')
	const [selected, setSelected] = React.useState(preSelectedData || [])
	const [selectedData, setSelectedData] = React.useState(values.product || [])
	// const [page, setPage] = React.useState(0)
	const [dense, setDense] = React.useState(false)
	// const [rowsPerPage, setRowsPerPage] = React.useState(5)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.name)
			setSelected(newSelecteds)
			return
		}
		setSelected([])
	}

	const handleClick = (event, name, row) => {
		const selectedIndex = selected.indexOf(name)
		// const selectedDataIndex = selectedData.indexOf(row)
		const selectedDataIndex = selectedData.findIndex((x) => x.product_id === _.toString(name))
		let newSelected = []

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name)
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1))
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1))
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
		}

		let newSelectedData = []

		if (selectedDataIndex === -1) {
			newSelectedData = newSelectedData.concat(selectedData, row)
		} else if (selectedDataIndex === 0) {
			newSelectedData = newSelectedData.concat(selectedData.slice(1))
		} else if (selectedDataIndex === selectedData.length - 1) {
			newSelectedData = newSelectedData.concat(selectedData.slice(0, -1))
		} else if (selectedDataIndex > 0) {
			newSelectedData = newSelectedData.concat(selectedData.slice(0, selectedDataIndex), selectedData.slice(selectedDataIndex + 1))
		}

		setSelected(newSelected)
		setSelectedData(newSelectedData)
		handleChange({
			target: { name: 'product', value: newSelectedData },
		})
	}

	// const handleChangePage = (event, newPage) => {
	// 	setPage(newPage)
	// }

	// const handleChangeRowsPerPage = (event) => {
	// 	setRowsPerPage(parseInt(event.target.value, 10))
	// 	setPage(0)
	// }

	const handleChangeDense = (event) => {
		setDense(event.target.checked)
	}

	const isSelected = (name) => selected.indexOf(name) !== -1

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

	const getTableRows = (data) => {
		// if (_.isEmpty(data))
		// 	return (
		// 		<TableRow>
		// 			<TableCell colSpan={headersToShow.length}>
		// 				<div className={classes.infoContainer}>
		// 					{emptyImage}
		// 					<Typography variant='body2'>No Records Found</Typography>
		// 				</div>
		// 			</TableCell>
		// 		</TableRow>
		// 	)

		return data.map((row, index) => {
			const isItemSelected = isSelected(row.product_id)
			const labelId = `enhanced-table-checkbox-${index}`

			return (
				<TableRow
					hover
					// onClick={(event) => handleClick(event, row.product_title)}
					role='checkbox'
					aria-checked={isItemSelected}
					tabIndex={-1}
					key={row.product_id}
					selected={isItemSelected}
				>
					<TableCell align='center'>
						<Checkbox
							onClick={(event) => handleClick(event, row.product_id, row)}
							checked={isItemSelected}
							inputProps={{ 'aria-labelledby': labelId }}
						/>
					</TableCell>
					<TableCell align='left'>{row.product_id}</TableCell>
					<TableCell align='left'>{row.product_title}</TableCell>
					{/* <TableCell align='right'>{row.fat}</TableCell>
                        <TableCell align='right'>{row.carbs}</TableCell>
                        <TableCell align='right'>{row.protein}</TableCell> */}
				</TableRow>
			)
		})
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
				<TableContainer>
					<Table className={classes.table} aria-labelledby='tableTitle' size={dense ? 'small' : 'medium'} aria-label='enhanced table'>
						<TableHead>
							<TableRow>
								<TableCell align='center' style={{ color: 'white', fontWeight: 'bold' }}>
									SELECT
								</TableCell>

								<TableCell
									// key={headCell.id}
									// align={headCell.numeric ? 'right' : 'left'}
									// padding={headCell.disablePadding ? 'none' : 'normal'}
									// sortDirection={orderBy === headCell.id ? order : false}
									align='left'
									style={{ width: '150px', color: 'white', fontWeight: 'bold' }}
								>
									PRODUCT ID
								</TableCell>
								<TableCell
									// key={headCell.id}
									// align={headCell.numeric ? 'right' : 'left'}
									// padding={headCell.disablePadding ? 'none' : 'normal'}
									// sortDirection={orderBy === headCell.id ? order : false}
									align='left'
									style={{ color: 'white', fontWeight: 'bold' }}
								>
									PRODUCT TITLE
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!loading ? (
								getTableRows(rowData)
							) : (
								<TableRow>
									<TableCell colSpan={3}>
										<LoadingIndicator></LoadingIndicator>
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 50]}
					component='div'
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={onChangePage}
					onRowsPerPageChange={onChangeRowsPerPage}
				/>
			</Paper>
			{/* <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label='Dense padding' /> */}
		</div>
	)
}
