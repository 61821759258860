import { Avatar, Box, Button, Grid, Icon, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Field } from 'formik'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import MultiSelect from '../../../../components/MultiSelect'
import { RETAILER_MATCH_TYPE } from '../../../../constants'
import Api from '../../../../services/aiqa/bb/api'
import api from '../../../../services/aiqa/dimension/api'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import Validator from './validator'
import { hideLoader, showLoader } from '../../../../services/loader/actions'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		// textAlign: 'center',
	},
	textField: {
		marginTop: theme.spacing(2.5),
		maxWidth: 400,
		margin: '0 auto',
	},
	selectField: {
		marginTop: '10px',
		maxWidth: 600,
		margin: '0 auto',
	},
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	paperHead: {
		// maxWidth: 900,
		margin: '16px auto',
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	key: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	gridContainer: {
		// marginTop: theme.spacing(2.5),
		// maxWidth: 600,
		margin: '0 auto',
		padding: '12px',
	},
	subGrid: {
		marginBottom: '20px',
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
}))

function ChangePassword({ selectedbatch, setSelectedbatch, toggle }) {
	const classes = useStyles()
	const [isPwdUpdated] = useState(false)
	const renderAfterCalled = useRef(false)
	const [initialData, setinitialData] = useState([])
	const [batch, setBatch] = useState([])

	const dispatch = useDispatch()
	const formRef = useRef(null)

	useEffect(() => {
		// if (!renderAfterCalled.current) {
		dispatch(showLoader('Please wait, getting data flow settings'))
		Api.fetchBatch(
			toggle === true ? 'oem_bb' : 'bb',
			'all',
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', [])

				let res = _.map(testList, (list) => ({
					id: _.toString(list.id),
					name: list.batch_number,
				}))

				let selected = _.map(
					_.filter(testList, function (test) {
						return test?.is_active === 1
					}),
					(list) => ({
						id: _.toString(list.id),
						name: list.batch_number,
					})
				)
				setBatch(res)
				setSelectedbatch(selected)
				setinitialData([])
				// setinitialData((prevState) => [...prevState, ...selected])
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
		// }
		// renderAfterCalled.current = true
	}, [toggle])

	var isArrayEqual = function (x, y) {
		let isSameSize = _.size(x) === _.size(y)
		return isSameSize && _(x).xorWith(y, _.isEqual).isEmpty()
	}

	var result = isArrayEqual(selectedbatch, initialData)

	const onSubmitForm = () => {
		let pay_values = {
			batch_ids: _.toString(_.map(initialData, (list) => list.id)),
		}

		Api.putbatch(
			toggle === true ? 'oem_bb' : 'bb',
			pay_values,
			(res) => {
				setSelectedbatch(initialData)
				dispatch(showSnackbarWithTimeout(res.data.message, 2000))
				// formRef.current && formRef.current.resetForm()
				// if (_.isEmpty(userId)) {
				// 	getFormData('all')
				// } else {
				// 	history.push(`/aiqa_tool/dimension/products/form`)
				// }
			},
			(err) => {
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	return (
		<>
			<Paper className={classes.paperHead} variant='outlined' square>
				<Grid container className={classes.gridWrapper}>
					<Grid item xs={12} className={classes.tabPanelWrapper}>
						<Box className={classes.contentWrapper} component='div'>
							<>
								<Grid item xs={12} lg={12}>
									<Typography className={classes.paperTitle} variant='body1'>
										Settings:
									</Typography>
								</Grid>
								<Validator initialFormData={initialData} ref={formRef}>
									{(formik) => {
										return (
											<form>
												<Field key={'batch_ids'} name={'batch_ids'}>
													{({ form, field }) => {
														return (
															<Grid container>
																<Grid item xs={12} lg={3}></Grid>
																<Grid item xs={12} lg={6}>
																	<MultiSelect
																		items={batch || []}
																		// getOptionDisabled={getOptionDisabled}
																		label='Select Batch Numbers'
																		// placeholder='Placeholder for textbox'
																		name='brand'
																		selectAllLabel=''
																		onChange={(e) => setinitialData(e)}
																		value={selectedbatch}
																		// onBlur={handleBlur}
																		// error={errors?.brand && touched.brand}
																		// helperText={errors?.brand && touched.brand && errors?.brand}
																		// disabled={disabled}
																		// onChange={(e) => {
																		// 	e === []
																		// 		? form.setFieldValue(field.name, '')
																		// 		: form.setFieldValue(field.name, _.toString(_.map(e, (list) => list.id)))
																		// }}
																		inputClass={classes.selectField}
																		onBlur={field.onBlur}
																		// disabled={disabled}
																		helperText={form.touched[field.name] && form.errors[field.name]}
																		error={Boolean(form.errors[field.name] && form.touched[field.name])}
																	/>
																</Grid>
																<Grid item xs={12} lg={3}></Grid>
															</Grid>
														)
													}}
												</Field>
											</form>
										)
									}}
								</Validator>
								<div style={{ textAlign: 'center', marginTop: '40px' }}>
									{/* <Button
										onClick={onClickRst}
										color='primary'
										variant='text'
										size='medium'
										disableElevation
										style={{ padding: 'inherit', marginRight: '40px', fontWeight: 'bold' }}
									>
										Reset
									</Button> */}
									<Button
										onClick={onSubmitForm}
										color='primary'
										type='submit'
										variant='text'
										size='medium'
										disableElevation
										disabled={result || _.isEmpty(initialData)}
										style={{ padding: 'inherit', fontWeight: 'bold' }}
									>
										Save
									</Button>
								</div>
							</>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</>
	)
}

export default ChangePassword
