import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	createTest: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.CREATE_TEST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchTest: (id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.EDIT_TEST, {
			headers: { isAuthRequired: true, path: { id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	viewTest: (id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.VIEW_TEST, {
			headers: { isAuthRequired: true, path: { id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	updateTest: (id, data, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.EDIT_TEST,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	s3Upload: (id) => {
		Axios.put(
			API_ENDPOINTS.S3_UPLOAD,
			{},
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
		// .then((resp) => {
		// 	successCallback && successCallback(resp)
		// })
		// .catch((err) => {
		// 	errorCallback && errorCallback(err)
		// })
	},
	fetchBrands: (cancelSource) => {
		return Axios.get(API_ENDPOINTS.FETCH_BRANDS, { cancelToken: cancelSource.token, headers: { isAuthRequired: true } })
	},
	fetchCountries: (cancelSource) => {
		return Axios.get(API_ENDPOINTS.FETCH_COUNTRIES, { cancelToken: cancelSource.token, headers: { isAuthRequired: true } })
	},
	fetchRetailers: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.FETCH_RETAILERS, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchProducts: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.FETCH_PRODUCTS, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}

export default api
