import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import Products from './Products'
import Distributors from './Distributors'
import Report from './Report'
import _ from 'lodash'
import { usePermission } from '../../components/hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from '../../constants/modules'

const MODULES = {
	PRODUCTS: 'products',
	DISTRIBUTORS: 'distributors',
	REPORT: 'report',
}

const useStyles = makeStyles((theme) => ({
	tabs: {
		margin: '16px auto',
		minHeight: 'unset',
		boxSizing: 'border-box',
		height: '38px',
	},
	tabsContainer: {
		'& > button:first-child': {
			borderLeft: `1px solid ${theme.palette.primary.main}86!important`,
			borderTop: `1px solid ${theme.palette.primary.main}86`,
			borderBottom: `1px solid ${theme.palette.primary.main}86`,
			borderRight: '0px',
		},
		'& > button:last-child': {
			borderRight: `1px solid ${theme.palette.primary.main}86`,
			borderTop: `1px solid ${theme.palette.primary.main}86`,
			borderBottom: `1px solid ${theme.palette.primary.main}86`,
			borderLeft: '0px',
		},
		'& > button:not(:last-child):not(:first-child)': {
			border: `1px solid ${theme.palette.primary.main}86`,
		},
	},
	tabsIndicator: {
		display: 'none',
	},
	tabRoot: {
		letterSpacing: '2px',
		textTransform: 'none',
		minHeight: 'unset',
		minWidth: '100px',
		width: 'auto',
		'&:hover:not($tabSelected)': {
			// color:theme.palette.background.paper,
			background: `${theme.palette.primary.light}52`,
		},
	},
	tabIco: {
		verticalAlign: 'top',
	},
	tabPanel: {
		display: 'block',
		width: '100%',
	},
	tabSelected: {
		background: theme.palette.primary.dark,
		color: theme.palette.common.white,
		'&:hover': {
			color: theme.palette.common.white,
		},
	},
}))

const getModuleIdx = (moduleName) => {
	switch (moduleName) {
		case MODULES.PRODUCTS:
			return 0
		case MODULES.DISTRIBUTORS:
			return 1
		case MODULES.REPORT:
			return 2
		default:
			return -1
	}
}

const isModuleHasViewPerm = (modulePerms) => _.get(modulePerms, PERMISSION_TYPE.VIEW, false)

const UserManagement = ({ history, match }) => {
	const userPerms = usePermission(ROLE_MODULES.AIQA_DIMENSION)
	const rolePerms = usePermission(ROLE_MODULES.ROLE_MANAGEMENT)

	const isUsrEnabled = useMemo(() => isModuleHasViewPerm(userPerms), [userPerms])
	const isRoleEnabled = useMemo(() => isModuleHasViewPerm(rolePerms), [rolePerms])

	const defModule = useMemo(() => {
		return (isUsrEnabled && MODULES.USERS) || (isRoleEnabled && MODULES.ROLES) || null
	}, [isRoleEnabled, isUsrEnabled])

	const module = _.get(match, 'params.module')
	const action = _.get(match, 'params.action', '')
	const dataId = _.get(match, 'params.id', '')

	const activeTabIdx = getModuleIdx(module)

	const classes = useStyles()

	useEffect(() => {
		if (_.isEmpty(module) && !_.isEmpty(defModule)) history.replace(`/usermanagement/${defModule}`)
	}, [module, history, defModule])

	//IF MODULE IS EMPTY
	if (!module) return null

	return (
		<div className={classnames('container-no-fluid', 'page-content')}>
			<Grid container>
				<Grid item container xs={12} className={classnames(classes.tabPanel)}>
					<TabPanel value={activeTabIdx} index={0}>
						<Products action={action} userId={dataId} history={history} perms={userPerms}></Products>
					</TabPanel>
					<TabPanel value={activeTabIdx} index={1}>
						<Distributors action={action} roleId={dataId} history={history} perms={userPerms}></Distributors>
					</TabPanel>
					<TabPanel value={activeTabIdx} index={2}>
						<Report action={action} roleId={dataId} history={history} perms={userPerms}></Report>
					</TabPanel>
				</Grid>
			</Grid>
		</div>
	)
}

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
			{value === index && (
				<Box marginLeft={1} marginRight={1}>
					{children}
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

export default UserManagement
