import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import _ from 'lodash'
import React from 'react'
import MultiSelect from '../../../../components/MultiSelect'
import SelectBox from '../../../../components/SelectBox'
import {
	DEVICE_TYPE,
	FLIX_PRODUCTS,
	INPAGE_SCENERIOS,
	KPIS,
	PLATFORM,
	SCENERIOS,
	TEMPLATE_TRAFFIC_SPLIT_RULE,
	TRAFFIC_SPLIT_RULE,
} from '../../../../constants'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	gridWrapper: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: theme.spacing(0, 3),
		maxWidth: 800,
	},
	textField: {
		// maxWidth: 400,
		margin: `${theme.spacing(1)}px auto`,
		padding: theme.spacing(2),
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
	},
	paperTitle: {
		fontWeight: 'bold',
	},
	table: {
		'& .MuiTableRow-head': {
			background: '#41e886',
		},
	},
}))

function StepOne(props) {
	const classes = useStyles()
	const { values, errors, touched, handleBlur, setFieldValue, handleChange, disabled, secondary } = props

	function createData(varient, split) {
		return { varient, split }
	}

	const fifty = [createData('CV', '50%'), createData('1', '50%')]
	const triple = [createData('CV', '33%'), createData('1', '33%'), createData('2', '33%')]
	const double = [createData('CV', '80%'), createData('1', '20%')]

	return (
		<Box className={classes.contentWrapper} component='div'>
			<Grid container spacing={2} className={classes.gridWrapper}>
				<Typography className={classes.title} variant='h6'>
					Define:
				</Typography>
				<Grid item xs={12} lg={12} className={classes.textField}>
					<TextField
						variant='outlined'
						onBlur={handleBlur}
						margin='none'
						onChange={(e) => {
							handleChange(e)
							e.target.value === ' ' && setFieldValue('test_name', '')
						}}
						value={values?.test_name}
						error={errors?.test_name && touched?.test_name}
						helperText={errors?.test_name && touched?.test_name && errors?.test_name}
						size='small'
						fullWidth
						name='test_name'
						label='Test Name*'
						autoComplete='off'
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={12} lg={12} className={classes.textField}>
					<TextField
						id='outlined-multiline-static'
						onBlur={handleBlur}
						error={errors?.hypothesis && touched.hypothesis}
						helperText={errors?.hypothesis && touched.hypothesis && errors?.hypothesis}
						name='hypothesis'
						onChange={(e) => {
							handleChange(e)
							e.target.value === ' ' && setFieldValue('hypothesis', '')
						}}
						value={values?.hypothesis}
						label='Hypothesis*'
						fullWidth
						multiline
						rows={4}
						variant='outlined'
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={12} lg={12} className={classes.textField}>
					<TextField
						id='outlined-multiline-static'
						label='Sales Force Case (Approved Opt Link)'
						name='sf_case'
						onChange={(e) => {
							handleChange(e)
							e.target.value === ' ' && setFieldValue('sf_case', '')
						}}
						onBlur={handleBlur}
						error={errors?.sf_case && touched.sf_case}
						helperText={errors?.sf_case && touched.sf_case && errors?.sf_case}
						value={values?.sf_case}
						fullWidth
						multiline
						rows={4}
						variant='outlined'
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={12} lg={12} className={classes.textField}>
					<SelectBox
						label='Flix Product*'
						name='flix_product_type'
						onChange={(e) =>
							handleChange({
								target: { name: 'flix_product_type', value: e },
							})
						}
						value={values?.flix_product_type}
						onBlur={handleBlur}
						isError={errors?.flix_product_type && touched.flix_product_type}
						helperText={errors?.flix_product_type && touched.flix_product_type && errors?.flix_product_type}
						// onChange={(value) => {
						// 	handleProductChange(value)
						// }}
						options={_.values(FLIX_PRODUCTS)}
						disabled={disabled}
					></SelectBox>
				</Grid>
				<Grid item xs={12} lg={12} className={classes.textField}>
					<SelectBox
						label='Scenerio*'
						value={values?.test_type}
						name='test_type'
						onBlur={handleBlur}
						isError={errors?.test_type && touched.test_type}
						helperText={errors?.test_type && touched.test_type && errors?.test_type}
						onChange={(e) =>
							handleChange({
								target: { name: 'test_type', value: e },
							})
						}
						disabled={disabled}
						options={values?.flix_product_type === 'Inpage' ? _.values(INPAGE_SCENERIOS) : _.values(SCENERIOS)}
					></SelectBox>
				</Grid>
				<Grid item xs={12} lg={6} className={classes.textField}>
					<MultiSelect
						items={_.values(DEVICE_TYPE)}
						// getOptionDisabled={getOptionDisabled}
						label='Device Type*'
						// placeholder='Placeholder for textbox'
						selectAllLabel='All'
						value={values?.device_type}
						onChange={(e) =>
							handleChange({
								target: { name: 'device_type', value: e },
							})
						}
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={12} lg={6} className={classes.textField}>
					<MultiSelect
						items={_.values(PLATFORM)}
						// getOptionDisabled={getOptionDisabled}
						label='Platform*'
						// placeholder='Placeholder for textbox'
						selectAllLabel='All'
						value={values?.platform_type}
						onChange={(e) =>
							handleChange({
								target: { name: 'platform_type', value: e },
							})
						}
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={12} lg={12}>
					<Paper className={classes.paper} elevation={0} square>
						<Grid container spacing={2} className={classes.gridWrapper}>
							<Typography className={classes.paperTitle} variant='h6'>
								KPI's
							</Typography>
							<Grid item xs={12} lg={12} className={classes.textField}>
								<SelectBox
									label='Primary KPI*'
									name='primary_kpi'
									value={values?.primary_kpi}
									onChange={(e) =>
										handleChange({
											target: { name: 'primary_kpi', value: e },
										})
									}
									onBlur={handleBlur}
									isError={errors?.primary_kpi && touched.primary_kpi}
									helperText={errors?.primary_kpi && touched.primary_kpi && errors?.primary_kpi}
									options={_.values(KPIS)}
									disabled={disabled}
								></SelectBox>
							</Grid>
							<Grid item xs={12} lg={6} className={classes.textField}>
								<TextField
									type='number'
									variant='outlined'
									margin='none'
									size='small'
									fullWidth
									InputProps={{ inputProps: { min: 0, max: 100 } }}
									value={values?.expected_lift}
									onChange={(e) => {
										handleChange(e)
										e.target.value === ' ' && setFieldValue('expected_lift', '')
									}}
									name='expected_lift'
									onBlur={handleBlur}
									error={errors?.expected_lift && touched.expected_lift}
									helperText={errors?.expected_lift && touched.expected_lift && errors?.expected_lift}
									label='Expected Lift (%)'
									autoComplete='off'
									disabled={disabled}
								/>
							</Grid>
							<Grid item xs={12} lg={6} className={classes.textField}>
								<TextField
									type='number'
									variant='outlined'
									margin='none'
									size='small'
									fullWidth
									InputProps={{ inputProps: { min: 0, max: 100 } }}
									value={values?.confident_level}
									onChange={(e) => {
										handleChange(e)
										e.target.value === ' ' && setFieldValue('confident_level', '')
									}}
									name='confident_level'
									onBlur={handleBlur}
									error={errors?.confident_level && touched.confident_level}
									helperText={errors?.confident_level && touched.confident_level && errors?.confident_level}
									label='Confident Level'
									autoComplete='off'
									disabled={disabled}
								/>
							</Grid>
							<Grid item xs={12} lg={12} className={classes.textField}>
								<MultiSelect
									items={values?.primary_kpi === '' ? _.values(KPIS) : _.reject(_.values(KPIS), { id: values?.primary_kpi })}
									// getOptionDisabled={getOptionDisabled}
									label='Secondary KPI*'
									onBlur={handleBlur}
									name='secondary_kpi'
									selectAllLabel=''
									error={errors?.secondary_kpi && touched.secondary_kpi}
									helperText={errors?.secondary_kpi && touched.secondary_kpi && errors?.secondary_kpi}
									value={secondary}
									// value={values?.secondary_kpi[0]}
									onChange={(e) =>
										handleChange({
											target: { name: 'secondary_kpi', value: e },
										})
									}
									disabled={disabled}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12} lg={6} style={{ textAlign: 'left' }}>
					<Typography className={classes.paperTitle} variant='h6'>
						Traffic Split
					</Typography>
				</Grid>
				<Grid item xs={12} lg={6} className={classes.textField}>
					<SelectBox
						label='Traffic Split Rule*'
						value={values?.split_range}
						onChange={(e) =>
							handleChange({
								target: { name: 'split_range', value: e },
							})
						}
						name='split_range'
						onBlur={handleBlur}
						disabled={disabled}
						isError={errors?.split_range && touched.split_range}
						helperText={errors?.split_range && touched.split_range && errors?.split_range}
						options={values?.test_type === 'template_variation' ? _.values(TEMPLATE_TRAFFIC_SPLIT_RULE) : _.values(TRAFFIC_SPLIT_RULE)}
					></SelectBox>
				</Grid>
				{values?.test_type === 'template_variation' && values?.split_range !== '' && (
					<>
						<Grid item xs={12} lg={12} className={classes.textField}>
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								value={values?.variant1}
								onChange={(e) => {
									handleChange(e)
									e.target.value === ' ' && setFieldValue('variant1', '')
								}}
								name='variant1'
								onBlur={handleBlur}
								error={errors?.variant1 && touched.variant1}
								helperText={errors?.variant1 && touched.variant1 && errors?.variant1}
								label='Variant 1 Asset URL (template/flix/standard-v2abtest)'
								autoComplete='off'
								disabled={disabled}
							/>
						</Grid>
						{values?.split_range === '33%:33%:33%' && (
							<Grid item xs={12} lg={12} className={classes.textField}>
								<TextField
									variant='outlined'
									margin='none'
									size='small'
									fullWidth
									value={values?.variant2}
									onChange={(e) => {
										handleChange(e)
										e.target.value === ' ' && setFieldValue('variant2', '')
									}}
									name='variant2'
									onBlur={handleBlur}
									error={errors?.variant2 && touched.variant2}
									helperText={errors?.variant2 && touched.variant2 && errors?.variant2}
									label='Variant 2 Asset URL (template/flix/standard-v3abtest)'
									autoComplete='off'
									disabled={disabled}
								/>
							</Grid>
						)}
					</>
				)}

				<Grid item xs={12} lg={6} className={classes.textField}>
					<TableContainer>
						<Table className={classes.table} aria-label='simple table'>
							<TableHead>
								<TableRow>
									<TableCell style={{ color: 'white', fontWeight: 'bold' }}>VARIENT</TableCell>
									<TableCell align='right' style={{ color: 'white', fontWeight: 'bold' }}>
										TRAFFIC SPLIT
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{values?.split_range === '50%:50%' &&
									fifty.map((row) => (
										<TableRow key={row.varient}>
											<TableCell component='th' scope='row'>
												{row.varient}
											</TableCell>
											<TableCell align='right'>{row.split}</TableCell>
										</TableRow>
									))}
								{values?.split_range === '33%:33%:33%' &&
									triple.map((row) => (
										<TableRow key={row.varient}>
											<TableCell component='th' scope='row'>
												{row.varient}
											</TableCell>
											<TableCell align='right'>{row.split}</TableCell>
										</TableRow>
									))}
								{values?.split_range === '80%:20%' &&
									double.map((row) => (
										<TableRow key={row.varient}>
											<TableCell component='th' scope='row'>
												{row.varient}
											</TableCell>
											<TableCell align='right'>{row.split}</TableCell>
										</TableRow>
									))}
								{values?.split_range === '' && (
									<TableRow>
										<Typography className={classes.tableCaption} variant='caption'>
											No Split Rules Selected
										</Typography>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Box>
	)
}

export default StepOne
