import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Grid, TextField, Typography, Switch, FormControlLabel } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'formik'
import classnames from 'classnames'
import SelectBox from '../../../../../components/SelectBox'
import Api from '../../../../../services/prmatching/api'

const useStyles = makeStyles((theme) => ({
	profilePic: {
		width: 170,
		height: 170,
		[theme.breakpoints.down('xs')]: {
			width: 140,
			height: 140,
			margin: '0 auto',
		},
	},
	flexRootResponsive: {
		display: 'flex',
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	basicHeaderRight: {
		flex: 1,
		// paddingLeft: theme.spacing(2),
		marginTop: theme.spacing(0.8),
		[theme.breakpoints.down('xs')]: {
			flex: 'unset',
			paddingLeft: 0,
		},
	},
	basicHeaderLeft: {
		maxWidth: 170,
		[theme.breakpoints.down('xs')]: {
			flex: 1,
			maxWidth: '100%',
			paddingBottom: theme.spacing(2),
			textAlign: 'center',
		},
	},
	textField: {
		marginBottom: theme.spacing(2.2),
		'& .Mui-disabled': {
			color: 'inherit',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
			},
		},
	},
	parallelInputRoot: {
		width: 'calc(50% - 8px)',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	parallelInputEnd: {
		marginLeft: 16,
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		},
	},
	basicHeader: {
		marginBottom: theme.spacing(0.5),
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
		},
	},
	pwdTitle: {
		textTransform: 'none',
	},
	statusIndicator: {
		marginLeft: theme.spacing(2),
		borderRadius: '50%',
		width: '10px',
		height: '10px',
		display: 'inline-block',
		marginRight: theme.spacing(1),
	},
}))

const BasicDetails = ({ isEditMode, isCreateMode, isViewMode, formik }) => {
	const classes = useStyles()

	const { distributor_id, product_id } = formik.values
	const [products, setProducts] = useState([])
	const [prodSearch, setProdSearch] = useState('')
	const [prodSrLoad, setprodSrLoad] = useState(false)
	const [distributors, setDistributors] = useState([])
	const [distriSrLoad, setdistriSrLoad] = useState(false)
	const [searchkey, setSearchkey] = useState('')
	const searchBoxTimeout = useRef(undefined)

	// const fetchProducts = useCallback(({ prodSearch }) => {
	// 	let pay_values = {
	// 		search_text: prodSearch,
	// 	}
	// 	setprodSrLoad(true)
	// 	Api.fetchProducts(
	// 		pay_values,
	// 		(resp) => {
	// 			const totalCount = _.get(resp, 'data.data.total_count', 0)
	// 			const testList = _.get(resp, 'data.data.products', [])

	// 			let res = _.map(testList, (list) => ({
	// 				id: _.toString(list.product_id),
	// 				name: `${list.product_title} ---- (${list.product_id})`,
	// 			}))
	// 			setProducts(res)
	// 			setprodSrLoad(false)
	// 			formik.setFieldValue('product_id', '')
	// 		},
	// 		(err) => {
	// 			// if (!isAxiosCancelError(err) && isMounted.current) {
	// 			// setUsersTblState((prevState) => ({
	// 			// 	...prevState,
	// 			// 	loading: false,
	// 			// 	totalCount: 0,
	// 			// 	testList: [],
	// 			// }))
	// 			// }
	// 			setprodSrLoad(false)
	// 		}
	// 	)
	// 	// }
	// }, [])

	useEffect(() => {
		// if (!renderAfterCalled.current) {
		let pay_values = {
			search_text: searchkey,
		}
		setdistriSrLoad(true)
		Api.matchDistributor(pay_values).then((res) => {
			let resp = _.map(res.data.data.retailers, (retailer) => ({
				id: _.toString(retailer.distributor_id),
				name: `${retailer.title} ---- (${retailer.distributor_id})`,
			}))
			setDistributors(resp)
			setdistriSrLoad(false)
		})
		// }
		// renderAfterCalled.current = true
	}, [searchkey])

	// useEffect(() => {
	// 	fetchProducts({ prodSearch })
	// }, [prodSearch])

	const onInputProductChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					setProdSearch(e.target.value)
				}, 1500)
			}
		}
	}

	const showFieldValue = (value) => {
		return isViewMode ? (_.isEmpty(_.trim(value)) ? '-' : value) : value
	}

	const onInputSRChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					// let product_id = e.target.value
					// setProdSearch(e.target.value)
					setSearchkey(e.target.value)
				}, 1500)
			}
		}
	}

	return (
		<Grid container>
			<Grid item xs={12} className={classnames(classes.flexRootResponsive, classes.basicHeader)}>
				<div className={classes.basicHeaderRight}>
					<Field type='text' name='distributor_id'>
						{({ field, form }) => (
							<SelectBox
								label='Retailer'
								value={field.value || ''}
								onChange={(e) => {
									e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
								}}
								onInputChange={(e) => onInputSRChange(e)}
								isLoading={distriSrLoad}
								inputClass={classes.textField}
								name={field.name}
								onBlur={field.onBlur}
								// disabled={disabled}
								helperText={form.touched[field.name] && form.errors[field.name]}
								isError={Boolean(form.errors[field.name] && form.touched[field.name])}
								options={distributors}
							></SelectBox>
						)}
					</Field>
					<Field type='text' name='product_id'>
						{({ field, form }) => (
							// <SelectBox
							// 	label='Product'
							// 	value={field.value || ''}
							// 	onChange={(e) => {
							// 		e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
							// 	}}
							// 	onInputChange={(e) => onInputProductChange(e)}
							// 	inputClass={classes.textField}
							// 	isLoading={prodSrLoad}
							// 	name={field.name}
							// 	onBlur={field.onBlur}
							// 	// disabled={disabled}
							// 	helperText={form.touched[field.name] && form.errors[field.name]}
							// 	isError={Boolean(form.errors[field.name] && form.touched[field.name])}
							// 	options={products}
							// 	noOptionsText={'No Options'}
							// ></SelectBox>
							<TextField
								variant='outlined'
								margin='none'
								size='small'
								fullWidth
								name={field.name}
								label={'Product*'}
								type='text'
								autoComplete='off'
								onChange={field.onChange}
								onBlur={field.onBlur}
								value={field.value || ''}
								// disabled={isViewMode || _.get(form, 'values.is_email_verified', false)}
								className={classes.textField}
								helperText={form.touched[field.name] && form.errors[field.name]}
								error={Boolean(form.errors[field.name] && form.touched[field.name])}
							/>
						)}
					</Field>
				</div>
			</Grid>
		</Grid>
	)
}

BasicDetails.propTypes = {
	isCreateMode: PropTypes.bool.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	isViewMode: PropTypes.bool.isRequired,
	formik: PropTypes.object.isRequired,
}

export default BasicDetails
