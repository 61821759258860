

import { SAGA_ACTIONS } from '../../../constants'

export const fetchUserViaSaga = (userId, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.FETCH_USER,
	payload: { userId, successCallback, dialogCloseBtnCallback },
})

export const updateUser = (userId, userDetails, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.UPDATE_USER,
	payload: { userId, userDetails, successCallback, dialogCloseBtnCallback },
})

export const createNewUser = (userDetails, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.CREATE_NEW_USER,
	payload: { userDetails, successCallback, dialogCloseBtnCallback },
})

export const fetchAllUsers = (filters, successCallback, errorCallback) => ({
	type: SAGA_ACTIONS.FETCH_ALL_USERS,
	payload: { filters, successCallback, errorCallback },
})

export const fetchUserSpecPerms = (userId, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.FETCH_USER_PERMISSIONS,
	payload: { userId, successCallback, dialogCloseBtnCallback },
})

export const updateUserSpecPerms = (userId, permissions, loaderMsg, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.UPDATE_USER_PERMISSIONS,
	payload: {
		userId,
		permissions,
		successCallback,
		dialogCloseBtnCallback,
		loaderMsg,
	},
})

export const deleteUser = (userId, formData, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.DELETE_USER,
	payload: { userId, formData, successCallback, dialogCloseBtnCallback },
})
