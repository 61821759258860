import React, { useState, useEffect } from 'react'
import { makeStyles, Button } from '@material-ui/core'
import _ from 'lodash'
import PM from '../../images/Home.gif'
import classnames from 'classnames'
import { usePermission } from '../../components/hooks/usePermission'
import { PERMISSION_TYPE, ROLE_MODULES } from '../../constants/modules'

const useStyles = makeStyles((theme) => ({
	img: {
		display: 'flex',
		// justifyContent: 'center' /* horizontally center */,
		// alignItems: 'center' /* vertically center */,
		height: '500px',
		width: '500px',
		// marginTop: '100px',
		marginLeft: '300px',
	},
}))

const isModuleHasViewPerm = (modulePerms) => _.get(modulePerms, PERMISSION_TYPE.VIEW, false)

const Home = ({ history }) => {
	const abTest = usePermission(ROLE_MODULES.AB_TEST)
	const prMatch = usePermission(ROLE_MODULES.PRODUCT_MATCHING)
	const classes = useStyles()

	return (
		<div className={classnames('container-no-fluid', 'page-content')}>
			{/* <frameset rows='100%,*'>
				<frame src='https://www.flixmedia.com/flix-solutions/brand/flix-a-plus' />
			</frameset> */}
			{/* <div className='image-container'> */}
			<div style={{ display: 'flex', flexDirection: 'row', marginTop: '50px', flexWrap: 'nowrap', justifyContent: 'left' }}>
				{isModuleHasViewPerm(abTest) && (
					<Button onClick={() => window.location.replace('/ab_test_tool/home')} variant='contained' style={{ fontWeight: 'bold' }} color='primary'>
						A/B Test
					</Button>
				)}
				{isModuleHasViewPerm(prMatch) && (
					<Button
						onClick={() => history.push('/product_match/matching_process')}
						variant='contained'
						style={{ left: '10px', fontWeight: 'bold' }}
						color='primary'
					>
						Product Match
					</Button>
				)}
			</div>
			<img src={PM} alt={PM} className={classes.img} />

			{/* </div> */}
			{/* <div className={classes.img} /> */}
		</div>
	)
}

export default Home
