import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormLabel,
	TextField,
	Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import FormDialog from './FormDialog'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AgGridCustom from '../../../../components/AgGridCustom'
import { PAGE_MODES } from '../../.././../constants'
import { PERMISSION_TYPE } from '../../../../constants/modules'
import ListPageFilterSection from '../../../../components/ListPageFilterSection'
import { hideLoader, showLoader, showConfirmMessage } from '../../../../services/loader/actions'
import Api from '../../../../services/aiqa/master/api'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import { isAxiosCancelError } from '../../../../utils'
import Query from './QueryBuilder'
import {
	ActionCellRenderer,
	CreatedCellRenderer,
	UpdatedCellRenderer,
	EmailCellRenderer,
	RoleCellRenderer,
	StatusCellRenderer,
	withCellRenderState,
} from './CustomCells'

import MaterialConfig from 'react-awesome-query-builder/lib/config/material'

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	switchLabel: {
		fontSize: '14px',
	},
	radioFormLabel: {
		fontSize: '15px',
		// marginBottom: theme.spacing(0.5),
		marginTop: '11px',
	},
	groupBorder: {
		border: 'ridge',
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		justifyContent: 'flex-end',
	},
	filterInc: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		flexDirection: 'row-reverse',
		// border: 'ridge',
	},
	grpInc: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		flexDirection: 'row-reverse',
		border: 'ridge',
	},
	groupButton: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
		marginLeft: '450px',
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	statusCellRoot: {
		verticalAlign: 'middle',
		'& span': {
			width: '8px',
			height: '8px',
			display: 'inline-block',
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		},
	},
	emailCellRoot: {
		verticalAlign: 'middle',
		'& > span': {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(1),
			fontSize: '18px',
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
}))

const Report = ({ width, action, userId, history, perms }) => {
	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		search_text: '',
		offset: 0,
		limit: 10,
		roleFilter: [],
		totalCount: 0,
		userList: [],
	})

	const [roleListState, setRoleListState] = useState({
		isFetchRoles: true,
		roleList: null,
	})
	const [currentUser, setCurrentUser] = useState('All')
	const [searchQuery, setsearchQuery] = useState('')
	const [actionType, setactionType] = useState('create')
	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		selectedData: null,
	})

	const { open, anchorEl, selectedData } = popOverState

	const isEditAllowed = _.get(perms, PERMISSION_TYPE.EDIT, false)
	const isDeleteAllowed = _.get(perms, PERMISSION_TYPE.DELETE, false)

	const InitialConfig = MaterialConfig

	InitialConfig.settings = {
		...InitialConfig.settings,
		...{
			addRuleLabel: 'Add Filter',
			showNot: false,
		},
		customFieldSelectProps: {
			showSearch: true,
		},
	}

	const config = {
		...InitialConfig,
		fields: {
			user_id: {
				label: 'User',
				type: 'select',
				valueSources: ['value'],
				defaultValue: undefined,
				fieldSettings: {
					asyncFetch: async (search) => {
						const toSearch = _.isEmpty(search) ? '' : search
						let pay_values = { search_text: toSearch }
						const usr = await Api.fetchReportUsr(pay_values)
						const usrValues = usr.data.data.map((usrs) => ({
							title: usrs.email,
							value: usrs.user_id,
						}))

						return {
							values: usrValues,
							// hasMore: true,
						}
					},
					useAsyncSearch: true,
					// useLoadMore: true,
					forceAsyncSearch: true,
					showSearch: true,
					validateValue: (val) => !!val,
				},
			},
			processed_date: {
				label: 'Date',
				type: 'date',
				valueSources: ['value'],
				preferWidgets: ['date'],
				operators: ['between'],
				fieldSettings: {
					dateFormat: 'DD-MM-YYYY',
					valueFormat: 'YYYY-MM-DD',
					validateValue: (val) => !!val,
				},
			},
		},
	}

	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))
	// const [openFormDialog, setopenFormDialog] = useState(false)
	const { search_text, loading, offset, totalCount, limit, roleFilter, rptStatusFilter, userList } = usersTblState
	const { isFetchRoles, roleList } = roleListState

	const isMounted = useRef(true)
	const searchBoxTimeout = useRef(undefined)
	const dispatch = useDispatch()

	const openFormDialog = !_.isEmpty(action)

	const changeToEditMode = (userId) => {
		history.replace({
			pathname: `/aiqa_tool/master/bb_category/parent_category/${PAGE_MODES.EDIT}/${userId}`,
		})
	}

	const handleCloseFormDialog = (e, isRefreshList) => {
		history.replace({
			pathname: `/aiqa_tool/master/bb_category/parent_category`,
			isRefreshList: _.isEqual(isRefreshList, true),
		})
	}

	const classes = useStyles()

	const closePopover = () => {
		setPopOverState({ ...popOverState, open: false })
	}

	const onClickMoreOptions = useCallback((event, data) => {
		setPopOverState({
			open: true,
			anchorEl: event.currentTarget,
			selectedData: data,
		})
	}, [])

	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		return [
			{
				headerName: 'Category Name',
				field: 'category',
				// cellRenderer: 'emailCellRenderer',
				cellRendererParams: { containerClass: emailCellRoot },
				hide: !isWidthUp('sm', width),
			},
			{
				headerName: 'Created Date',
				field: 'created_at',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Created By',
				field: 'created_by',
				// cellRenderer: 'createdCellRenderer',
				// cellRendererParams: {
				// 	containerClass: nameCellContent,
				// 	authToken: authToken,
				// 	isRenderMobView: _.isEqual('xs', width),
				// },
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Updated Date',
				field: 'updated_at',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Updated By',
				field: 'modified_by',
				// cellRenderer: 'updatedCellRenderer',
				// cellRendererParams: {
				// 	containerClass: nameCellContent,
				// 	authToken: authToken,
				// 	isRenderMobView: _.isEqual('xs', width),
				// },
				hide: !isWidthUp('md', width),
			},
			// {
			// 	headerName: '',
			// 	field: '',
			// 	maxWidth: 80,
			// 	cellRenderer: 'actionCellRenderer',
			// 	cellRendererParams: {
			// 		containerClass: actionCellContainer,
			// 		onClickMoreOptions,
			// 	},
			// },
		]
	}, [width, classes, authToken, onClickMoreOptions, roleList])

	const addBtnClick = () => {
		history.replace({ pathname: `/aiqa_tool/master/bb_category/parent_category/create` })
	}

	const onClickEditMenu = () => {
		closePopover()
		// let idx = selectedData?.test_id
		// setactionType('edit')
		changeToEditMode(_.get(selectedData, 'user_id'))
	}

	const onClickPermMenu = () => {
		closePopover()
		let idx = selectedData?.test_id
		history.push(`/ab_test_tool/view_test/${idx}`)
	}

	const deleteRoleFunc = useCallback(
		// (roleId) => {
		// 	dispatch(
		// 		deleteRole(
		// 			roleId,
		// 			() => {
		// 				//success
		// 				dispatch(hideLoader())
		// 				setRoleListState((prevState) => ({
		// 					...prevState,
		// 					loading: true,
		// 					roleList: [],
		// 				}))
		// 				// dispatch(showSnackbarWithTimeout('Deleted successfully!', 2000))
		// 			},
		// 			() => {
		// 				dispatch(hideLoader())
		// 			}
		// 		)
		// 	)
		// },
		[dispatch]
	)

	const onClickDeleteMenu = useCallback(() => {
		closePopover()
		dispatch(
			showConfirmMessage(
				// `Do you want to delete the "${_.get(selectedData, 'role_name')}" role?`,
				`Are you sure you want to delete this Sub Category?`,
				'',
				'Confirm',
				(e) => {
					dispatch(hideLoader())
					deleteRoleFunc(_.get(selectedData, 'role_id'))
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}, [dispatch, closePopover, deleteRoleFunc, selectedData])

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const onChangeSearchBox = (e) => {
		const search_text = e.target.value
		if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
		searchBoxTimeout.current = setTimeout(() => {
			addFilterAndRefreshTbl({ search_text, offset: 0 })
		}, 300)
	}

	const fetchReportList = ({ limit, offset, search_text }) => {
		// dispatch(showLoader('Please wait, getting reports'))
		Api.GetBBCategoryList(
			{
				limit,
				offset,
				search_text,
			},
			'parent_cat',
			(resp) => {
				const totalCount = _.get(resp, 'data.data.total_count', 0)
				const userList = _.get(resp, 'data.data.cat_datas', [])
				// const userList = results
				if (isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount,
						userList,
					}))
				}
				// dispatch(hideLoader())
			},
			(err) => {
				if (!isAxiosCancelError(err) && isMounted.current) {
					setUsersTblState((prevState) => ({
						...prevState,
						loading: false,
						totalCount: 0,
						userList: [],
					}))
				}
				// dispatch(hideLoader())
			}
		)
	}

	const exportList = ({ searchQuery, currentUser }) => {
		dispatch(
			showLoader('The Exporting process has been started and the Exported details of the report will be downloaded once it is exported completely')
		)
		Api.reportExport(
			{
				search_query: searchQuery,
				report_for: currentUser,
			},
			(response) => {
				const filename = response.headers['content-disposition'].split('filename=')[1]
				const contentType = response.headers['content-type']
				// response.data.then((blob) => {
				let blob = new Blob([response.data], { type: contentType })
				let url = window.URL.createObjectURL(blob)
				let a = document.createElement('a')
				a.href = url
				a.download = filename
				a.click()
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout('Something went wrong.', 2000))
			}
		)
	}

	useEffect(() => {
		// if (_.isNull(roleList)) fetchRolesList()
		// else
		fetchReportList({
			limit,
			offset,
			search_text,
		})
	}, [limit, offset, search_text])

	const onFilterClick = () => {
		fetchReportList({
			limit,
			offset,
			searchQuery,
		})
	}
	const onClearClick = (searchQuery) => {
		setsearchQuery(searchQuery)
		fetchReportList({
			limit,
			offset,
			searchQuery,
		})
	}

	const onExportClick = () => {
		exportList({
			searchQuery,
			currentUser,
		})
	}

	//Refresh function start
	const isNeedRefreshList = _.get(history, 'location.isRefreshList', false)
	useEffect(() => {
		if (isNeedRefreshList === true) {
			setUsersTblState((prevState) => {
				fetchReportList({
					limit: 10,
					offset: 0,
					search_text: prevState.search_text,
					roleFilter: [],
				})
				return {
					...prevState,
					loading: true,
					offset: 0,
					userList: [],
					roleFilter: [],
				}
			})
			_.set(history, 'location.isRefreshList', false)
		}
	}, [isNeedRefreshList, fetchReportList, history])
	//Refresh function end

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<Grid container className={classes.root} item xs={12}>
			<ListPageFilterSection
				disabled={true}
				searchPlaceholder={'Search By Category Name...'}
				addBtnClick={addBtnClick}
				// hideAddBtn={!isCreateAllowed}
				onChangeSearchBox={onChangeSearchBox}
				addBtnTxt={'Add Category'}
				hideAddBtn={false}
				hideSearch={false}
			>
				{/* <div className={classes.groupBorder}>
					<Query
						config={config}
						searchQuery={searchQuery}
						setsearchQuery={setsearchQuery}
						onClearClick={onClearClick}
						onFilterClick={onFilterClick}
						onExportClick={onExportClick}
					/>
				</div> */}
			</ListPageFilterSection>
			{/* <div> */}
			{/* </div> */}
			<Grid item xs={12}>
				<div>
					<AgGridCustom
						columnDefs={gridHeaders}
						rowData={userList}
						loading={loading}
						frameworkComponents={{
							createdCellRenderer: withCellRenderState(CreatedCellRenderer),
							updatedCellRenderer: withCellRenderState(UpdatedCellRenderer),
							roleCellRenderer: withCellRenderState(RoleCellRenderer),
							statusCellRenderer: withCellRenderState(StatusCellRenderer),
							emailCellRenderer: withCellRenderState(EmailCellRenderer),
							actionCellRenderer: withCellRenderState(ActionCellRenderer),
						}}
						disableClickSelectionRenderers={['actionCellRenderer']}
						// onRowClicked={onRowClicked}
						rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
						noDataTxt={'No categories found'}
					></AgGridCustom>
				</div>
				<div>
					{!loading && (
						<TablePagination
							component='div'
							count={totalCount}
							page={offset / limit}
							onChangePage={onChangePage}
							rowsPerPage={limit}
							onChangeRowsPerPage={onChangeRowsPerPage}
						/>
					)}
					<Popover
						id={'more_options'}
						open={open}
						anchorEl={anchorEl}
						onClose={closePopover}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuList autoFocusItem={open} id='options_list'>
							{isEditAllowed && (
								<MenuItem dense={true} onClick={onClickEditMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											edit
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Edit
									</Typography>
								</MenuItem>
							)}

							{isDeleteAllowed && (
								<MenuItem dense={true} onClick={onClickDeleteMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											delete
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Delete
									</Typography>
								</MenuItem>
							)}
						</MenuList>
					</Popover>
					<FormDialog
						open={openFormDialog}
						userId={userId}
						action={action}
						// distributors={distributors}
						// products={products}
						isRoleListLoading={isFetchRoles}
						roleList={roleList}
						isEditAllowed={isEditAllowed}
						changeToEditMode={changeToEditMode}
						handleClose={handleCloseFormDialog}
					></FormDialog>
				</div>
			</Grid>
		</Grid>
	)
}

Report.propTypes = {
	action: PropTypes.string,
	userId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Report)
