import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import classnames from 'classnames'
import {
	Grid,
	TablePagination,
	Popover,
	Typography,
	MenuList,
	MenuItem,
	ListItemIcon,
	Icon,
	Radio,
	RadioGroup,
	FormControlLabel,
	Badge,
	Fab,
	Grow,
	Divider,
} from '@material-ui/core'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
// import FilterMenuList from './FilterMenu'
import api from '../../../services/bot/api'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { hideLoader, showLoader } from '../../../services/loader/actions'
import LoadingIndicator from '../../../components/LoadingIndicator'
import FlagComponent from '../../../components/Flags'
// import { data1, data2 } from './data'
// import BarChart from './Chart'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		// overflowX: 'auto',
		margin: '16px auto',
	},
	table: {
		minWidth: 650,
		'& .MuiTableCell-root': {
			// borderLeft: '2px solid #ececec',
			padding: '8px',
		},
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
	head: {
		backgroundColor: '#fbfbfd',
		color: 'gray',
		minWidth: '150px',
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 2,
		// '& .MuiTableCell-root': {
		// 	borderLeft: '2px solid #41e886',
		// 	borderBottom: '1px solid #41e886',
		// },
	},
	body: {
		// backgroundColor: '#ddd',
		minWidth: '150px',
		left: 0,
		position: 'sticky',
		zIndex: theme.zIndex.appBar + 1,
		// '& .MuiTableCell-root': {
		// 	borderLeft: '2px solid #ddd',
		// 	borderBottom: '1px solid #ddd',
		// },
	},
	cellWidth: {
		width: '120px',
	},
	timecellWidth: {
		width: '200px',
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
		margin: '16px auto',
	},
	Title: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		// textDecoration: 'underline',
		margin: '16px auto',
	},
	tableHead: {
		backgroundColor: '#F9FAFB',
		color: '#8181a5',
		height: '2.5rem',
		fontSize: '.8rem',
	},
	divide: {
		borderTop: '1px solid #F6F6F6',
	},
}))

const StickyTableCell = withStyles((theme) => ({
	// head: {
	// 	backgroundColor: theme.palette.common.black,
	// 	color: theme.palette.common.white,
	// 	left: 0,
	// 	position: 'sticky',
	// 	zIndex: theme.zIndex.appBar + 2,
	// },
	// body: {
	// 	backgroundColor: '#ddd',
	// 	minWidth: '50px',
	// 	left: 0,
	// 	position: 'sticky',
	// 	zIndex: theme.zIndex.appBar + 1,
	// },
}))(TableCell)

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}))

const TablePaginationActions = (props) => {
	const classes = useStyles1()
	const theme = useTheme()
	const { count, page, rowsPerPage, onPageChange } = props

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0)
	}

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1)
	}

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1)
	}

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	return (
		<div className={classes.root}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='next page'>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label='last page'>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	)
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
}

export default function SimpleTable({ data }) {
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const [filter, setFilter] = useState({ searchText: '', filterData: { layer: 'l1' } })
	const { filterData } = filter
	const dispatch = useDispatch()
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(filterData, _.isEmpty)), [filterData])

	const [cardData, setCardData] = useState([])
	const [tableData, settableData] = useState([])
	const [currentPage, setCurrentPage] = React.useState(0)
	const [TblState, setTblState] = useState({
		loading: true,
		offset: 0,
		limit: 10,
		totalCount: 0,
	})
	const { loading, offset, totalCount, limit } = TblState
	const [count, setCount] = React.useState(0)
	const [resAll, setresAll] = React.useState(false)
	const [chartData, setchartData] = React.useState({})
	const [summaryData, setsummaryData] = React.useState({})
	const [enupdate, setEnupdate] = React.useState(false)
	const [Okfilter, setOkfilter] = React.useState({})
	const [matchtype, setMatchtype] = React.useState('OEM')
	const [filters, setFilters] = React.useState({
		// layer: data2,
		// status: data1,
		user_id: [],
		distributor_id: [],
	})
	const [incExc, setincExc] = React.useState({
		layer: 'include',
		status: 'include',
		user_id: 'include',
		distributor_id: 'include',
		processed_date: 'include',
	})
	const renderAfterCalled = useRef(false)

	const addFilterAndRefreshTbl = (filterParams) => {
		setTblState((prevState) => ({
			...prevState,
			loading: true,
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		addFilterAndRefreshTbl({ offset: pageNo * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const formatNumber = (n) => {
		if (n < 1e3) return n
		if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
		if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
		if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
		if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
	}

	const formatColor = (n) => {
		if (n >= 80) return '#13bb70'
		if (n >= 60 && n < 80) return '#f6b73e'
		if (n > 60) return 'red'
	}

	const ScrapperLog = () => {
		let pay_values = {
			// search_text: '',

			filters: [
				{
					column: 'processed_date',
					data: filterData.processed_date ? [JSON.stringify(filterData.processed_date)] : [],
					method: incExc.processed_date,
					type: 'range',
				},
				{
					column: 'layer',
					data: filterData.layer ? [filterData.layer] : ['l1'],
					method: incExc.layer,
					type: 'str',
				},
				{
					column: 'status',
					data: filterData.status ? filterData.status : [],
					method: incExc.status,
					type: 'str',
				},
				{
					column: 'user_id',
					data: filterData.user_id ? filterData.user_id : [],
					method: incExc.user_id,
					type: 'str',
				},
				{
					column: 'distributor_id',
					data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
					method: incExc.distributor_id,
					type: 'int',
				},
			],
			all: _.isEmpty(filterData) ? true : false,
			offset: offset,
			limit: limit,
		}

		api.ChartData(
			pay_values,
			(res) => {
				let resp = res.data.data
				// setmatchmissData(resp)
				setchartData((prevState) => ({
					...prevState,
					...resp,
				}))
				// console.log(resp, res.data, 'resp')
			},
			(err) => {}
		)
		dispatch(showLoader('Please wait, getting details'))
		api.SummaryData(
			pay_values,
			(res) => {
				let resp = res.data.data
				// setmatchmissData(resp)
				// setsummaryData((prevState) => ({
				// 	...prevState,
				// 	...resp,
				// }))
				setsummaryData(resp)
				dispatch(hideLoader())
				// console.log(resp, res.data, 'resp')
			},
			(err) => {
				dispatch(hideLoader())
			}
		)
	}

	const updateClick = () => {
		let pay_values = {
			// search_text: '',

			filters: [
				{
					column: 'processed_date',
					data: filterData.processed_date ? [JSON.stringify(filterData.processed_date)] : [],
					method: incExc.processed_date,
					type: 'range',
				},
				{
					column: 'layer',
					data: filterData.layer ? [filterData.layer] : ['l1'],
					method: incExc.layer,
					type: 'str',
				},
				{
					column: 'status',
					data: filterData.status ? filterData.status : [],
					method: incExc.status,
					type: 'str',
				},
				{
					column: 'user_id',
					data: filterData.user_id ? filterData.user_id : [],
					method: incExc.user_id,
					type: 'str',
				},
				{
					column: 'distributor_id',
					data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
					method: incExc.distributor_id,
					type: 'int',
				},
			],
			all: _.isEmpty(filterData) ? true : false,
			offset: offset,
			limit: limit,
		}
		addFilterAndRefreshTbl({ loading: true })
		api.GetTable(
			pay_values,
			(res) => {
				let resp = res.data.data.layer_data
				settableData(resp)

				if (_.isEmpty(resp)) {
					addFilterAndRefreshTbl({ totalCount: res.data.data.total_count, loading: false })
					dispatch(showSnackbarWithTimeout('No data found.', 2000))
				} else {
					addFilterAndRefreshTbl({ totalCount: res.data.data.total_count, loading: false })
				}
				setEnupdate(false)
			},
			(err) => {
				addFilterAndRefreshTbl({ loading: false })
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	const initialFilter = () => {
		let filtArray = ['user_id', 'distributor_id']
		filtArray.forEach((key) => {
			let pay_values = {
				search_text: '',
				all: true,
			}

			api.GetFilters(
				pay_values,
				key,
				(res) => {
					let resp = res.data.data
					// setmatchmissData(resp)
					setFilters((prevState) => ({
						...prevState,
						...resp,
					}))
				},
				(err) => {}
			)
		})
		// })
		// renderAfterCalled.current = true
		// console.log('calling...')
	}

	useEffect(() => {
		ScrapperLog()
	}, [resAll])

	useEffect(() => {
		updateClick()
	}, [resAll, offset, limit])

	useEffect(() => {
		if (!renderAfterCalled.current) {
			// alert('1')
			initialFilter()
		}
		renderAfterCalled.current = true
	}, [])

	const updateFilter = useCallback(
		(value) => {
			let formatedData = {
				...value,
			}
			setFilter((prevState) => ({ ...prevState, filterData: formatedData }))
			// setOkfilter((prevState) => ({ ...prevState, ...value }))
			let data = _.omitBy(formatedData, _.isEmpty)
		},
		[dispatch]
	)
	const updateOKFilter = useCallback(
		(value, path) => {
			let formatedData = {
				...value,
			}
			// setFilter((prevState) => ({ ...prevState, filterData: formatedData }))

			setOkfilter(value)

			if (value === '') {
				setEnupdate(true)
			}
			let data = _.omitBy(formatedData, _.isEmpty)
		},
		[dispatch]
	)
	const classes = useStyles()

	const onChangeFilter = (path, fn) => {
		// if (path === 'processed_date') {
		// 	if (fn === 'reset') {
		// 		setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['layer']) }))
		// 	} else {
		// 		setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date','layer']) }))
		// 	}

		// 	// updateClick()
		// }
		if (path === 'layer') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer']) }))
			}

			// updateClick()
		}
		if (path === 'status') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer', 'status']) }))
			}

			// updateClick()
		}
		if (path === 'user_id') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer', 'status']) }))
			} else {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer', 'status', 'user_id']) }))
			}

			// updateClick()
		}
		if (path === 'distributor_id') {
			if (fn === 'reset') {
				setFilter((prevState) => ({ ...prevState, filterData: _.pick(filterData, ['processed_date', 'layer', 'status', 'user_id']) }))
			} else {
				setFilter((prevState) => ({
					...prevState,
					filterData: _.pick(filterData, ['processed_date', 'layer', 'status', 'user_id', 'distributor_id']),
				}))
			}
			// updateClick()
		}
	}

	const onOkClick = (path) => {
		// if (path === 'processed_date' || path === 'distributor_id') {
		ScrapperLog()
		addFilterAndRefreshTbl({ limit: limit, offset: 0 })
		updateClick()

		// }
		let pay_values = {}
		let filtArray = []
		let initial_values = [
			{
				column: 'processed_date',
				data: filterData.processed_date ? [JSON.stringify(filterData.processed_date)] : [],
				method: incExc.processed_date,
				type: 'range',
			},
			{
				column: 'layer',
				data: filterData.layer ? [filterData.layer] : ['l1'],
				method: incExc.layer,
				type: 'str',
			},
			{
				column: 'status',
				data: filterData.status ? filterData.status : [],
				method: incExc.status,
				type: 'str',
			},
			{
				column: 'user_id',
				data: filterData.user_id ? filterData.user_id : [],
				method: incExc.user_id,
				type: 'str',
			},
			{
				column: 'distributor_id',
				data: filterData.distributor_id ? filterData.distributor_id.map(String) : [],
				method: incExc.distributor_id,
				type: 'int',
			},
		]
		if (path === 'processed_date') {
			pay_values = {
				onchange_for: 'processed_date',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['processed_date', 'layer'], column)])),
			}
			filtArray = ['distributor_id', 'user_id']
		}
		if (path === 'layer') {
			pay_values = {
				onchange_for: 'layer',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['processed_date', 'layer'], column)])),
			}
			filtArray = ['distributor_id', 'user_id']
		}
		if (path === 'status') {
			pay_values = {
				onchange_for: 'status',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['processed_date', 'layer', 'status'], column)])),
			}
			filtArray = ['distributor_id', 'user_id']
		}
		if (path === 'user_id') {
			pay_values = {
				onchange_for: 'user_id',
				filters: _.filter(initial_values, ({ column }) => _.every([_.includes(['processed_date', 'layer', 'status', 'user_id'], column)])),
			}
			filtArray = ['distributor_id']
		}

		filtArray.forEach((key) => {
			api.filterOnChange(
				pay_values,
				key,
				(res) => {
					let resp = res.data.data
					// setmatchmissData(resp)
					setFilters((prevState) => ({
						...prevState,
						...resp,
					}))
					// setValue(!_.isEmpty(resp.mpn) ? resp.mpn : !_.isEmpty(resp.ean) ? resp.ean : resp.product_title)
					// console.log(resp, key)
				},
				(err) => {}
			)
		})
	}

	const flag = (text) => {
		if (text === 'Completed') {
			return <FlagComponent text={text} color='secondary' />
		}
		if (text === 'In progress') {
			return <FlagComponent text={text} color='success' />
		}
	}

	return (
		<div>
			<div style={{ padding: '16px' }}>
				<TableContainer>
					<Table className={classes.table} aria-label='simple table'>
						<TableHead className={classes.tableHead}>
							<TableRow>
								<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
									A/B Test ID
								</TableCell>
								<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
									Test Name
								</TableCell>
								<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
									Test Type
								</TableCell>
								<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
									Date Added
								</TableCell>
								<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
									Date Modified
								</TableCell>
								<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
									Updated Date
								</TableCell>
								<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161', fontFamily: 'system-ui', fontWeight: '600' }}>
									Updated By
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{loading ? (
								<TableRow>
									<TableCell colSpan={8}>
										<LoadingIndicator></LoadingIndicator>
									</TableCell>
								</TableRow>
							) : _.isEmpty(data) ? (
								<TableRow>
									<TableCell colSpan={5}></TableCell>
									<TableCell>No Data Found</TableCell>
								</TableRow>
							) : (
								data?.map((row) => (
									<TableRow key={row.name}>
										<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
											{row.test_id}
										</TableCell>
										<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
											{row.test_name}
										</TableCell>
										<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
											{row.flix_product_type}
										</TableCell>
										<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
											{row.created_date}
										</TableCell>
										<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
											{row.created_date}
										</TableCell>
										<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
											{row.modified_date}
										</TableCell>
										<TableCell align='left' style={{ fontSize: '.75rem', color: '#616161' }} className={classes.timecellWidth}>
											{row.updated_by_name}
										</TableCell>
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	)
}
