

import { SAGA_ACTIONS } from '../../../constants'

export const fetchAllRoles = (filters, successCallback, errorCallback) => ({
	type: SAGA_ACTIONS.FETCH_ALL_ROLES,
	payload: { filters, successCallback, errorCallback },
})

export const createRole = (roleDetails, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.CREATE_ROLE,
	payload: { roleDetails, successCallback, dialogCloseBtnCallback },
})

export const fetchRole = (roleId, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.FETCH_ROLE,
	payload: { roleId, successCallback, dialogCloseBtnCallback },
})

export const updateRole = (roleId, roleDetails, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.UPDATE_ROLE,
	payload: { roleId, roleDetails, successCallback, dialogCloseBtnCallback },
})

export const deleteRole = (roleId, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.DELETE_ROLE,
	payload: { roleId, successCallback, dialogCloseBtnCallback },
})
