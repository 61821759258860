

import React, { useMemo } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import { PAGE_MODES } from '../../../../constants'

const Validator = React.forwardRef((props, ref) => {
	const initialValues = _.isEmpty(props.initialFormData) ? { role_name: '', is_default: false, modules: [] } : props.initialFormData

	const validateForm = (values) => {
		let errors = {}
		let regex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
		const roleName = _.get(values, 'role_name')
		if (_.isEmpty(_.trim(roleName))) {
			_.set(errors, 'role_name', 'Please enter the Role name')
		} else if (regex && !regex.test(roleName)) {
			_.set(errors, 'role_name', 'Role name is invalid')
		}
		return errors
	}

	//Initial validation solution for edit screen
	const formExtraProps = useMemo(() => {
		return props.formMode === PAGE_MODES.EDIT ? { validateOnMount: _.isEmpty(validateForm(initialValues)) } : {}
	}, [props.formMode, initialValues])

	return (
		<Formik innerRef={ref} initialValues={initialValues} validate={validateForm} onSubmit={props.onSubmit || null} {...formExtraProps}>
			{props.children}
		</Formik>
	)
})

export default Validator
