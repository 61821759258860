import { Avatar, Box, Button, Grid, Icon, makeStyles, Paper, TextField, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Field } from 'formik'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import SelectBox from '../../../../components/SelectBox'
import { RETAILER_MATCH_TYPE_PM } from '../../../../constants'
import Api from '../../../../services/prmatching/api'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'
import Validator from './validator'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	textField: {
		marginTop: theme.spacing(2.5),
		maxWidth: 300,
		margin: '0 auto',
	},
	selectField: {
		marginTop: '10px',
		maxWidth: 300,
		margin: '0 auto',
	},
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	paper: {
		maxWidth: 500,
		margin: '16px auto',
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
}))

function ChangePassword() {
	const classes = useStyles()
	const [isPwdUpdated] = useState(false)
	const renderAfterCalled = useRef(false)
	const [distributors, setDistributors] = useState([])
	const [distriSrLoad, setdistriSrLoad] = useState(false)
	const [searchkey, setSearchkey] = useState('')
	const searchBoxTimeout = useRef(undefined)
	const dispatch = useDispatch()
	const formRef = useRef(null)
	const formFields = [
		{ name: 'mpn', label: 'Matching SKU*', type: 'text' },
		{ name: 'product_id', label: 'Product Id*', type: 'text' },
	]

	useEffect(() => {
		// if (!renderAfterCalled.current) {
		let pay_values = {
			search_text: searchkey,
		}
		setdistriSrLoad(true)
		Api.matchDistributor(pay_values)
			.then((res) => {
				let resp = _.map(res.data.data.retailers, (retailer) => ({
					id: _.toString(retailer.distributor_id),
					name: `${retailer.title} ---- (${retailer.distributor_id})`,
				}))
				setDistributors(resp)
				setdistriSrLoad(false)
			})
			.catch((err) => {
				// dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
				setdistriSrLoad(false)
			})
		// }
		// renderAfterCalled.current = true
	}, [searchkey])

	const onSubmitForm = (values) => {
		Api.matchSubmit(
			values,
			(resp) => {
				dispatch(showSnackbarWithTimeout(resp.data.message, 2000))
				formRef.current.resetForm()
			},
			(err) => {
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	const onClickRst = () => {
		formRef.current && formRef.current.resetForm()
	}
	const onClickSubmit = () => {
		formRef.current && formRef.current.submitForm()
	}

	const onInputSRChange = (e) => {
		if (e !== null) {
			if (e.target.value !== 0) {
				if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
				searchBoxTimeout.current = setTimeout(() => {
					// let product_id = e.target.value
					// setProdSearch(e.target.value)
					setSearchkey(e.target.value)
				}, 1500)
			}
		}
	}

	return (
		// <Paper className={classes.paper} variant='outlined' square>
		// 	<Grid container className={classes.gridWrapper}>
		// 		<Grid item xs={12} className={classes.tabPanelWrapper}>
		// 			<Box className={classes.contentWrapper} component='div'>
		<>
			{isPwdUpdated ? (
				<>
					<Avatar alt={'success'} className={classes.successIco}>
						<Icon>done</Icon>
					</Avatar>
					<Typography variant='body1' gutterBottom>
						Password Updated Successfully
					</Typography>
				</>
			) : (
				<>
					<Validator onSubmit={onSubmitForm} ref={formRef}>
						{(formik) => {
							return (
								<form>
									{_.map(formFields, (formField) => (
										<Field key={formField.name} type='text' name={formField.name}>
											{({ form, field }) => {
												return (
													<TextField
														variant='outlined'
														margin='none'
														size='small'
														fullWidth
														name={field.name}
														label={formField.label}
														type={formField.type}
														autoComplete='off'
														onChange={(e) => {
															e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
														}}
														onBlur={field.onBlur}
														value={field.value || ''}
														className={classes.textField}
														helperText={form.touched[field.name] && form.errors[field.name]}
														error={Boolean(form.errors[field.name] && form.touched[field.name])}
													/>
												)
											}}
										</Field>
									))}
									<Field key={'match_for'} name={'match_for'}>
										{({ form, field }) => {
											return (
												<SelectBox
													label='Match Type'
													value={field.value}
													onChange={(e) => {
														e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
													}}
													inputClass={classes.selectField}
													name='match_for'
													onBlur={field.onBlur}
													// disabled={disabled}
													helperText={form.touched[field.name] && form.errors[field.name]}
													isError={Boolean(form.errors[field.name] && form.touched[field.name])}
													options={_.values(RETAILER_MATCH_TYPE_PM)}
												></SelectBox>
											)
										}}
									</Field>
									<Field key={'action_type'} name={'action_type'}>
										{({ form, field }) => {
											return (
												<FormControl component='fieldset' style={{ textAlign: 'center', marginTop: '10px' }}>
													<RadioGroup
														row
														aria-label='position'
														name='action_type'
														value={field.value}
														onChange={(e) => {
															e.target.value === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e.target.value)
														}}
													>
														<FormControlLabel value='add' control={<Radio size='small' color='primary' />} label='Add' />
														<FormControlLabel value='remove' control={<Radio size='small' color='primary' />} label='Remove' />
													</RadioGroup>
												</FormControl>
											)
										}}
									</Field>
									<Field key={'distributor_id'} name={'distributor_id'}>
										{({ form, field }) => {
											return (
												<SelectBox
													label='Distributor'
													value={field.value}
													onChange={(e) => {
														e === ' ' ? form.setFieldValue(field.name, '') : form.setFieldValue(field.name, e)
													}}
													onInputChange={(e) => onInputSRChange(e)}
													isLoading={distriSrLoad}
													inputClass={classes.selectField}
													name='distributor_id'
													onBlur={field.onBlur}
													// disabled={disabled}
													helperText={form.touched[field.name] && form.errors[field.name]}
													isError={Boolean(form.errors[field.name] && form.touched[field.name])}
													options={distributors}
													noOptionsText='No Options'
												></SelectBox>
											)
										}}
									</Field>
								</form>
							)
						}}
					</Validator>
					<div style={{ textAlign: 'center', marginTop: '40px' }}>
						<Button
							onClick={onClickSubmit}
							color='primary'
							variant='text'
							size='medium'
							disableElevation
							style={{ padding: 'inherit', marginRight: '40px', fontWeight: 'bold' }}
						>
							Proceed
						</Button>
						<Button
							onClick={onClickRst}
							color='primary'
							variant='text'
							size='medium'
							disableElevation
							style={{ padding: 'inherit', fontWeight: 'bold' }}
						>
							Reset
						</Button>
					</div>
				</>
			)}
		</>
		// 			</Box>
		// 		</Grid>
		// 	</Grid>
		// </Paper>
	)
}

export default ChangePassword
