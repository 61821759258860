import { Button, Grid, Icon, ListItemIcon, MenuItem, MenuList, Popover, TablePagination, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import Pagination from '@material-ui/lab/Pagination'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import AgGridCustom from '../../../components/AgGridCustom'
import ListPageFilterSection from '../../../components/ListPageFilterSection'
import { PERMISSION_TYPE } from '../../../constants/modules'
import { fetchAllTests } from '../../../services/abtestresults/actions'
import api from '../../../services/abtestresults/api'
import { hideLoader, showConfirmMessage, showErrorMessage, showLoader } from '../../../services/loader/actions'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { isAxiosCancelError } from '../../../utils'
import {
	ActionCellRenderer,
	CreatedCellRenderer,
	EmailCellRenderer,
	RoleCellRenderer,
	StatusCellRenderer,
	UpdatedCellRenderer,
	withCellRenderState,
} from './CustomCells'
import CustomTable from './CustomTable'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const USER_STATUS_FILTER = {
	ALL: 'all',
	ACTIVE: 'active',
	INACTIVE: 'inactive',
}

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 80,
		},
	},
	switchLabel: {
		fontSize: '14px',
	},
	radioFormLabel: {
		fontSize: '15px',
		marginBottom: theme.spacing(0.5),
	},
	advFilterContent: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		'& > div': {
			flex: '1',
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
				width: '100%',
			},
		},
	},
	actionCellContainer: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'center',
		'& a': {
			display: 'block',
			lineHeight: 'initial',
			color: theme.palette.primary.main,
		},
	},
	nameCellContent: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		justifyContent: 'flex-start',
		'& .avatar-span': {
			width: theme.spacing(4),
			height: theme.spacing(4),
			fontSize: '14px',
			marginRight: theme.spacing(1),
		},
		'& .m-details': {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
		},
		'& .details-span': {
			lineHeight: 'unset',
			'& span': {
				lineHeight: 'inherit',
			},
		},
	},
	statusCellRoot: {
		verticalAlign: 'middle',
		'& span': {
			width: '8px',
			height: '8px',
			display: 'inline-block',
			borderRadius: '50%',
			marginRight: theme.spacing(1),
		},
	},
	emailCellRoot: {
		verticalAlign: 'middle',
		'& > span': {
			verticalAlign: 'middle',
			marginLeft: theme.spacing(1),
			fontSize: '18px',
		},
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
	},
	menuText: {
		fontSize: '13px',
	},
}))

const Results = ({ width, action, userId, history, perms, result }) => {
	const initialState = {
		loading: true,
		search_text: '',
		offset: 0,
		limit: 10,
		status: result,
		search_field: ['test_name'],
		totalCount: 0,
		testList: [],
	}
	const [usersTblState, setUsersTblState] = useState({
		loading: true,
		search_text: '',
		offset: 0,
		limit: 10,
		status: result,
		search_field: ['test_name', 'test_id'],
		totalCount: 0,
		testList: [],
	})
	const [roleListState, setRoleListState] = useState({
		isFetchRoles: true,
		roleList: null,
	})
	const [currentUser, setCurrentUser] = useState('All')
	const [page, setPage] = useState(1)
	const [popOverState, setPopOverState] = useState({
		open: false,
		anchorEl: null,
		selectedData: null,
	})
	const { open, anchorEl, selectedData } = popOverState

	const isEditAllowed = _.get(perms, PERMISSION_TYPE.EDIT, false)
	const isDeleteAllowed = _.get(perms, PERMISSION_TYPE.DELETE, false)

	const authToken = useSelector((state) => _.get(state, 'session.authToken', ''))

	const { search_text, loading, offset, totalCount, limit, roleFilter, usrStatusFilter, testList, status, search_field } = usersTblState
	const { isFetchRoles, roleList } = roleListState

	const isMounted = useRef(true)
	const searchBoxTimeout = useRef(undefined)
	const dispatch = useDispatch()

	const classes = useStyles()

	const closePopover = () => {
		setPopOverState({ ...popOverState, open: false })
	}

	const onClickMoreOptions = useCallback((event, data) => {
		setPopOverState({
			open: true,
			anchorEl: event.currentTarget,
			selectedData: data,
		})
	}, [])

	const gridHeaders = useMemo(() => {
		const { nameCellContent, actionCellContainer, emailCellRoot, statusCellRoot } = classes
		return [
			{
				headerName: 'A/B Test ID',
				field: 'test_id',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				sortable: true,
			},
			{
				headerName: 'Test Name',
				field: 'test_name',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				sortable: true,
			},
			{
				headerName: 'Test Type',
				field: 'flix_product_type',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Created Date',
				field: 'created_date',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Created By',
				field: 'created_by_name',
				// cellRenderer: 'roleCellRenderer',
				cellRenderer: 'createdCellRenderer',
				cellRendererParams: {
					containerClass: nameCellContent,
					authToken: authToken,
					isRenderMobView: _.isEqual('xs', width),
				},
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Updated Date',
				field: 'modified_date',
				// cellRenderer: 'roleCellRenderer',
				cellRendererParams: { roleList },
				hide: !isWidthUp('md', width),
			},
			{
				headerName: 'Updated By',
				field: 'updated_by_name',
				// cellRenderer: 'roleCellRenderer',
				cellRenderer: 'updatedCellRenderer',
				cellRendererParams: {
					containerClass: nameCellContent,
					authToken: authToken,
					isRenderMobView: _.isEqual('xs', width),
				},
				hide: !isWidthUp('md', width),
			},
			{
				headerName: '',
				field: '',
				maxWidth: 80,
				cellRenderer: 'actionCellRenderer',
				cellRendererParams: {
					containerClass: actionCellContainer,
					onClickMoreOptions,
				},
			},
		]
	}, [width, classes, authToken, onClickMoreOptions, roleList])

	// const onRowClicked = (e) => {
	// 	const isSuppressed = e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection
	// 	if (!isSuppressed) openExistingUser(_.get(e, 'data.user_id'), PAGE_MODES.VIEW)
	// }

	const addBtnClick = () => {
		history.replace({ pathname: `/ab-test-tool/create_test` })
	}

	const resetBtn = () => {
		setUsersTblState(initialState)
	}

	const onClickEditMenu = () => {
		closePopover()
		let idx = selectedData?.test_id
		history.push(`/ab_test_tool/edit_test/${idx}`)
		// changeToEditMode(_.get(selectedData, 'user_id'))
	}

	const onClickPermMenu = () => {
		closePopover()
		let idx = selectedData?.test_id
		history.push(`/ab_test_tool/view_test/${idx}`)
	}

	const onClickDelMenu = () => {
		let idx = selectedData?.test_id
		closePopover()
		dispatch(
			showConfirmMessage(
				`Are you sure, you want to delete this Test?`,
				'',
				'Confirm',
				(e) => {
					dispatch(showLoader('Please wait...'))
					api.deleteTst(
						idx,
						(res) => {
							dispatch(hideLoader())
							fetchUsersList({
								limit,
								offset,
								search_text,
								search_field,
								status,
							})
							dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Something went wrong. Please try again!'), 2000))
						},
						(err) => {
							dispatch(hideLoader())
							dispatch(
								showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
									dispatch(hideLoader())
								})
							)
						}
					)
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				}
			)
		)
	}

	const onClickMovMenu = () => {
		let idx = selectedData?.test_id
		let stat = result === 'live' ? 'completed' : 'live'
		closePopover()
		dispatch(
			showConfirmMessage(
				`Are you sure, you want to move this Test?`,
				'',
				'Confirm',
				(e) => {
					dispatch(showLoader('Please wait...'))
					api.moveStat(
						idx,
						stat,
						(res) => {
							dispatch(hideLoader())
							fetchUsersList({
								limit,
								offset,
								search_text,
								search_field,
								status,
							})
							dispatch(showSnackbarWithTimeout(_.get(res, 'data.more_info', 'Something went wrong. Please try again!'), 2000))
						},
						(err) => {
							dispatch(hideLoader())
							dispatch(
								showErrorMessage(_.get(err, 'response.data.more_info', 'Something went wrong. Please try again!'), 'Close', () => {
									dispatch(hideLoader())
								})
							)
						}
					)
				},
				'Cancel',
				(e) => {
					dispatch(hideLoader())
				},
				'drive_file_move'
			)
		)
	}

	const addFilterAndRefreshTbl = (filterParams) => {
		setUsersTblState((prevState) => ({
			...prevState,
			loading: true,
			userList: [],
			...filterParams,
		}))
	}

	const onChangePage = (e, pageNo) => {
		console.log(pageNo, 'page')
		setPage(pageNo)
		let page = pageNo - 1
		addFilterAndRefreshTbl({ offset: page * limit })
	}

	const onChangeRowsPerPage = (e) => {
		addFilterAndRefreshTbl({ limit: e.target.value, offset: 0 })
	}

	const onChangeSearchBox = (e) => {
		const search_text = e.target.value
		if (searchBoxTimeout.current) clearTimeout(searchBoxTimeout.current)
		searchBoxTimeout.current = setTimeout(() => {
			addFilterAndRefreshTbl({ search_text, offset: 0 })
		}, 300)
	}

	const onChangeRoleSelectBox = (event, roles) => {
		addFilterAndRefreshTbl({ roleFilter: roles, offset: 0 })
	}

	const fetchUsersList = useCallback(
		({ limit, offset, search_text, search_field, status }) => {
			dispatch(
				fetchAllTests(
					{
						limit,
						offset,
						search_text,
						search_field,
						status,
					},
					(resp) => {
						const totalCount = _.get(resp, 'data.data.total_count', 0)
						const testList = _.get(resp, 'data.data.abtests', [])
						if (isMounted.current) {
							setUsersTblState((prevState) => ({
								...prevState,
								loading: false,
								totalCount,
								testList,
							}))
						}
					},
					(err) => {
						if (!isAxiosCancelError(err) && isMounted.current) {
							setUsersTblState((prevState) => ({
								...prevState,
								loading: false,
								totalCount: 0,
								testList: [],
							}))
						}
					}
				)
			)
		},
		[dispatch]
	)

	useEffect(() => {
		fetchUsersList({
			limit,
			offset,
			search_text,
			search_field,
			status,
		})
	}, [limit, offset, search_text, result, fetchUsersList])

	//Refresh function start
	const isNeedRefreshList = _.get(history, 'location.isRefreshList', false)
	useEffect(() => {
		if (isNeedRefreshList) {
			setUsersTblState((prevState) => {
				fetchUsersList({
					limit: 10,
					offset: 0,
					search_text: prevState.search_text,
					roleFilter: [],
					usrStatusFilter: USER_STATUS_FILTER.ALL,
				})
				return {
					...prevState,
					loading: true,
					offset: 0,
					userList: [],
					roleFilter: [],
					usrStatusFilter: USER_STATUS_FILTER.ALL,
				}
			})
			_.set(history, 'location.isRefreshList', false)
		}
	}, [isNeedRefreshList, fetchUsersList, history])
	//Refresh function end

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	return (
		<Grid container className={classes.root} item xs={12}>
			<Grid item xs={12}>
				<div>
					{/* <AgGridCustom
						columnDefs={gridHeaders}
						rowData={testList}
						loading={loading}
						frameworkComponents={{
							createdCellRenderer: withCellRenderState(CreatedCellRenderer),
							updatedCellRenderer: withCellRenderState(UpdatedCellRenderer),
							roleCellRenderer: withCellRenderState(RoleCellRenderer),
							statusCellRenderer: withCellRenderState(StatusCellRenderer),
							emailCellRenderer: withCellRenderState(EmailCellRenderer),
							actionCellRenderer: withCellRenderState(ActionCellRenderer),
						}}
						disableClickSelectionRenderers={['actionCellRenderer']}
						// onRowClicked={onRowClicked}
						rowHeight={_.isEqual(width, 'xs') ? 60 : 50}
						noDataTxt={!_.isEqual(currentUser, 'All') ? `No ${currentUser} test found` : 'No tests found'}
					></AgGridCustom> */}
					<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
						<div style={{ border: '1px solid #E5E8EB', borderRadius: '4px' }}>
							<InputBase
								className={classes.input}
								placeholder='Search...'
								onChange={onChangeSearchBox}
								style={{ width: '205px', height: '40px' }}
								inputProps={{ 'aria-label': 'search google maps' }}
							/>
							<IconButton type='submit' className={classes.iconButton} aria-label='search'>
								<SearchIcon />
							</IconButton>
						</div>
						<div>
							{!loading && (
								<TablePagination
									component='div'
									count={totalCount}
									page={offset / limit}
									onPageChange={onChangePage}
									rowsPerPage={limit}
									onRowsPerPageChange={onChangeRowsPerPage}
									labelRowsPerPage='Results Per Page'
									labelDisplayedRows={({ from, to, count }) => ``}
									nextIconButtonProps={{ style: { display: 'none' } }}
									backIconButtonProps={{ style: { display: 'none' } }}
									// rowsPerPageOptions={[]}
								/>
							)}
						</div>
					</div>
					<CustomTable data={testList} />

					{!loading && (
						<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
							<TablePagination
								component='div'
								count={totalCount}
								page={offset / limit}
								onPageChange={onChangePage}
								rowsPerPage={limit}
								onRowsPerPageChange={onChangeRowsPerPage}
								labelRowsPerPage='Results Per Page'
								labelDisplayedRows={({ from, to, count }) => `Showing ${from}-${to} results of ${count !== -1 ? count : `MORE THAN ${to}`}`}
								nextIconButtonProps={{ style: { display: 'none' } }}
								backIconButtonProps={{ style: { display: 'none' } }}
								rowsPerPageOptions={[]}
							/>
							{/* <TablePagination
								component='div'
								count={totalCount}
								page={offset / limit}
								variant='outlined'
								shape='rounded'
								onPageChange={onChangePage}
								rowsPerPage={limit}
								onRowsPerPageChange={onChangeRowsPerPage}
								labelRowsPerPage='Results Per Page'
								labelDisplayedRows={({ from, to, count }) => ``}
								rowsPerPageOptions={[]}
							/> */}
							<Pagination
								count={Math.ceil(totalCount / limit)}
								page={page}
								variant='outlined'
								onChange={onChangePage}
								shape='rounded'
								siblingCount={1}
								boundaryCount={1}
							/>
						</div>
					)}
					<Popover
						id={'more_options'}
						open={open}
						anchorEl={anchorEl}
						onClose={closePopover}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<MenuList autoFocusItem={open} id='options_list'>
							{isEditAllowed && result !== 'completed' && (
								<MenuItem dense={true} onClick={onClickEditMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											edit
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Edit
									</Typography>
								</MenuItem>
							)}

							{isDeleteAllowed && result !== 'completed' && (
								<MenuItem dense={true} onClick={onClickDelMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											delete
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Delete
									</Typography>
								</MenuItem>
							)}
							{isEditAllowed && result === 'live' && (
								<MenuItem dense={true} onClick={onClickMovMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											drive_file_move
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Move To Completed
									</Typography>
								</MenuItem>
							)}
							{isEditAllowed && result === 'pre_live' && (
								<MenuItem dense={true} onClick={onClickMovMenu}>
									<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
										<Icon className={classes.menuIcon} fontSize='small'>
											drive_file_move
										</Icon>
									</ListItemIcon>
									<Typography className={classes.menuText} variant='inherit'>
										Move To Live
									</Typography>
								</MenuItem>
							)}
							{/* {result === 'completed' && ( */}
							<MenuItem dense={true} onClick={onClickPermMenu}>
								<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
									<Icon className={classes.menuIcon} fontSize='small'>
										visibility
									</Icon>
								</ListItemIcon>
								<Typography className={classes.menuText} variant='inherit'>
									View
								</Typography>
							</MenuItem>
							{/* )} */}
						</MenuList>
					</Popover>
				</div>
			</Grid>
		</Grid>
	)
}

Results.propTypes = {
	action: PropTypes.string,
	userId: PropTypes.string,
	perms: PropTypes.object,
	history: PropTypes.object.isRequired,
}

export default withWidth()(Results)
