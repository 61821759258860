import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Login from './scenes/Login'
import SisenseLogin from './scenes/SisenseLogin'
import { connect, useDispatch } from 'react-redux'
import Header from './components/Header'
import PrivateRoute from './components/PrivateRoute'
import Home from './scenes/Home'
import MyAccount from './scenes/MyAccount'
import Loader from './components/Loader'
import Snackbar from '@material-ui/core/Snackbar'
import { hideAdvaceSnackbar } from './services/snackbarAdvance/actions'
import Slide from '@material-ui/core/Slide'
import { Alert } from '@material-ui/lab'
import { ROLE_MODULES } from './constants/modules'
import UserManagement from './scenes/UserManagement'
import AbTestTool from './scenes/AbTestTool'
import AiqaDimension from './scenes/AiqaDimension'
import AiqaBB from './scenes/AiqaBB'
import AiqaPrQa from './scenes/AiqaPrQa'
import MasterManagement from './scenes/MasterManagement'
import ProductMatchNavigation from './scenes/ProductMatchNavigation'
import AbTestToolResults from './scenes/AbTestToolResults'
import AbTestNavigation from './scenes/AbTestNavigation'
import Bot from './scenes/Bot'
import AbTestHeader from './components/AbTestHeader'

const App = (props) => {
	const {
		snackbar: { showSnackbar, msgText },
		isAuthenticated,
		loader,
		// authToken,
		// userId,
		advanceSnackbar: { showAdvanceSnackbar, severity, snackbarText, onClose },
	} = props
	const dispatch = useDispatch()
	const usrMgnModuleMapper = {
		users: ROLE_MODULES.USER_MANAGEMENT,
		roles: ROLE_MODULES.ROLE_MANAGEMENT,
		home: ROLE_MODULES.AB_TEST,
		create_test: ROLE_MODULES.AB_TEST,
		edit_test: ROLE_MODULES.AB_TEST,
		view_test: ROLE_MODULES.AB_TEST,
		matching_process: ROLE_MODULES.PRODUCT_MATCHING,
		monitor: ROLE_MODULES.BOT_MONITORING,
		dimension: ROLE_MODULES.AIQA_DIMENSION,
		master: ROLE_MODULES.AIQA_MASTER,
		bb_prediction: ROLE_MODULES.AIQA_BB,
		match: ROLE_MODULES.AIQA_PR_MATCHING,
	}

	return (
		<>
			<Router>
				<Switch>
					{/* <Route exact path='/pr_matching/sisense_auth' component={SisenseLogin} />
					<Route exact path='/ab_test/sisense_auth' component={SisenseLogin} /> */}
					<Route exact path='/sisense_auth' component={SisenseLogin} />
					{/* <PrivateRoute exact path='/ab_test_tool/:module?' moduleMapper={usrMgnModuleMapper} component={AbTestNavigation} /> */}

					{/* </div> */}
					{/* <div>
						<div style={{ display: 'none' }}>
							<AbTestHeader />
						</div> */}

					{/* </div> */}
					{/* <PrivateRoute exact path='/ab-test-tool/:module?' moduleMapper={usrMgnModuleMapper} component={AbTestToolResults} /> */}
					<div className='App'>
						{isAuthenticated &&
							(window.location.pathname.split('/')[1] === 'ab_test_tool' ? (
								<div style={{ display: 'none' }}>
									<Header></Header>
								</div>
							) : (
								<Header></Header>
							))}
						<PrivateRoute exact path='/ab_test_tool/:module/:id?' moduleMapper={usrMgnModuleMapper} component={AbTestNavigation} />
						{/* <Route exact path='/' component={Login} /> */}
						<PrivateRoute exact path='/home' component={Home} />
						<PrivateRoute exact path='/myaccount' component={MyAccount} />
						<PrivateRoute exact path='/usermanagement/:module?' moduleMapper={usrMgnModuleMapper} component={UserManagement} />
						<PrivateRoute exact path='/usermanagement/:module/:action/:id?' moduleMapper={usrMgnModuleMapper} component={UserManagement} />
						<PrivateRoute exact path='/product_match/:module?' moduleMapper={usrMgnModuleMapper} component={ProductMatchNavigation} />
						{/* <PrivateRoute exact path='/product_matching/:module/:action/:id?' moduleMapper={usrMgnModuleMapper} component={ProductMatchNavigation} /> */}

						{/* <PrivateRoute exact path='/category_master' moduleMapper={usrMgnModuleMapper} component={MasterManagement} /> */}
						<PrivateRoute exact path='/aiqa_tool/master/:module/:tab' moduleMapper={usrMgnModuleMapper} component={MasterManagement} />
						<PrivateRoute exact path='/aiqa_tool/master/:module/:tab/:action/:id?' moduleMapper={usrMgnModuleMapper} component={MasterManagement} />
						<PrivateRoute exact path='/aiqa_tool/dimension/:module/:tab' moduleMapper={usrMgnModuleMapper} component={AiqaDimension} />
						<PrivateRoute exact path='/aiqa_tool/dimension/:module/:tab/:action/:id?' moduleMapper={usrMgnModuleMapper} component={AiqaDimension} />
						<PrivateRoute exact path='/aiqa_tool/:module/:tab?' moduleMapper={usrMgnModuleMapper} component={AiqaBB} />
						<PrivateRoute exact path='/aiqa_tool/product/:module/:tab?' moduleMapper={usrMgnModuleMapper} component={AiqaPrQa} />
						<PrivateRoute exact path='/bot/:module?' moduleMapper={usrMgnModuleMapper} component={Bot} />
					</div>
				</Switch>
			</Router>
			<Snackbar open={showSnackbar} TransitionComponent={Slide} message={msgText} />
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={showAdvanceSnackbar}
				onClose={onClose ? () => dispatch(hideAdvaceSnackbar()) : null}
				autoHideDuration={onClose ? 2000 : null}
			>
				<Alert severity={severity} onClose={onClose ? () => dispatch(hideAdvaceSnackbar()) : null}>
					{snackbarText}
				</Alert>
			</Snackbar>
			<Loader {...loader}></Loader>
		</>
	)
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.session.isAuthenticated,
	authToken: state.session?.authToken,
	userId: state.session?.user?.userDetails?.user_id,
	snackbar: state.snackbar,
	advanceSnackbar: state.snackbarAdvance,
	loader: state.loader,
})

export default connect(mapStateToProps)(App)
