import { Box, FormHelperText, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	gridWrapper: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: theme.spacing(0, 3),
		maxWidth: 800,
	},
	textField: {
		// maxWidth: 400,
		// margin: `${theme.spacing(1)}px auto`,
		padding: theme.spacing(2),
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
		fontFamily: 'FS-Jack',
		fontStyle: 'normal',
		// fontWeight: 400,
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f8f9fa',
	},
	paperTitle: {
		fontWeight: 'bold',
	},
	table: {
		'& .MuiTableRow-head': {
			background: '#41e886',
		},
	},
}))

function StepThree(props) {
	const { values, errors, touched, handleBlur, setFieldValue, handleChange, disabled } = props
	const classes = useStyles()

	return (
		<Box className={classes.contentWrapper} component='div'>
			<Grid container spacing={2} className={classes.gridWrapper}>
				{/* <Typography className={classes.title} variant='h6'>
					Schedule:
				</Typography> */}
				<Grid item xs={12} lg={12} className={classes.textField}>
					<p className={classes.title} style={{ textAlign: 'left' }}>
						Feasability Analysis (Salesforce Feasibility Link)
					</p>
					<TextField
						id='outlined-multiline-static'
						onBlur={handleBlur}
						error={errors?.feasibility_analysis && touched.feasibility_analysis}
						helperText={errors?.feasibility_analysis && touched.feasibility_analysis && errors?.feasibility_analysis}
						name='feasibility_analysis'
						onChange={(e) => {
							handleChange(e)
							e.target.value === ' ' && setFieldValue('feasibility_analysis', '')
						}}
						value={values?.feasibility_analysis}
						placeholder='Enter a Feasability Analysis (Salesforce Feasibility Link)'
						fullWidth
						multiline
						minRows={4}
						variant='outlined'
						disabled={disabled}
					/>
				</Grid>
				<Grid item xs={12} lg={6} className={classes.textField}>
					<p className={classes.title} style={{ textAlign: 'left' }}>
						Start Date
					</p>
					<DateTimePicker
						// label='Start Date'
						fullWidth
						disabled={disabled}
						clearable={true}
						inputVariant='outlined'
						value={_.get(values, 'start_date', null)}
						size={'small'}
						disablePast={true}
						error={errors.start_date}
						format={'DD MMM YYYY, hh:mm A'}
						onChange={(newDate) => {
							if (newDate) {
								newDate.set({ seconds: 0, milliseconds: 0 })
							}
							handleChange({
								target: {
									name: 'start_date',
									value: newDate,
								},
							})
							handleChange({
								target: {
									name: 'end_date',
									value: moment(newDate).add(1, 'days'),
								},
							})
						}}
					/>
					{errors?.start_date && <FormHelperText error>{errors.start_date}</FormHelperText>}
				</Grid>
				<Grid item xs={12} lg={6} className={classes.textField}>
					<p className={classes.title} style={{ textAlign: 'left' }}>
						End Date
					</p>
					<DateTimePicker
						// label='End Date'
						fullWidth
						disabled={disabled}
						clearable={true}
						inputVariant='outlined'
						value={_.get(values, 'end_date', null)}
						size={'small'}
						disablePast={true}
						error={errors.end_date}
						format={'DD MMM YYYY, hh:mm A'}
						onChange={(newDate) => {
							if (newDate) {
								newDate.set({ seconds: 0, milliseconds: 0 })
							}
							handleChange({
								target: {
									name: 'end_date',
									value: newDate,
								},
							})
						}}
					/>
					{errors?.end_date && <FormHelperText error>{errors.end_date}</FormHelperText>}
				</Grid>
			</Grid>
		</Box>
	)
}

export default StepThree
