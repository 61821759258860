import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Button, Typography, Box, Icon, Fab, Chip, IconButton, Radio, RadioGroup, Tooltip, ButtonGroup, Switch } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Card from './Card'
import { PRODUCT_MATCH_TYPE } from '../../../constants'
import SelectBox from '../../../components/SelectBox'
import classnames from 'classnames'
import api from '../../../services/aiqa/bb/api'
import Api from '../../../services/aiqa/master/api'
import throttle from 'lodash/throttle'
import _ from 'lodash'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { hideLoader, showLoader } from '../../../services/loader/actions'
import { data } from './data'
import NoImage from './No.png'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import ReportDialog from './ReportDialog'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	contentWrapper: {
		width: '100%',
		// textAlign: 'center',
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	// gridWrapper: {
	// 	flexGrow: 1,
	// 	overflow: 'hidden',
	// 	padding: theme.spacing(0, 3),
	// 	maxWidth: 800,
	// },
	textField: {
		// maxWidth: 400,
		// margin: `${theme.spacing(1)}px auto`,
		// padding: theme.spacing(2),
	},
	textSpacer: {
		margin: `${theme.spacing(1)}px auto`,
		// padding: theme.spacing(2),
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		'& .MuiGrid-spacing-xs-3': {
			margin: 0,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paperHead: {
		// margin: '16px auto',
		padding: theme.spacing(1),
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	actionButton: {
		textAlign: 'center',
		fontWeight: 'bold',
		// textDecoration: 'underline',
	},
	key: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	table: {
		'& .MuiTableRow-head': {
			background: 'rgba(3, 169, 244, 0.44)',
		},
	},
	mobileBoardContainerOpened: {
		[theme.breakpoints.down('xs')]: {
			'&::after': {
				position: 'absolute',
				width: '100%',
				height: '100%',
				content: '" "',
				background: 'rgba(0,0,0,0.5)',
				zIndex: 1,
			},
		},
		paddingBottom: theme.spacing(0),
	},
	searchBox: {
		marginTop: '15px',
	},
	totalLength: {
		marginTop: '20px',
		textAlign: 'center',
	},
	prevNext: {
		marginTop: '11px',
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
	switch_track: {
		backgroundColor: '#9f9f9f',
	},
	switch_base: {
		color: '#9f9f9f',
		'&.Mui-disabled': {
			color: '#9f9f9f',
		},
		'&.Mui-checked': {
			color: '#41e886',
		},
		'&.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#41e886',
		},
	},
	switch_primary: {
		'&.Mui-checked': {
			color: '#41e886',
		},
		'&.Mui-checked + .MuiSwitch-track': {
			backgroundColor: '#41e886',
		},
	},

	switchRoot: {
		width: '155px',
		height: '24px',
		padding: '0px',
	},
	switchBase: {
		color: '#818181',
		padding: '1px',
		'&$checked': {
			'& + $track': {
				backgroundColor: '#23bf58',
			},
		},
	},
	thumb: {
		color: 'white',
		width: '20px',
		height: '20px',
		margin: '1px',
	},
	trackBef: {
		borderRadius: '20px',
		backgroundColor: '#818181',
		opacity: '1 !important',
		'&:after, &:before': {
			color: 'white',
			fontSize: '11px',
			fontWeight: 'bold',
			position: 'absolute',
			top: '5px',
		},
		'&:before': {
			content: "'OEM Brand Prediction'",
			right: '9px',
		},
	},
	trackAft: {
		borderRadius: '20px',
		backgroundColor: '#41e886',
		color: 'green',
		opacity: '1 !important',
		'&:after, &:before': {
			color: 'green',
			fontSize: '11px',
			fontWeight: 'bold',
			position: 'absolute',
			top: '5px',
		},
		'&:after': {
			content: "'OEM Brand Prediction'",
			left: '7px',
		},
	},
	checked: {
		color: '#41e886 !important',
		transform: 'translateX(130px) !important',
	},
}))

export default function CenteredGrid() {
	const classes = useStyles()
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const dispatch = useDispatch()
	const authToken = useSelector((state) => _.get(state, 'session.authToken'))
	const [filter, setFilter] = useState({ searchText: '', filterData: {} })
	const { filterData } = filter
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(filterData, _.isEmpty)), [filterData])
	const [parentBrand, setparentBrand] = useState([])
	const [parentCat, setparentCat] = useState([])
	const [matchmissData, setmatchmissData] = useState([])
	const [currentPage, setCurrentPage] = React.useState(0)
	const [offset, setOffset] = React.useState(0)
	const [limit, setLimit] = React.useState(6)
	const [count, setCount] = React.useState(0)
	const [value, setValue] = React.useState('')
	const [toggle, setToggle] = React.useState(false)
	const [matchData, setmatchData] = React.useState('')
	const [enupdate, setEnupdate] = React.useState(false)
	const [Okfilter, setOkfilter] = React.useState({})
	const [summary, setSummary] = useState({})
	const [preStatus, setpreStatus] = React.useState({
		nlp_brand_qa_status: 'null',
		nlp_category_qa_status: 'null',
		cv_category_qa_status: 'null',
	})
	const [preSugg, setpreSugg] = React.useState({
		sugg_nlp_brand_user_input: '',
		sugg_nlp_cat_user_input: '',
		sugg_cv_cat_user_input: '',
	})
	// const [enableSave, setenableSave] = React.useState(false)

	const { nlp_brand_qa_status, nlp_category_qa_status, cv_category_qa_status } = preStatus
	const { sugg_nlp_brand_user_input, sugg_nlp_cat_user_input, sugg_cv_cat_user_input } = preSugg

	//Enable Save&Next
	let enableSave = true

	if (!_.isEmpty(matchmissData?.aipr_predicted_brand_nlp) && nlp_brand_qa_status === 'null') {
		enableSave = false
	}
	if (nlp_brand_qa_status === 'false' && sugg_nlp_brand_user_input === '') {
		enableSave = false
	}

	if (!_.isEmpty(matchmissData?.aipr_predicted_category_nlp) && nlp_category_qa_status === 'null') {
		enableSave = false
	}
	if (nlp_category_qa_status === 'false' && sugg_nlp_cat_user_input === '') {
		enableSave = false
	}

	if (!_.isEmpty(matchmissData?.aipr_predicted_category_cv) && cv_category_qa_status === 'null') {
		enableSave = false
	}
	if (cv_category_qa_status === 'false' && sugg_cv_cat_user_input === '') {
		enableSave = false
	}

	const renderAfterCalled = useRef(false)

	const updateClick = (prdFor) => {
		dispatch(showLoader('Please wait, getting details'))
		api.fetchPrediction(
			prdFor === true ? 'oem_bb' : 'bb',
			(res) => {
				let resp = res.data.data
				setmatchmissData(resp)
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	const getSummary = (prdFor) => {
		api.fetchSummary(
			prdFor === true ? 'oem_bb' : 'bb',
			(res) => {
				let resp = res.data.data
				setSummary(resp)
				// dispatch(hideLoader())
			},
			(err) => {}
		)
	}

	useEffect(() => {
		getSummary(toggle)
	}, [toggle])

	useEffect(() => {
		updateClick(toggle)
	}, [toggle])

	useEffect(() => {
		let pay_values = {
			// cat_id: '',
		}
		Api.GetParentBBCategoryList(
			pay_values,
			'parent_cat',
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', [])

				let res = _.map(testList, (list) => ({
					id: _.toString(list.cat_id),
					name: list.category,
				}))
				res.splice(0, 0, { id: 'null', name: '--Others--' })
				setparentCat(res)
			},
			(err) => {
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
		Api.GetParentBrandList(
			pay_values,
			'parent_brand',
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', [])

				let res = _.map(testList, (list) => ({
					id: _.toString(list.brand_id),
					name: list.brand,
				}))
				res.splice(0, 0, { id: 'null', name: '--Others--' })
				setparentBrand(res)
			},
			(err) => {
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
	}, [])

	const onClipboardClick = () => {
		navigator.clipboard.writeText(!_.isEmpty(matchmissData?.aipr_ptitle) && matchmissData?.aipr_ptitle)
		dispatch(showSnackbarWithTimeout('Product Title Copied Into Clipboard', 2000))
	}

	const preStatusChange = (key, value) => {
		setpreStatus((prev) => ({
			...prev,
			[key]: value,
		}))
	}
	const preSuggChange = (key, value) => {
		setpreSugg((prev) => ({
			...prev,
			[key]: value,
		}))
	}

	const onsameClick = () => {
		setpreSugg((prev) => ({
			...prev,
			sugg_cv_cat_user_input: sugg_nlp_cat_user_input,
		}))
	}

	const onSkipClick = () => {
		let pay_values = {
			qa_for: 'skip',
		}
		api.skipPrediction(
			toggle === true ? 'oem_bb' : 'bb',
			matchmissData?.allot_id,
			pay_values,
			(res) => {
				setpreStatus({
					nlp_brand_qa_status: 'null',
					nlp_category_qa_status: 'null',
					cv_category_qa_status: 'null',
				})
				setpreSugg({
					sugg_nlp_brand_user_input: '',
					sugg_nlp_cat_user_input: '',
					sugg_cv_cat_user_input: '',
				})
				updateClick(toggle)
			},
			(err) => {}
		)
	}

	var str2bool = (value) => {
		if (value && typeof value === 'string') {
			if (value.toLowerCase() === 'true') return true
			if (value.toLowerCase() === 'false') return false
			if (value.toLowerCase() === 'null') return null
		}
		return value
	}

	var num2bool = (value) => {
		// if (value && typeof value === 'integer') {
		if (value === 0) return 'false'
		if (value === 1) return 'true'
		if (value === null) return 'null'
		// }
		// return value
	}

	var brand2value = (value) => {
		// if (value && typeof value === 'integer') {
		let findBrand = _.find(parentBrand, { id: _.toString(value) })
		if (value === null) return ''
		else if (value === 'others') return { id: 'null', name: '--Others--' }
		else return findBrand?.id
		// }
		// return value
	}

	var cat2value = (value) => {
		// if (value && typeof value === 'integer') {
		let findCat = _.find(parentCat, { id: _.toString(value) })
		if (value === null) return ''
		else if (value === 'others') return { id: 'null', name: '--Others--' }
		else return findCat?.id
		// }
		// return value
	}

	const onSaveClick = () => {
		let brandnlpData = sugg_nlp_brand_user_input !== '' && _.find(parentBrand, { id: sugg_nlp_brand_user_input })
		let brandnlpObj = { brand_id: brandnlpData?.id, brand_name: brandnlpData?.name }
		let catnlpData = sugg_nlp_cat_user_input !== '' && _.find(parentCat, { id: sugg_nlp_cat_user_input })
		let catnlpObj = { cat_id: catnlpData?.id, cat_name: catnlpData?.name }
		let pay_values = {
			qa_for: 'save',
			nlp_brand_qa_status: str2bool(nlp_brand_qa_status),
			nlp_category_qa_status: str2bool(nlp_category_qa_status),
			cv_category_qa_status: str2bool(cv_category_qa_status),
			sugg_nlp_brand_user_input: sugg_nlp_brand_user_input === '' ? null : brandnlpObj,
			sugg_nlp_cat_user_input: sugg_nlp_cat_user_input === '' ? null : _.isUndefined(catnlpData) ? null : catnlpObj,
			sugg_cv_cat_user_input: null,
			ai_prediction_id: matchmissData?.aipr_id,
		}
		api.savePrediction(
			toggle === true ? 'oem_bb' : 'bb',
			matchmissData?.allot_id,
			pay_values,
			(res) => {
				let resp = res.data.data
				setpreStatus({
					nlp_brand_qa_status: 'null',
					nlp_category_qa_status: 'null',
					cv_category_qa_status: 'null',
				})
				setpreSugg({
					sugg_nlp_brand_user_input: '',
					sugg_nlp_cat_user_input: '',
					sugg_cv_cat_user_input: '',
				})

				getSummary(toggle)

				if (_.isEmpty(resp)) {
					updateClick(toggle)
				} else {
					setmatchmissData(resp)
				}
				// setmatchData('')
			},
			(err) => {}
		)
	}

	const onPrevClick = () => {
		dispatch(showLoader('Please wait, getting previous details'))
		api.prevPrediction(
			toggle === true ? 'oem_bb' : 'bb',
			matchmissData?.allot_id,
			(res) => {
				let resp = res.data.data
				let qa = resp.qa_result
				if (_.isEmpty(resp)) {
					updateClick(toggle)
					setpreStatus({
						nlp_brand_qa_status: 'null',
						nlp_category_qa_status: 'null',
						cv_category_qa_status: 'null',
					})
					setpreSugg({
						sugg_nlp_brand_user_input: '',
						sugg_nlp_cat_user_input: '',
						sugg_cv_cat_user_input: '',
					})
				} else {
					setmatchmissData(resp)
				}
				if (_.isUndefined(qa)) {
					setpreStatus({
						nlp_brand_qa_status: 'null',
						nlp_category_qa_status: 'null',
						cv_category_qa_status: 'null',
					})
					setpreSugg({
						sugg_nlp_brand_user_input: '',
						sugg_nlp_cat_user_input: '',
						sugg_cv_cat_user_input: '',
					})
				}
				setpreStatus({
					nlp_brand_qa_status: num2bool(qa.nlp_brand_qa_status),
					nlp_category_qa_status: num2bool(qa.nlp_cat_qa_status),
					cv_category_qa_status: num2bool(qa.cv_cat_qa_status),
				})
				setpreSugg({
					sugg_nlp_brand_user_input: brand2value(qa.suggested_nlp_brand_id),
					sugg_nlp_cat_user_input: cat2value(qa.suggested_nlp_cat_id),
					sugg_cv_cat_user_input: cat2value(qa.suggested_cv_cat_id),
				})
				dispatch(hideLoader())
				// setmatchData('')
			},
			(err) => {
				dispatch(hideLoader())
			}
		)
	}

	let info =
		toggle !== true ? (
			<p>
				Instructions : <br />
				<br />
				1) Select the right status for brand and categories if the shown value. <br /> <br />
				2) Select True if the brand and category are right <br />
				<br />
				3) Select False if the brand and category are wrong, then select the right brand name and category name from the suggested brand, category
				field. <br />
				<br />
			</p>
		) : (
			<p>
				Instructions : <br />
				<br />
				1) Select the right status for brand if the shown value. <br /> <br />
				2) Select True if the brand is right <br />
				<br />
				3) Select False if the brand is wrong, then select the right brand name from the suggested brand field. <br />
				<br />
			</p>
		)

	const handleChange = (event, newValue) => {
		// setToggle(newValue)
		setToggle(event.target.checked)
		setpreStatus({
			nlp_brand_qa_status: 'null',
			nlp_category_qa_status: 'null',
			cv_category_qa_status: 'null',
		})
		setpreSugg({
			sugg_nlp_brand_user_input: '',
			sugg_nlp_cat_user_input: '',
			sugg_cv_cat_user_input: '',
		})
	}

	return (
		<div
			className={classnames(classes.boardContainer, {
				[classes.mobileBoardContainerOpened]: filterMenuProps.isOpen,
			})}
		>
			<Box className={classes.contentWrapper} component='div'>
				{/* <Paper className={classes.paperHead}> */}
				{/* <Grid container xs={12} lg={12}>
				<Grid item xs={12} lg={7} className={classes.textField} /> */}
				<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
					<div>
						<Switch
							classes={{
								root: classes.switchRoot,
								switchBase: classes.switchBase,
								thumb: classes.thumb,
								track: toggle === true ? classes.trackAft : classes.trackBef,
								checked: classes.checked,
							}}
							checked={toggle}
							onChange={handleChange}
							name='checkedA'
							inputProps={{ 'aria-label': 'secondary checkbox' }}
						/>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'end', marginRight: '5px' }}>
						{/* <Grid item xs={12} lg={2} className={classes.textField}> */}
						<Typography className={classes.key} variant='body1' style={{ marginTop: '4px' }}>
							Total Count
						</Typography>
						{/* </Grid>
					<Grid item xs={12} lg={1} className={classes.textField}> */}
						{/* <Tooltip title={!_.isEmpty(matchmissData?.aipr_ptitle) ? matchmissData?.aipr_ptitle : '-'} classes={{ tooltip: classes.tooltip }}> */}
						<Chip
							label={!_.isEmpty(summary) ? summary?.total_count : '0'}
							variant='outlined'
							style={{
								color: '#3ec78a',
								border: '1px solid #3ec78a',
								// width: '40px',
								height: '20px',
								marginTop: '6px',
								marginLeft: '10px',
								marginRight: '15px',
							}}
						/>
						{/* </Tooltip> */}
						{/* </Grid>
					<Grid item xs={12} lg={2} className={classes.textField}> */}
						<Typography className={classes.key} variant='body1' style={{ marginTop: '4px' }}>
							Total Count Today
						</Typography>
						{/* </Grid>
					<Grid item xs={12} lg={1} className={classes.textField}> */}
						{/* <Tooltip title={!_.isEmpty(matchmissData?.aipr_ptitle) ? matchmissData?.aipr_ptitle : '-'} classes={{ tooltip: classes.tooltip }}> */}
						{/* <Typography noWrap className={classes.title} variant='body1' style={{ marginRight: '15px', marginLeft: '15px' }}>
							{!_.isEmpty(summary) ? summary?.today_count : '0'}
						</Typography> */}
						<Chip
							label={!_.isEmpty(summary) ? summary?.today_count : '0'}
							variant='outlined'
							style={{ color: 'blue', border: '1px solid blue', height: '20px', marginTop: '6px', marginLeft: '10px' }}
						/>
						{/* </Tooltip> */}
						{/* </Grid>
					<Grid item xs={12} lg={2} className={classes.textField} style={{ textAlign: 'center' }}> */}
						<Tooltip title={info} classes={{ tooltip: classes.tooltip }}>
							<IconButton size='small' style={{ marginRight: '15px', marginLeft: '15px' }}>
								<i className='material-icons'>info</i>
							</IconButton>
						</Tooltip>
						{/* </Grid> */}
					</div>
				</div>

				{/* </Grid> */}
				{/* </Paper> */}
				<Paper className={classes.paper}>
					<Grid container>
						<Grid item xs={12} lg={11} className={classes.textField} style={{ marginBottom: '25px' }}>
							<Typography className={classes.paperTitle} variant='body1'>
								Product Details:
							</Typography>
						</Grid>
						<Grid item xs={1}>
							<ReportDialog data={matchmissData} toggle={toggle} />
						</Grid>
						<Grid container item xs={12} lg={8} className={classes.textField}>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Product Title:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={2} className={classes.textField}>
								<Tooltip title={!_.isEmpty(matchmissData?.aipr_ptitle) ? matchmissData?.aipr_ptitle : '-'} classes={{ tooltip: classes.tooltip }}>
									<Typography className={classes.title} variant='body1'>
										{!_.isEmpty(matchmissData?.aipr_ptitle) ? matchmissData?.aipr_ptitle : '-'}
									</Typography>
								</Tooltip>
							</Grid>
							<Grid item xs={12} lg={1} className={classes.textField}>
								{!_.isEmpty(matchmissData?.aipr_ptitle) && (
									<Icon fontSize='small' onClick={onClipboardClick}>
										content_copy
									</Icon>
								)}
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Typography className={classes.key} variant='body1'>
									Page URL:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<Tooltip
									title={!_.isEmpty(matchmissData?.aipr_page_name) ? matchmissData?.aipr_page_name : '-'}
									classes={{ tooltip: classes.tooltip }}
								>
									<div style={{ wordWrap: 'break-word' }}>
										<Typography className={classes.title} variant='body1'>
											<a style={{ textDecoration: 'none' }} target='_blank' href={matchmissData?.aipr_page_name}>
												{!_.isEmpty(matchmissData?.aipr_page_name) ? matchmissData?.aipr_page_name : '-'}
											</a>
										</Typography>
									</div>
								</Tooltip>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textSpacer}>
								<Typography className={classes.key} variant='body1'>
									Language:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textSpacer}>
								<Typography className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.aipr_lang) ? matchmissData?.aipr_lang : '-'}
								</Typography>
							</Grid>

							<Grid item xs={12} lg={3} className={classes.textSpacer}>
								<Typography className={classes.key} variant='body1'>
									Price:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textSpacer}>
								<Typography className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.aipr_avg_price_gbp) ? matchmissData?.aipr_avg_price_gbp : '-'}
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textSpacer}>
								<Typography className={classes.key} variant='body1'>
									Brand By NLP:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textSpacer}>
								<Typography noWrap className={classes.title} variant='body1'>
									{!_.isEmpty(matchmissData?.aipr_predicted_brand_nlp) ? matchmissData?.aipr_predicted_brand_nlp : '-'}
								</Typography>
							</Grid>
							{toggle === true && <Grid item xs={12} lg={6} className={classes.textSpacer} />}
							{toggle !== true && (
								<>
									<Grid item xs={12} lg={3} className={classes.textSpacer}>
										<Typography className={classes.key} variant='body1'>
											Category By NLP:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={3} className={classes.textSpacer}>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.aipr_predicted_category_nlp) ? matchmissData?.aipr_predicted_category_nlp : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={3} style={{ margin: '8px 0px 8px 0px' }}>
										<Typography className={classes.key} variant='body1'>
											Category By CV:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={3} style={{ margin: '8px 0px 8px 0px' }}>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.aipr_predicted_category_cv) ? matchmissData?.aipr_predicted_category_cv : '-'}
										</Typography>
									</Grid>
								</>
							)}
						</Grid>

						<Grid container item xs={12} lg={4} className={classes.textField}>
							<Grid item xs={12} lg={6} className={classes.textField}>
								<img src={!_.isEmpty(matchmissData?.aipr_img) ? matchmissData?.aipr_img : NoImage} width={180} height={180} />
							</Grid>
						</Grid>
					</Grid>
					<Grid container style={{ marginBottom: '25px', marginTop: '25px' }}>
						<Grid item xs={12} lg={12} className={classes.textField}>
							<Typography className={classes.paperTitle} variant='body1'>
								Prediction Results:
							</Typography>
						</Grid>
						<Grid container item xs={12} lg={10} className={classes.textField}>
							<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '25px' }}>
								<Typography className={classes.key} variant='body1'>
									Brand NLP Status:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '17px' }}>
								<RadioGroup
									row
									aria-label='position'
									// name='position'
									value={nlp_brand_qa_status}
									onChange={(e) => preStatusChange('nlp_brand_qa_status', e.target.value)}
								>
									<FormControlLabel
										value='true'
										classes={{ label: classes.switchLabel }}
										disabled={_.isEmpty(matchmissData?.aipr_predicted_brand_nlp)}
										control={<Radio size='small' color='primary' />}
										label='True'
										labelPlacement='end'
									/>
									<FormControlLabel
										value='null'
										classes={{ label: classes.switchLabel }}
										disabled={_.isEmpty(matchmissData?.aipr_predicted_brand_nlp)}
										control={<Radio size='small' color='primary' />}
										label='N/A'
										labelPlacement='end'
									/>
									<FormControlLabel
										value='false'
										classes={{ label: classes.switchLabel }}
										disabled={_.isEmpty(matchmissData?.aipr_predicted_brand_nlp)}
										control={<Radio size='small' color='primary' />}
										label='False'
										labelPlacement='end'
									/>
								</RadioGroup>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '25px' }}>
								<Typography className={classes.key} variant='body1'>
									Sugg. Brand NLP:
								</Typography>
							</Grid>
							<Grid item xs={12} lg={3} className={classes.textField}>
								<SelectBox
									label='Brand'
									name='test_type'
									value={sugg_nlp_brand_user_input}
									disabled={nlp_brand_qa_status === 'true' || nlp_brand_qa_status === 'null'}
									onChange={(e) => preSuggChange('sugg_nlp_brand_user_input', e)}
									options={parentBrand}
								></SelectBox>
							</Grid>
							{toggle !== true && (
								<>
									<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '25px' }}>
										<Typography className={classes.key} variant='body1'>
											Category NLP Status:
										</Typography>
									</Grid>

									<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '17px' }}>
										<RadioGroup
											row
											aria-label='position'
											name='position'
											value={nlp_category_qa_status}
											onChange={(e) => preStatusChange('nlp_category_qa_status', e.target.value)}
										>
											<FormControlLabel
												value='true'
												classes={{ label: classes.switchLabel }}
												disabled={_.isEmpty(matchmissData?.aipr_predicted_category_nlp)}
												control={<Radio size='small' color='primary' />}
												label='True'
												labelPlacement='end'
											/>
											<FormControlLabel
												value='null'
												classes={{ label: classes.switchLabel }}
												disabled={_.isEmpty(matchmissData?.aipr_predicted_category_nlp)}
												control={<Radio size='small' color='primary' />}
												label='N/A'
												labelPlacement='end'
											/>
											<FormControlLabel
												value='false'
												classes={{ label: classes.switchLabel }}
												disabled={_.isEmpty(matchmissData?.aipr_predicted_category_nlp)}
												control={<Radio size='small' color='primary' />}
												label='False'
												labelPlacement='end'
											/>
										</RadioGroup>
									</Grid>

									<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '25px' }}>
										<Typography className={classes.key} variant='body1'>
											Sugg. Category NLP:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={3} className={classes.textField}>
										<SelectBox
											label='Category'
											name='test_type'
											value={sugg_nlp_cat_user_input}
											disabled={nlp_category_qa_status === 'true' || nlp_category_qa_status === 'null'}
											onChange={(e) => preSuggChange('sugg_nlp_cat_user_input', e)}
											options={parentCat}
										></SelectBox>
									</Grid>
									<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '25px' }}>
										<Typography className={classes.key} variant='body1'>
											Category CV Status:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '17px' }}>
										<RadioGroup
											row
											aria-label='position'
											name='position'
											value={cv_category_qa_status}
											onChange={(e) => preStatusChange('cv_category_qa_status', e.target.value)}
										>
											<FormControlLabel
												value='true'
												disabled={_.isEmpty(matchmissData?.aipr_predicted_category_cv)}
												classes={{ label: classes.switchLabel }}
												control={<Radio size='small' color='primary' />}
												label='True'
												labelPlacement='end'
											/>
											<FormControlLabel
												value='null'
												disabled={_.isEmpty(matchmissData?.aipr_predicted_category_cv)}
												classes={{ label: classes.switchLabel }}
												control={<Radio size='small' color='primary' />}
												label='N/A'
												labelPlacement='end'
											/>
											<FormControlLabel
												value='false'
												disabled={_.isEmpty(matchmissData?.aipr_predicted_category_cv)}
												classes={{ label: classes.switchLabel }}
												control={<Radio size='small' color='primary' />}
												label='False'
												labelPlacement='end'
											/>
										</RadioGroup>
									</Grid>

									<Grid item xs={12} lg={3} className={classes.textField} style={{ marginTop: '25px' }}>
										<Typography className={classes.key} variant='body1'>
											Sugg. Category CV:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={3} className={classes.textField}>
										<SelectBox
											label='Category'
											name='test_type'
											value={sugg_cv_cat_user_input}
											disabled={cv_category_qa_status === 'true' || cv_category_qa_status === 'null'}
											onChange={(e) => preSuggChange('sugg_cv_cat_user_input', e)}
											options={parentCat}
										></SelectBox>
									</Grid>
								</>
							)}
						</Grid>
						{toggle !== true && (
							<>
								<Grid container item xs={12} lg={2} className={classes.textField}>
									<Grid item xs={12} lg={12} className={classes.textField}>
										<FormControlLabel
											disabled={cv_category_qa_status === 'true' || cv_category_qa_status === 'null'}
											control={
												<Checkbox
													icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
													checkedIcon={<CheckBoxIcon fontSize='small' />}
													name='checkedI'
												/>
											}
											onClick={onsameClick}
											style={{ marginTop: '145px', marginLeft: '15px' }}
											label='Same as above'
										/>
									</Grid>
								</Grid>
							</>
						)}
					</Grid>
					<Grid container spacing={3} className={classes.formControl}>
						<Grid item xs={12} lg={4} className={classes.actionButton}>
							<Button style={{ fontWeight: 'bold' }} color='primary' onClick={onSkipClick}>
								Skip
							</Button>
						</Grid>
						<Grid item xs={12} lg={4} className={classes.actionButton}>
							<Button style={{ fontWeight: 'bold' }} color='primary' onClick={onPrevClick}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={12} lg={4} className={classes.actionButton}>
							<Button style={{ fontWeight: 'bold' }} color='primary' onClick={onSaveClick} disabled={enableSave === false}>
								Save & Next
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Box>
		</div>
	)
}
