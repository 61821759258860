import * as Yup from 'yup'
import moment from 'moment'

const URL =
	/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const validationSchema = [
	Yup.object().shape({
		test_name: Yup.string().trim().required('Please enter the Test name'),
		hypothesis: Yup.string().trim().required('Please enter the Hypothesis'),
		sf_case: Yup.string().trim().required('Please enter the Sales Force Case').matches(URL, 'URL is not valid'),
		flix_product_type: Yup.string().trim().required('Please select the Flix Product.'),
		test_type: Yup.string().trim().required('Please select the Scenrios.'),
		primary_kpi: Yup.string().trim().required('Please select the Primary KPI.'),
		expected_lift: Yup.number().required('Please enter the Expected Lift').min(0, 'Min value 0.').max(100, 'Max value 100.'),
		confident_level: Yup.number().required('Please enter the Confident Level').min(0, 'Min value 0.').max(100, 'Max value 100.'),
		secondary_kpi: Yup.array().min(1, 'Please select the Secondary KPI.').required('Please select the Secondary KPI.'),
		split_range: Yup.string().trim().required('Please select the Traffic Split Rule.'),
		// variant1: Yup.string().trim().required('Please enter the Variant 1 Asset'),
		variant1: Yup.string().when('test_type', {
			is: (test_type) => test_type === 'template_variation',
			then: Yup.string()
				.required('Please enter the Variant 1 Asset')
				.test('Check prefix', 'Asset URL must start with template/', function () {
					let variant1 = this.parent['variant1']
					// console.log(carriercode, variant1);
					if (variant1) {
						return variant1.startsWith('template/') ? true : false
					}
				}),
		}),
		variant2: Yup.string().when('split_range', {
			is: (split_range) => split_range === '33%:33%:33%',
			then: Yup.string()
				.required('Please enter the Variant 2 Asset')
				.test('Check prefix', 'Asset URL must start with template/', function () {
					let variant2 = this.parent['variant2']
					// console.log(carriercode, variant2);
					if (variant2) {
						return variant2.startsWith('template/') ? true : false
					}
				}),
		}),
	}),
	Yup.object().shape({
		test_level: Yup.string().trim().required('Please select test level'),
		// brand: Yup.array().min(1, 'Please select the Brand.').required('Please select the Brand.'),
		brand: Yup.array().when('test_level', {
			is: (test_level) => test_level === 'brand' || test_level === 'product' || test_level === 'retailer_brand',
			then: Yup.array().min(1, 'Please select the Brand.').required('Please select the Brand.'),
		}),
		country: Yup.array().when('test_level', {
			is: (test_level) => test_level !== 'brand',
			then: Yup.array().min(1, 'Please select the Country.').required('Please select the Country.'),
		}),
		retailer: Yup.array().when('test_level', {
			is: (test_level) => test_level !== 'brand',
			then: Yup.array().min(1, 'Please select the Retailer.').required('Please select the Retailer.'),
		}),
	}),
	Yup.object().shape({
		feasibility_analysis: Yup.string().trim().required('Please enter the Feasability Analysis').matches(URL, 'URL is not valid'),
		start_date: Yup.date().required('Start time is required').typeError('Invalid Start Time'),
		// .test('isFuture', "The start time can't be in the future", function (value) {
		// 	return moment(value).isBefore(new Date())
		// })
		end_date: Yup.date()
			.required('End time is required')
			.typeError('Invalid End Time')
			// .test('isFuture', "The end time can't be in the future", (value) => {
			// 	return moment(value).isBefore(new Date()) || moment(value).isSame(new Date())
			// })
			.test('isBeforeStartDate', 'The end time must be after the start time', function (value) {
				const startDate = this.options.parent?.start_date
				return moment(moment(value)).isAfter(startDate)
			}),
	}),
]
