import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Paper } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import api from '../../../services/abtestresults/api'
import Axios from 'axios'
import Beaker from '../../../images/beaker.png'
import Duplicate from '../../../images/duplicate.png'
import Check from '../../../images/check.png'

const CancelToken = Axios.CancelToken

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 310,
		maxHeight: 110,
		margin: '16px auto',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.light}52`,
			cursor: 'pointer',
		},
	},
	container: {
		// paddingTop: 50,
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	avatar: {
		width: 60,
		height: 60,
		[theme.breakpoints.down('xs')]: {
			width: 40,
			height: 40,
		},
	},
	title: {
		marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '16px',
		},
	},
	subtitle: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	tabWrapper: {
		paddingTop: theme.spacing(2),
		'& .MuiTabs-scrollable': {
			display: 'flex',
			justifyContent: 'space-around',
		},
	},
	tab: {
		letterSpacing: '2px',
		fontSize: '12px',
		'& span': {
			verticalAlign: 'middle',
		},
	},
	tabIco: {
		paddingRight: theme.spacing(1),
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	pos: {
		marginBottom: 12,
	},
	paper: {
		width: 310,
		height: 110,
		borderRadius: '10px',
		margin: '16px auto',
	},
	img: {
		width: '30px',
		height: '30px',
		padding: '10px',
	},
}))

function Dashboard(props) {
	const classes = useStyles()
	const cancelDashLstReq = useRef(null)
	const renderAfterCalled = useRef(false)
	const [usersTblState, setUsersTblState] = useState({
		live: 0,
		pre_live: 0,
		completed: 0,
	})
	const { live, pre_live, completed } = usersTblState

	const result = _.get(props.match, 'params.result')

	useEffect(() => {
		if (!renderAfterCalled.current) {
			api
				.fetchDashboard(
					new CancelToken((exec) => {
						cancelDashLstReq.current = exec
					})
				)
				.then((res) => {
					let resp = res.data.data[0]
					setUsersTblState((prevState) => ({
						...prevState,
						...resp,
					}))
				})
		}
		renderAfterCalled.current = true
	}, [])

	return (
		<div className={clsx(classes.container)}>
			<Grid container>
				<Grid item xs={4}>
					<Paper className={classes.paper}>
						<div style={{ padding: '12px' }}>
							<p>
								LIVE TESTS
								<span
									style={{ width: '50px', height: '50px', position: 'absolute', marginLeft: '140px', borderRadius: '50%', background: '#42516A' }}
								>
									<img src={Beaker} alt={Beaker} className={classes.img} />
								</span>
							</p>

							<p>{live}</p>
						</div>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper className={classes.paper}>
						<div style={{ padding: '12px' }}>
							<p>
								PRE LIVE TESTS
								<span
									style={{ width: '50px', height: '50px', position: 'absolute', marginLeft: '105px', borderRadius: '50%', background: '#8C8CA2' }}
								>
									<img src={Duplicate} alt={Duplicate} className={classes.img} />
								</span>
							</p>
							<p>{pre_live}</p>
						</div>
					</Paper>
				</Grid>
				<Grid item xs={4}>
					<Paper className={classes.paper}>
						<div style={{ padding: '12px' }}>
							<p>
								COMPLETED TESTS
								<span style={{ width: '50px', height: '50px', position: 'absolute', marginLeft: '75px', borderRadius: '50%', background: '#42E886' }}>
									<img src={Check} alt={Check} className={classes.img} />
								</span>
							</p>
							<p>{completed}</p>
						</div>
					</Paper>
				</Grid>
			</Grid>
		</div>
	)
}

export default Dashboard
