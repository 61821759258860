import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import PermissionInput from '../../../../components/PermissionInput'
import { useDispatch } from 'react-redux'
import { fetchUserSpecPerms, updateUserSpecPerms } from '../../../../services/users/actions'
import { hideLoader } from '../../../../services/loader/actions'
import { Typography, Avatar, ListItem, ListItemAvatar, List, ListItemText } from '@material-ui/core'
import { getImgUrl, getSuccessMsg } from '../../../../utils'
import _ from 'lodash'
import { showSnackbarWithTimeout } from '../../../../services/snackbar/actions'

const useStyles = makeStyles((theme) => ({
	dialogTitle: {
		paddingBottom: 0,
		paddingLeft: theme.spacing(0),
		paddingRight: 0,
		paddingTop: theme.spacing(0),
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
		'& h6': {
			[theme.breakpoints.down('xs')]: {
				fontSize: 16,
			},
		},
	},
	dialogFooter: {
		padding: theme.spacing(2),
		borderTop: `1px solid ${theme.palette.grey[300]}`,
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
			padding: theme.spacing(1),
		},
	},
	resetBtn: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 10px)',
			marginLeft: '5px',
			marginRight: '5px',
		},
	},
	otherActionBtn: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(50% - 10px)',
			marginLeft: '5px!important',
			margin: '8px 5px',
		},
	},
	dialogContent: {
		display: 'flex',
	},
}))

function SpecialPermDialog({ open, userId, firstName, lastName, email, profilePic, isEditAllowed, accessToken, handleClose }) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const [response, setResponse] = useState([])
	const [permState, setPermState] = useState({
		loading: true,
		permissions: [],
	})
	const { permissions, loading } = permState
	const dispatch = useDispatch()

	const classes = useStyles()

	const onChangePerms = useCallback((e) => {
		setPermState((prevState) => ({
			...prevState,
			permissions: e.target.value,
		}))
	}, [])

	const loadUserSpecPerms = useCallback(
		(userId) => {
			dispatch(
				fetchUserSpecPerms(
					userId,
					(resp) => {
						setPermState((prevState) => ({
							...prevState,
							loading: false,
							permissions: _.get(resp, 'data.data.modules', []),
						}))
						setResponse(_.get(resp, 'data.data.modules', []))
					},
					() => {
						dispatch(hideLoader())
					}
				)
			)
		},
		[dispatch]
	)

	const checkBfrClose = () => {
		if (_.isEqual(permissions, response)) {
			handleClose()
		} else {
			dispatch(showSnackbarWithTimeout('The changes has not been saved', 2000))
			handleClose()
		}
	}

	const updateSpecPermissions = useCallback(
		(permissions, loaderMsg, isRefreshDialog) => {
			const hideLoaderDialog = dispatch(hideLoader())
			const onSuccess = (resp) => {
				dispatch(showSnackbarWithTimeout(getSuccessMsg(resp), 2000))
				if (isRefreshDialog) setPermState((prevState) => ({ ...prevState, loading: true }))
				else handleClose()
			}
			dispatch(
				updateUserSpecPerms(userId, permissions, loaderMsg, onSuccess, () => {
					hideLoaderDialog()
				})
			)
		},
		[dispatch, userId, handleClose]
	)

	const onSaveBtnClick = useCallback(() => {
		updateSpecPermissions(permissions, 'Updating permissions...', false)
	}, [permissions, updateSpecPermissions])

	const onResetBtnClick = () => {
		updateSpecPermissions([], 'Resetting special Permissions...', true)
	}

	useEffect(() => {
		if (open && loading) loadUserSpecPerms(userId)
	}, [open, userId, loading, loadUserSpecPerms])

	useEffect(() => {
		//reset the state when closes the dialog
		if (!open)
			setPermState((prevState) => ({
				...prevState,
				loading: true,
				permissions: [],
			}))
	}, [open])

	const profilePicUrl = _.isEmpty(profilePic) ? '' : getImgUrl(accessToken, profilePic)

	if (open && loading) return null
	else
		return (
			<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth={'md'} aria-labelledby='special-permission-dialog'>
				<DialogTitle className={classes.dialogTitle} disableTypography id='special-permission-dialog-title'>
					<List dense>
						<ListItem dense>
							<ListItemAvatar>
								<Avatar alt={firstName} src={profilePicUrl} size='small'>
									{firstName && firstName.substring(0, 1)}
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={`${firstName} ${lastName}`}
								secondary={
									<React.Fragment>
										<Typography component='span' variant='body2' className={classes.inline} color='textPrimary'>
											{email}
										</Typography>
										{' — Permissions'}
									</React.Fragment>
								}
							/>
						</ListItem>
					</List>
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					<PermissionInput isMobileView={fullScreen} value={permissions} viewMode={!isEditAllowed} onChange={onChangePerms} />
				</DialogContent>
				<DialogActions className={classes.dialogFooter}>
					{isEditAllowed ? (
						<>
							<Button
								size={fullScreen ? 'small' : 'medium'}
								className={classes.resetBtn}
								onClick={onResetBtnClick}
								variant='outlined'
								disableElevation
								color='default'
								autoFocus
							>
								Reset Special Permissions
							</Button>
							<Button
								size={fullScreen ? 'small' : 'medium'}
								className={classes.otherActionBtn}
								onClick={checkBfrClose}
								variant='outlined'
								disableElevation
								color='primary'
								autoFocus
							>
								Cancel
							</Button>
							<Button
								size={fullScreen ? 'small' : 'medium'}
								className={classes.otherActionBtn}
								onClick={onSaveBtnClick}
								variant='contained'
								disableElevation
								color='primary'
								autoFocus
							>
								Save
							</Button>
						</>
					) : (
						<Button
							size={fullScreen ? 'small' : 'medium'}
							className={classes.otherActionBtn}
							onClick={handleClose}
							variant='outlined'
							disableElevation
							color='primary'
							autoFocus
						>
							Close
						</Button>
					)}
				</DialogActions>
			</Dialog>
		)
}

SpecialPermDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	userId: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	profilePic: PropTypes.string,
	accessToken: PropTypes.string,
	isEditAllowed: PropTypes.bool,
}

export default SpecialPermDialog
