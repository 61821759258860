import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import productMatching from '../../images/PM.gif'
import { hideLoader, showLoader } from '../../services/loader/actions'
import { sisenseLogin } from '../../services/session/actions'
import { showSnackbarWithTimeout } from '../../services/snackbar/actions'

const mapStateToProps = (state) => ({
	session: state.session,
})

const mapDispatchToProps = (dispatch) => ({
	sisenseLogin: (params) => dispatch(sisenseLogin(params)),
})

const useStyles = makeStyles((theme) => ({
	wlBtn: {
		marginLeft: '-200px',
		marginTop: '85px',
		border: '3px solid rgba(61, 213, 152, 0.5)',
		color: '#000000',
	},
}))

function SisenseLogin({ sisenseLogin, session, history }) {
	const classes = useStyles()
	function getQueryVariable(variable) {
		var query = window.location.search.substring(1)
		// console.log(query, '1') //"app=article&act=news_content&aid=160990"
		var vars = query.split('&')
		// console.log(vars, '2') //[ 'app=article', 'act=news_content', 'aid=160990' ]
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=')
			// console.log(pair, '3') //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
			if (pair[0] === variable) {
				return pair[1]
			}
		}
		return false
	}
	const dispatch = useDispatch()

	// let str = window.location.pathname
	// str = str.split('/')
	// let image = ''
	// let btnText = ''
	// if (str[1] === 'pr_matching') {
	// 	image = productMatching
	// 	btnText = 'Go to Product Matching'
	// } else if (str[1] === 'ab_test') {
	// 	image = abTesting
	// 	btnText = 'Go to AB Testing'
	// }

	const redirectToHomeIfLoggedIn = () => {
		if (session.isAuthenticated) {
			// const configLanding = this.props.session.user?.userDetails?.config_landing
			// switch (configLanding) {
			// 	case ROLE_MODULES.DISCOVERX:
			// 		this.props.history.push('/discoverx')
			// 		return
			// default:
			dispatch(hideLoader())
			// history.push(session.rediURL)
			if (session?.rediURL !== undefined) {
				window.open(session?.rediURL, '_blank')
			}
			// 	return
		}
	}

	useEffect(() => {
		redirectToHomeIfLoggedIn()
	}, [session.isAuthenticated])

	useEffect(() => {
		session.isLoading && dispatch(showLoader('Please wait'))
		!session.isLoading && dispatch(hideLoader())
		session.error && dispatch(showSnackbarWithTimeout(session.error.message, 2000))
	}, [session.isLoading, session.error])

	const onClickGo = () => {
		let params = {
			sisense_token: getQueryVariable('sisense_token'),
			dash_id: getQueryVariable('dash_id'),
			// dash_for: getQueryVariable('dash_for'),
		}
		sisenseLogin(params)
	}

	return (
		<div className='page-container'>
			<div className='image-container'>
				<img src={productMatching} alt={productMatching} className='loginBG' />
			</div>

			<div className='form-container'>
				<Button onClick={onClickGo} className={classes.wlBtn} variant='outlined' color='secondary' disableElevation>
					Welcome to Flix Lab
				</Button>
			</div>
		</div>
	)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SisenseLogin))
