import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Button, Typography, Box, Icon, Fab, Chip, IconButton, Radio, RadioGroup, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Card from './Card'
import { PRODUCT_MATCH_TYPE } from '../../../constants'
import SelectBox from '../../../components/SelectBox'
import classnames from 'classnames'
import api from '../../../services/aiqa/bb/api'
import Api from '../../../services/aiqa/master/api'
import ApiPR from '../../../services/aiqa/PrMatch/api'
import throttle from 'lodash/throttle'
import _ from 'lodash'
import { showSnackbarWithTimeout } from '../../../services/snackbar/actions'
import { hideLoader, showLoader } from '../../../services/loader/actions'
import { data } from './data'
import NoImage from './No.png'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import ReportDialog from './ReportDialog'

const MENU_WIDTH = 320

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	contentWrapper: {
		width: '100%',
		// textAlign: 'center',
	},
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '54px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	fabUpdate: {
		// position: 'fixed',
		// zIndex: 100,
		// // bottom: '84px',
		// right: '354px',
		// bottom: '540px',
		position: 'absolute',
		zIndex: 100,
		// bottom: '84px',
		right: '543px',
		bottom: '448px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	menuContainer: {
		width: 0,
		opacity: 0,
		height: '100%',
		boxSizing: 'border-box',
		transition: 'width 200ms',
		[theme.breakpoints.down('xs')]: {
			width: `0.00001vw`,
		},
		position: 'fixed',
		zIndex: 100,
		// bottom: '4px',
		right: '7px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	openMenu: {
		width: MENU_WIDTH,
		opacity: 1,
		// zIndex: 1,
		height: '100%',
		boxShadow: `0px 0px 4px 0px ${theme.palette.almostBlack[400]}`,
		background: 'white',
		// position: 'fixed',
		// zIndex: 100,
		bottom: '-64px',
		// right: '24px',
		// [theme.breakpoints.down('sm')]: {
		// 	display: 'inline-flex',
		// },
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	// gridWrapper: {
	// 	flexGrow: 1,
	// 	overflow: 'hidden',
	// 	padding: theme.spacing(0, 3),
	// 	maxWidth: 800,
	// },
	textField: {
		// maxWidth: 400,
		margin: `${theme.spacing(1)}px auto`,
		// padding: theme.spacing(2),
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		'& .MuiGrid-spacing-xs-3': {
			margin: 0,
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paperHead: {
		// margin: '16px auto',
		padding: theme.spacing(1),
	},
	paper: {
		width: '100%',
		height: '740px',
		margin: '16px auto',
		background: '#f5f5f8',
		padding: theme.spacing(2),
	},
	paperTitle: {
		// textAlign: 'initial',
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
	actionButton: {
		textAlign: 'center',
		fontWeight: 'bold',
		// textDecoration: 'underline',
	},
	key: {
		fontWeight: 'bold',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	table: {
		'& .MuiTableRow-head': {
			background: 'rgba(3, 169, 244, 0.44)',
		},
	},
	mobileBoardContainerOpened: {
		[theme.breakpoints.down('xs')]: {
			'&::after': {
				position: 'absolute',
				width: '100%',
				height: '100%',
				content: '" "',
				background: 'rgba(0,0,0,0.5)',
				zIndex: 1,
			},
		},
		paddingBottom: theme.spacing(0),
	},
	searchBox: {
		marginTop: '15px',
	},
	totalLength: {
		marginTop: '20px',
		textAlign: 'center',
	},
	prevNext: {
		marginTop: '11px',
	},
	tooltip: {
		backgroundColor: 'black',
		color: 'white',
	},
}))

export default function CenteredGrid() {
	const classes = useStyles()
	const [filterMenuProps, setMenuProps] = useState({ isOpen: false, page: 'menulist', isButtonVisible: true })
	const dispatch = useDispatch()
	const authToken = useSelector((state) => _.get(state, 'session.authToken'))
	const [filter, setFilter] = useState({ searchText: '', filterData: {} })
	const { filterData } = filter
	const isFilterActive = useMemo(() => !_.isEmpty(_.omitBy(filterData, _.isEmpty)), [filterData])
	const [parentBrand, setparentBrand] = useState([])
	const [parentCat, setparentCat] = useState([])
	const [subCat, setSubCat] = useState([])
	const [matchmissData, setmatchmissData] = useState([])
	const [currentPage, setCurrentPage] = React.useState(0)
	const [offset, setOffset] = React.useState(0)
	const [limit, setLimit] = React.useState(6)
	const [count, setCount] = React.useState(0)
	const [value, setValue] = React.useState('')
	const [matchData, setmatchData] = React.useState('')
	const [enupdate, setEnupdate] = React.useState(false)
	const [matchtype, setMatchtype] = React.useState('OEM')
	const [summary, setSummary] = useState({})
	const [preStatus, setpreStatus] = React.useState({
		nlp_brand_qa_status: 'null',
		nlp_category_qa_status: 'null',
		cv_category_qa_status: 'null',
	})
	const [preSugg, setpreSugg] = React.useState({
		sugg_brand_id: '',
		sugg_brand_name: '',
		sugg_parent_cat_id: '',
		sugg_parent_cat_name: '',
		sugg_sub_cat_id: '',
		sugg_sub_cat_name: '',
	})
	// const [enableSave, setenableSave] = React.useState(false)

	const { nlp_brand_qa_status, nlp_category_qa_status, cv_category_qa_status } = preStatus
	const { sugg_brand_id, sugg_brand_name, sugg_parent_cat_id, sugg_parent_cat_name, sugg_sub_cat_id, sugg_sub_cat_name } = preSugg

	const updateClick = () => {
		dispatch(showLoader('Please wait, getting details'))
		ApiPR.fetchPrediction(
			(res) => {
				let resp = res.data.data
				setmatchmissData(resp)
				dispatch(hideLoader())
			},
			(err) => {
				dispatch(hideLoader())
				dispatch(showSnackbarWithTimeout(err.response.data.message, 2000))
			}
		)
	}

	// useEffect(() => {
	// 	api.fetchSummary(
	// 		(res) => {
	// 			let resp = res.data.data
	// 			setSummary(resp)
	// 			// dispatch(hideLoader())
	// 		},
	// 		(err) => {}
	// 	)
	// }, [])

	useEffect(() => {
		if (_.isEmpty(matchmissData)) {
			updateClick()
		}
	}, [matchmissData])

	useEffect(() => {
		let pay_values = {
			// cat_id: '',
		}
		ApiPR.GetParentPRCategoryList(
			pay_values,
			'',
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', [])

				let res = _.map(testList, (list) => ({
					id: _.toString(list.cat_id),
					// id: list.category,
					name: list.category,
				}))
				res.splice(0, 0, { id: 'null', name: '--Others--' })
				setparentCat(res)
			},
			(err) => {
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
		Api.GetParentBBCategoryList(
			pay_values,
			'parent_cat',
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', [])

				let res = _.map(testList, (list) => ({
					id: _.toString(list.cat_id),
					// id: list.category,
					name: list.category,
				}))
				res.splice(0, 0, { id: 'null', name: '--Others--' })
				setSubCat(res)
			},
			(err) => {
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
		Api.GetParentBrandList(
			pay_values,
			'parent_brand',
			(resp) => {
				// const totalCount = _.get(resp, 'data.data.total_count', 0)
				const testList = _.get(resp, 'data.data', [])

				let res = _.map(testList, (list) => ({
					id: _.toString(list.brand_id),
					// id: list.brand,
					name: list.brand,
				}))
				res.splice(0, 0, { id: 'null', name: '--Others--' })
				setparentBrand(res)
			},
			(err) => {
				// if (!isAxiosCancelError(err) && isMounted.current) {
				// setUsersTblState((prevState) => ({
				// 	...prevState,
				// 	loading: false,
				// 	totalCount: 0,
				// 	testList: [],
				// }))
				// }
			}
		)
	}, [])

	//Enable Save&Next
	let enableSave = true

	if (!_.isEqual(matchmissData?.brand_target, 'nan') && nlp_brand_qa_status === 'null') {
		enableSave = false
	}
	if (nlp_brand_qa_status === 'false' && sugg_brand_name === '') {
		enableSave = false
	}
	if (!_.isEqual(matchmissData?.target_parent_category, 'nan') && nlp_brand_qa_status === 'null') {
		enableSave = false
	}
	if (!_.isEqual(matchmissData?.target_parent_category, 'nan') && nlp_brand_qa_status === 'false' && sugg_parent_cat_name === '') {
		enableSave = false
	}
	if (!_.isEqual(matchmissData?.target_sub_category, 'nan') && nlp_brand_qa_status === 'null') {
		enableSave = false
	}
	if (!_.isEqual(matchmissData?.target_sub_category, 'nan') && nlp_brand_qa_status === 'false' && sugg_sub_cat_name === '') {
		enableSave = false
	}

	// const renderAfterCalled = useRef(false)

	const onClipboardClick = (value, content) => {
		navigator.clipboard.writeText(value)
		dispatch(showSnackbarWithTimeout(`${content} Copied Into Clipboard`, 2000))
	}

	const preStatusChange = (key, value) => {
		setpreStatus((prev) => ({
			...prev,
			[key]: value,
		}))
	}
	const preSuggChange = (key, value) => {
		setpreSugg((prev) => ({
			...prev,
			[key]: value,
		}))
	}

	const onsameClick = () => {
		setpreSugg((prev) => ({
			...prev,
			sugg_sub_cat_name: sugg_parent_cat_name,
		}))
	}

	const onSkipClick = () => {
		let pay_values = {
			qa_for: 'skip',
			ai_pm_result_id: matchmissData?.ai_pm_result_id,
		}
		ApiPR.skipSavePrediction(
			matchmissData?.qc_allot_id,
			pay_values,
			(res) => {
				setpreStatus({
					nlp_brand_qa_status: 'null',
					nlp_category_qa_status: 'null',
					cv_category_qa_status: 'null',
				})
				setpreSugg({
					sugg_brand_id: '',
					sugg_brand_name: '',
					sugg_parent_cat_id: '',
					sugg_parent_cat_name: '',
					sugg_sub_cat_id: '',
					sugg_sub_cat_name: '',
				})
				updateClick()
			},
			(err) => {}
		)
	}

	var str2bool = (value) => {
		if (value && typeof value === 'string') {
			if (value.toLowerCase() === 'true') return true
			if (value.toLowerCase() === 'false') return false
			if (value.toLowerCase() === 'null') return null
		}
		return value
	}

	var num2bool = (value) => {
		// if (value && typeof value === 'integer') {
		if (value === 0) return 'false'
		if (value === 1) return 'true'
		if (value === null) return 'null'
		// }
		// return value
	}

	var brand2value = (value) => {
		// if (value && typeof value === 'integer') {
		let findBrand = _.find(parentBrand, { id: _.toString(value) })
		if (value === null) return ''
		else if (value === 'others') return { id: 'null', name: '--Others--' }
		else return findBrand?.id
		// }
		// return value
	}

	var cat2value = (value) => {
		// if (value && typeof value === 'integer') {
		let findCat = _.find(parentCat, { id: _.toString(value) })
		if (value === null) return ''
		else if (value === 'others') return { id: 'null', name: '--Others--' }
		else return findCat?.id
		// }
		// return value
	}

	const onSaveClick = () => {
		// let brandnlpData = sugg_brand_name !== '' && _.find(parentBrand, { id: sugg_brand_name })
		// let brandnlpObj = { brand_id: brandnlpData.id, brand_name: brandnlpData.name }
		// let catnlpData = sugg_parent_cat_name !== '' && _.find(parentCat, { id: sugg_parent_cat_name })
		// let catnlpObj = { cat_id: catnlpData.id, cat_name: catnlpData.name }
		let pay_values = {
			qa_for: 'save',
			sugg_brand_id,
			sugg_brand_name,
			sugg_parent_cat_id,
			sugg_parent_cat_name,
			sugg_sub_cat_id,
			sugg_sub_cat_name,
			prod_match_type: matchtype,
			ai_pm_result_id: matchmissData?.ai_pm_result_id,
		}
		ApiPR.skipSavePrediction(
			matchmissData?.qc_allot_id,
			pay_values,
			(res) => {
				setpreStatus({
					nlp_brand_qa_status: 'null',
					nlp_category_qa_status: 'null',
					cv_category_qa_status: 'null',
				})
				setpreSugg({
					sugg_brand_id: '',
					sugg_brand_name: '',
					sugg_parent_cat_id: '',
					sugg_parent_cat_name: '',
					sugg_sub_cat_id: '',
					sugg_sub_cat_name: '',
				})
				updateClick()
				// setmatchData('')
			},
			(err) => {}
		)
	}

	const onPrevClick = () => {
		dispatch(showLoader('Please wait, getting previous details'))
		ApiPR.prevPrediction(
			matchmissData?.qc_allot_id,
			(res) => {
				let resp = res.data.data
				let qa = resp.qa_result
				let status = resp?.mapping_brand_id !== null || resp?.mapping_parent_cat_id !== null || resp?.mapping_sub_cat_id !== null
				setmatchmissData(resp)
				setMatchtype(resp?.prod_match_type)
				setpreStatus({
					nlp_brand_qa_status: status === true ? 'false' : 'null',
					nlp_category_qa_status: 'null',
					cv_category_qa_status: 'null',
				})
				setpreSugg({
					sugg_brand_id: _.toString(resp?.mapping_brand_id),
					// sugg_brand_name: '',
					sugg_parent_cat_id: _.toString(resp?.mapping_parent_cat_id),
					// sugg_parent_cat_name: '',
					sugg_sub_cat_id: _.toString(resp?.mapping_sub_cat_id),
					// sugg_sub_cat_name: '',
				})
				dispatch(hideLoader())
				// setmatchData('')
			},
			(err) => {
				dispatch(hideLoader())
			}
		)
	}

	let info = (
		<p>
			Instructions : <br />
			<br />
			1) Select the right status for Brand and Categories if the shown value. <br /> <br />
			2) Select True if the brand and category are right <br />
			<br />
			3) Select False if the brand and category are wrong, then select the right brand name and category name from the suggested brand, category
			field. <br />
			<br />
		</p>
	)

	return (
		<div
			className={classnames(classes.boardContainer, {
				[classes.mobileBoardContainerOpened]: filterMenuProps.isOpen,
			})}
		>
			<Box className={classes.contentWrapper} component='div'>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={6}>
						<Paper className={classes.paper}>
							<Grid container>
								<Grid item xs={12} lg={12} className={classes.textField} style={{ marginBottom: '25px' }}>
									<Typography className={classes.paperTitle} variant='body1'>
										Product Matching QA (Source Matchmiss Product):
									</Typography>
								</Grid>
								<Grid container item xs={12} lg={8} className={classes.textField}>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Product Title:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={7} className={classes.textField}>
										<Tooltip
											title={!_.isEmpty(matchmissData?.source_ptitle) ? matchmissData?.source_ptitle : '-'}
											classes={{ tooltip: classes.tooltip }}
										>
											<Typography noWrap className={classes.title} variant='body1'>
												{!_.isEmpty(matchmissData?.source_ptitle) ? matchmissData?.source_ptitle : '-'}
											</Typography>
										</Tooltip>
									</Grid>
									<Grid item xs={12} lg={1} className={classes.textField}>
										{!_.isEmpty(matchmissData?.source_ptitle) && (
											<Icon fontSize='small' onClick={() => onClipboardClick(matchmissData?.source_ptitle, 'Source Product Title')}>
												content_copy
											</Icon>
										)}
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Language:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.lang) ? matchmissData?.lang : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											MPN:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.source_mpn) && !_.isEqual(matchmissData?.source_mpn, 'nan') ? matchmissData?.source_mpn : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											EAN:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.source_ean) && !_.isEqual(matchmissData?.source_ean, 'nan') ? matchmissData?.source_ean : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Distributor ID:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{matchmissData?.source_distributor_id}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Price:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.price_gbp) ? matchmissData?.price_gbp : '-'}
										</Typography>
									</Grid>

									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Page URL:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Tooltip
											title={!_.isEmpty(matchmissData?.source_page_name) ? matchmissData?.source_page_name : '-'}
											classes={{ tooltip: classes.tooltip }}
										>
											<Typography noWrap className={classes.title} variant='body1'>
												<a style={{ textDecoration: 'none' }} target='_blank' href={matchmissData?.source_page_name}>
													{!_.isEmpty(matchmissData?.source_page_name) ? matchmissData?.source_page_name : '-'}
												</a>
											</Typography>
										</Tooltip>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Impression:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.source_page_rank) ? matchmissData?.source_page_rank : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={6} className={classes.textField} style={{ marginTop: '35px' }}>
										<Typography className={classes.key} variant='body1'>
											Brand:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={6} className={classes.textField} style={{ marginTop: '35px' }}>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.source_brand) && !_.isEqual(matchmissData?.source_brand, 'nan') ? matchmissData?.source_brand : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={6} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Parent Category:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={6} className={classes.textField}>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.source_parent_category) && !_.isEqual(matchmissData?.source_parent_category, 'nan')
												? matchmissData?.source_parent_category
												: '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={6} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Sub Category:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={6} className={classes.textField}>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.source_sub_category) && !_.isEqual(matchmissData?.source_sub_category, 'nan')
												? matchmissData?.source_sub_category
												: '-'}
										</Typography>
									</Grid>
								</Grid>

								<Grid container item xs={12} lg={4} className={classes.textField}>
									<Grid item xs={12} lg={6}>
										<img src={!_.isEmpty(matchmissData?.source_img) ? matchmissData?.source_img : NoImage} width={180} height={180} />
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Paper className={classes.paper}>
							<Grid container>
								<Grid item xs={12} lg={12} className={classes.textField} style={{ marginBottom: '25px' }}>
									<Typography className={classes.paperTitle} variant='body1'>
										AI Prediction Results (Target):
									</Typography>
								</Grid>
								<Grid container item xs={12} lg={8} className={classes.textField}>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Product Title:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={7} className={classes.textField}>
										<Tooltip
											title={!_.isEmpty(matchmissData?.title_target) ? matchmissData?.title_target : '-'}
											classes={{ tooltip: classes.tooltip }}
										>
											<Typography noWrap className={classes.title} variant='body1'>
												{!_.isEmpty(matchmissData?.title_target) ? matchmissData?.title_target : '-'}
											</Typography>
										</Tooltip>
									</Grid>
									<Grid item xs={12} lg={1} className={classes.textField}>
										{!_.isEmpty(matchmissData?.title_target) && (
											<Icon fontSize='small' onClick={() => onClipboardClick(matchmissData?.title_target, 'Target Product Title')}>
												content_copy
											</Icon>
										)}
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Language:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.lang) ? matchmissData?.lang : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											MPN:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.mpn_target) && !_.isEqual(matchmissData?.mpn_target, 'nan') ? matchmissData?.mpn_target : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											EAN:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.ean_target) && !_.isEqual(matchmissData?.ean_target, 'nan') ? matchmissData?.ean_target : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Product ID:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.product_id) ? matchmissData?.product_id : '-'}
										</Typography>
									</Grid>
									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											In Page:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Typography className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.status_inpage) ? matchmissData?.status_inpage : '-'}
										</Typography>
									</Grid>

									<Grid item xs={12} lg={4} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											In Page Link:
										</Typography>
									</Grid>
									<Grid item xs={12} lg={8} className={classes.textField}>
										<Tooltip title={!_.isEmpty(matchmissData?.inpage_link) ? matchmissData?.inpage_link : '-'} classes={{ tooltip: classes.tooltip }}>
											<Typography noWrap className={classes.title} variant='body1'>
												<a style={{ textDecoration: 'none' }} target='_blank' href={matchmissData?.inpage_link}>
													<Icon>link</Icon>
												</a>
											</Typography>
										</Tooltip>
									</Grid>
								</Grid>

								<Grid container item xs={12} lg={4} className={classes.textField}>
									<Grid item xs={12} lg={6}>
										<img src={!_.isEmpty(matchmissData?.aipr_img) ? matchmissData?.aipr_img : NoImage} width={180} height={180} />
									</Grid>
								</Grid>
							</Grid>

							<Grid container style={{ marginBottom: '35px' }}>
								<Grid container item xs={12} lg={12}>
									<Grid item xs={12} lg={6} className={classes.textField} style={{ marginTop: '17px' }}>
										<RadioGroup
											row
											aria-label='position'
											// name='position'
											value={nlp_brand_qa_status}
											onChange={(e) => preStatusChange('nlp_brand_qa_status', e.target.value)}
										>
											<FormControlLabel
												value='true'
												classes={{ label: classes.switchLabel }}
												// disabled={_.isEmpty(matchmissData?.aipr_predicted_brand_nlp)}
												control={<Radio size='small' color='primary' />}
												label='True'
												labelPlacement='end'
											/>
											<FormControlLabel
												value='null'
												classes={{ label: classes.switchLabel }}
												// disabled={_.isEmpty(matchmissData?.aipr_predicted_brand_nlp)}
												control={<Radio size='small' color='primary' />}
												label='N/A'
												labelPlacement='end'
											/>
											<FormControlLabel
												value='false'
												classes={{ label: classes.switchLabel }}
												// disabled={_.isEmpty(matchmissData?.aipr_predicted_brand_nlp)}
												control={<Radio size='small' color='primary' />}
												label='False'
												labelPlacement='end'
											/>
										</RadioGroup>
									</Grid>
									<Grid item xs={12} lg={6}>
										<SelectBox
											label='Match Type'
											name='test_type'
											value={matchtype}
											onChange={(e) => setMatchtype(e)}
											options={_.values(PRODUCT_MATCH_TYPE)}
										></SelectBox>
									</Grid>
								</Grid>
								<Grid container item xs={12} lg={6}>
									<Grid item xs={12} lg={11} className={classes.textField} style={{ marginTop: '20px' }}>
										<Typography className={classes.key} variant='body1'>
											Brand:
										</Typography>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.brand_target) && !_.isEqual(matchmissData?.brand_target, 'nan') ? matchmissData?.brand_target : '-'}
										</Typography>
									</Grid>
									{/* <Grid item xs={12} lg={6} className={classes.textField} style={{ marginTop: '40px' }}>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.brand_target) && !_.isEqual(matchmissData?.brand_target, 'nan') ? matchmissData?.source_brand : '-'}
										</Typography>
									</Grid> */}
									<Grid item xs={12} lg={11} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Parent Category:
										</Typography>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.target_parent_category) && !_.isEqual(matchmissData?.target_parent_category, 'nan')
												? matchmissData?.target_parent_category
												: '-'}
										</Typography>
									</Grid>
									{/* <Grid item xs={12} lg={6} className={classes.textField}>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.target_parent_category) && !_.isEqual(matchmissData?.target_parent_category, 'nan')
												? matchmissData?.target_parent_category
												: '-'}
										</Typography>
									</Grid> */}
									<Grid item xs={12} lg={11} className={classes.textField}>
										<Typography className={classes.key} variant='body1'>
											Sub Category:
										</Typography>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.target_sub_category) && !_.isEqual(matchmissData?.target_sub_category, 'nan')
												? matchmissData?.target_sub_category
												: '-'}
										</Typography>
									</Grid>
									{/* <Grid item xs={12} lg={6} className={classes.textField}>
										<Typography noWrap className={classes.title} variant='body1'>
											{!_.isEmpty(matchmissData?.target_sub_category) && !_.isEqual(matchmissData?.target_sub_category, 'nan')
												? matchmissData?.target_sub_category
												: '-'}
										</Typography>
									</Grid> */}
								</Grid>
								<Grid container item xs={12} lg={6} className={classes.textField}>
									<Grid item xs={12} lg={12}>
										<SelectBox
											label='Sugg. Brand'
											name='test_type'
											value={sugg_brand_id}
											disabled={
												nlp_brand_qa_status === 'true' ||
												nlp_brand_qa_status === 'null' ||
												_.isEmpty(matchmissData?.brand_target) ||
												_.isEqual(matchmissData?.brand_target, 'nan')
											}
											onChange={(e) => preSuggChange('sugg_brand_id', e)}
											getSelectedLabel={(e) => preSuggChange('sugg_brand_name', e)}
											options={parentBrand}
										></SelectBox>
									</Grid>
									<Grid item xs={12} lg={12}>
										<SelectBox
											label='Sugg. Parent Category'
											name='test_type'
											value={sugg_parent_cat_id}
											disabled={
												nlp_brand_qa_status === 'true' ||
												nlp_brand_qa_status === 'null' ||
												_.isEmpty(matchmissData?.target_parent_category) ||
												_.isEqual(matchmissData?.target_parent_category, 'nan')
											}
											onChange={(e) => preSuggChange('sugg_parent_cat_id', e)}
											getSelectedLabel={(e) => preSuggChange('sugg_parent_cat_name', e)}
											options={parentCat}
										></SelectBox>
									</Grid>
									<Grid item xs={12} lg={12}>
										<SelectBox
											label='Sugg. Sub Category'
											name='test_type'
											value={sugg_sub_cat_id}
											disabled={
												nlp_brand_qa_status === 'true' ||
												nlp_brand_qa_status === 'null' ||
												_.isEmpty(matchmissData?.target_sub_category) ||
												_.isEqual(matchmissData?.target_sub_category, 'nan')
											}
											onChange={(e) => preSuggChange('sugg_sub_cat_id', e)}
											getSelectedLabel={(e) => preSuggChange('sugg_sub_cat_name', e)}
											options={subCat}
										></SelectBox>
									</Grid>
								</Grid>
							</Grid>
							<Grid container spacing={3} className={classes.formControl}>
								<Grid item xs={12} lg={4} className={classes.actionButton}>
									<Button style={{ fontWeight: 'bold' }} color='primary' onClick={onSkipClick}>
										Skip
									</Button>
								</Grid>
								<Grid item xs={12} lg={4} className={classes.actionButton}>
									<Button style={{ fontWeight: 'bold' }} color='primary' onClick={onPrevClick}>
										Previous
									</Button>
								</Grid>
								<Grid item xs={12} lg={4} className={classes.actionButton}>
									<Button style={{ fontWeight: 'bold' }} color='primary' onClick={onSaveClick} disabled={enableSave === false}>
										Save & Next
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</div>
	)
}
