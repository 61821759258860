export const data = [
	{
		value: '0-1000',
		title: 'Less than 1000',
	},
	{
		value: '1000-10000',

		title: '1000-10000',
	},
	{
		value: '10000-100000',

		title: '10000-100000',
	},
	{
		value: '100000-500000',

		title: '100000-500000',
	},
	{
		value: '>500000',

		title: '500000 & above',
	},
]
