import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 400,
		flexGrow: 1,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		height: 50,
		paddingLeft: theme.spacing(4),
		backgroundColor: theme.palette.background.default,
	},
	img: {
		height: 255,
		display: 'block',
		maxWidth: 400,
		overflow: 'hidden',
		width: '100%',
	},
}))

function SwipeableTextMobileStepper({ images }) {
	const classes = useStyles()
	const theme = useTheme()
	const [activeStep, setActiveStep] = React.useState(0)
	const [img, setImg] = React.useState([])
	const maxSteps = images.length

	React.useEffect(() => {
		setImg(images)
	}, [images])

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleStepChange = (step) => {
		setActiveStep(step)
	}

	return (
		<div className={classes.root}>
			<AutoPlaySwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeStep}
				onChangeIndex={handleStepChange}
				enableMouseEvents
			>
				{img.map((step, index) => (
					<div key={index}>{Math.abs(activeStep - index) <= 2 ? <img className={classes.img} src={step} alt={step} /> : null}</div>
				))}
			</AutoPlaySwipeableViews>
			<MobileStepper
				steps={maxSteps}
				style={{ padding: '2px' }}
				position='static'
				variant='text'
				activeStep={activeStep}
				nextButton={
					<Button size='small' style={{ minWidth: '40px' }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
						{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
					</Button>
				}
				backButton={
					<Button size='small' style={{ minWidth: '40px' }} onClick={handleBack} disabled={activeStep === 0}>
						{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
					</Button>
				}
			/>
		</div>
	)
}

export default SwipeableTextMobileStepper
