import { API_ENDPOINTS } from '../../../constants'
import Axios from 'axios'
import _ from 'lodash'

const api = {
	fetchSummary: (successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_SUMMARY_PREDICT, { headers: { isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchPrediction: (successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_PR__PREDICTION_QA, { headers: { isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	skipSavePrediction: (id, data, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.PR_SKIP_SAVE_PREDICT,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	savePrediction: (id, data, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.SKIP_SAVE_PREDICT,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	prevPrediction: (id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.PR_PREV_PREDICT, {
			headers: { isAuthRequired: true, path: { id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchBatch: (batch, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_PR_BATCH, { headers: { isAuthRequired: true, path: { batch } } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	putbatch: (data, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.PUT_PR__BATCH,
			{ ...data },
			{
				headers: { isAuthRequired: true },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetDistList: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.GET_DIST_LIST, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	ErrorRpt: (data, successCallback, errorCallback) => {
		Axios.post(API_ENDPOINTS.ERROR_REPORT, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchDistForm: (id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_DIST_FORM, {
			headers: { isAuthRequired: true, path: { id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	putDistForm: (data, id, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.GET_DIST_FORM,
			{ ...data },
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetBucDetails: (id, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_PR_BUC_DETAILS, {
			headers: { isAuthRequired: true, path: { id } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	PutBucDetails: (id, successCallback, errorCallback) => {
		Axios.put(
			API_ENDPOINTS.GET_PR_BUC_DETAILS,
			{},
			{
				headers: { isAuthRequired: true, path: { id } },
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetRptPivot: (report, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.GET_RPT_PIVOT, {
			headers: { isAuthRequired: true, path: { report } },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	PrdExport: (data, exp, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.PRD_EXPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { exp } }, responseType: 'blob' }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	DistExport: (data, exp, successCallback, errorCallback) => {
		Axios.post(
			API_ENDPOINTS.DIST_EXPORT,
			{ ...data },
			{ headers: { 'Content-Type': 'application/json', isAuthRequired: true, path: { exp } }, responseType: 'blob' }
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	GetParentPRCategoryList: (data, cat, successCallback, errorCallback) => {
		Axios.get(API_ENDPOINTS.PARENT_PR_CAT_LIST, {
			headers: { isAuthRequired: true },
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
}

export default api
