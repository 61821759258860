import React from 'react'
import { Tabs, Tab, Icon, Box, Grid, Paper, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import CreateTest from './CreateTest'
import Home from './Home'
import ViewTest from './ViewTest'
import Dashboard from './Dashboard'
import Header from './Header'
import AB from '../../images/Logo-AB.svg'
import ABIcon from '../../images/ABIcon.png'
import './index.css'
import { NavLink } from 'react-router-dom'

const MODULES = {
	DASH: 'home',
	TEST: 'create_test',
	EDIT: 'edit_test',
	VIEW: 'view_test',
}

const useStyles = makeStyles((theme) => ({
	tabs: {
		margin: '16px auto',
		minHeight: 'unset',
		boxSizing: 'border-box',
		height: '38px',
	},
	tabsContainer: {
		'& > button:first-child': {
			borderLeft: `1px solid ${theme.palette.primary.main}86!important`,
			borderTop: `1px solid ${theme.palette.primary.main}86`,
			borderBottom: `1px solid ${theme.palette.primary.main}86`,
			borderRight: '0px',
		},
		'& > button:last-child': {
			borderRight: `1px solid ${theme.palette.primary.main}86`,
			borderTop: `1px solid ${theme.palette.primary.main}86`,
			borderBottom: `1px solid ${theme.palette.primary.main}86`,
			borderLeft: '0px',
		},
		'& > button:not(:last-child):not(:first-child)': {
			border: `1px solid ${theme.palette.primary.main}86`,
		},
	},
	tabsIndicator: {
		display: 'none',
	},
	tabRoot: {
		letterSpacing: '2px',
		textTransform: 'none',
		minHeight: 'unset',
		minWidth: '100px',
		width: 'auto',
		'&:hover:not($tabSelected)': {
			// color:theme.palette.background.paper,
			background: `${theme.palette.primary.light}52`,
		},
	},
	tabIco: {
		verticalAlign: 'top',
	},
	tabPanel: {
		display: 'block',
		width: '100%',
	},
	tabSelected: {
		background: theme.palette.primary.dark,
		color: theme.palette.common.white,
		'&:hover': {
			color: theme.palette.common.white,
		},
	},
	paper: {
		width: '237px',
		height: '916px',
		// background: 'green',
		padding: '16px 20px 8px',
		borderRadius: '10px',
	},
	gradientColor: {
		background: 'linear-gradient(180deg, rgb(131 231 172 / 65%) 0%, rgba(66, 232, 134, 0) 94.28%)',
		// opacity: '0.4',
		height: '301.24px',
	},
	noFluid: {
		maxWidth: '1400px',
		margin: '0px auto',
	},
	pageContent: {
		position: 'relative',
		paddingTop: '20px',
	},
	button: {
		'&.active': {
			background: '#f6f9fc',
		},
		'& .MuiButton-label': {
			justifyContent: 'start',
		},
	},
}))

const getModuleIdx = (moduleName) => {
	switch (moduleName) {
		case MODULES.DASH:
			return 0
		case MODULES.TEST:
			return 1
		case MODULES.EDIT:
			return 2
		case MODULES.VIEW:
			return 3
		default:
			return -1
	}
}

const AbTestTool = ({ history, match }) => {
	const module = _.get(match, 'params.module')

	const activeTabIdx = getModuleIdx(module)

	const classes = useStyles()

	//IF MODULE IS EMPTY
	if (!module) return null

	return (
		<div className={classes.gradientColor}>
			<div className={classnames(classes.noFluid, classes.pageContent)}>
				<Grid container>
					<Grid item xs={2}>
						<Paper className={classes.paper}>
							<img src={AB} alt={AB} className={classes.img} />
							<Button
								className={classes.button}
								component={NavLink}
								to='/ab_test_tool/home'
								style={{
									display: 'flex',
									// alignItems: 'center',
									flexWrap: 'wrap',
									marginTop: '10px',
									textTransform: 'none',
									fontFamily: 'FS-Jack',
									fontStyle: 'normal',
									fontWeight: '400',
									fontSize: '16px',
									letterSpacing: '0em',
								}}
							>
								<Icon fontSize='small'>home</Icon>
								<span style={{ marginLeft: '10px' }}>A/B Home</span>
							</Button>
							<Button
								className={classes.button}
								component={NavLink}
								to='/ab_test_tool/create_test'
								style={{
									display: 'flex',
									// alignItems: 'center',
									flexWrap: 'wrap',
									textTransform: 'none',
									fontFamily: 'FS-Jack',
									fontStyle: 'normal',
									fontWeight: '400',
									fontSize: '16px',
									letterSpacing: '0em',
								}}
							>
								<img src={ABIcon} alt={ABIcon} style={{ width: '20px', height: '20px' }} />
								<span style={{ marginLeft: '10px' }}>Create New Test</span>
							</Button>
						</Paper>
					</Grid>
					<Grid item xs={10}>
						<Header module={module} />
						{/* <Dashboard history={history} match={match} /> */}
						<TabPanel value={activeTabIdx} index={0}>
							<Home history={history} match={match} />
						</TabPanel>
						<TabPanel value={activeTabIdx} index={1}>
							<CreateTest history={history} match={match} />
						</TabPanel>
						<TabPanel value={activeTabIdx} index={2}>
							<CreateTest history={history} match={match} />
						</TabPanel>
						<TabPanel value={activeTabIdx} index={3}>
							<ViewTest history={history} match={match} />
						</TabPanel>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
			{value === index && (
				<Box marginLeft={1} marginRight={1}>
					{children}
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

export default AbTestTool
