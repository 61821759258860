import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import Api from '../../../../services/aiqa/dimension/api'
import Axios from 'axios'

const CancelToken = Axios.CancelToken

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 350,
		maxHeight: 80,
		margin: '16px auto',
		'&:hover': {
			backgroundColor: `${theme.palette.primary.light}52`,
			cursor: 'pointer',
		},
	},
	container: {
		// paddingTop: 50,
	},
	gridWrapper: {
		padding: theme.spacing(2.5, 2),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1.5, 2),
		},
	},
	avatar: {
		width: 60,
		height: 60,
		[theme.breakpoints.down('xs')]: {
			width: 40,
			height: 40,
		},
	},
	title: {
		marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '16px',
		},
	},
	subtitle: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
		},
	},
	tabWrapper: {
		paddingTop: theme.spacing(2),
		'& .MuiTabs-scrollable': {
			display: 'flex',
			justifyContent: 'space-around',
		},
	},
	tab: {
		letterSpacing: '2px',
		fontSize: '12px',
		'& span': {
			verticalAlign: 'middle',
		},
	},
	tabIco: {
		paddingRight: theme.spacing(1),
	},
	tabPanelWrapper: {
		padding: theme.spacing(1.5, 0),
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},
	pos: {
		marginBottom: 12,
	},
}))

function Dashboard(props) {
	const classes = useStyles()
	const cancelDashLstReq = useRef(null)
	const renderAfterCalled = useRef(false)
	const [usersTblState, setUsersTblState] = useState({
		pending: 0,
		completed: 0,
	})
	const { pending, completed } = usersTblState
	const { report } = props

	const result = _.get(props.match, 'params.result')

	useEffect(() => {
		if (!renderAfterCalled.current) {
			Api.GetRptSummary(
				report,
				(resp) => {
					// const totalCount = _.get(resp, 'data.data.total_count', 0)
					const testList = _.get(resp, 'data.data', {})
					setUsersTblState((prevState) => ({
						...prevState,
						...testList,
					}))
				},
				(err) => {
					// if (!isAxiosCancelError(err) && isMounted.current) {
					// setUsersTblState((prevState) => ({
					// 	...prevState,
					// 	loading: false,
					// 	totalCount: 0,
					// 	testList: [],
					// }))
					// }
				}
			)
		}
		renderAfterCalled.current = true
	}, [])

	return (
		<div className={clsx(classes.container)}>
			<Grid container>
				<Grid item xs={6}>
					<Card className={clsx(classes.root)} style={{ color: result === 'live' && '#41e886' }}>
						<CardContent>
							<Typography component='h5' variant='h5' className='card-title'>
								Total Done - <strong>{completed}</strong>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6}>
					<Card className={clsx(classes.root)} style={{ color: result === 'pre_live' && '#41e886' }}>
						<CardContent>
							<Typography component='h5' variant='h5' className='card-title'>
								Total Pending - <strong>{pending}</strong>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</div>
	)
}

export default Dashboard
