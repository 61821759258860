export const PERMISSION_TYPE = {
	VIEW: 'view',
	CREATE: 'create',
	EDIT: 'edit',
	DELETE: 'delete',
	EXPORT: 'export',
}

export const PERMISSION_TYPE_LABEL = {
	[PERMISSION_TYPE.VIEW]: 'View',
	[PERMISSION_TYPE.CREATE]: 'Create',
	[PERMISSION_TYPE.EDIT]: 'Edit',
	[PERMISSION_TYPE.DELETE]: 'Delete',
	[PERMISSION_TYPE.EXPORT]: 'Export / Report',
}

export const ROLE_MODULES = {
	AB_TEST: 'ab_test',
	AIQA_MASTER: 'aiqa_master',
	AIQA_DIMENSION: 'aiqa_dimensions',
	AIQA_BB: 'aiqa_bb',
	AIQA_PR_MATCHING: 'aiqa_pr_matching',
	USER_MANAGEMENT: 'user_management',
	ROLE_MANAGEMENT: 'role_management',
	PRODUCT_MATCHING: 'pr_matching',
	BOT_MONITORING: 'bot_monitoring',
}

export const ROLE_MODULES_LABEL = {
	[ROLE_MODULES.AB_TEST]: 'AB Test',
	[ROLE_MODULES.AIQA_MASTER]: 'AIQA Master Data',
	[ROLE_MODULES.AIQA_DIMENSION]: 'AIQA Dimension',
	[ROLE_MODULES.AIQA_BB]: 'AIQA BB',
	[ROLE_MODULES.AIQA_PR_MATCHING]: 'AIQA PR MATCHING',
	[ROLE_MODULES.USER_MANAGEMENT]: 'User Management',
	[ROLE_MODULES.ROLE_MANAGEMENT]: 'Role Management',
	[ROLE_MODULES.PRODUCT_MATCHING]: 'Product Matching',
	[ROLE_MODULES.BOT_MONITORING]: 'Bot Monitoring',
}

export const ROLE_MODULE_CONFIG = {
	[ROLE_MODULES.AB_TEST]: [PERMISSION_TYPE.VIEW, PERMISSION_TYPE.CREATE, PERMISSION_TYPE.EDIT, PERMISSION_TYPE.DELETE, PERMISSION_TYPE.EXPORT],
	[ROLE_MODULES.AIQA_MASTER]: [PERMISSION_TYPE.VIEW, PERMISSION_TYPE.CREATE, PERMISSION_TYPE.EDIT, PERMISSION_TYPE.DELETE, PERMISSION_TYPE.EXPORT],
	[ROLE_MODULES.AIQA_DIMENSION]: [PERMISSION_TYPE.VIEW, PERMISSION_TYPE.CREATE, PERMISSION_TYPE.EDIT, PERMISSION_TYPE.DELETE, PERMISSION_TYPE.EXPORT],
	[ROLE_MODULES.AIQA_BB]: [PERMISSION_TYPE.VIEW, PERMISSION_TYPE.CREATE, PERMISSION_TYPE.EDIT, PERMISSION_TYPE.DELETE, PERMISSION_TYPE.EXPORT],
	[ROLE_MODULES.AIQA_PR_MATCHING]: [
		PERMISSION_TYPE.VIEW,
		PERMISSION_TYPE.CREATE,
		PERMISSION_TYPE.EDIT,
		PERMISSION_TYPE.DELETE,
		PERMISSION_TYPE.EXPORT,
	],
	[ROLE_MODULES.USER_MANAGEMENT]: [
		PERMISSION_TYPE.VIEW,
		PERMISSION_TYPE.CREATE,
		PERMISSION_TYPE.EDIT,
		PERMISSION_TYPE.DELETE,
		PERMISSION_TYPE.EXPORT,
	],
	[ROLE_MODULES.ROLE_MANAGEMENT]: [
		PERMISSION_TYPE.VIEW,
		PERMISSION_TYPE.CREATE,
		PERMISSION_TYPE.EDIT,
		PERMISSION_TYPE.DELETE,
		PERMISSION_TYPE.EXPORT,
	],
	[ROLE_MODULES.PRODUCT_MATCHING]: [
		PERMISSION_TYPE.VIEW,
		PERMISSION_TYPE.CREATE,
		PERMISSION_TYPE.EDIT,
		PERMISSION_TYPE.DELETE,
		PERMISSION_TYPE.EXPORT,
	],
	[ROLE_MODULES.BOT_MONITORING]: [PERMISSION_TYPE.VIEW, PERMISSION_TYPE.CREATE, PERMISSION_TYPE.EDIT, PERMISSION_TYPE.DELETE, PERMISSION_TYPE.EXPORT],
}
