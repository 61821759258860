import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'
import './styles.css'
import Table from './table'

const useStyles = makeStyles((theme) => ({
	contentWrapper: {
		width: '100%',
		textAlign: 'center',
	},
	// textField: {
	// 	marginTop: theme.spacing(2.5),
	// 	maxWidth: 300,
	// 	margin: '0 auto',
	// },
	button: {
		marginTop: theme.spacing(3.5),
	},
	successIco: {
		margin: '16px auto',
		background: theme.palette.success.main,
	},
	gridWrapper: {
		flexGrow: 1,
		overflow: 'hidden',
		padding: theme.spacing(0, 3),
		maxWidth: 1230,
	},
	textField: {
		// maxWidth: 400,
		// margin: `${theme.spacing(1)}px auto`,
		padding: theme.spacing(2),
		textAlign: 'left',
	},
	title: {
		// marginTop: 10,
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
		fontFamily: 'FS-Jack',
		fontStyle: 'normal',
	},
	tableCaption: {
		// marginTop: 10,
		textAlign: 'center',
		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	paper: {
		width: '100%',
		margin: '16px auto',
		background: '#f5f5f8',
		borderRadius: '4px',
	},
	paperTitle: {
		fontWeight: 'bold',
		fontFamily: 'FS-Jack',
		fontStyle: 'normal',
		fontSize: '18px',
	},
	key: {
		fontWeight: 'bold',
		fontFamily: 'FS-Jack',
		fontStyle: 'normal',

		[theme.breakpoints.down('xs')]: {
			fontSize: '12px',
		},
	},
	table: {
		'& .MuiTableRow-head': {
			background: 'rgba(3, 169, 244, 0.44)',
		},
	},
}))

function ChangePassword(props) {
	const classes = useStyles()
	const { values } = props

	return (
		<Box className={classes.contentWrapper} component='div'>
			<Grid container spacing={2} className={classes.gridWrapper}>
				<Grid item xs={12} lg={12} className={classes.textField}>
					<Typography className={classes.paperTitle} variant='body1'>
						Test Information
					</Typography>
				</Grid>
				<Paper className={classes.paper} elevation={0} square>
					<Grid container item xs={12} lg={12} className={classes.textField}>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.key} variant='body1'>
								Test Name:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.title} variant='body1'>
								{values?.test_name}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.key} variant='body1'>
								Test Level:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.title} variant='body1'>
								{values?.test_level}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.key} variant='body1'>
								Flix Product:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.title} variant='body1'>
								{values?.flix_product_type}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.key} variant='body1'>
								Scenerio:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.title} variant='body1'>
								{values?.test_type}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.key} variant='body1'>
								Device Type:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.title} variant='body1'>
								{_.map(values?.device_type, 'name').join('|')}
							</Typography>
						</Grid>
						{!_.isEmpty(values?.variant1) && (
							<>
								<Grid item xs={12} lg={3} className={classes.textField}>
									<Typography className={classes.key} variant='body1'>
										Variant 1:
									</Typography>
								</Grid>
								<Grid item xs={12} lg={3} className={classes.textField}>
									<Typography className={classes.title} variant='body1'>
										{values?.variant1}
									</Typography>
								</Grid>
							</>
						)}
						{!_.isEmpty(values?.variant2) && (
							<>
								<Grid item xs={12} lg={3} className={classes.textField}>
									<Typography className={classes.key} variant='body1'>
										Variant 2:
									</Typography>
								</Grid>
								<Grid item xs={12} lg={3} className={classes.textField}>
									<Typography className={classes.title} variant='body1'>
										{values?.variant2}
									</Typography>
								</Grid>
							</>
						)}
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.key} variant='body1'>
								Traffic Split Rule:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.title} variant='body1'>
								{values?.split_range}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.key} variant='body1'>
								Start Date:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.title} variant='body1'>
								{_.toString(new Date(values?.start_date).toLocaleString())}
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.key} variant='body1'>
								End Date:
							</Typography>
						</Grid>
						<Grid item xs={12} lg={3} className={classes.textField}>
							<Typography className={classes.title} variant='body1'>
								{_.toString(new Date(values?.end_date).toLocaleString())}
							</Typography>
						</Grid>
					</Grid>
				</Paper>
				<Grid item xs={12} lg={12} className={classes.textField}>
					<Typography className={classes.paperTitle} variant='body1'>
						S3 File Information
					</Typography>
				</Grid>
				<Grid item xs={12} lg={12}>
					<Table tableList={values?.s3_jsons} />
				</Grid>
			</Grid>
		</Box>
	)
}

export default ChangePassword
